import AddIcon from '@mui/icons-material/Add';
import { Card, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteExercise,
  getExercises,
  getTags,
} from '../../../../utilities/Demo/ExerciseManagement/firestore';
import ExerciseConfigurator from '../Configurator/Exercise/ExerciseConfigurator';
import DeleteButton from '../DeleteButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import _ from 'lodash';

const initialExerciseState = {
  id: null,
  createdAt: null,
  updatedAt: null,
  updatedBy: null,
  createdBy: null,
  name: null,
  view: null,
  timeThresh: null,
  global: { feedback: [] },
  states: [],
  videoUrl: null,
  introAudioUrl: null,
};

export default function Exercises({
  searchValue,
  orderByField = 'name',
  searchTermField = 'name',
  orderByDirection,
  searchTag = '',
  setTags,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [exercises, setExercises] = React.useState([]);
  const [selectedExercise, setSelectedExercise] = React.useState(null);
  const [configuratorOpen, setConfiguratorOpen] = React.useState(false);

  const removeExercise = (exerciseId) => {
    setExercises(exercises.filter((exercise) => exercise.id !== exerciseId));
  };

  const updateExercise = (exercise) => {
    setExercises(
      exercises.map((e) => {
        if (e.id === exercise.id) {
          return exercise;
        }
        return e;
      })
    );
  };

  const addExercise = (exercise) => {
    setExercises([...exercises, exercise]);
  };

  React.useEffect(() => {
    setLoading(true);
    getExercises({
      searchTerm: searchValue,
      orderByField,
      searchTermField,
      orderByDirection,
      searchTag,
    }).then((exercises) => {
      setLoading(false);
      setExercises(exercises || []);
    });
  }, [searchValue, orderByField, searchTermField, orderByDirection, searchTag]);

  useEffect(() => {
    getTags({ type: 'exercise' }).then((tags) => {
      setTags(tags?.map((tag) => tag));
    });
  }, []);

  if (configuratorOpen) {
    return (
      <ExerciseConfigurator
        exerciseConfig={selectedExercise || {}}
        updateExerciseInParent={updateExercise}
        addExerciseInParent={addExercise}
        onClose={() => setConfiguratorOpen(false)}
      />
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <h1>Exercise Overview</h1>
      <Grid container spacing={2}>
        <Grid item key={'createNew'} xs={2} sx={{ height: '100%' }}>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              padding: 2,
              border: 1,
              borderRadius: 5,
              m: 2,
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setSelectedExercise(_.cloneDeep(initialExerciseState));
                dispatch({
                  type: 'RESET-EXERCISE-CONFIG',
                });
                setConfiguratorOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
        {loading && <CircularProgress />}
        {exercises.map((exercise) => {
          return (
            <Grid item key={exercise.name} xs={12} sm={6} md={4} lg={3}>
              <Card
                onClick={() => {
                  setSelectedExercise(exercise);
                  dispatch({
                    type: 'SET-EXERCISE-CONFIG',
                    payload: exercise,
                  });
                  setConfiguratorOpen(true);
                  // navigate('/exercise_generator');
                }}
                sx={{
                  '&:hover': { cursor: 'pointer' },
                  height: '100%',
                  padding: 2,
                }}
              >
                <Typography variant={'h2'} sx={{ height: '70%' }}>
                  {exercise.name}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mb: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid item xs={2}>
                    <DeleteButton
                      onDelete={() => {
                        deleteExercise({ exerciseId: exercise.id }).then(() => {
                          removeExercise(exercise.id);
                        });
                      }}
                      deletionTarget={'Exercise' + exercise.name}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedExercise({
                          ...exercise,
                          id: null,
                          name: exercise.name + ' (copy)',
                        });
                        dispatch({
                          type: 'SET-EXERCISE-CONFIG',
                          payload: {
                            ...exercise,
                            id: null,
                            name: exercise.name + ' (copy)',
                          },
                        });
                        setConfiguratorOpen(true);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
