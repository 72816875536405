import { useEffect } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCountsInTimeCoreFeedback } from '../../../../hooks/Demo/feedbackTypes';
import { safeIterScore } from '../../../../hooks/Demo/sharedLogic';
import { loadExerciseConfigAudio } from '../../../../utilities/Demo/audioFeedback';
import { countScore } from '../../../../utilities/Demo/ExerciseManagement/Generators/scoreComputations';
import FeedbackGenerator from './FeedbackGenerator';

export default function CountsFeedbackGenerator({
  onComplete,
  config,
  showWebcam,
}) {
  const dispatch = useDispatch();
  const iterLanguage = useSelector(({ iter }) => iter.values.language);
  const {
    correctCount,
    correctCountLeft,
    correctCountRight,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    startingTime,
    raiseLevel,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh: config.timeThresh,
    loadAudio: () => loadExerciseConfigAudio(config, iterLanguage),
    maxScore: config.scoreConfig?.maxScore || 10,
  });
  cptScore.current = countScore({
    config,
    correctCount,
  });

  useEffect(() => {
    seconds.current = 0;
    correctCount.current = 0;
    startingTime.current = 0;
  }, [config]);

  const onCorrect = ({ passedOnSide }) => {
    if (passedOnSide) {
      if (passedOnSide === 'left') {
        if (
          correctCountLeft.current < config.scoreConfig.cptConfig?.maxCounts
        ) {
          correctCountLeft.current += 1;
        }
      } else if (passedOnSide === 'right') {
        if (
          correctCountRight.current < config.scoreConfig.cptConfig?.maxCounts
        ) {
          correctCountRight.current += 1;
        }
      }
      correctCount.current +=
        correctCountLeft.current + correctCountRight.current;
    } else {
      correctCount.current += 1;
    }
    updateCounts(correctCount.current);
    const score = cptScore.current();
    if (!raiseLevel(score)) {
      playAudio(audioFeedback.current?.achievement, true);
    } else {
      safeIterScore(dispatch, score);
    }
    if (correctCount.current >= config.scoreConfig.cptConfig?.maxCounts) {
      // wait for audio to finish
      setTimeout(() => {
        onComplete();
      }, 500);
    }
    setStartingTime(seconds);
  };

  return (
    <FeedbackGenerator
      updateFeedback={updateFeedback}
      config={config}
      finishedIntro={finishedIntro}
      onCorrect={onCorrect}
      playAudio={playAudio}
      audioFeedback={audioFeedback}
      showWebcam={showWebcam}
    />
  );
}
