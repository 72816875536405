const initialState = {
  values: { introState: null, skip: false },
};

export const introReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET-INTRO-FINISHED': {
      const newState = { introState: 'done' };
      return { values: { ...state.values, ...newState } };
    }
    case 'SET-INTRO-STARTED': {
      const newState = { introState: 'started' };
      return { values: { ...state.values, ...newState } };
    }
    case 'SET-INTRO-SKIPPED': {
      return { values: { ...state.values, skip: action.payload } };
    }
    case 'RESET-INTRO':
      return initialState;
    default: {
      return state;
    }
  }
};
