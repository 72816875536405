import React from 'react';
import { useNavigate } from 'react-router-dom';

const Redirect = ({ target }) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(target);
  }, [target]);

  return <div>Redirecting...</div>;
};

export default Redirect;
