const initialState = {};

export const clubsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLUBDATA': {
      console.log('CRS is::' + JSON.stringify(action));
      return { values: action.payload };
    }
    default: {
      return state;
    }
  }
};
export const ageClubsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AGECLUBDATA': {
      console.log('ACG is::' + JSON.stringify(action));
      return { values: action.payload };
    }
    default: {
      return state;
    }
  }
};
