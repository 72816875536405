//#F0ECEB
import React, { useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
// import div from "@mui/material/Container";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RankingBg from '../../components/Ranking/RankingBg';
import RankingComponent from '../../components/Ranking/RankingComponent';
import AllStudents from '../../components/Ranking/AllStudents';
import { getClubData } from '../../redux/actions/getDashboard';
import '../../components/Ranking/ranking.css';

const ClubRanking = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  // getRankings(dispatch,(a)=>a);
  // getClubData(dispatch, (a) => a);
  // const ranks = useSelector(({ ranks }) => ranks.values);
  const club = useSelector(({ club }) => club.values);
  useEffect(() => {
    console.log('Club::', club);
    if (!club) {
      getClubData(dispatch, (a) => a);
    }
  }, []);
  return (
    <div className="ranking-container">
      <HeaderComponent bgcol={'#F0ECEB'} />
      <RankingBg />
      <DrawerComponent open={open} setOpen={setOpen} />

      <div className="ranking-grid">
        {/* <div item lg={1} xs={1} md={1} sm={1}></div> */}
        {/* <div item lg={10} xs={10} md={10} sm={10}> */}
        <RankingComponent details={club} />
        <AllStudents details={club} />
        {/* </div> */}
      </div>
    </div>
  );
};

export default ClubRanking;
