import React, { useContext } from 'react';
import { pdf } from '@react-pdf/renderer';
import ResultPdf from './ResultPdf';
import Button from '@mui/material/Button';
import { sendReportEmailToPatient } from '../../../../../../service/Demo/Physio/GaitAnalysis/results';
import FirebaseAuthContext from '../../../../../../contexts/auth/FirebaseAuthContext';
import { CircularProgress } from '@mui/material';
import { emitToast } from '../../../../../../utilities/Demo/ExerciseManagement/Configurators/toasts';

export default function ResultPdfSendButton({
  cycleImage,
  pressureImage,
  pressureMapImage,
  doctorMetricsImage,
  patientInfo,
  metrics = {},
}) {
  const [loading, setLoading] = React.useState(false);
  const { user } = useContext(FirebaseAuthContext);
  const getPDFBlob = async () => {
    // Ensure MyDocument includes all necessary props and data.
    return await pdf(
      <ResultPdf
        doctorMetricsImage={doctorMetricsImage}
        cycleImage={cycleImage}
        pressureImage={pressureImage}
        pressureMapImage={pressureMapImage}
        metrics={metrics}
        patientInfo={patientInfo}
      />
    ).toBlob();
  };

  const sendPDFToAPI = async () => {
    const blob = await getPDFBlob();
    setLoading(true);
    sendReportEmailToPatient({
      patientId: patientInfo.id,
      blob,
      authToken: user.accessToken,
    })
      .then(() => {
        setLoading(false);
        emitToast({
          render: 'Sent report to patient',
          type: 'success',
        });
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        emitToast({
          render: 'Failed to send report to patient!',
          type: 'error',
        });
      });
  };

  return (
    <Button onClick={sendPDFToAPI}>
      {loading ? <CircularProgress /> : 'Send to patient'}
    </Button>
  );
}
