import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from '../../../../../firebase';

export async function getAssignments({ doctorId, patientId }) {
  try {
    const docs = await getDocs(
      query(
        collection(db, 'assignments'),
        where('doctorId', '==', doctorId),
        where('patientId', '==', patientId),
        where('deletedAt', '==', null), // filter out deleted assignments (soft delete
        orderBy('createdAt', 'desc'),
        limit(20)
      )
    );
    if (docs.empty) {
      console.log('No assignments found');
      return [];
    }
    return docs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (e) {
    console.error('Error getting assignment documents: ', e);
    return [];
  }
}

export async function addAssignment({ userId, patientId, data }) {
  data = {
    doctorId: userId,
    patientId: patientId,
    ...data,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    createdBy: userId,
    updatedBy: userId,
    deletedAt: null,
  };

  try {
    const docRef = await addDoc(collection(db, 'assignments'), data);
    console.log('Document written with ID: ', docRef.id);
    return {
      ...data,
      id: docRef.id,
    };
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function deleteAssignment({ userId, assignmentId }) {
  try {
    const docRef = doc(db, 'assignments', assignmentId);
    await updateDoc(docRef, {
      deletedAt: Timestamp.now(),
      updatedBy: userId,
    });
    console.log('Document deleted with ID: ', assignmentId);
  } catch (e) {
    console.error('Error deleting document: ', e);
  }
}

export async function updateAssignment({ userId, assignmentId, data }) {
  try {
    data = {
      ...data,
      updatedAt: Timestamp.now(),
      updatedBy: userId,
    };
    const docRef = doc(db, 'assignments', assignmentId);
    await updateDoc(docRef, data);
    console.log('Document updated with ID: ', assignmentId);
    return {
      ...data,
      id: assignmentId,
    };
  } catch (e) {
    console.error('Error updating document: ', e);
  }
}
