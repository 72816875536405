import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Card, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FirebaseAuthContext from '../../../../../contexts/auth/FirebaseAuthContext';
import { useStartSchedule } from '../../../../../hooks/Demo/exerciseManagement/startEntity';
import {
  getRoadmap,
  getSchedule,
} from '../../../../../utilities/Demo/ExerciseManagement/firestore';
import { getLastResults } from '../../../../../utilities/Demo/ExerciseManagement/Results/shared';
import _ from 'lodash';

export default function RoadmapResultOverview() {
  const navigate = useNavigate();
  const { roadmapId } = useParams();
  const [schedules, setSchedules] = React.useState([]);
  const [roadmapDoc, setRoadmapDoc] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { user } = React.useContext(FirebaseAuthContext);
  const startSchedule = useStartSchedule();

  useEffect(() => {
    setLoading(true);
    getRoadmap({ roadmapId }).then((roadmapDoc) => {
      setRoadmapDoc(roadmapDoc);
      const scheduleObjects = [];
      const promises = roadmapDoc.schedules.map(async (schedule) => {
        const scheduleDoc = await getSchedule({ scheduleId: schedule });
        const results = await getLastResults({
          exerciseIds: scheduleDoc.exercises,
          scheduleId: scheduleDoc.id,
          user,
        });
        scheduleDoc.result = _.meanBy(results, 'score');
        if (scheduleDoc) {
          scheduleObjects.push(scheduleDoc);
        }
      });
      Promise.all(promises).then(() => {
        setSchedules(scheduleObjects);
        setLoading(false);
      });
    });
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4">Roadmap Results</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Typography variant={'h4'} sx={{ padding: 0 }}>
              {roadmapDoc?.name}
            </Typography>
          </Card>
        </Grid>
        {loading && <CircularProgress sx={{ margin: 5 }} />}
        {schedules.map((schedule) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={schedule.id}>
              <Card
                sx={{
                  padding: 2,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate(
                    `/schedule_results/${schedule.id}?roadmapId=${roadmapId}`
                  )
                }
              >
                <Typography variant={'h4'} sx={{ padding: 0 }}>
                  {schedule.name}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: 2,
                    boxShadow: 5,
                    padding: 3,
                    borderRadius: 5,
                  }}
                >
                  <Typography variant={'h6'} sx={{ padding: 0 }}>
                    Score
                  </Typography>
                  <Typography variant={'h6'} sx={{ padding: 0 }}>
                    {Number(schedule.result) || schedule.result === 0
                      ? schedule.result.toFixed(1)
                      : 'N/A'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="delete patient"
                    onClick={(e) => {
                      e.stopPropagation();
                      startSchedule({
                        scheduleDoc: schedule,
                        queryParams: 'roadmapId=' + roadmapId,
                        setCalibrated: false,
                      });
                    }}
                  >
                    <PlayCircleOutlineIcon />
                  </IconButton>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
