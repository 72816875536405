import React, { useState, useEffect, useCallback } from 'react';
import './DemoAnimation.css';
import { socketo } from '../../../service/socket';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DemoAnimation from './DemoAnimation';
import OtherParticipants from '../OtherParticipants';
import { useFullScreenHandle } from 'react-full-screen';
import { updateStudentPoints } from '../../../redux/actions/updatepoints';
import { setWorkoutData } from '../../../redux/actions/setDatas';
import {
  getDashboardData,
  getRankings,
} from '../../../redux/actions/getDashboard';
import {
  getClassHome,
  getClassProgress,
} from '../../../redux/actions/getClassHome';

export const SchoolAnimationComponent = ({
  demoType = 'school',
  setJoiners,
  role,
  duration,
  joiners,
  setDuration,
}) => {
  const handle = useFullScreenHandle();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [, setCurrentDate] = useState('');
  const [limit, setLimit] = useState(false);
  const [socket] = useState(socketo);
  const schedule = useSelector(({ schedule }) => schedule.values);
  const sp = useSelector(({ sweatPoints }) => sweatPoints.values.total);

  const handleTraningStarted = useCallback((data) => {
    if (role !== 'teacher') {
      setCurrentDate(data);
    }
  }, []);

  const handleNewJoin = (data) => {
    let dataObj = JSON.parse(data);
    const users = Object.keys(dataObj).map(function (key) {
      return JSON.parse(dataObj[key]);
    });
    const unique = users.filter((v, i, a) => a.indexOf(v) === i);
    const u2 = unique.filter((item) => item.class_id === schedule.class_id);
    setJoiners(u2);
  };

  const handleExit = async (setLoad = (a) => a) => {
    setLoad(true);
    await updateStudentPoints(
      dispatch,
      { sweatpoints: sp, duration: duration },
      setLoad
    );
    await setWorkoutData(dispatch, sp, duration, setLoad);
    await getRankings(dispatch, setLoad);
    await getDashboardData(dispatch);
    let { role } = JSON.parse(localStorage.getItem('user_data'));
    if (role === 'teacher') {
      await getClassProgress(dispatch, setLoad);
      await getClassHome(dispatch);
    }
    navigate('/highlights', { state: { from: 'header' } });
  };

  useEffect(() => {
    socket.on('newJoin', handleNewJoin);
  }, [socket]);
  socket.on('newJoin', handleNewJoin);
  useEffect(() => {
    let socketTimer = setTimeout(() => {
      console.log('Trng Started');
      socket.on('traningStarted', handleTraningStarted);
      clearTimeout(socketTimer);
      socketTimer = null;
    }, 200);

    socket.on('newJoin', handleNewJoin);
    socket.emit('joinedTraining', localStorage.getItem('user_data'));
  }, [schedule]);

  useEffect(() => {
    () => {
      socket.off('newJoin');
      socket.emit('leftTraining', localStorage.getItem('user_data'));
      handle.exit();
    };
    handle.enter();
  }, []);

  const bottomBarComponent = (
    <OtherParticipants
      joiners={joiners}
      setDuration={setDuration}
      shouldShowTimer={false}
      limit={limit}
      setLimit={setLimit}
    />
  );

  return (
    <DemoAnimation
      demoType={demoType}
      bottomBarComponent={bottomBarComponent}
      handleExit={handleExit}
    />
  );
};
