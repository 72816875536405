import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { blazePoseJointWeights } from '../constants/JointWeights';

function getRatio({ canvasRef, axis }) {
  const videoWidth = parseInt(canvasRef.current.width);
  const videoHeight = parseInt(canvasRef.current.height);

  if (videoHeight < videoWidth) {
    if (axis === 'x') {
      return videoHeight / videoWidth;
    } else {
      return videoWidth / videoHeight;
    }
  } else {
    if (axis === 'x') {
      return videoWidth / videoHeight;
    } else {
      return videoHeight / videoWidth;
    }
  }
}

export function useSweatPoints() {
  const dispatch = useDispatch();
  const prevResults = useRef({ poseLandmarks: undefined });
  const sweatPoints = useRef(0);

  useEffect(() => {
    dispatch({ type: 'RESET_SWEAT_POINTS_COUNT' });
  });

  function computeSweatPoints(results, canvasRef) {
    if (!prevResults.current.poseLandmarks) {
      prevResults.current = results;
      return;
    } else {
      const ratioX = getRatio({ canvasRef, axis: 'x' });
      const ratioY = getRatio({ canvasRef, axis: 'y' });
      Object.keys(blazePoseJointWeights).forEach((joint) => {
        joint = parseInt(joint);
        const prevX = prevResults.current.poseLandmarks[joint].x;
        const prevY = prevResults.current.poseLandmarks[joint].y;
        const currentX = results.poseLandmarks[joint].x;
        const currentY = results.poseLandmarks[joint].y;
        const deltaX = Math.abs(1 - currentX / prevX) * ratioX;
        const deltaY = Math.abs(1 - currentY / prevY) * ratioY;
        sweatPoints.current += blazePoseJointWeights[joint] * (deltaX + deltaY);
      });
      prevResults.current = results;
    }
    dispatch({
      type: 'UPDATE_SWEAT_POINTS_COUNT',
      payload: { total: parseFloat((sweatPoints.current / 100).toFixed(2)) },
    });
  }

  return { computeSweatPoints };
}
