import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

export function getValueColor(subMetric, subMetricExerciseConf) {
  if (!subMetricExerciseConf) {
    return 'black';
  }
  if (
    Number(subMetricExerciseConf.upperNormalValue) >= subMetric.value &&
    Number(subMetricExerciseConf.lowerNormalValue) <= subMetric.value
  ) {
    return 'green';
  } else if (
    Number(subMetricExerciseConf.lowerNormalValue) > subMetric.value ||
    subMetric.value > Number(subMetricExerciseConf.upperNormalValue)
  ) {
    return 'red';
  }
  return 'black';
}

export default function MetricBox({ metric, metricExerciseConfig }) {
  return (
    <Box
      sx={{
        borderRadius: 5,
        bachgroundColor: 'white',
        boxShadow: 2,
        padding: 3,
        mt: 3,
      }}
    >
      <Grid
        container
        spacing={2}
        key={metric.name}
        sx={{
          m: 1,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: 5,
            boxShadow: 4,
            paddingBottom: 2,
            mr: 4,
          }}
        >
          <h3>{metric.name}</h3>
        </Grid>
        {metric.subMetrics?.map((subMetric, index) => {
          let range;
          if (
            metricExerciseConfig?.subMetrics[index]?.upperNormalValue ||
            metricExerciseConfig?.subMetrics[index]?.lowerNormalValue
          ) {
            range = ` [ ${metricExerciseConfig.subMetrics[index]?.lowerNormalValue}, ${metricExerciseConfig.subMetrics[index]?.upperNormalValue} ]`;
          }
          return (
            <Grid
              container
              direction={'column'}
              key={JSON.stringify(subMetric)}
              item
              xs={12}
            >
              <Grid item xs={5}>
                <Typography variant="h5">{subMetric?.name}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h6"
                  sx={{
                    color: getValueColor(
                      subMetric,
                      metricExerciseConfig?.subMetrics[index]
                    ),
                  }}
                >
                  {Number(subMetric?.value)?.toFixed(1)}
                </Typography>
                {range && (
                  <Grid item xs={12}>
                    <Typography variant="h6">{range}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
