import React, { useRef } from 'react';
import { useGaitActions } from '../../../../../hooks/GaitAnalysis/actions';
import {
  checkPersonOutOfFrame,
  useTimer,
} from '../../../../../hooks/GaitAnalysis/shared';
import { checkIfSide } from '../../../../../utilities/Demo/angles';
import {
  checkIfFront,
  findGaitAnglesFront,
  getStepLength,
  updateTimeSeriesData,
} from '../../../../../utilities/Demo/gaitAnalysis';
import PoseDetection from '../../../PoseDetection';

function GaitAnalysis({ setLegAngleData, showWebcam, recordingEnabled }) {
  const { time, stopTimeDiff, timeRunning } = useTimer();
  const peaked = useRef(false);
  const switched = useRef(false);
  const gaitSide = useRef('left');
  const validStep = useRef(true);
  const {
    updateSteps,
    updateStrideTime,
    updateStanceTime,
    updateStepTimeLength,
    updateStepLength,
    updateFeedback,
    appendCenterOfMassData,
    updateStepValidity,
  } = useGaitActions(true);

  const observeGait = (results, canvasCtx) => {
    const leftAnkleZ = results.poseLandmarks[31].z;
    const rightAnkleZ = results.poseLandmarks[32].z;

    if (gaitSide.current === 'left') {
      if (leftAnkleZ > rightAnkleZ && !peaked.current) {
        if (timeRunning.current) {
          peaked.current = true;
          switched.current = false;
          const stepLength = getStepLength(1.8, results);
          updateStepLength(stepLength, 'left');
          if (validStep.current) {
            updateSteps('left');
          }
          const timeDiff = stopTimeDiff();
          updateStrideTime(timeDiff, 'left');
          updateStanceTime(timeDiff, 'right');
          updateStepTimeLength('left');
          updateStepValidity(validStep.current, 'left');
          validStep.current = true;
        } else {
          timeRunning.current = true;
          validStep.current = false;
        }
      }
      if (leftAnkleZ * 1.1 < rightAnkleZ) {
        switched.current = true;
        gaitSide.current = 'right';
        peaked.current = false;
        if (checkPersonOutOfFrame(results, canvasCtx)) {
          timeRunning.current = false;
        }
      }
    } else {
      if (rightAnkleZ > leftAnkleZ && !peaked.current) {
        if (timeRunning.current) {
          peaked.current = true;
          switched.current = false;
          const stepLength = getStepLength(1.8, results);
          updateStepLength(stepLength, 'right');
          if (validStep.current) {
            updateSteps('right');
          }
          const timeDiff = stopTimeDiff();
          updateStrideTime(timeDiff, 'right');
          updateStanceTime(timeDiff, 'left');
          updateStepTimeLength('right');
          updateStepValidity(validStep.current, 'right');
          validStep.current = true;
        } else {
          timeRunning.current = true;
          validStep.current = false;
        }
      }
      if (
        peaked.current &&
        !switched.current &&
        rightAnkleZ * 1.1 < leftAnkleZ
      ) {
        switched.current = true;
        gaitSide.current = 'left';
        peaked.current = false;
        if (checkPersonOutOfFrame(results, canvasCtx)) {
          timeRunning.current = false;
        }
      }
    }
  };
  const onResults = (results, canvasCtx) => {
    const front = checkIfFront(results);
    const {
      leftKneeAngle,
      rightKneeAngle,
      leftKneeAnkleToeAngle,
      rightKneeAnkleToeAngle,
      leftHipKneeAngle,
      rightHipKneeAngle,
      midHipAngle,
      hipRotationAngle,
    } = findGaitAnglesFront(results, front);
    if (checkIfSide(results, 4)) {
      updateFeedback('Please turn to towards or away from the camera');
      timeRunning.current = false;
    } else if (
      timeRunning.current &&
      !checkPersonOutOfFrame(results, canvasCtx) &&
      recordingEnabled
    ) {
      observeGait(results, canvasCtx);
      updateFeedback('');
      updateTimeSeriesData({
        setLegAngleData,
        leftHipAnkleAngle:
          results.poseLandmarks[23].z * 100 - results.poseLandmarks[24].z * 100,
        rightHipAnkleAngle:
          results.poseLandmarks[24].z * 100 - results.poseLandmarks[23].z * 100,
        leftKneeAngle,
        rightKneeAngle,
        leftKneeAnkleToeAngle,
        rightKneeAnkleToeAngle,
        leftHipKneeAngle,
        rightHipKneeAngle,
        time: time.current,
        hipRotationAngle: hipRotationAngle,
        hipBalanceAngle: midHipAngle,
      });
      appendCenterOfMassData(time.current, results);
    } else {
      updateFeedback('Person out of frame');
    }
  };
  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}

export default React.memo(GaitAnalysis);
