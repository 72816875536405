import React, { useRef } from 'react';
import { useBalanceScore } from '../../../../../hooks/BergBalance/scores';
import { useBalanceCoreFeedback } from '../../../../../hooks/Demo/feedbackTypes';
import { checkIfSide } from '../../../../../utilities/Demo/angles';
import { loadStandUnsupportedAudio } from '../../../../../utilities/Demo/audioFeedback';
import {
  findAngle,
  findCounterAngle,
  findDependentAngle,
} from '../../../../../utilities/Demo/physio';
import { checkArmsUsedFront } from '../../../../../utilities/Demo/Physio/basics';
import PoseDetection from '../../../PoseDetection';
import {
  computeMetrics,
  leftUpperLegRomMetrics,
  rightUpperLegRomMetrics,
} from '../../../../../utilities/Demo/Physio/BergBalance/metrics';
import { useDispatch } from 'react-redux';

export default function StandUnsupported({ onComplete, showWebcam = false }) {
  const timeThresh = 20;
  const usedArms = useRef(false);
  const {
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateSecondsPoseHeld,
    seconds,
    started,
    cptScore,
    updatePoseHeldDeps,
    playAudio,
    audioFeedback,
    finishedIntro,
  } = useBalanceCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadStandUnsupportedAudio,
    maxScore: 1,
  });
  const dispatch = useDispatch();
  const metrics = useRef(
    leftUpperLegRomMetrics('Adduction', 'Abduction').concat(
      rightUpperLegRomMetrics('Adduction', 'Abduction')
    )
  );
  cptScore.current = useBalanceScore({
    secondsPoseHeldComplete,
    secondsPoseHeld,
    timeThresh,
    usedArms,
  });

  function observation(
    hipKneeAngleLeft,
    hipKneeAngleRight,
    kneeAngleLeft,
    kneeAngleRight,
    results
  ) {
    const state = holdsPose.current;
    if (
      hipKneeAngleLeft < 10 &&
      hipKneeAngleRight < 10 &&
      kneeAngleLeft < 200 &&
      kneeAngleRight < 200 &&
      kneeAngleLeft > 160 &&
      kneeAngleRight > 160 &&
      results.poseLandmarks[24].visibility > 0.5 &&
      results.poseLandmarks[26].visibility > 0.5 &&
      results.poseLandmarks[28].visibility > 0.5 &&
      results.poseLandmarks[23].visibility > 0.5 &&
      results.poseLandmarks[25].visibility > 0.5 &&
      results.poseLandmarks[27].visibility > 0.5
    ) {
      holdsPose.current = true;
      secondsPoseHeld.current = seconds.current - secondPoseHeldStarted.current;
      updateFeedback('');
    } else {
      updateFeedback('Stand unsupported!');
      holdsPose.current = false;
    }
    updatePoseHeldDeps(state);
    if (started.current) {
      if (checkArmsUsedFront(results, updateFeedback)) {
        updateFeedback('Do not use your arms!');
        usedArms.current = true;
      }
    }
  }

  function onResults(results) {
    if (!finishedIntro.current) return;

    const side = checkIfSide(results, 4);
    if (side) {
      updateFeedback('Face the camera!');
      playAudio(audioFeedback.current?.faceCamera);
      return;
    }

    const hipKneeAngleLeft = findAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25]
    );
    const hipKneeAngleRight = findCounterAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26]
    );
    const kneeAngleLeft = findDependentAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      results.poseLandmarks[27]
    );
    const kneeAngleRight = findDependentAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      results.poseLandmarks[28]
    );
    // drawAngle(canvasCtx, hipKneeAngleLeft, results.poseLandmarks[23]);
    // drawAngle(canvasCtx, hipKneeAngleRight, results.poseLandmarks[24]);
    // drawAngle(canvasCtx, kneeAngleLeft, results.poseLandmarks[25]);
    // drawAngle(canvasCtx, kneeAngleRight, results.poseLandmarks[26]);
    computeMetrics({
      metrics: metrics.current,
      angles: {
        current: {
          // kneeAngleLeft,
          // kneeAngleRight,
          hipKneeAngleLeft,
          hipKneeAngleRight,
        },
      },
      dispatch,
    });
    observation(
      hipKneeAngleLeft,
      hipKneeAngleRight,
      kneeAngleLeft,
      kneeAngleRight,
      results
    );
    updateSecondsPoseHeld(
      secondsPoseHeldComplete.current + secondsPoseHeld.current
    );
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
