import React, { useState, useEffect } from 'react';
import './AdminUiComponent.css';
//import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import { CircularProgress } from '@mui/material';
import success from '../../assets/sucess.png';
import wrong from '../../assets/wrong.png';
import { useTranslation } from 'react-i18next';
import { scheduleExerciseSet } from '../../redux/actions/superadmin';

export const ScheduleExerciseSet = (props) => {
  const { t } = useTranslation();
  const { classes, sets } = props;
  const [startDate, setStartDate] = useState(moment().format('DD-MM-YYYY'));
  const [endDate, setEndDate] = useState('');
  const [classvalue, setClassValue] = useState([]);
  const [setsvalue, setSetsValue] = useState('');
  const [classArray, setClassArray] = useState([]);
  const [msg, setMsg] = useState('');
  const [result, setResult] = useState('');
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [resp, setResp] = useState();
  const ITEM_HEIGHT = 96;
  const ITEM_PADDING_TOP = 16;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 'max-content',
      },
    },
  };
  const closeModal = () => {
    window.location.reload();
    setShow(false);
  };
  useEffect(() => {
    setClassArray(classes);
  }, [classes]);
  const style = {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    margin: 'auto',
    height: '70vh',
    width: '70vw',
    bgcolor: 'white',
    borderRadius: '2.5em',
    p: 0,
  };
  const handleModelUploadClick = async (e) => {
    e.preventDefault();
    setLoad(true);
    let payload = {
      setId: setsvalue,
      startDate: moment(startDate).format('DD-MM-YYYY'),
      endDate: moment(endDate).format('DD-MM-YYYY'),
      classes: classvalue.map((item) => parseInt(item)),
    };
    await scheduleExerciseSet(
      payload,
      setShow,
      setLoad,
      setResp,
      setResult,
      setMsg
    );
  };

  return (
    <div className="admin-settings-container">
      <div className="admin-settings-title">
        <div className="admin-page-orange-rect"></div>
        <h2>Schedule Exercise Set</h2>
      </div>

      <br />
      <div className="admin-page-content">
        <div className="form-group">
          <div className="form-group-heading">Select Sets</div>
          <div className="class-dropdown">
            <FormControl sx={{ m: 1, width: 300 }}>
              <Select
                className="select-dialog"
                value={setsvalue}
                onChange={(e) => {
                  setSetsValue(e.target.value);
                }}
              >
                {sets.map((option, index) => (
                  <MenuItem
                    value={option.set_id}
                    key={option.set_name + '-' + index}
                  >
                    <ListItemText primary={option.set_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <br />
        <div className="form-group">
          <div className="form-group-heading">Start Date</div>
          <input
            type="date"
            value={startDate}
            className="date-field"
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </div>
        <br />
        <div className="form-group">
          <div className="form-group-heading">End Date</div>
          <input
            type="date"
            value={endDate}
            className="date-field"
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <div className="form-group-heading">
            {t('adminDashboard.selectClasses')}
          </div>
          <div className="class-dropdown">
            <FormControl sx={{ m: 1, width: 300 }}>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                className="select-dialog"
                value={classvalue}
                onChange={(e) => {
                  setClassValue(e.target.value);
                }}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {classArray.map((option, index) => (
                  <MenuItem
                    value={option.class_id}
                    key={option.class_id + '-' + index}
                  >
                    <Checkbox
                      checked={classvalue.indexOf(option.class_id) > -1}
                    />
                    <ListItemText
                      primary={`${option.school}-${option.class_name}`}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="form-grp">
        {load ? (
          <CircularProgress
            size={42}
            color="success"
            sx={{ justifyContent: 'center' }}
          />
        ) : (
          <Button
            type="submit"
            variant="contained"
            onClick={handleModelUploadClick}
          >
            Schedule
          </Button>
        )}
      </div>
      <Modal
        open={show}
        onClose={() => closeModal()}
        height={'100vh'}
        sx={{ justify: 'center', margin: 'auto', pt: '10rem', border: 'none' }}
        bordered={false}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        {/* <img className="success-img" src={pose10}></img>
         */}
        <Box sx={style} border={'none'}>
          {result != 'error' ? (
            <>
              <img className="success-img" src={success}></img>
              <h2 id="parent-modal-title">{t('adminDashboard.scheduled')}</h2>
              <center>
                {' '}
                <div className="mod_head">{resp}</div>
              </center>
            </>
          ) : (
            <>
              <img className="success-img" src={wrong}></img>
              <h2 id="parent-modal-title">{t('adminDashboard.failed')}</h2>

              <div className="mod_data">
                <center> {msg}</center>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};
