import React from 'react';
import './ranking.css';
import TopThree from './TopThree';
import { useTranslation } from 'react-i18next';

const RankingComponent = ({ details, page }) => {
  const { t } = useTranslation();
  return (
    <div className="top-three-rankers-container ranking-article">
      <div className="ranking-article-navigation">
        <div className="ranking-orange-rect"></div>
        <h1>{page ? page : t('MyRankComponent.ranking')}</h1>
      </div>
      {!page && <TopThree ranks={details} />}
    </div>
  );
};

export default RankingComponent;
