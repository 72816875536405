const initialState = { values: { correct: true } };

export const sideReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET-SIDE': {
      return { values: { ...state.values, ...action.payload } };
    }
    case 'RESET-SIDE':
      return initialState;
    default: {
      return state;
    }
  }
};
