//#F0ECEB
import React, { useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
import Container from '@mui/material/Container';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProfileBg from '../../components/Profile/ProfileBg';
import ProfileComponent from '../../components/Profile/ProfileComponent';
import { getDashboardData } from '../../redux/actions/getDashboard';

const Profile = () => {
  const [open, setOpen] = useState(false);
  const board = useSelector(({ board }) => board.values);
  const dispatch = useDispatch();
  console.log('board is::', JSON.stringify(board));
  useEffect(() => {
    console.log('boards is::', JSON.stringify(board));
    if (!board) getDashboardData(dispatch);
  }, []);
  return (
    <Container maxWidth="xxl" className="bdy">
      <HeaderComponent bgcol={'#F0ECEB'} />
      <ProfileBg />
      <DrawerComponent open={open} setOpen={setOpen} />

      <div className="profile-page-content-container">
        <ProfileComponent board={board} />
      </div>
      {/* <Grid container padding={2} style={{ zIndex: "2"}}>
        <Grid item lg={1} xs={1} md={1} sm={1}></Grid>
        <Grid item lg={10} xs={10} md={10} sm={10} spacing={0}>
         <ProfileComponent board={board}/>
         </Grid>
      </Grid> */}
    </Container>
  );
};

export default Profile;
