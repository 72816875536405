import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import BrowserSupport from './components/BrowserSupport';
import RoadmapResultOverview from './components/Demo/ExerciseManagement/Results/Roadmap/RoadmapResultOverview';
import GaitResulTabs from './components/Demo/Physio/GaitAnalysis/results/BasicTabs';
import Redirect from './components/Redirect';
import { googleclientId } from './constants';
import FirebaseAuthProvider from './contexts/auth/FirebaseAuthProvider';
import PatientInfoProvider from './contexts/patientInfo/PatientInfoProvider';
import ScheduleResults from './views/Demo/Assessments/ScheduleResults';
import { Exhibition } from './views/Demo/Assessments/Exhibition';
import ExerciseManagementOverview from './views/Demo/ExerciseManagement/ExerciseManagement';
import ExerciseConfigurator from './components/Demo/ExerciseManagement/Configurator/Exercise/ExerciseConfigurator';
import AllPatientsView from './views/Demo/Physio/Overview/AllPatientsView';
import RecordingView from './views/Demo/Physio/GaitAnalysis/RecordingView';
import Welcome from './views/Welcome';
import DemoWelcome from './views/Demo/Welcome';
import Dashboard from './views/Student/Dashboard';
import Tdash from './views/Teacher/Tdash';
import Schedule from './views/Student/Schedule';
import Highlights from './views/Student/Highlights';
import ExerciseArena from './views/Student/ExerciseArena';
import Profile from './views/Student/Profile';
import Ranking from './views/Student/Ranking';
import ClubRanking from './views/Student/ClubRanking';
import AgeClub from './views/Student/AgeClubs';
import ChatScreen from './views/Student/ChatScreen';
import Progress from './views/Student/Progress';
import ClassProgress from './views/Teacher/ClassProgress';
import ClassHome from './views/Teacher/ClassHome';
import Students from './views/Teacher/Students';
import TeacherChatScreen from './views/Teacher/TeacherChatScreen';
import AdminDashboard from './views/Admin/AdminDashboard';
import HigherPreviledges from './components/ErrorPages/HigherPreviledges';
import Signup from './views/Parents-Signup/Signup';
import Rewards from './views/Student/Rewards';
import Exercise from './views/Demo/Exercise.jsx';
import DemoSummary from './views/Demo/Summary.jsx';
import TestDemo from './components/Demo/Test';
import PoseModelProvider from './contexts/pose/PoseModelProvider';
import { GeneralFitness } from './views/Demo/Assessments/GeneralFitness';
import { Trainers } from './views/Demo/Trainers';
import DemoSignUp from './views/Demo/Signup';
import SitAndStandResultTabs from './components/Demo/Physio/SitAndStand/Results/BasicTabs';
import BergBalanceResultTabs from './components/Demo/Physio/BergBalance/Results/BasicTabs';
import Sports2DDashboard from './views/Demo/Sports2D/Sports2DDashboard';
import PatientOverview from './views/Demo/Physio/Patient/PatientOverview';

const App = () => {
  // const authContext = createContext();
  // function useAuth() {
  //   return useContext(authContext);
  // }
  // const ProvideAuth = ({ children }) => {
  //   const auth = useProvideAuth();
  //   return <authContext.Provider value={auth}>{children}</authContext.Provider>;
  // };
  // function PrivateRoute({ children, ...rest }) {
  //   let auth = useAuth();
  //   return (
  //     <Route
  //       {...rest}
  //       render={({ location }) =>
  //         auth.user ? (
  //           children
  //         ) : (
  //           <Navigate
  //             to={{
  //               pathname: '/',
  //               state: { from: location },
  //             }}
  //           />
  //         )
  //       }
  //     />
  //   );
  // }
  // function useProvideAuth() {
  //   return true;
  // }
  return (
    <main>
      <GoogleOAuthProvider clientId={googleclientId}>
        <PoseModelProvider>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/home" element={<Dashboard />} />
            <Route path="/teacherhome" element={<Tdash />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/highlights" element={<Highlights />} />
            <Route path="/exercise" element={<ExerciseArena />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/ranking" element={<Ranking />} />
            <Route path="/club-ranking" element={<ClubRanking />} />
            <Route path="/all-clubs" element={<AgeClub />} />
            <Route path="/chat" element={<ChatScreen />} />
            <Route path="/progress" element={<Progress />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/class-progress" element={<ClassProgress />} />
            <Route path="/class-home" element={<ClassHome />} />
            <Route path="/class-students" element={<Students />} />
            <Route path="/teacherchat" element={<TeacherChatScreen />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/error_page" element={<HigherPreviledges />} />
            <Route
              path="/calibration"
              element={<Exercise demoType={'assessment'} />}
            />
          </Routes>
        </PoseModelProvider>
      </GoogleOAuthProvider>
      <Routes>
        <Route path="/login/:loginTarget" element={<DemoWelcome />} />
        <Route path="/demosignup/:loginTarget" element={<DemoSignUp />} />
      </Routes>
      <BrowserSupport>
        <PoseModelProvider>
          <Routes>
            <Route
              path={'/squats'}
              element={<Redirect target={'/demos/squats'} />}
            />
            <Route path={'/kickboxing'} element={<div id={'kick-demo'} />} />
            <Route path="/test" element={<TestDemo />} />
            <Route path="/fitness_assessment" element={<GeneralFitness />} />
            <Route path="/exhibition_assessment" element={<Exhibition />} />
            <Route path="/trainers" element={<Trainers />} />
            <Route
              path="/demos/:demoType"
              element={<Exercise redirectTarget={'/demoSummary'} />}
            />
            <Route path="/demoSummary" element={<DemoSummary />} />
          </Routes>
          <FirebaseAuthProvider>
            <Routes>
              <Route path="/summary" element={<DemoSummary />} />
              <Route
                path={'/exercise_generator'}
                element={<ExerciseConfigurator />}
              />
              <Route
                path={'/exercise_management'}
                element={<ExerciseManagementOverview />}
              />
              <Route path={'all_patients'} element={<AllPatientsView />} />
              <Route
                path={'schedule_results/:scheduleId'}
                element={<ScheduleResults />}
              />
              <Route
                path={'roadmap/:roadmapId'}
                element={<RoadmapResultOverview />}
              />
            </Routes>
            <PatientInfoProvider>
              <Routes>
                <Route
                  path="/exercise/:demoType"
                  element={<Exercise redirectTarget={'/summary'} />}
                />
                <Route path={`/gait_analysis`} element={<RecordingView />} />
                <Route path={`/gait_result`} element={<GaitResulTabs />} />
                <Route
                  path={'sit_and_stand_result'}
                  element={<SitAndStandResultTabs />}
                />
                <Route
                  path={'/berg_balance_result/:type'}
                  element={<BergBalanceResultTabs />}
                />
                <Route
                  path={'/sports_2d_results'}
                  element={<Sports2DDashboard />}
                />
                <Route
                  path={'/patient_overview'}
                  element={<PatientOverview />}
                />
              </Routes>
            </PatientInfoProvider>
          </FirebaseAuthProvider>
        </PoseModelProvider>
      </BrowserSupport>
    </main>
  );
};

export default App;
