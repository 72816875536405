export const fitnessAssessmentComponents = [
  'vstretch',
  'pushups',
  'boatpose',
  'treepose',
  'flamingo',
  'highknees',
  // 'pushups',
];

export const exhibitionAssessmentComponents = [
  'squats',
  'pushups',
  // 'boatpose',
  'treepose',
  // 'flamingo',
  'highknees',
  'kickboxing',
  // 'pushups',
];

export const sitAndStandAssessmentComponents = ['sitandstand'];

export const bergBalanceAssessmentComponents = [
  'sittostand',
  'standunsupported',
  'situnsupported',
  'standtosit',
  'transferbetweenchairs',
  'standunsupportedwitheyesclosed',
  'standwithfeettogether',
  'reachforwardwitharmoutstretched',
  'pickupoffofthefloor',
  'turnandlookbehind',
  'turnaroundincompletecircle',
  'placealternatefootonstep',
  'standwithfeetinline',
  'standononeleg',
];

export const bergBalanceAssessmentMiniComponents = [
  'sittostand',
  'standwithfeettogether',
  'standunsupportedwitheyesclosed',
  'placealternatefootonstep',
  'standononeleg',
];

export const trainerComponents = [
  'vstretch',
  'pushups',
  'boatpose',
  'treepose',
  // 'flamingo',
  'highknees',
  // 'pushups',
];

export const fitnessAssessmentVideos = [
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/V+Sit+Stretch.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/Pushups.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/Boat+Pose.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/Tree+Pose.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/Flamingo+Pose.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/High+Knees.mp4',
  // 'https://napply-open-access.s3.eu-central-1.amazonaws.com/Beginner+Pushups.mp4',
];

export const exhibitionAssessmentVideos = [
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/SquatsLoop.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/Pushups.mp4',
  // 'https://napply-open-access.s3.eu-central-1.amazonaws.com/Boat+Pose.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/Tree+Pose.mp4',
  // 'https://napply-open-access.s3.eu-central-1.amazonaws.com/Flamingo+Pose.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/High+Knees.mp4',
  // 'https://napply-open-access.s3.eu-central-1.amazonaws.com/Beginner+Pushups.mp4',
];

export const trainerVideos = [
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/V+Sit+Stretch.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/Pushups.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/Boat+Pose.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/Tree+Pose.mp4',
  // 'https://napply-open-access.s3.eu-central-1.amazonaws.com/Flamingo+Pose.mp4',
  'https://napply-open-access.s3.eu-central-1.amazonaws.com/High+Knees.mp4',
  // 'https://napply-open-access.s3.eu-central-1.amazonaws.com/Beginner+Pushups.mp4',
];

export const videoMap = {
  squats:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/SquatsLoop.mp4',
  vstretch:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/V+Sit+Stretch.mp4',
  pushups:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/Pushups.mp4',
  boatpose:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/Boat+Pose.mp4',
  treepose:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/Tree+Pose.mp4',
  flamingo:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/Flamingo+Pose.mp4',
  highknees:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/High+Knees.mp4',
  // 'https://napply-open-access.s3.eu-central-1.amazonaws.com/Beginner+Pushups.mp4',
  sittostand:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/sitting_to_standing.mp4',
  standunsupported:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/standing_unsupported.mp4',
  situnsupported:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/sitting_unsupported.mp4',
  standtosit:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/standing_to_sitting.mp4',
  transferbetweenchairs:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/transfers.mp4',
  standunsupportedwitheyesclosed:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/standing_with_eyes_closed.mp4',
  standwithfeettogether:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/standing_with_feet_together.mp4',
  reachforwardwitharmoutstretched:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/reaching_forward_with_outstretched_arm.mp4',
  pickupoffofthefloor:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/retrieve_object_from_floor.mp4',
  turnandlookbehind:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/turning_to_look_behind.mp4',
  turnaroundincompletecircle:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/turning_360_degrees.mp4',
  placealternatefootonstep:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/placing_alternate_foot_on_step.mp4',
  standwithfeetinline:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/standing_unsupported_with_feet_in_line.mp4',
  standononeleg:
    'https://napply-open-access.s3.eu-central-1.amazonaws.com/bergs_balance/standing_on_one_foot.mp4',
};

export const assessmentComponentsMap = {
  fitness_assessment: fitnessAssessmentComponents,
  exhibition_assessment: exhibitionAssessmentComponents,
  sit_and_stand_assessment: sitAndStandAssessmentComponents,
  berg_balance_assessment: bergBalanceAssessmentComponents,
  berg_balance_assessment_mini: bergBalanceAssessmentMiniComponents,
};
