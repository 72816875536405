import AddIcon from '@mui/icons-material/Add';
import { Dialog } from '@mui/material';
import Badge from '@mui/material/Badge';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import CloseBadge from '../CloseBadge';
import Recommendation from './Recommendation';

export default function RecommendationConfigDialog({
  onClose,
  open,
  schedules,
  recommendations = [],
  setRecommendations,
}) {
  return (
    <Dialog onClose={onClose} open={open} maxWidth={'lg'} fullWidth>
      <Badge
        badgeContent={<CloseBadge setConfig={onClose} sx={{ mt: 5, mr: 5 }} />}
      >
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12}>
            <DialogTitle sx={{ mr: 5 }}>Configure Recommendation</DialogTitle>
          </Grid>
          {recommendations.map((recommendation, index) => (
            <Grid item xs={12} key={index}>
              <Recommendation
                schedules={schedules}
                recommendation={recommendation}
                deleteRecommendation={() => {
                  recommendations.splice(index, 1);
                  setRecommendations(recommendations);
                }}
                setRecommendation={(recommendation) => {
                  recommendations[index] = recommendation;
                  setRecommendations(recommendations);
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                recommendations.push({});
                setRecommendations(recommendations);
              }}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Badge>
    </Dialog>
  );
}
