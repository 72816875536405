import { db } from '../../firebase';
import {
  collection,
  addDoc,
  Timestamp,
  where,
  getDocs,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';

export const addExerciseResult = async ({
  exerciseId,
  score,
  sweatPoints,
  duration,
  metrics = null,
  collectionName = 'trials',
  relativeScore = null,
  userId = null,
  scheduleId = null,
  sensorDataResults = null,
  additionalResultInfo = null,
  patientId = null,
  doctorId = null,
}) => {
  const data = {
    exerciseId,
    score,
    sweatPoints,
    duration,
    metrics,
    scheduleId,
    relativeScore,
    sensorDataResults,
    additionalResultInfo,
    patientId,
    doctorId,
  };
  data.createdAt = Timestamp.now();
  data.userId = userId;
  data.updatedAt = Timestamp.now();

  try {
    const docRef = await addDoc(collection(db, collectionName), {
      ...data,
      createdAt: Timestamp.now(),
    });
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

export const getLastExerciseResult = async ({
  exerciseId,
  collectionName = 'trials',
  user,
  scheduleId = null,
  patientId = null,
}) => {
  try {
    console.log('getting last exercise result', patientId);
    let q = query(collection(db, collectionName));

    q = query(
      q,
      where('userId', '==', user.uid),
      where('exerciseId', '==', exerciseId),
      where('scheduleId', '==', scheduleId),
      orderBy('createdAt', 'desc')
    );

    if (patientId) {
      q = query(q, where('patientId', '==', patientId));
    }

    q = query(q, limit(1));

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log('No matching documents.');
      return;
    }
    return {
      ...querySnapshot.docs[0].data(),
      id: querySnapshot.docs[0].id,
    };
  } catch (e) {
    console.error('Error getting documents: ', e);
  }
};
