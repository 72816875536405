import React from 'react';

const ProfileBg = () => {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '150%',
        top: '0',
        zIndex: -100,
        backgroundColor: '#F0ECEB',
        overflow: 'hidden',
        overflowX: 'hidden',
      }}
    ></div>
  );
};

export default ProfileBg;
