import * as React from 'react';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import '../assessments.css';
import '../../../Schedule/schedule.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLastBergResults } from '../../../../hooks/BergBalance/results';
import IndicationHealthStatusBox from './IndicationHealthStatusBox';
import TestResult from './TestResult';
import _ from 'lodash';

function parseAndRound(value) {
  const floatNumber = parseFloat(value);
  if (isNaN(floatNumber)) {
    return value;
  } else {
    return Math.round(floatNumber * 100) / 100;
  }
}

function checkScoreNumber(value) {
  if (isNaN(value) || value === undefined) {
    return 0;
  } else {
    return parseAndRound(value);
  }
}

export const LastTestMini = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const balanceScore = (iter.scores[3] + iter.scores[4]) / 2;
  const { bergBalanceResults } = useLastBergResults('mini');
  const overallScore = _.sum(
    bergBalanceResults?.map((item) => item?.score || 0)
  );
  const iter = useSelector(({ iter }) => iter.values);
  console.log(iter);

  const handleTryAgain = () => {
    dispatch({ type: 'RESET-ITER', payload: { values: { maxScore: 1 } } });
    dispatch({ type: 'RESET-INTRO' });
    navigate('/exercise/berg_balance_assessment_mini');
  };
  if (!bergBalanceResults) return null;
  return (
    <div
      className="today-session-container"
      style={{
        // height: '95vh',
        padding: '4em 1em',
        marginTop: '1em',
        // overflow: 'scroll',
      }}
    >
      <div className="box-container">
        <div className="today-session-box">Last Test</div>
      </div>
      <IndicationHealthStatusBox score={overallScore} fullTest={false} />
      <div className="list list-container">
        <TestResult name="Date" value={moment().format('DD MMM YYYY')} />
        <TestResult
          name="Overall Score"
          value={checkScoreNumber(overallScore) + '/15'}
        />
        <TestResult
          name="Sit to stand"
          value={checkScoreNumber(bergBalanceResults[0]?.score) + '/3'}
        />
        <TestResult
          name="Stand with Feet Together"
          value={checkScoreNumber(bergBalanceResults[1]?.score) + '/3'}
        />
        <TestResult
          name="Stand with eyes closed"
          value={checkScoreNumber(bergBalanceResults[2]?.score) + '/3'}
        />
        <TestResult
          name="Place alternate foot on step"
          value={checkScoreNumber(bergBalanceResults[3]?.score) + '/3'}
        />
        <TestResult
          name="Stand on one foot"
          value={checkScoreNumber(bergBalanceResults[4]?.score) + '/3'}
        />
      </div>
      <div className="join-session-btn-container">
        <Button
          type="submit"
          variant="contained"
          className="join-session-btn"
          sx={{
            borderRadius: 2,
            fontSize: '1.5rem',
            backgroundColor: '#FF592C',
          }}
          onClick={handleTryAgain}
        >
          Try Again
        </Button>
      </div>
    </div>
  );
};
