export const refComputations = {
  absoluteDistance: (ref, secondRef) => {
    return {
      x: Math.abs(ref.x - secondRef.x),
      y: Math.abs(ref.y - secondRef.y),
      z: Math.abs(ref.z - secondRef.z),
    };
  },
  relativeDistance: (ref, secondRef) => {
    return {
      x: ref.x - secondRef.x,
      y: ref.y - secondRef.y,
      z: ref.z - secondRef.z,
    };
  },
};

export const blazePoseCoordinateNames = {
  Nose: (results) => results.poseLandmarks[0],
  leftEar: (results) => results.poseLandmarks[7],
  rightEar: (results) => results.poseLandmarks[8],
  midEar: (results) => {
    return cptMid(results, 7, 8);
  },
  leftShoulder: (results) => results.poseLandmarks[11],
  rightShoulder: (results) => results.poseLandmarks[12],
  shoulder: (results, isLeft) => {
    return isLeft
      ? blazePoseCoordinateNames.leftShoulder(results)
      : blazePoseCoordinateNames.rightShoulder(results);
  },
  leftElbow: (results) => results.poseLandmarks[13],
  rightElbow: (results) => results.poseLandmarks[14],
  elbow: (results, isLeft) => {
    return isLeft
      ? blazePoseCoordinateNames.leftElbow(results)
      : blazePoseCoordinateNames.rightElbow(results);
  },
  leftWrist: (results) => results.poseLandmarks[15],
  rightWrist: (results) => results.poseLandmarks[16],
  wrist: (results, isLeft) => {
    return isLeft
      ? blazePoseCoordinateNames.leftWrist(results)
      : blazePoseCoordinateNames.rightWrist(results);
  },
  leftPinky: (results) => results.poseLandmarks[17],
  rightPinky: (results) => results.poseLandmarks[18],
  leftIndex: (results) => results.poseLandmarks[19],
  rightIndex: (results) => results.poseLandmarks[20],
  leftThumb: (results) => results.poseLandmarks[21],
  rightThumb: (results) => results.poseLandmarks[22],
  leftHip: (results) => results.poseLandmarks[23],
  rightHip: (results) => results.poseLandmarks[24],
  leftKnee: (results) => results.poseLandmarks[25],
  rightKnee: (results) => results.poseLandmarks[26],
  knee: (results, isLeft) => {
    return isLeft
      ? blazePoseCoordinateNames.leftKnee(results)
      : blazePoseCoordinateNames.rightKnee(results);
  },
  leftAnkle: (results) => results.poseLandmarks[27],
  rightAnkle: (results) => results.poseLandmarks[28],
  ankle: (results, isLeft) => {
    return isLeft
      ? blazePoseCoordinateNames.leftAnkle(results)
      : blazePoseCoordinateNames.rightAnkle(results);
  },
  leftHeel: (results) => results.poseLandmarks[29],
  rightHeel: (results) => results.poseLandmarks[30],
  leftFootIndex: (results) => results.poseLandmarks[31],
  rightFootIndex: (results) => results.poseLandmarks[32],
  footIndex: (results, isLeft) => {
    return isLeft
      ? blazePoseCoordinateNames.leftFootIndex(results)
      : blazePoseCoordinateNames.rightFootIndex(results);
  },
  midHip: (results) => {
    return cptMid(results, 23, 24);
  },
  midShoulder: (results) => {
    return cptMid(results, 11, 12);
  },
  midKnee: (results) => {
    return cptMid(results, 25, 26);
  },
  midAnkle: (results) => {
    return cptMid(results, 27, 28);
  },
};

const cptMid = (results, ref1, ref2) => {
  return {
    x: (results.poseLandmarks[ref1].x + results.poseLandmarks[ref2].x) / 2,
    y: (results.poseLandmarks[ref1].y + results.poseLandmarks[ref2].y) / 2,
    z: (results.poseLandmarks[ref1].z + results.poseLandmarks[ref2].z) / 2,
  };
};
