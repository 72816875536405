import * as React from 'react';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import { useNavigate } from 'react-router-dom';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import ScoreboardRoundedIcon from '@mui/icons-material/ScoreboardRounded';
import Alert from '@mui/material/Alert';
import CalibrationComponent from '../Arena/CalibrationComponent';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { socketo } from '../../service/socket';
import { getSweatPointsThresh } from '../../redux/actions/getDashboard';
import { CircularProgress } from '@mui/material';
import { clearaisession } from '../../redux/actions/aiserver';
import { useTranslation } from 'react-i18next';

const SessionComponent = ({ data }) => {
  const handle = useFullScreenHandle();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [msg, setMsg] = React.useState('');
  const [loady, setLoady] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  let { user_id } = JSON.parse(localStorage.getItem('user_data'));
  // const tout = setInterval(() => {
  //   if (
  //     data &&
  //     data.start &&
  //     moment()
  //       .local()
  //       .diff(
  //         moment.tz(data.start.dateTime, 'Europe/Berlin').local(),
  //         'seconds'
  //       ) >= -150 &&
  //     moment()
  //       .local()
  //       .diff(
  //         moment.tz(data.start.dateTime, 'Europe/Berlin').local(),
  //         'seconds'
  //       ) < 910
  //   ) {
  //     console.log('ENaBLES');
  //     setDisabled(false);
  //   } else setDisabled(true);
  // }, [1000]);
  const handleJoin = async () => {
    setLoady(true);
    // if (
    //   data &&
    //   data.start &&
    //   moment()
    //     .local()
    //     .diff(
    //       moment.tz(data.start.dateTime, 'Europe/Berlin').local(),
    //       'seconds'
    //     ) >= -150 &&
    //   moment()
    //     .local()
    //     .diff(
    //       moment.tz(data.start.dateTime, 'Europe/Berlin').local(),
    //       'seconds'
    //     ) < 910
    // ) {
    //check if 50 points crossed via new api
    let thresh = await getSweatPointsThresh(moment().format('DD-MM'));
    let limitex = false;
    //pass the limitex to exercise or save it in local storage so enable/disable ai
    localStorage.setItem('points', thresh.points);
    localStorage.setItem('limitex', thresh.thresh);
    if (thresh.points >= thresh.thresh) {
      limitex = true;
      setShowAlert(true);
      setMsg('Limit is exceeded. You cannot join');
      setLoady(false);
      //await new Promise((resolve) => setTimeout(resolve, 6000));
      return;
    }
    if (!limitex) {
      socketo.emit('joinedTraining', localStorage.getItem('user_data'));
      socketo.on('newJoin', async (data) => {
        let dataObj = JSON.parse(data);
        var users = Object.keys(dataObj).map(function (key) {
          return JSON.parse(dataObj[key]);
        });

        let usrs = users.filter((item) => item.user_id == user_id);
        console.log('SESS_URS', usrs);
        if (usrs.length > 1) {
          setMsg('You have already joined this session');
          setShowAlert(true);
          setLoady(false);
          socketo.off('newJoin');
          return;
          // socketo.off('newJoin');
          // socketo.emit('leftTraining', localStorage.getItem('user_data'));
          // setLoady(false);
          // navigate('/exercise');
        } else {
          socketo.off('newJoin');
          socketo.emit('leftTraining', localStorage.getItem('user_data'));
          await clearaisession(user_id);
          setLoady(false);
          navigate('/exercise');
        }
      });
    }
    // } else {
    //   setMsg('Please wait for the session to start');
    //   setShowAlert(true);
    //   setLoady(false);
    // }
    //navigate('/exercise');
  };
  React.useEffect(
    () => () => {
      socketo.off('newJoin');
      socketo.emit('leftTraining', localStorage.getItem('user_data'));
    },
    []
  );
  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    permissions
      .then(() => {
        setLoad(true);
        console.log('accepted the permissions');
      })
      .catch((err) => {
        console.log(`${err.name} : ${err.message}`);
        alert(
          'Please allow camera for calibration. Seems you have blocked camera access'
        );
      });
  };
  React.useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, [10000]);
    }
  }, [showAlert]);

  return (
    <div className="bottom-right article">
      {showAlert && (
        <Alert
          severity="error"
          variant="filled"
          sx={{ position: 'fixed' }}
          onClose={() => setShowAlert(false)}
        >
          <strong>{msg}</strong>
        </Alert>
      )}
      <div className="today-session-box">{t('SessionComponent.title')}</div>
      <div className="today-session-list">
        <div className="left">
          <EventNoteRoundedIcon />
          <span>{t('SessionComponent.class')}</span>
        </div>
        <div className="right">
          {data ? (data.class_no ? data.class_no : 'N/A') : 'N/A'}
        </div>
        <div className="left">
          <AccessTimeRoundedIcon />
          <span>{t('SessionComponent.Duration')}</span>
        </div>
        <div className="right">
          {data ? (data.duration ? `${data.duration} minutes` : 'N/A') : 'N/A'}
        </div>
        <div className="left">
          <ScoreboardRoundedIcon />
          <span>{t('SessionComponent.Difficulty')}</span>
        </div>
        <div className="right">
          {data ? t('SessionComponent.Beginner') : 'N/A'}
        </div>
        <div className="left">
          <CandlestickChartOutlinedIcon />
          <span>{t('SessionComponent.Intensity')}</span>
        </div>
        <div className="right">{t('SessionComponent.level')} 1</div>
        <div className="left">
          <CandlestickChartOutlinedIcon />
          <span>{t('SessionComponent.Type')}</span>
        </div>
        <div className="right">{data ? data.session_type : 'N/A'}</div>
        <div className="left">
          <AccessTimeRoundedIcon />
          <span>{t('SessionComponent.Start')}</span>
        </div>
        <div className="right">
          {' '}
          {data && data.start
            ? moment
                .tz(data.start.dateTime, 'Europe/Berlin')
                .local()
                .format('HH:mm')
            : 'NA'}
        </div>
      </div>
      {load && (
        <FullScreen handle={handle}>
          <CalibrationComponent
            load={load}
            setLoad={setLoad}
            handleJoin={handleJoin}
          />
        </FullScreen>
      )}
      <div style={{ display: 'flex' }}>
        {loady ? (
          <CircularProgress
            color="success"
            size={20}
            style={{ margin: 'auto 2rem auto 0' }}
          />
        ) : (
          <Button
            type="submit"
            variant="contained"
            className="today-session-btn"
            onClick={checkPermissions}
          >
            {t('SessionComponent.join')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SessionComponent;
