import React from 'react';
import Assignments from '../../../../components/Demo/Physio/Patient/Overview/Assignments';

export default function PatientOverview() {
  // slice patientId from query params
  const queryParams = new URLSearchParams(location.search);
  const patientId = queryParams.get('patientId');

  return (
    <div>
      <Assignments patientId={patientId} />
    </div>
  );
}
