import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { getDownloadURL } from 'firebase/storage';
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { toast, ToastContainer } from 'react-toastify';
import FirebaseAuthContext from '../../../../../contexts/auth/FirebaseAuthContext';
import { updateToastCaseFunction } from '../../../../../utilities/Demo/ExerciseManagement/Configurators/toasts';
import {
  addAudioFeedback,
  updateAudioFeedback,
} from '../../../../../utilities/Demo/ExerciseManagement/firestore';
import { uploadFile } from '../../../../../utilities/Demo/ExerciseManagement/Generators/storage';
import TagConfig from '../TagConfig';

export default function AudioFeedbackConfigurator({
  config,
  onClose,
  setConfig,
}) {
  const [loading, setLoading] = React.useState(false);
  const [id, setId] = React.useState(config.id);
  const [name, setName] = React.useState(config.name);
  const [description, setDescription] = React.useState(config.description);
  const [tags, setTags] = React.useState(config.tags || []);
  const [audioUrl, setAudioUrl] = React.useState(config.audioUrl);
  const [audioFile, setAudioFile] = React.useState();
  const { user } = React.useContext(FirebaseAuthContext);

  const onSave = () => {
    setLoading(true);
    const newConfig = {
      name,
      description,
      audioUrl,
      id,
      tags: tags.map((tag) => tag.id),
    };
    const toastId = toast.loading('Saving...');
    const updateToastCase = updateToastCaseFunction({
      id: toastId,
      successRender: 'Saved Audio Feedback!',
      errorRender: 'Error saving Audio Feedback!',
    });
    if (id) {
      if (audioFile) {
        uploadFile({
          file: audioFile,
          filePath: '/audios/' + id,
        }).then((fileRef) => {
          getDownloadURL(fileRef).then((url) => {
            setAudioUrl(url);
            newConfig.audioUrl = url;
            updateAudioFeedback({ audioFeedbackData: newConfig, user }).then(
              (audioFeedback) => {
                updateToastCase(audioFeedback);
                setConfig({ ...config, ...newConfig, audioUrl: url });
                setLoading(false);
              }
            );
          });
        });
      } else {
        updateAudioFeedback({ audioFeedbackData: newConfig, user }).then(
          (audioFeedback) => {
            updateToastCase(audioFeedback);
            setConfig({ ...config, ...newConfig });
            setLoading(false);
          }
        );
      }
    } else {
      newConfig.audioUrl = '';
      addAudioFeedback({ audioFeedbackData: newConfig, user }).then(
        (addedConf) => {
          updateToastCase(addedConf);
          setId(addedConf.id);
          uploadFile({
            file: audioFile,
            filePath: '/audios/' + addedConf.id,
          }).then((fileRef) => {
            getDownloadURL(fileRef).then((url) => {
              addedConf.audioUrl = url;
              updateAudioFeedback({ audioFeedbackData: addedConf, user }).then(
                () => {
                  setConfig({ ...config, ...addedConf });
                  setLoading(false);
                }
              );
            });
          });
        }
      );
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Grid container={true} spacing={2} direction={'column'}>
        <Grid item={true} xs={12} sm={6} md={4}>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} md={4}>
          <TextField
            label="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TagConfig type={'audio'} tags={tags} setTags={setTags} />
        </Grid>
        <Grid item xs={6} sm={2}>
          <input
            accept="video/mp3"
            style={{ display: 'none' }}
            id="raised-button-audio-file"
            type="file"
            onChange={(event) => {
              setAudioFile(event.target.files[0]);
            }}
          />
          <label htmlFor="raised-button-audio-file">
            <Button variant="raised" component="span">
              {!audioFile ? 'Choose File' : audioFile.name}
            </Button>
          </label>
        </Grid>
        <Grid item xs={6} sm={6}>
          <ReactAudioPlayer src={audioUrl} controls />
        </Grid>
      </Grid>
      <Box
        sx={{
          justifyContent: 'right',
          alignItems: 'right',
          display: 'flex',
          padding: 5,
        }}
      >
        <Button
          variant="contained"
          disabled={(!audioFile && !audioUrl) || !name}
          onClick={onSave}
        >
          {loading ? <CircularProgress /> : 'Save'}
        </Button>
        <ToastContainer />
      </Box>
    </Box>
  );
}
