import React, { useRef } from 'react';
import { useBalanceScore } from '../../../../../hooks/BergBalance/scores';
import { useBalanceCoreFeedback } from '../../../../../hooks/Demo/feedbackTypes';
import { checkIfSide } from '../../../../../utilities/Demo/angles';
import { loadStandUnsupportedWithEyesClosedAudio } from '../../../../../utilities/Demo/audioFeedback';
import {
  findAngle,
  findCounterAngle,
  findDependentAngle,
} from '../../../../../utilities/Demo/physio';
import { checkArmsUsedFront } from '../../../../../utilities/Demo/Physio/basics';
import PoseDetection from '../../../PoseDetection';
import { useDispatch } from 'react-redux';
import {
  computeMetrics,
  leftUpperLegRomMetrics,
  rightUpperLegRomMetrics,
} from '../../../../../utilities/Demo/Physio/BergBalance/metrics';

export default function StandUnsupportedWithEyesClosed({
  onComplete,
  showWebcam = false,
}) {
  const timeThresh = 20;
  const countDownTime = useRef(3);
  const usedArms = useRef(false);
  const {
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateSecondsPoseHeld,
    seconds,
    cptScore,
    updatePoseHeldDeps,
    playAudio,
    audioFeedback,
    finishedIntro,
  } = useBalanceCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadStandUnsupportedWithEyesClosedAudio,
    maxScore: 1,
  });
  const dispatch = useDispatch();
  const metrics = useRef(
    leftUpperLegRomMetrics('Adduction', 'Abduction').concat(
      rightUpperLegRomMetrics('Adduction', 'Abduction')
    )
  );
  cptScore.current = useBalanceScore({
    secondsPoseHeldComplete,
    secondsPoseHeld,
    timeThresh: timeThresh,
    usedArms,
  });

  function observation(
    hipKneeAngleLeft,
    hipKneeAngleRight,
    kneeAngleLeft,
    kneeAngleRight,
    results
  ) {
    const state = holdsPose.current;
    if (
      hipKneeAngleLeft < 10 &&
      hipKneeAngleRight < 10 &&
      kneeAngleLeft < 195 &&
      kneeAngleRight < 195 &&
      kneeAngleLeft > 165 &&
      kneeAngleRight > 165 &&
      results.poseLandmarks[24].visibility > 0.5 &&
      results.poseLandmarks[26].visibility > 0.5 &&
      results.poseLandmarks[28].visibility > 0.5 &&
      results.poseLandmarks[23].visibility > 0.5 &&
      results.poseLandmarks[25].visibility > 0.5 &&
      results.poseLandmarks[27].visibility > 0.5
    ) {
      computeMetrics({
        metrics: metrics.current,
        angles: {
          current: {
            // kneeAngleLeft,
            // kneeAngleRight,
            hipKneeAngleLeft,
            hipKneeAngleRight,
          },
        },
        dispatch,
      });
      holdsPose.current = true;
      secondsPoseHeld.current = seconds.current - secondPoseHeldStarted.current;
      updateFeedback('');
    } else {
      updateFeedback('Stand with your feet together and eyes closed!');
      holdsPose.current = false;
    }
    updatePoseHeldDeps(state);
    if (checkArmsUsedFront(results)) {
      updateFeedback('Do not use your arms!');
      usedArms.current = true;
    }
  }

  function onResults(results) {
    if (!finishedIntro.current) return;

    const side = checkIfSide(results, 4);
    if (side) {
      updateFeedback('Face the camera!');
      playAudio(audioFeedback.current?.faceCamera);
      return;
    }

    const hipKneeAngleLeft = findAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25]
    );
    const hipKneeAngleRight = findCounterAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26]
    );
    const kneeAngleLeft = findDependentAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      results.poseLandmarks[27]
    );
    const kneeAngleRight = findDependentAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      results.poseLandmarks[28]
    );
    // drawAngle(canvasCtx, hipKneeAngleLeft, results.poseLandmarks[23]);
    // drawAngle(canvasCtx, hipKneeAngleRight, results.poseLandmarks[24]);
    // drawAngle(canvasCtx, kneeAngleLeft, results.poseLandmarks[25]);
    // drawAngle(canvasCtx, kneeAngleRight, results.poseLandmarks[26]);

    observation(
      hipKneeAngleLeft,
      hipKneeAngleRight,
      kneeAngleLeft,
      kneeAngleRight,
      results
    );
    if (countDownTime.current > 0) {
      updateFeedback(`Close your eyes in ${countDownTime.current}`);
      if (holdsPose.current) {
        countDownTime.current = Math.max(
          0,
          3 - (seconds.current - secondPoseHeldStarted.current)
        );
      }
      if (countDownTime.current === 0) {
        secondPoseHeldStarted.current = 0;
        secondsPoseHeld.current = 0;
        secondsPoseHeldComplete.current = 0;
        seconds.current = 0;
        usedArms.current = false;
      }
    } else {
      updateSecondsPoseHeld(
        secondsPoseHeldComplete.current + secondsPoseHeld.current
      );
    }
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
