import React from 'react';
import Grid from '@mui/material/Grid';
import PieChart from './PieChart';

export default function StrikeDistribution({
  leftStrikeDistribution = {},
  rightStrikeDistribution = {},
}) {
  function toPercent(num) {
    return Math.round(num * 100);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <PieChart
          series={[
            toPercent(leftStrikeDistribution.heel),
            toPercent(leftStrikeDistribution.mid),
            toPercent(leftStrikeDistribution.front),
          ]}
          labels={['Heel', 'Mid', 'Front']}
          title={'Left Strike Dist.'}
        />
      </Grid>
      <Grid item xs={6}>
        <PieChart
          series={[
            toPercent(rightStrikeDistribution.heel),
            toPercent(rightStrikeDistribution.mid),
            toPercent(rightStrikeDistribution.front),
          ]}
          labels={['Heel', 'Mid', 'Front']}
          title={'Right Strike Dist.'}
        />
      </Grid>
    </Grid>
  );
}
