import React from 'react';

const ArticleNavigation = ({ title }) => {
  return (
    <div className="progress-article-navigation">
      <div className="left">
        <div className="progress-orange-rect"></div>
        <h1>{title}</h1>
      </div>
      {/* {
                title !== 'Attendance' &&
                <div class="right">
                    <Select className="progress-select" defaultValue={10} >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>{selectVal}</MenuItem>
                    </Select>
                </div>
            } */}
    </div>
  );
};

export default ArticleNavigation;
