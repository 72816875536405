import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import '@tensorflow/tfjs-backend-webgl';
import { useWebcamSettings } from '../../hooks/webcam';
import FrontsideLungesDemo from './Exercises/FrontsideLunges';
import JumpingJacks from './Exercises/JumpingJacks';
import Kickboxing from './Exercises/Kickboxing';
import LungesDemo from './Exercises/Lunges';
import PlankPoseDemo from './Exercises/PlankPose';
import SitAndStand from './Exercises/SitAndStand';
import SquatsFront from './Exercises/SquatsFront';

import TreePoseDemo from './Exercises/TreePose';
import { useLocation } from 'react-router-dom';
import BoatPoseDemo from './Exercises/BoatPose';
import FlamingoPoseDemo from './Exercises/FlamingoPose';
import VStretchDemo from './Exercises/VStretchDemo';
import PushUpDemo from './Exercises/PushUpDemo';
import HighKneesDemo from './Exercises/HighKneesDemo';
import SquatsDemo from './Exercises/Squats';
import PhysioDemo from './Exercises/Physio';
import {
  Feedbacks,
  CountsInTimeFeedbacks,
  CountsInTimeSurvivalFeedbacks,
  BalanceFeedbacks,
  StretchFeedbacks,
  GaitFeedbacks,
} from './Feedbacks';
import CountsFeedbackGenerator from './ExerciseManagement/Generators/CountsFeedbackGenerator';
import PickUpOffOfTheFloor from './Physio/BergBalance/Tasks/PickUpOffOfTheFloor';
import PlaceAlternateFootOnStep from './Physio/BergBalance/Tasks/PlaceAlternateFootOnStep';
import ReachForwardWithArmOutStretched from './Physio/BergBalance/Tasks/ReachForwardWithArmOutStretched';
import SitToStand from './Physio/BergBalance/Tasks/SitToStand';
import SitUnsupported from './Physio/BergBalance/Tasks/SitUnsupported';
import StandOnOneLeg from './Physio/BergBalance/Tasks/StandOnOneLeg';
import StandToSit from './Physio/BergBalance/Tasks/StandToSit';
import StandUnsupported from './Physio/BergBalance/Tasks/StandUnsupported';
import StandUnsupportedWithEyesClosed from './Physio/BergBalance/Tasks/StandUnsupportedWithEyesClosed';
import StandWithFeetInLine from './Physio/BergBalance/Tasks/StandWithFeetInLine';
import StandWithFeetTogether from './Physio/BergBalance/Tasks/StandWithFeetTogether';
import TransferBetweenChairs from './Physio/BergBalance/Tasks/transferBetweenChairs';
import TurnAndLookBehind from './Physio/BergBalance/Tasks/TurnAndLookBehind';
import TurnAroundInCompleteCircle from './Physio/BergBalance/Tasks/TurnAroundInCompleteCircle';
import GaitAnalysis from './Physio/GaitAnalysis/recording/GaitAnalysis';
import GaitAnalysisFront from './Physio/GaitAnalysis/recording/GaitAnalysisFront';
import PoseDetection from './PoseDetection';
import { VersionTag } from './VersionTag';

function TestDemo() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { updateWebcamSettings } = useWebcamSettings();
  let testComponent;
  let feedbackComponent = <Feedbacks />;
  const baseUrl = window.location.href.split('?')[0];

  useEffect(() => {
    updateWebcamSettings(false);
  });

  let componentName = useQuery().get('component')?.toLowerCase();
  const side = useQuery().get('side')?.toLowerCase();
  switch (componentName) {
    case 'tree_pose':
      feedbackComponent = <BalanceFeedbacks />;
      testComponent = (
        <TreePoseDemo onComplete={() => undefined} legSide={side} />
      );
      break;
    case 'boat_pose':
      feedbackComponent = <BalanceFeedbacks />;
      testComponent = (
        <BoatPoseDemo onComplete={() => undefined} legSide={side} />
      );
      break;
    case 'flamingo_pose':
      feedbackComponent = <BalanceFeedbacks />;
      testComponent = (
        <FlamingoPoseDemo onComplete={() => undefined} legSide={side} />
      );
      break;
    case 'v_stretch':
      feedbackComponent = <StretchFeedbacks />;
      testComponent = (
        <VStretchDemo onComplete={() => undefined} legSide={side} />
      );
      break;
    case 'push_up':
      feedbackComponent = <Feedbacks />;
      testComponent = <PushUpDemo setCorrectCounts={() => undefined} />;
      break;
    case 'high_knees':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = <HighKneesDemo onComplete={() => undefined} />;
      break;
    case 'squats':
      testComponent = (
        <SquatsDemo
          onComplete={() => undefined}
          showBackbones={true}
          showWebcam={true}
        />
      );
      break;
    case 'physio':
      testComponent = (
        <PhysioDemo
          onComplete={() => undefined}
          showWebcam={true}
          showBackbones={true}
          position={'absolute'}
        />
      );
      break;
    case 'detection':
      testComponent = (
        <PoseDetection
          showWebcam={true}
          showBackbones={true}
          onResults={() => undefined}
          // position={'absolute'}
        />
      );
      feedbackComponent = <></>;
      break;
    case 'plank_pose':
      feedbackComponent = <BalanceFeedbacks />;
      testComponent = (
        <PlankPoseDemo onComplete={() => undefined} showWebcam={false} />
      );
      break;
    case 'lunges':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = <LungesDemo onComplete={() => undefined} />;
      break;
    case 'front_lunges':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = <FrontsideLungesDemo onComplete={() => undefined} />;
      break;
    case 'jumping_jacks':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = <JumpingJacks onComplete={() => undefined} />;
      break;
    case 'sit_and_stand':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = (
        <SitAndStand onComplete={() => undefined} showBackbones={true} />
      );
      break;
    case 'gait_analysis':
      feedbackComponent = <GaitFeedbacks />;
      testComponent = <GaitAnalysis onComplete={() => undefined} />;
      break;
    case 'gait_analysis_front':
      feedbackComponent = <GaitFeedbacks front={true} />;
      testComponent = <GaitAnalysisFront onComplete={() => undefined} />;
      break;
    case 'kickboxing':
      feedbackComponent = <CountsInTimeSurvivalFeedbacks />;
      testComponent = (
        <Kickboxing
          onComplete={() => console.log('done')}
          showBackbones={true}
          showWebcam={false}
        />
      );
      break;
    case 'sit_unsupported':
      feedbackComponent = <BalanceFeedbacks />;
      testComponent = <SitUnsupported onComplete={() => console.log('done')} />;
      break;
    case 'berg_sit_to_stand':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = <SitToStand onComplete={() => undefined} />;
      break;
    case 'stand_to_sit':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = <StandToSit onComplete={() => console.log('done')} />;
      break;
    case 'stand_with_feet_together':
      feedbackComponent = <BalanceFeedbacks />;
      testComponent = (
        <StandWithFeetTogether onComplete={() => console.log('done')} />
      );
      break;
    case 'stand_on_one_leg':
      feedbackComponent = <BalanceFeedbacks />;
      testComponent = <StandOnOneLeg onComplete={() => console.log('done')} />;
      break;
    case 'reach_forward_with_arm_out_stretched':
      feedbackComponent = <StretchFeedbacks />;
      testComponent = (
        <ReachForwardWithArmOutStretched
          onComplete={() => console.log('done')}
        />
      );
      break;
    case 'pickupoffofthefloor':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = (
        <PickUpOffOfTheFloor onComplete={() => console.log('done')} />
      );
      break;
    case 'turnandlookbehind':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = (
        <TurnAndLookBehind onComplete={() => console.log('done')} />
      );
      break;
    case 'squats_front':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = (
        <SquatsFront onComplete={() => console.log('done')} showWebcam={true} />
      );
      break;
    case 'standwithfeetinline':
      feedbackComponent = <BalanceFeedbacks />;
      testComponent = (
        <StandWithFeetInLine onComplete={() => console.log('done')} />
      );
      break;
    case 'standunsupportedwitheyesclosed':
      feedbackComponent = <BalanceFeedbacks />;
      testComponent = (
        <StandUnsupportedWithEyesClosed
          onComplete={() => console.log('done')}
        />
      );
      break;
    case 'standunsupported':
      feedbackComponent = <BalanceFeedbacks />;
      testComponent = (
        <StandUnsupported onComplete={() => console.log('done')} />
      );
      break;
    case 'transferbetweenchairs':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = (
        <TransferBetweenChairs onComplete={() => console.log('done')} />
      );
      break;
    case 'placealternatefootonstep':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = (
        <PlaceAlternateFootOnStep onComplete={() => console.log('done')} />
      );
      break;
    case 'turnaroundincompletecircle':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = (
        <TurnAroundInCompleteCircle onComplete={() => console.log('done')} />
      );
      break;
    case 'countsfeedbackgenerator':
      feedbackComponent = <CountsInTimeFeedbacks />;
      testComponent = (
        <CountsFeedbackGenerator onComplete={() => console.log('done')} />
      );
      break;
    default:
      componentName = 'default';
      feedbackComponent = <></>;
      testComponent = (
        <div style={{ margin: 15 }}>
          <h1>Test components are:</h1>
          <ul>
            <li>
              <a href={baseUrl + '?component=tree_pose'}>TreePose</a>
            </li>
            <li>
              <a href={baseUrl + '?component=flamingo_pose'}>FlamingoPose</a>
            </li>
            <li>
              <a href={baseUrl + '?component=boat_pose'}>BoatPose</a>
            </li>
            <li>
              <a href={baseUrl + '?component=v_stretch'}>V-Stretch</a>
            </li>
            <li>
              <a href={baseUrl + '?component=push_up'}>PushUp</a>
            </li>
            <li>
              <a href={baseUrl + '?component=high_knees'}>HighKnees</a>
            </li>
            <li>
              <a href={baseUrl + '?component=squats'}>Squats</a>
            </li>
            <li>
              <a href={baseUrl + '?component=physio'}>Physio</a>
            </li>
            <li>
              <a href={baseUrl + '?component=plank_pose'}>PlankPose</a>
            </li>
            <li>
              <a href={baseUrl + '?component=lunges'}>Lunges</a>
            </li>
            <li>
              <a href={baseUrl + '?component=front_lunges'}>
                Front side Lunges
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=jumping_jacks'}>Jumping Jacks</a>
            </li>
            <li>
              <a href={baseUrl + '?component=gait_analysis'}>Gait Analysis</a>
            </li>
            <li>
              <a href={baseUrl + '?component=gait_analysis_front'}>
                Gait Analysis Front
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=berg_sit_to_stand'}>
                Berg Balance sit to stand
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=stand_to_sit'}>
                Berg Balance stand to sit
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=sit_unsupported'}>
                Berg Balance Sit Unsupported
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=stand_with_feet_together'}>
                Berg Balance Stand with feet together
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=stand_on_one_leg'}>
                Berg Balance Stand on one leg
              </a>
            </li>
            <li>
              <a
                href={
                  baseUrl + '?component=reach_forward_with_arm_out_stretched'
                }
              >
                Berg Balance Reach forward with arm out stretched
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=pickupoffofthefloor'}>
                Berg Balance Pick up off of the floor
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=turnandlookbehind'}>
                Berg Balance Turn and look behind
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=standunsupportedwitheyesclosed'}>
                Berg Balance Stand unsupported with eyes closed
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=standunsupported'}>
                Berg Balance Stand unsupported
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=transferbetweenchairs'}>
                Berg Balance Transfer between chairs
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=placealternatefootonstep'}>
                Berg Balance Place alternate foot on step
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=turnaroundincompletecircle'}>
                Berg Balance Turn around in complete circle
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=sit_and_stand'}>Sit and Stand</a>
            </li>
            <li>
              <a href={baseUrl + '?component=kickboxing'}>Kickboxing</a>
            </li>
            <li>
              <a href={baseUrl + '?component=detection'}>Detection</a>
            </li>
            <li>
              <a href={baseUrl + '?component=squats_front'}>Squats Front</a>
            </li>
            <li>
              <a href={baseUrl + '?component=standwithfeetinline'}>
                Stand with feet in line
              </a>
            </li>
            <li>
              <a href={baseUrl + '?component=countsfeedbackgenerator'}>
                Counts Feedback Generator
              </a>
            </li>
          </ul>
          <div style={{ marginTop: 20 }}>
            <VersionTag />
          </div>
        </div>
      );
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <div
            id={'__test__-demo'}
            style={{
              position: 'relative',
              padding: '10px',
              overflow: 'hidden',
              height: '100%',
              width: '100%',
              backgroundColor: componentName !== 'default' && 'black',
              zIndex: 100,
            }}
          >
            <div
              style={{ transform: componentName !== 'default' && 'scaleX(-1)' }}
            >
              {testComponent}
            </div>
            {feedbackComponent}
          </div>
        </Grid>
      </Grid>
      <button
        onClick={() => {
          throw new Error('Test if sentry is reporting this error');
        }}
      >
        Test if sentry is reporting this error
      </button>
    </div>
  );
}

export default React.memo(TestDemo);
