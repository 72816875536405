import React from 'react';
import BoatPose from '../../components/Demo/Exercises/BoatPose';
import DefaultExercise from '../../components/Demo/Exercises/DefaultExercise';
import FlamingoPose from '../../components/Demo/Exercises/FlamingoPose';
import HighKneesDemo from '../../components/Demo/Exercises/HighKneesDemo';
import Kickboxing from '../../components/Demo/Exercises/Kickboxing';
import Physio from '../../components/Demo/Exercises/Physio';
import PushUpDemo from '../../components/Demo/Exercises/PushUpDemo';
import SitAndStand from '../../components/Demo/Exercises/SitAndStand';
import Squats from '../../components/Demo/Exercises/Squats';
import TreePose from '../../components/Demo/Exercises/TreePose';
import VStretchDemo from '../../components/Demo/Exercises/VStretchDemo';
import {
  BalanceFeedbacks,
  CountsInTimeFeedbacks,
  CountsInTimeSurvivalFeedbacks,
  Feedback,
  StretchFeedbacks,
} from '../../components/Demo/Feedbacks';
import PickUpOffOfTheFloor from '../../components/Demo/Physio/BergBalance/Tasks/PickUpOffOfTheFloor';
import PlaceAlternateFootOnStep from '../../components/Demo/Physio/BergBalance/Tasks/PlaceAlternateFootOnStep';
import ReachForwardWithArmOutStretched from '../../components/Demo/Physio/BergBalance/Tasks/ReachForwardWithArmOutStretched';
import SitToStand from '../../components/Demo/Physio/BergBalance/Tasks/SitToStand';
import SitUnsupported from '../../components/Demo/Physio/BergBalance/Tasks/SitUnsupported';
import StandOnOneLeg from '../../components/Demo/Physio/BergBalance/Tasks/StandOnOneLeg';
import StandToSit from '../../components/Demo/Physio/BergBalance/Tasks/StandToSit';
import StandUnsupported from '../../components/Demo/Physio/BergBalance/Tasks/StandUnsupported';
import StandUnsupportedWithEyesClosed from '../../components/Demo/Physio/BergBalance/Tasks/StandUnsupportedWithEyesClosed';
import StandWithFeetInLine from '../../components/Demo/Physio/BergBalance/Tasks/StandWithFeetInLine';
import StandWithFeetTogether from '../../components/Demo/Physio/BergBalance/Tasks/StandWithFeetTogether';
import TransferBetweenChairs from '../../components/Demo/Physio/BergBalance/Tasks/transferBetweenChairs';
import TurnAndLookBehind from '../../components/Demo/Physio/BergBalance/Tasks/TurnAndLookBehind';
import TurnAroundInCompleteCircle from '../../components/Demo/Physio/BergBalance/Tasks/TurnAroundInCompleteCircle';
import {
  getAudioFeedback,
  getVideo,
} from '../Demo/ExerciseManagement/firestore';
import {
  loadBoatPoseAudio,
  loadFlamingoPoseAudio,
  loadHighKneesAudio,
  loadPickUpOffOfTheFloorAudio,
  loadPlaceAlternateFootOnStepAudio,
  loadPushUpAudio,
  loadReachForwardWithArmOutstretchedAudio,
  loadSitToStandAudio,
  loadSitUnsupportedAudio,
  loadSquatsAudio,
  loadStandOnOneLegAudio,
  loadStandToSitAudio,
  loadStandUnsupportedAudio,
  loadStandUnsupportedWithEyesClosedAudio,
  loadStandWithFeetInLineAudio,
  loadStandWithFeetTogetherAudio,
  loadTransferBetweenChairsAudio,
  loadTreePoseAudio,
  loadTurnAndLookBehindAudio,
  loadTurnAroundInCompleteCircleAudio,
  loadVStretchAudio,
} from '../Demo/audioFeedback';

export const getSummary = (demoType, correctCounts, incorrectCounts) => {
  switch (demoType) {
    case 'physio':
      return {
        state: {
          duration: '132.4000015258789',
          sp: correctCounts + incorrectCounts,
          squatCount: correctCounts,
          incorrectSquatCount: incorrectCounts,
          workOutScore:
            (correctCounts / (incorrectCounts + correctCounts)) * 10,
          message: 'Well Done!',
          demoType,
        },
      };
    default:
      return {
        state: {
          duration: '83.4000015258789',
          sp: correctCounts + incorrectCounts,
          squatCount: correctCounts,
          incorrectSquatCount: incorrectCounts,
          workOutScore:
            (correctCounts / (incorrectCounts + correctCounts)) * 10,
          message: 'Well Done, Your squats were perfect!',
          demoType,
        },
      };
  }
};

export const setHardCodedComponents = ({
  demoName,
  demoType,
  setObservationComponent,
  setFeedbackComponent,
  completeSession,
  showWebcam,
  setSplitScreen,
  handleExit,
  setLoad,
  setLoadAudio,
}) => {
  console.log('load', demoName);
  switch (demoName) {
    case 'sitandstand':
      setLoadAudio(() => loadSitToStandAudio);
      setObservationComponent(<SitAndStand onComplete={completeSession} />);
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'physio':
      setObservationComponent(<Physio onComplete={completeSession} />);
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'flamingo':
      setLoadAudio(() => loadFlamingoPoseAudio);
      setObservationComponent(<FlamingoPose onComplete={completeSession} />);
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
      break;
    case 'highknees':
      setLoadAudio(() => loadHighKneesAudio);
      setObservationComponent(
        <HighKneesDemo
          onComplete={completeSession}
          timeThresh={demoType === 'exhibition_assessment' ? 30 : 60}
        />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'squats':
      setLoadAudio(() => loadSquatsAudio);
      setObservationComponent(
        <Squats
          onComplete={completeSession}
          showWebcam={showWebcam}
          showBackbones={true}
          timeThresh={demoType === 'exhibition_assessment' ? 30 : 60}
        />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'pushups':
      setLoadAudio(() => loadPushUpAudio);
      setObservationComponent(
        <PushUpDemo
          onComplete={completeSession}
          timeThresh={demoType === 'exhibition_assessment' ? 30 : 60}
        />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'treepose':
      setLoadAudio(() => loadTreePoseAudio);
      setObservationComponent(
        <TreePose
          onComplete={completeSession}
          legSide={'left'}
          timeThresh={demoType === 'exhibition_assessment' ? 30 : 60}
        />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
      break;
    case 'boatpose':
      setLoadAudio(() => loadBoatPoseAudio);
      setObservationComponent(<BoatPose onComplete={completeSession} />);
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
      break;
    case 'vstretch':
      setLoadAudio(() => loadVStretchAudio);
      setObservationComponent(<VStretchDemo onComplete={completeSession} />);
      setFeedbackComponent(<Feedback FeedbackComponent={StretchFeedbacks} />);
      break;
    case 'kickboxing':
      setObservationComponent(
        <Kickboxing onComplete={completeSession} showWebcam={showWebcam} />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeSurvivalFeedbacks} />
      );
      setSplitScreen(false);
      break;
    case 'sittostand':
      console.log('sittostand intro', loadSitToStandAudio);
      setLoadAudio(() => loadSitToStandAudio);
      setObservationComponent(
        <SitToStand onComplete={completeSession} showWebcam={showWebcam} />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'situnsupported':
      setLoadAudio(() => loadSitUnsupportedAudio);
      setObservationComponent(
        <SitUnsupported onComplete={completeSession} showWebcam={showWebcam} />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
      break;
    case 'standtosit':
      setLoadAudio(() => loadStandToSitAudio);
      setObservationComponent(
        <StandToSit onComplete={completeSession} showWebcam={showWebcam} />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'standwithfeettogether':
      setLoadAudio(() => loadStandWithFeetTogetherAudio);
      setObservationComponent(
        <StandWithFeetTogether
          onComplete={completeSession}
          showWebcam={showWebcam}
        />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
      break;
    case 'standononeleg':
      setLoadAudio(() => loadStandOnOneLegAudio);
      setObservationComponent(
        <StandOnOneLeg onComplete={completeSession} showWebcam={showWebcam} />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
      break;
    case 'reachforwardwitharmoutstretched':
      setLoadAudio(() => loadReachForwardWithArmOutstretchedAudio);
      setObservationComponent(
        <ReachForwardWithArmOutStretched
          onComplete={completeSession}
          showWebcam={showWebcam}
        />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={StretchFeedbacks} />);
      break;
    case 'pickupoffofthefloor':
      setLoadAudio(() => loadPickUpOffOfTheFloorAudio);
      setObservationComponent(
        <PickUpOffOfTheFloor
          onComplete={completeSession}
          showWebcam={showWebcam}
        />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'turnandlookbehind':
      setLoadAudio(() => loadTurnAndLookBehindAudio);
      setObservationComponent(
        <TurnAndLookBehind
          onComplete={completeSession}
          showWebcam={showWebcam}
        />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'standwithfeetinline':
      setLoadAudio(() => loadStandWithFeetInLineAudio);
      setObservationComponent(
        <StandWithFeetInLine
          onComplete={completeSession}
          showWebcam={showWebcam}
        />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
      break;
    case 'standunsupportedwitheyesclosed':
      setLoadAudio(() => loadStandUnsupportedWithEyesClosedAudio);
      setObservationComponent(
        <StandUnsupportedWithEyesClosed
          onComplete={completeSession}
          showWebcam={showWebcam}
        />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
      break;
    case 'standunsupported':
      setLoadAudio(() => loadStandUnsupportedAudio);
      setObservationComponent(
        <StandUnsupported
          onComplete={completeSession}
          showWebcam={showWebcam}
        />
      );
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
      break;
    case 'turnaroundincompletecircle':
      setLoadAudio(() => loadTurnAroundInCompleteCircleAudio);
      setObservationComponent(
        <TurnAroundInCompleteCircle
          onComplete={completeSession}
          showWebcam={showWebcam}
        />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'transferbetweenchairs':
      setLoadAudio(() => loadTransferBetweenChairsAudio);
      setObservationComponent(
        <TransferBetweenChairs
          onComplete={completeSession}
          showWebcam={showWebcam}
        />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    case 'placealternatefootonstep':
      setLoadAudio(() => loadPlaceAlternateFootOnStepAudio);
      setObservationComponent(
        <PlaceAlternateFootOnStep
          onComplete={completeSession}
          showWebcam={showWebcam}
        />
      );
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      break;
    default:
      return <DefaultExercise onComplete={handleExit} setLoad={setLoad} />;
  }
};

export const getVideoUrl = async (
  scheduleSpecificExerciseConfigs,
  exercise
) => {
  console.log('videoId', scheduleSpecificExerciseConfigs[exercise.id]?.videoId);
  if (scheduleSpecificExerciseConfigs[exercise.id]?.videoId) {
    await getVideo({
      videoId: scheduleSpecificExerciseConfigs[exercise.id]?.videoId,
    }).then((video) => {
      exercise.videoUrl = video.url;
    });
  } else if (exercise.videoId) {
    await getVideo({ videoId: exercise.videoId }).then((video) => {
      exercise.videoUrl = video.url;
    });
  }
  console.log(exercise.videoUrl);
  return exercise;
};

export const getIntroAudioUrl = async (
  scheduleSpecificExerciseConfigs,
  exercise
) => {
  console.log(scheduleSpecificExerciseConfigs[exercise.id]);
  if (scheduleSpecificExerciseConfigs[exercise.id]?.introAudioFeedbackId) {
    await getAudioFeedback({
      audioFeedbackId:
        scheduleSpecificExerciseConfigs[exercise.id]?.introAudioFeedbackId,
    }).then((audioFeedback) => {
      exercise.introAudioUrl = audioFeedback.audioUrl;
    });
  } else if (exercise.introAudioFeedbackId) {
    await getAudioFeedback({
      audioFeedbackId: exercise.introAudioFeedbackId,
    }).then((audioFeedback) => {
      exercise.introAudioUrl = audioFeedback.audioUrl;
    });
  }
  return exercise;
};
