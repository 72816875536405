import { angleFrontComputations } from '../Generators/angleFront';
import {
  angleSideComputations,
  angleSideForceComputations,
} from '../Generators/anglesSide';

export const refOptions = (side) => {
  const options = {
    front: Object.keys(angleFrontComputations),
  };
  if (side) {
    options.side = Object.keys(angleSideForceComputations);
  } else {
    options.side = Object.keys(angleSideComputations);
  }
  return options;
};
