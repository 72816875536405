import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { blazePoseCoordinateNames } from '../../../../../utilities/Demo/ExerciseManagement/Generators/coordinates';
import BasicSelect from '../BasicSelect';

export default function CptFunctionConfig({
  config,
  setConfig,
  dependent = false,
}) {
  const coordOptions = Object.keys(blazePoseCoordinateNames);
  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <BasicSelect
            value={config.coord0}
            setValue={(value) => setConfig({ ...config, coord0: value })}
            label={'Coord0'}
            options={coordOptions}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={4}>
          <BasicSelect
            value={config.coord1}
            setValue={(value) => setConfig({ ...config, coord1: value })}
            label={'Coord1'}
            options={coordOptions}
            sx={{ width: '100%' }}
          />
        </Grid>
        {dependent && (
          <Grid item xs={4}>
            <BasicSelect
              value={config.coord2}
              setValue={(value) => setConfig({ ...config, coord2: value })}
              label={'Coord2'}
              options={coordOptions}
              sx={{ width: '100%' }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <BasicSelect
            value={
              config.counterClockWise !== undefined
                ? config.counterClockWise
                : false
            }
            setValue={(value) =>
              setConfig({ ...config, counterClockWise: value })
            }
            label={'Counter Clockwise'}
            options={[true, false]}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            type={'number'}
            id="outlined-basic"
            label="Respect"
            variant="outlined"
            value={config.respect}
            sx={{ width: '100%' }}
            onChange={(e) => setConfig({ ...config, respect: e.target.value })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
