import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FirebaseAuthContext from '../../contexts/auth/FirebaseAuthContext';
import * as gaitFirestore from '../../utilities/Demo/Physio/GaitAnalysis/firestore';
import { withTimeout } from '../../utilities/Demo/generic';

export const useGaitActions = (front = false) => {
  const dispatch = useDispatch();
  const stepsLeft = useRef(0);
  const stepsRight = useRef(0);
  const strideTimeLeft = useRef([]);
  const strideTimeRight = useRef([]);
  // const strideTimeWindowsLeft = useRef([]);
  // const strideTimeWindowsRight = useRef([]);
  const overPronatedLeft = useRef([]);
  const overPronatedRight = useRef([]);
  const stanceTimeLeft = useRef([]);
  const stanceTimeRight = useRef([]);
  const stepLengthLeft = useRef([]);
  const stepLengthRight = useRef([]);
  const stepTimeLengthLeft = useRef([]);
  const stanceTimeLengthLeft = useRef([]);
  const stanceTimeLengthRight = useRef([]);
  const stanceTimeWindowsLeft = useRef([]);
  const stanceTimeWindowsRight = useRef([]);
  const strideTimeWindowsLeft = useRef([]);
  const strideTimeWindowsRight = useRef([]);
  const stepTimeWindowsLeft = useRef([]);
  const stepTimeWindowsRight = useRef([]);
  const stepTimeLengthRight = useRef([]);
  const savedHipShoulderRWY = useRef(front);
  const stepValidityLeft = useRef([]);
  const stepValidityRight = useRef([]);
  const strideValidityLeft = useRef([]);
  const strideValidityRight = useRef([]);
  const invalidMarkerEvents = useRef([]);
  const validMarkerEvents = useRef([]);
  const dispatchAppendix = front ? '-FRONT' : '';

  const updateFeedback = (feedback) => {
    dispatch({
      type: `GAIT${dispatchAppendix}-FEEDBACK`,
      payload: {
        feedback: feedback,
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: `RESET-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: {},
    });
  });

  function safeHipShoulderRWU(personHeightRWU, pixelRWU, results) {
    if (savedHipShoulderRWY.current) return;
    const leftShoulder = results.poseLandmarks[11];
    const leftHip = results.poseLandmarks[23];
    const hipShoulderRWU = Math.abs(leftShoulder.y - leftHip.y) * pixelRWU;
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: {
        hipShoulderDelta: {
          pixels: Math.abs(leftShoulder.y - leftHip.y),
          RWU: hipShoulderRWU,
        },
      },
    });
    savedHipShoulderRWY.current = true;
  }

  const updateInvalidMarkerEvents = (time) => {
    if (invalidMarkerEvents.current.includes(time)) return;
    invalidMarkerEvents.current.push(time);
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: {
        invalidMarkerEvents: [...invalidMarkerEvents.current, time],
      },
    });
  };

  const updateValidMarkerEvents = (time) => {
    validMarkerEvents.current.push(time);
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: {
        validMarkerEvents: [...validMarkerEvents.current, time],
      },
    });
  };

  const updateSteps = (side) => {
    let payload = {};
    switch (side) {
      case 'left':
        if (
          stanceTimeWindowsLeft.current.slice(-1)[0] &&
          !(
            stanceTimeWindowsLeft.current.slice(-1)[0]?.end >=
              invalidMarkerEvents.current.slice(-1)[0] &&
            stanceTimeWindowsLeft.current.slice(-1)[0]?.start <=
              invalidMarkerEvents.current.slice(-1)[0]
          )
        ) {
          stepsLeft.current += 1;
          console.log(
            'Adding step left',
            stepsLeft.current,
            JSON.stringify(stanceTimeWindowsLeft.current.slice(-1)[0]),
            invalidMarkerEvents.current.slice(-1)[0]
          );
          payload = { stepsLeft: stepsLeft.current };
        }
        break;
      case 'right':
        if (
          stanceTimeWindowsRight.current.slice(-1)[0] &&
          !(
            stanceTimeWindowsRight.current.slice(-1)[0]?.end >=
              invalidMarkerEvents.current.slice(-1)[0] &&
            stanceTimeWindowsRight.current.slice(-1)[0]?.start <=
              invalidMarkerEvents.current.slice(-1)[0]
          )
        ) {
          stepsRight.current += 1;
          console.log(
            'Adding step right',
            stepsRight.current,
            JSON.stringify(stanceTimeWindowsRight.current.slice(-1)[0]),
            invalidMarkerEvents.current.slice(-1)[0]
          );
          payload = { stepsRight: stepsRight.current };
        }
        break;
      default:
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  const updateStrideTime = (strideTime, side) => {
    let payload = {};
    switch (side) {
      case 'left':
        strideTimeLeft.current.push(strideTime);
        payload = { strideTimeLeft: [...strideTimeLeft.current] };
        break;
      case 'right':
        strideTimeRight.current.push(strideTime);
        payload = { strideTimeRight: [...strideTimeRight.current] };
        break;
      default:
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  const updateStanceTime = (stanceTime, side) => {
    let payload = {};
    switch (side) {
      case 'left':
        stanceTimeLeft.current.push(stanceTime);
        payload = { stanceTimeLeft: [...stanceTimeLeft.current] };
        break;
      case 'right':
        stanceTimeRight.current.push(stanceTime);
        payload = { stanceTimeRight: [...stanceTimeRight.current] };
        break;
      default:
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  const updateStanceTimeWindow = (time, start, side) => {
    let payload = {};
    switch (side) {
      case 'left':
        if (start) {
          stanceTimeWindowsLeft.current.push({ start: time });
        } else {
          if (
            stanceTimeWindowsLeft.current[
              stanceTimeWindowsLeft.current.length - 1
            ]
          ) {
            stanceTimeWindowsLeft.current[
              stanceTimeWindowsLeft.current.length - 1
            ].end = time;
          }
        }
        payload = { stanceTimeWindowsLeft: [...stanceTimeWindowsLeft.current] };
        break;
      case 'right':
        if (start) {
          stanceTimeWindowsRight.current.push({ start: time });
        } else {
          if (
            stanceTimeWindowsRight.current[
              stanceTimeWindowsRight.current.length - 1
            ]
          ) {
            stanceTimeWindowsRight.current[
              stanceTimeWindowsRight.current.length - 1
            ].end = time;
          }
        }
        payload = {
          stanceTimeWindowsRight: [...stanceTimeWindowsRight.current],
        };
        break;
      default:
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  const updateStepTimeWindow = (time, start, side) => {
    let payload = {};
    switch (side) {
      case 'left':
        if (start) {
          stepTimeWindowsLeft.current.push({ start: time });
        } else {
          if (
            stepTimeWindowsLeft.current[stepTimeWindowsLeft.current.length - 1]
          ) {
            stepTimeWindowsLeft.current[
              stepTimeWindowsLeft.current.length - 1
            ].end = time;
          }
        }
        payload = { stepTimeWindowsLeft: [...stepTimeWindowsLeft.current] };
        break;
      case 'right':
        if (start) {
          stepTimeWindowsRight.current.push({ start: time });
        } else {
          if (
            stepTimeWindowsRight.current[
              stepTimeWindowsRight.current.length - 1
            ]
          ) {
            stepTimeWindowsRight.current[
              stepTimeWindowsRight.current.length - 1
            ].end = time;
          }
        }
        payload = {
          stepTimeWindowsRight: [...stepTimeWindowsRight.current],
        };
        break;
      default:
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  const updateStrideTimeWindow = (time, start, side) => {
    let payload = {};
    switch (side) {
      case 'left':
        if (start) {
          strideTimeWindowsLeft.current.push({ start: time });
        } else {
          if (
            strideTimeWindowsLeft.current[
              strideTimeWindowsLeft.current.length - 1
            ]
          ) {
            strideTimeWindowsLeft.current[
              strideTimeWindowsLeft.current.length - 1
            ].end = time;
          }
        }
        payload = { strideTimeWindowsLeft: [...strideTimeWindowsLeft.current] };
        break;
      case 'right':
        if (start) {
          strideTimeWindowsRight.current.push({ start: time });
        } else {
          if (
            strideTimeWindowsRight.current[
              strideTimeWindowsRight.current.length - 1
            ]
          ) {
            strideTimeWindowsRight.current[
              strideTimeWindowsRight.current.length - 1
            ].end = time;
          }
        }
        payload = {
          strideTimeWindowsRight: [...strideTimeWindowsRight.current],
        };
        break;
      default:
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  const updateStepLength = (stepLength, side, time) => {
    let payload = {};
    switch (side) {
      case 'left':
        stepLengthLeft.current.push({ value: stepLength, time });
        payload = { stepLengthLeft: [...stepLengthLeft.current] };
        break;
      case 'right':
        stepLengthRight.current.push({ value: stepLength, time });
        payload = { stepLengthRight: [...stepLengthRight.current] };
        break;
      default:
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  const updateOverPronated = (overPronated, side) => {
    let payload = {};
    switch (side) {
      case 'left':
        overPronatedLeft.current.push(overPronated);
        payload = { overPronatedLeft: [...overPronatedLeft.current] };
        break;
      case 'right':
        overPronatedRight.current.push(overPronated);
        payload = { overPronatedRight: [...overPronatedRight.current] };
        break;
      default:
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  const updateStepTimeLength = (side) => {
    let payload = {};
    switch (side) {
      case 'left':
        stepTimeLengthLeft.current.push(
          (strideTimeRight.current[strideTimeRight.current.length - 1] || 0) +
            strideTimeLeft.current[strideTimeLeft.current.length - 1]
        );
        payload = { stepTimeLengthLeft: [...stepTimeLengthLeft.current] };
        break;
      case 'right':
        stepTimeLengthRight.current.push(
          (strideTimeLeft.current[strideTimeLeft.current.length - 1] || 0) +
            strideTimeRight.current[strideTimeRight.current.length - 1]
        );
        payload = { stepTimeLengthRight: [...stepTimeLengthRight.current] };
        break;
      default:
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  const updateStanceTimeLength = (side) => {
    let payload = {};
    switch (side) {
      case 'left':
        stanceTimeLengthLeft.current.push(
          (stanceTimeRight.current[stanceTimeRight.current.length - 1] || 0) +
            stanceTimeLeft.current[stanceTimeLeft.current.length - 1]
        );
        payload = { stanceTimeLengthLeft: [...stanceTimeLengthLeft.current] };
        break;
      case 'right':
        stanceTimeLengthRight.current.push(
          (stanceTimeLeft.current[stanceTimeLeft.current.length - 1] || 0) +
            stanceTimeRight.current[stanceTimeRight.current.length - 1]
        );
        payload = { stanceTimeLengthRight: [...stanceTimeLengthRight.current] };
        break;
      default:
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  function getLastIndexValue(array) {
    if (!array.length) return undefined;
    return array[array.length - 1];
  }

  const updateStepValidity = (valid, side) => {
    let payload = {};
    switch (side) {
      case 'left':
        stepValidityLeft.current.push(valid);
        if (!valid && getLastIndexValue(stepValidityRight.current)) {
          stepValidityRight.current[stepValidityRight.current.length - 1] =
            valid;
        }
        payload = {
          stepValidityLeft: [...stepValidityLeft.current],
          stepValidityRight: [...stepValidityRight.current],
        };
        break;
      case 'right':
        stepValidityRight.current.push(valid);
        if (!valid && getLastIndexValue(stepValidityLeft.current)) {
          stepValidityLeft.current[stepValidityLeft.current.length - 1] = valid;
        }
        payload = {
          stepValidityRight: [...stepValidityRight.current],
          strideValidityLeft: [...strideValidityLeft.current],
        };
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  const updateStrideValidity = (valid, side) => {
    let payload = {};
    switch (side) {
      case 'left':
        strideValidityLeft.current.push(valid);
        if (!valid && getLastIndexValue(strideValidityRight.current)) {
          strideValidityRight.current[strideValidityRight.current.length - 1] =
            valid;
        }
        payload = {
          strideValidityLeft: [...strideValidityLeft.current],
          strideValidityRight: [...strideValidityRight.current],
        };
        break;
      case 'right':
        strideValidityRight.current.push(valid);
        if (!valid && getLastIndexValue(strideValidityLeft.current)) {
          strideValidityLeft.current[strideValidityLeft.current.length - 1] =
            valid;
        }
        payload = {
          strideValidityRight: [...strideValidityRight.current],
          strideValidityLeft: [...strideValidityLeft.current],
        };
        break;
    }
    dispatch({
      type: `UPDATE-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: payload,
    });
  };

  function appendCenterOfMassData(time, centerOfMassData) {
    dispatch({
      type: `APPEND-COM-GAIT${dispatchAppendix}-OBSERVATION`,
      payload: { x: time, y: centerOfMassData },
    });
  }

  return {
    updateFeedback,
    updateSteps,
    updateStrideTime,
    updateStanceTime,
    updateStepLength,
    updateStepTimeLength,
    appendCenterOfMassData,
    safeHipShoulderRWU,
    updateStepValidity,
    updateStanceTimeLength,
    updateStanceTimeWindow,
    updateStrideTimeWindow,
    updateStepTimeWindow,
    updateOverPronated,
    updateInvalidMarkerEvents,
    updateValidMarkerEvents,
    updateStrideValidity,
    invalidMarkerEvents: invalidMarkerEvents,
  };
};

export function useGaitPatientActions() {
  const dispatch = useDispatch();

  const updatePatientInfo = (patientInfo) => {
    dispatch({
      type: `UPDATE-GAIT-PATIENT-INFO`,
      payload: patientInfo,
    });
  };

  return {
    updatePatientInfo,
  };
}

export function useGaitResultsActions(exerciseType = 'gaitAnalysis') {
  const dispatch = useDispatch();
  const { user } = useContext(FirebaseAuthContext);
  const patientInfo = useSelector((state) => state.gaitPatientInfo.values);

  function resetGaitResults() {
    dispatch({
      type: `RESET-GAIT-RESULTS`,
      payload: {},
    });
    dispatch({
      type: `RESET-LAST-GAIT-RESULTS`,
      payload: {},
    });
  }

  const addResults = async () => {
    console.log('getting patient data');

    async function addResultWithRetry(payload, retryCount = 3, timeout = 3000) {
      try {
        return await withTimeout(gaitFirestore.addResult(payload), timeout);
      } catch (error) {
        if (retryCount <= 0) {
          window.location.reload();
        } else {
          console.error(`Retrying... attempts left: ${retryCount}`);
          return await addResultWithRetry(payload, retryCount - 1, timeout);
        }
      }
    }

    // Usage
    const patientDoc = await gaitFirestore.getPatient({ id: patientInfo.id });
    const payload = {
      userId: user.uid,
      patientId: patientInfo.id,
      doctorId: patientDoc.doctorId,
      exerciseType,
    };
    console.log('adding results', payload);
    let result;
    try {
      result = await addResultWithRetry(
        { ...payload, id: crypto.randomUUID() },
        2,
        4000
      ); // retries 3 times with a 10-second timeout
      console.log('Result added:', result);
    } catch (error) {
      console.error('Failed to add result:', error);
    }

    console.log('adding results', result);
    dispatch({
      type: `ADD-GAIT-RESULTS`,
      payload: result || payload,
    });
    return result;
  };

  const fetchLastResults = async ({
    startDate = null,
    endDate = null,
    n = 1,
  }) => {
    dispatch({
      type: `SET-LAST-GAIT-RESULTS-LOADING-STATE`,
      payload: {
        loadingResults: true,
      },
    });
    console.log('fetching last results', patientInfo.id, user.uid);
    const payload = {
      docs: await gaitFirestore.getLastResults({
        patientId: patientInfo.id,
        userId: user.uid,
        exerciseType,
        startDate,
        endDate,
        n,
      }),
    };
    dispatch({
      type: `SET-LAST-GAIT-RESULTS-LOADING-STATE`,
      payload: {
        loadingResults: false,
      },
    });
    if (!payload.docs) return [];
    dispatch({
      type: `SET-LAST-GAIT-RESULTS`,
      payload: payload,
    });
    return payload;
  };

  return {
    fetchLastResults,
    addResults,
    resetGaitResults,
  };
}

export function useUpdatingGaitResultsActions() {
  const dispatch = useDispatch();

  async function updateResultWithRetry(
    id,
    payload,
    retryCount = 3,
    timeout = 7000
  ) {
    try {
      return await withTimeout(
        gaitFirestore.updateResult({ id, payload }),
        timeout
      );
    } catch (error) {
      if (retryCount <= 0) {
        // window.location.reload();
      } else {
        console.error(`Retrying... attempts left: ${retryCount}`, error);
        return await updateResultWithRetry(
          id,
          payload,
          retryCount - 1,
          timeout
        );
      }
    }
  }

  const updateResults = async ({ id, payload }) => {
    if (payload) {
      dispatch({
        type: `UPDATE-GAIT-RESULTS`,
        payload: payload,
      });
    }
    await updateResultWithRetry(id, payload, 2, 120000);
  };

  const updateResultsLocally = (payload) => {
    dispatch({
      type: `UPDATE-GAIT-RESULTS`,
      payload: payload,
    });
  };

  return { updateResults, updateResultsLocally };
}
