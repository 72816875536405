import { Dialog } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import CloseBadge from '../CloseBadge';
import ScoreConfig from '../ScoreConfig';

export default function ScoringDialog({
  onClose,
  open,
  config,
  setConfig,
  exercise,
}) {
  return (
    <Dialog
      maxWidth="lg" // 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
      fullWidth={true}
      onClose={onClose}
      open={open}
    >
      <Badge
        badgeContent={<CloseBadge setConfig={onClose} sx={{ mt: 5, mr: 5 }} />}
      >
        <DialogTitle sx={{ mr: 5, mb: 5 }}>Edit Scoring</DialogTitle>
        <Box sx={{ border: 1, mt: 1, mb: 1, mr: 1, width: '100%', padding: 5 }}>
          <ScoreConfig
            exerciseType={exercise?.type}
            config={config?.scoreConfig || exercise?.scoreConfig}
            setConfig={setConfig}
          />
        </Box>
      </Badge>
    </Dialog>
  );
}
