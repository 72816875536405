export function drawLinearConnectors(ctx, backbones) {
  Object.keys(backbones).forEach((key) => {
    const from = backbones[key];
    const to = backbones[Number(key) + 1];
    if (!from || !to) return;
    if (from.x < 80 && from.y < 80) return;

    ctx.beginPath();
    ctx.moveTo(from.x, from.y);
    ctx.lineTo(to.x, to.y);
    ctx.strokeStyle = '#FFFFFF';
    ctx.lineWidth = 6;
    ctx.stroke();
  });
}
