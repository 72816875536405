import { blazePoseCoordinateNames } from './coordinates';

export const conditionMet = (
  condition,
  results,
  assignedReferences,
  angles
) => {
  try {
    if (condition.type === 'angle') {
      return angleConditionMet(condition, angles);
    } else {
      return coordinatesConditionMet(condition, results, assignedReferences);
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const angleConditionMet = (condition, angles) => {
  let angle = angles.current[condition.ref?.name || condition.ref];
  if (condition.clip) {
    angle = angle > condition.clip ? 0 : angle;
  }
  return applyThresholdOperator(angle, condition.operator, condition.value);
};

export const coordinatesConditionMet = (
  condition,
  results,
  assignedReferences
) => {
  const {
    ref,
    operator,
    value,
    coordinateOperatingRef,
    coordinateOperator,
    axis,
  } = condition;
  let refValue;
  if (blazePoseCoordinateNames[ref]) {
    refValue = blazePoseCoordinateNames[ref](results);
  } else {
    refValue = assignedReferences.current[ref];
  }
  if (!coordinateOperatingRef)
    return applyThresholdOperator(refValue[axis], operator, value);

  let coordinateOperatingRefValue;
  if (blazePoseCoordinateNames[coordinateOperatingRef]) {
    coordinateOperatingRefValue =
      blazePoseCoordinateNames[coordinateOperatingRef](results);
  } else {
    coordinateOperatingRefValue =
      assignedReferences.current[coordinateOperatingRef];
  }
  const appliedCoordinateOperatingRefValue = applyRefOperator(
    coordinateOperatingRefValue[axis],
    coordinateOperator,
    value
  );
  return applyThresholdOperator(
    refValue[axis],
    operator,
    appliedCoordinateOperatingRefValue
  );
};

const applyThresholdOperator = (value, operator, threshold) => {
  value = parseFloat(value);
  threshold = parseFloat(threshold);
  switch (operator) {
    case '>':
      return value > threshold;
    case '<':
      return value < threshold;
    case '==':
      return value === threshold;
    case '>=':
      return value >= threshold;
    case '<=':
      return value <= threshold;
    default:
      return false;
  }
};

const applyRefOperator = (ref, operator, value) => {
  ref = parseFloat(ref);
  value = parseFloat(value);
  switch (operator) {
    case '*':
      return ref * value;
    case '/':
      return ref / value;
    case '+':
      return ref + value;
    case '-':
      return ref - value;
    default:
      return ref;
  }
};
