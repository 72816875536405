import * as React from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import Modal from '@mui/material/Modal';
import './schedule.css';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import Cal, { getCalApi } from '@calcom/embed-react';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../../constants';
import { useState } from 'react';
import CustomDialog from '../Dialog';

const TodaySessionB2cComponent = ({ data }) => {
  const { t } = useTranslation();
  const [showCal, setShowCal] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [load] = React.useState(false);
  const [msg] = React.useState('');
  const [counter, setCounter] = React.useState('1');
  const [teacherName, setTeacherName] = useState('NA');
  const [openDialog, setOpenDialog] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  let { name, email, user_details } = JSON.parse(
    localStorage.getItem('user_data')
  );

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleUpgrade = async () => {
    setTimeout(async () => {
      window.open('https://www.exar.fit/subscriptions', '_blank') ||
        window.location.replace('https://www.exar.fit/subscriptions');
    }, 1500);
  };

  const closeModal = () => {
    setShowCal(false);
  };

  const counterFetch = async () => {
    await fetchEvent({ email });
  };
  useEffect(() => {
    counterFetch();
  }, [counter]);

  React.useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, [10000]);
    }
  }, [showAlert]);

  const saveEvent = async (data) => {
    const url = `${baseUrl}/event-details`;
    try {
      const saveEventPayload = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      if (
        saveEventPayload.status === 200 &&
        saveEventPayload.data.message === 'success'
      ) {
        console.log('Event saved sucessfully!');
      } else {
        console.log('Something went wrong while saving the event!!');
      }
    } catch (error) {
      console.log('Error', error ? error.message : error);
    }
  };

  const fetchEvent = async (data) => {
    const url = `${baseUrl}/event-details-user`;
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200 && response.data.message === 'success') {
        setAllEvents(response.data.event_details_calendar_fetch);
        setTeacherName(
          response.data.event_details_calendar_fetch[0].assigned_teacher
        );
        setCounter(response.data.event_details_calendar_fetch.length);
      } else {
        console.log('Something went wrong while fetching the event!!');
      }
    } catch (error) {
      console.log('Error', error ? error.message : error);
    }
  };

  const handleCalender = async (e) => {
    console.log('Hey Inside handleCalender');
    e.preventDefault();
    setShowCal(true);
    (async function () {
      const cal = await getCalApi();
      cal('ui', {
        styles: { branding: { brandColor: '#000000' } },
        hideEventTypeDetails: true,
      });
      cal('on', {
        action: '*',
        callback: async (e) => {
          if (e.detail.type === 'bookingSuccessful') {
            let data = {
              title: e.detail.data.booking.title,
              id: e.detail.data.booking.id,
              uid: e.detail.data.booking.uid,
              startTime: e.detail.data.booking.startTime,
              endTime: e.detail.data.booking.endTime,
              metadata: {
                videoCallUrl: e.detail.data.booking.metadata.videoCallUrl,
              },
              user: {
                name: e.detail.data.booking.user.name,
                email: e.detail.data.booking.user.email,
                username: e.detail.data.booking.user.username,
              },
              attendees: {
                name: e.detail.data.booking.attendees[0].name,
                email: e.detail.data.booking.attendees[0].email,
              },
              rescheduleLink: `https://app.cal.com/vinay-sl-exar/30min?rescheduleUid=${e.detail.data.booking.uid}`,
            };
            await saveEvent(data);
          }
        },
      });
    })();
  };

  return (
    <div className="today-session-container">
      {showAlert && (
        <Alert
          severity="error"
          variant="filled"
          onClose={() => setShowAlert(false)}
        >
          <strong>{msg}</strong>
        </Alert>
      )}
      <div className="box-container">
        <div className="today-session-box">{t('SessionComponent.title')}</div>
      </div>

      <div className="list list-container">
        <div className="schedule-left left">
          <EventNoteRoundedIcon />
          <span>{t('SessionComponent.class')}</span>
        </div>
        <div className="right">
          <span>{data && data.class_no ? data.class_no : 'NA'}</span>
        </div>
        <div className="schedule-left left">
          <AccessTimeRoundedIcon />
          <span>{t('SessionComponent.Teacher')}</span>
        </div>
        <div className="right">
          <span>{teacherName}</span>
        </div>
        <div className="schedule-left left">
          <CandlestickChartOutlinedIcon />
          <span>{t('SessionComponent.SessionLeft')}</span>
        </div>
        <div className="right">
          {/* <span>{t('SessionComponent.SessionLeft')} 1</span> */}
          <span>{counter > 4 ? 'No session left!' : 4 - counter}</span>
        </div>
      </div>
      <p>
        <div className="schedule-divider"></div>
        {data ? data.session_desc : t('SessionComponent.excercise-description')}
      </p>
      <div className="join-session-btn-container">
        {load ? (
          <CircularProgress size={25} color="success" />
        ) : (
          <Button
            type="submit"
            onClick={handleCalender}
            disabled={user_details.membership === 'trial'}
            variant="contained"
            className="join-session-btn"
            sx={{
              width: 400,
              borderRadius: 2,
              fontSize: '1.5rem',
              backgroundColor: '#FF592C',
            }}
          >
            {t('SessionComponent.bookSession')}
          </Button>
        )}
      </div>
      <div className="join-session-btn-container">
        {load ? (
          <CircularProgress size={25} color="success" />
        ) : (
          <Button
            type="submit"
            variant="contained"
            className="join-session-btn"
            disabled={user_details.membership === 'trial'}
            sx={{
              width: 400,
              borderRadius: 2,
              fontSize: '1.5rem',
              backgroundColor: '#FF592C',
            }}
            onClick={handleOpenDialog}
          >
            {t('SessionComponent.reschedule')}
          </Button>
        )}
      </div>
      <div className="custom-dialog">
        <CustomDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          title="Reschedule your Session!"
          actions={[{ label: 'Cancel', onClick: handleCloseDialog }]}
        >
          {allEvents &&
            allEvents.map((e) => (
              // eslint-disable-next-line react/jsx-key
              <Button
                type="submit"
                variant="contained"
                className="join-session-btn"
                sx={{
                  width: 'auto',
                  height: 'auto',
                  borderRadius: 2,
                  fontSize: '0.8rem',
                  backgroundColor: '#FF592C',
                }}
              >
                <a target="_blank" href={e.reschedule_link} rel="noreferrer">
                  {e.meeting_start.split('T')[0]} -{' '}
                  {e.meeting_start.split('T')[1].split('.')[0]}
                </a>
              </Button>
            ))}
          {allEvents.length === 0 && <p>No session available to Reschedule!</p>}
        </CustomDialog>
      </div>
      <div className="join-session-btn-container">
        {load ? (
          <CircularProgress size={25} color="success" />
        ) : (
          <Button
            type="submit"
            variant="contained"
            className="join-session-btn"
            sx={{
              width: 400,
              borderRadius: 2,
              fontSize: '1.5rem',
              backgroundColor: '#FF592C',
            }}
            onClick={() => handleUpgrade()}
          >
            {t('SessionComponent.upgrade')}
          </Button>
        )}
      </div>

      <Modal
        open={showCal}
        onClose={() => closeModal()}
        sx={{
          margin: '7% 0% 0% 0%',
          alignContent: 'flex-end',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {
          <Cal
            calLink="vinay-sl-exar/30min"
            style={{ width: '80%', height: '80%', overflow: 'scroll' }}
            config={{
              name: `${name}`,
              email: `${email}`,
              notes: 'Workout for stronger muscles',
              // guests: ["janedoe@gmail.com"],
              theme: 'dark',
            }}
          />
        }
      </Modal>
    </div>
  );
};

export default TodaySessionB2cComponent;
