import React from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

export default function StartButtonOverlay({ handleStart }) {
  return (
    <Box
      sx={{
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transform: 'scaleX(-1)',
        paddingLeft: 20,
        height: '100%',
        zIndex: 1000000000,
        '&::hover': {
          backgroundColor: 'white',
        },
      }}
    >
      <IconButton size="large" edge="start" onClick={() => handleStart(true)}>
        <PlayCircleOutlineIcon style={{ fontSize: 300, color: 'white' }} />
      </IconButton>
    </Box>
  );
}
