let current_env = 'prod'; //prod //loc

export const baseUrl =
  current_env === 'prod' ? 'https://api.exar.fit' : 'http://localhost:3000';
export const AppUrl =
  current_env === 'prod' ? 'https://app.exar.fit' : 'http://localhost:3001';
export const chaturl = 'https://api.exar.fit/chat';
export const aiUrl = 'https://backai.exar.fit';
export const googleclientId =
  '517337941812-iv5ks5ncrljjm9dn2s0on3tkiroumokm.apps.googleusercontent.com';
export const apiToken = localStorage.getItem('token');
