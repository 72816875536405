import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import * as React from 'react';
import { Paper } from '@mui/material';
import { useStore } from 'react-redux';
import FirebaseAuthContext from '../../../../../contexts/auth/FirebaseAuthContext';
import { useGaitResultsActions } from '../../../../../hooks/GaitAnalysis/actions';
import ControlledDateRangePicker from '../../ControlledDateRangePicker';
import { HistoricalResultsBox } from '../../HistoricalResultsBox';

HistoricalResultsBox.propTypes = {
  dataKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  name: PropTypes.string,
};
export default function HistoricalResults() {
  const { fetchLastResults } = useGaitResultsActions('sitAndStand');
  const store = useStore();
  const { user } = useContext(FirebaseAuthContext);
  const [data, setData] = React.useState([]);
  const [dateRange, setDateRange] = React.useState([
    dayjs().subtract(1, 'month'),
    dayjs(),
  ]);

  React.useEffect(() => {
    if (!user) return;
    const lastGaitResults = store.getState().lastGaitResults;
    if (lastGaitResults.docs.length === 0) {
      fetchLastResults({
        startDate: dateRange[0],
        endDate: dateRange[1],
        n: 2,
      }).then((fetchedData) => fetchedData && setData(fetchedData.docs));
    } else {
      setData(lastGaitResults.docs);
    }
  }, [user]);

  React.useEffect(() => {
    if (!user) return;
    fetchLastResults({
      startDate: dateRange[0],
      endDate: dateRange[1],
      n: 100,
    }).then((fetchedData) => {
      setData(fetchedData.docs);
    });
  }, [dateRange, user]);

  return (
    <div>
      <Paper sx={{ paddingBottom: 2, marginBottom: -2 }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '50%',
              border: 0.5,
              borderRadius: 5,
              padding: 3,
              boxShadow: 3,
              mt: 1,
            }}
          >
            <ControlledDateRangePicker
              value={dateRange}
              setValue={setDateRange}
            />
          </Box>
        </div>
        <Box sx={{ height: '75vh' }}>
          <Grid container spacing={2} sx={{ padding: 2, height: '50%' }}>
            <Grid item xs={6}>
              <HistoricalResultsBox
                data={data}
                name={'Score'}
                dataKey={'score'}
              />
            </Grid>
            <Grid item xs={6}>
              <HistoricalResultsBox
                data={data}
                name={'Counts'}
                dataKey={'counts'}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
