import React, { useEffect } from 'react';
import { useState } from 'react';
import './ranking.css';
import { Divider } from '@mui/material';
import ClubRow from './ClubRow';
import { useTranslation } from 'react-i18next';

const AllAgeClubs = ({ details }) => {
  console.log('detailsdetailsdetails', details);
  const { t } = useTranslation();
  const [ar, setAr] = useState([]);
  useEffect(() => {
    if (details) {
      if (details[0]) {
        setAr(details[0]);
      }
    }
  }, [ar]);
  return (
    <div className="all-students-container ranking-article">
      <div className="all-students-navigation">
        <div className="title">
          <div className="violet-rect" />
          <h1>{t('AllAgeClubs.title')}</h1>
        </div>
        {/* <SearchBar
                    className="search-input"
                    setFilter={setFilter}
                    filter={filter}
                /> */}
      </div>
      <div className="gridder">
        <span>{t('AllAgeClubs.rank')}</span>
        <span>{t('AllAgeClubs.clubname')}</span>
        <span>{t('AllAgeClubs.agegroup')}</span>
        <span>{t('PointsComponent.xrCoins')}</span>
        <span>{t('AllAgeClubs.sweatpoints')}</span>
        <span>{t('AllAgeClubs.totalmembers')}</span>
        {/* <span>{t('AllAgeClubs.rank')}</span>
                <span>{t('AllAgeClubs.workoutCompleted')}</span>
                <span>{t('PointsComponent.xrCoins')}</span> */}
      </div>
      <Divider style={{ backgroundColor: 'rgba(0, 0, 0, .12)' }} />
      {ar &&
        ar.map((item, index) => (
          <ClubRow key={item + index} details={item} index={index} />
        ))}
      {/* {ar && ar.map((item, index) => <StudentRow details={item} />)} */}
    </div>
  );
};

export default AllAgeClubs;
