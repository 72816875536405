import React, { useEffect } from 'react';
import { useState } from 'react';
import Pose1 from '../../assets/Pose_03.png';
import Planet from '../../assets/planetb.png';
import Ring from '../../assets/ring.png';

const BgComponent = ({ pageName }) => {
  const [astroPos, setAstroPos] = useState(25);
  const [dashboardAstro, setDashboardAstro] = useState(10);

  useEffect(() => {
    if (document.body.clientWidth < 2600) {
      setAstroPos(20);
    } else if (document.body.clientWidth < 1600) {
      setAstroPos(45);
    }
    window.addEventListener('resize', () => {
      if (document.body.clientWidth < 2600) {
        setAstroPos(20);
      } else if (document.body.clientWidth < 1600) {
        setAstroPos(45);
      }
    });
  });

  useEffect(() => {
    if (document.body.clientWidth < 1750) {
      setDashboardAstro(15);
    } else if (document.body.clientWidth > 1750) {
      setDashboardAstro(10);
    }
    window.addEventListener('resize', () => {
      if (document.body.clientWidth < 1750) {
        setDashboardAstro(15);
      } else if (document.body.clientWidth > 1750) {
        setDashboardAstro(10);
      }
    });
  });

  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        zIndex: -100,
        backgroundColor: '#FCFCF9',
        overflow: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <img
        src={pageName == 'schedule' ? Ring : Pose1}
        alt="Exar"
        style={{
          objectFit: 'contain',
          position: 'fixed',
          top: pageName == 'schedule' ? '-10%' : '20%',
          left: pageName == 'schedule' ? '1%' : '-20%',
          height: pageName == 'schedule' ? '60%' : '90%',
          width: pageName == 'schedule' ? '20%' : '55%',
        }}
      />
      <img
        src={pageName == 'schedule' ? Pose1 : Planet}
        alt="Exar"
        style={{
          objectFit: 'contain',
          position: 'fixed',
          top: '25%',
          right: pageName == 'schedule' ? `${astroPos}%` : `${dashboardAstro}%`,
          height: pageName == 'schedule' ? '80%' : '45%',
          width: pageName == 'schedule' ? '45%' : '40%',
        }}
      />
    </div>
  );
};

export default BgComponent;
