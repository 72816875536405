import ForwardIcon from '@mui/icons-material/Forward';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { useEffect } from 'react';
import * as React from 'react';
import AnglesChart from './GaitAnalysis/results/AnglesChart';

export function HistoricalResultsBox({
  data,
  dataKey,
  name,
  mirror = false,
  worsenedText = 'declined',
  improvedText = 'improved',
}) {
  const [chartSeries, setChartSeries] = React.useState([0]);
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    if (!data) return;
    if (data.length === 0) return;
    const series = data
      .map((item) => {
        if (!item.metrics) {
          return;
        }
        const date = new Date(item.timeStamp);
        return {
          y: item.metrics[dataKey] * (mirror ? -1 : 1),
          x: date.getTime(),
        };
      })
      .filter((item) => item !== undefined);
    if (series[0] && series[series.length - 1]) {
      setChartSeries(series);
      if (data.length <= 1) return;
      setProgress(
        (1 - series[series.length - 1].y / series[0].y) *
          100 *
          (mirror ? -1 : 1)
      );
    }
  }, [data]);

  return (
    <Grid container spacing={2} sx={{ padding: 2, height: '100%' }}>
      <Grid item xs={6}>
        <AnglesChart
          chartKey={`${name.toLowerCase()}-history-chart`}
          data={[
            {
              name: name,
              data: chartSeries,
            },
          ]}
          height={'100%'}
          slice={false}
          title={name}
          symmetryIndex={false}
          numericX={false}
        />
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '33vh',
            border: 1,
            borderColor: 'grey.500',
            borderRadius: 5,
            boxShadow: 5,
            padding: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: 18,
              color: progress >= 0 ? 'green' : 'red',
              ml: 3,
              mr: 0,
              mb: 3,
            }}
          >
            {progress >= 0
              ? `Amazing! Your ${name.toLowerCase()} ${improvedText} by`
              : `Your ${name.toLowerCase()} ${worsenedText} by:`}
          </Typography>
          <Typography
            sx={{
              fontSize: 30,
              fontWeight: 'bold',
              marginTop: 0,
              color: progress >= 0 ? 'green' : 'red',
            }}
          >
            {progress >= 0 ? '+' : ''}
            {progress.toFixed(1).slice(1).toLowerCase() !== 'infinity'
              ? progress.toFixed(1) + '%'
              : '∞'}
          </Typography>
          <ForwardIcon
            sx={{
              fontSize: 70,
              color: progress >= 0 ? 'green' : 'red',
              transform: `rotate(${_.clamp(-progress / 2, -90, 90)}deg)`,
              transition: 'transform 0.3s ease-in-out', // smooth the rotation
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
