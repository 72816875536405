import { TextField } from '@mui/material';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import React, { useRef } from 'react';
import BasicSelect from '../BasicSelect';
import CloseBadge from '../CloseBadge';

export default function Recommendation({
  schedules,
  recommendation,
  setRecommendation,
  deleteRecommendation,
}) {
  const scheduleRef = useRef(recommendation?.schedule);
  const operatorRef = useRef(recommendation?.operator);
  const valueRef = useRef(recommendation?.value);

  return (
    <Badge
      sx={{ width: '100%', boxShadow: 3, padding: 2 }}
      badgeContent={
        <CloseBadge setConfig={deleteRecommendation} sx={{ mt: 5, mr: 5 }} />
      }
    >
      <Grid item xs={5}>
        <BasicSelect
          sx={{ m: 5, width: '100%' }}
          label="Select Schedule"
          options={schedules?.map((schedule) => ({
            name: schedule.name,
            id: schedule.id,
          }))}
          value={scheduleRef.current}
          setValue={(schedule) => {
            scheduleRef.current = schedule;
            setRecommendation({
              ...recommendation,
              schedule: scheduleRef.current,
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <BasicSelect
          sx={{ m: 5, width: '100%' }}
          label={'Operator'}
          options={['>', '<', '>=', '<=', '==']}
          value={operatorRef.current}
          setValue={(operator) => {
            operatorRef.current = operator;
            setRecommendation({
              ...recommendation,
              operator: operatorRef.current,
            });
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          sx={{ m: 5, width: '100%' }}
          label={'Value'}
          type={'number'}
          value={valueRef.current}
          onChange={(e) => {
            valueRef.current = e.target.value;
            setRecommendation({
              ...recommendation,
              value: valueRef.current,
            });
          }}
        />
      </Grid>
    </Badge>
  );
}
