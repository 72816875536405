const initialState = {
  id: null,
  createdAt: null,
  updatedAt: null,
  updatedBy: null,
  createdBy: null,
  name: null,
  view: null,
  timeThresh: null,
  global: { feedback: [] },
  states: [],
  videoUrl: null,
  introAudioUrl: null,
};

export const exerciseConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET-EXERCISE-CONFIG': {
      return { ...initialState, ...action.payload };
    }
    case 'RESET-EXERCISE-CONFIG':
      return initialState;
    default: {
      return state;
    }
  }
};
