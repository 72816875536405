import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BalanceFeedbackGenerator from '../../components/Demo/ExerciseManagement/Generators/BalanceFeedbackGenerator';
import CountsFeedbackGenerator from '../../components/Demo/ExerciseManagement/Generators/CountsFeedbackGenerator';
import StretchCountFeedbackGenerator from '../../components/Demo/ExerciseManagement/Generators/StretchCountFeedbackGenerator';
import StretchFeedbackGenerator from '../../components/Demo/ExerciseManagement/Generators/StretchFeedbackGenerator';
import {
  BalanceFeedbacks,
  CountsInTimeFeedbacks,
  Feedback,
  StretchCountFeedbacks,
  StretchFeedbacks,
} from '../../components/Demo/Feedbacks';
import PoseDetection from '../../components/Demo/PoseDetection';
import FirebaseAuthContext from '../../contexts/auth/FirebaseAuthContext';
import {
  getIntroAudioUrl,
  getSummary,
  getVideoUrl,
  setHardCodedComponents,
} from '../../utilities/Arena/demoAnimation';
import {
  assessmentComponentsMap,
  exhibitionAssessmentVideos,
  fitnessAssessmentVideos,
  videoMap,
} from '../../utilities/Demo/demoComponents';
import {
  getAngle,
  getExercise,
} from '../../utilities/Demo/ExerciseManagement/firestore';
import { addExerciseResult } from '../../utilities/Demo/firestore';
import { parseTimeToSeconds } from '../../utilities/Demo/generic';
import { addResult } from '../../utilities/Demo/Physio/GaitAnalysis/firestore';
import { useIntroAudio, useTimer } from '../Demo/sharedLogic';
import { debounce } from 'lodash';
import { commonSensorDataProcessing } from '../../utilities/Demo/SensorDataResults/commonSensorDataProcessing';
import _ from 'lodash';

export const useAddResultToDB = ({ demoType }) => {
  const iteration = useSelector(({ iter }) => iter.values.iteration);
  const iter = useSelector(({ iter }) => iter.values);
  const { user } = React.useContext(FirebaseAuthContext) || {};
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );
  const metrics = useSelector(({ iter }) => iter.values.metrics);
  const metricsRef = useRef(metrics);

  const scores = useSelector(({ iter }) => iter.values.scores);
  const scoresRef = useRef(scores);

  useEffect(() => {
    metricsRef.current = metrics;
  }, [metrics]);

  useEffect(() => {
    scoresRef.current = scores;
  }, [scores]);

  function updateMetricsWithAdditionalData() {
    if (iter.exerciseTypes[iteration] === 'stretchCount') {
      if (!metricsRef.current[iteration]) metricsRef.current[iteration] = [];
      if (
        metricsRef.current.findIndex(
          (metric) => metric?.name === 'Air Time'
        ) !== -1
      ) {
        console.log(metricsRef.current, 'already added');
        return;
      }
      metricsRef.current[iteration].push({
        name: 'Air Time',
        subMetrics: [
          {
            name: 'Mean [ms]',
            value: _.mean(iter.results[iteration]?.stretchTimes),
          },
          {
            name: 'Max [ms]',
            value: _.max(iter.results[iteration]?.stretchTimes),
          },
        ],
      });
      if (
        metricsRef.current.findIndex(
          (metric) => metric?.name === 'Measure Units'
        ) !== -1
      ) {
        console.log(metricsRef.current, 'already added');
        return;
      }
      metricsRef.current[iteration].push({
        name: 'Measure Units',
        subMetrics: [
          {
            name: 'Mean',
            value: _.mean(iter.results[iteration]?.units),
          },
          {
            name: 'Max',
            value: _.max(iter.results[iteration]?.units),
          },
        ],
      });
    }
  }

  let exercise = Object.keys(assessmentComponentsMap).includes(demoType)
    ? assessmentComponentsMap[demoType] &&
      assessmentComponentsMap[demoType][iteration]
    : demoType;
  if (exercise === 'exerciseConfig' || exercise === 'transitionConfig') {
    exercise = iter.exerciseIds[iter.iteration];
  }
  const introState =
    process.env.REACT_APP_SKIP_INTRO_STATE ||
    useSelector(({ intro }) => intro.values.introState);

  return () => {
    if (introState !== 'done') return;
    updateMetricsWithAdditionalData();
    const sensorDataResults = commonSensorDataProcessing({
      sensorData: iter.sensorData[iteration],
    });
    try {
      if (
        [
          'sit_and_stand_assessment',
          'berg_balance_assessment',
          'berg_balance_assessment_mini',
        ].includes(demoType)
      ) {
        // console.log('adding', JSON.stringify(iter.scores), {
        //   userId: user.uid,
        //   patientId: patientInfo?.id,
        //   exerciseType: exercise,
        //   data: {
        //     score: iter.scores[iteration] * 4,
        //     // sweatPoints: sp ? sp : 0,
        //     // duration: observation.time,
        //     metrics: metrics[iteration] || null,
        //   },
        // });
        console.log(scoresRef.current, 'scores');
        setTimeout(() => {
          addResult({
            userId: user.uid,
            patientId: patientInfo?.id || null,
            exerciseType: exercise,
            data: {
              score: scoresRef.current[iteration] * 3,
              // sweatPoints: sp ? sp : 0,
              // duration: observation.time,
              metrics: metricsRef.current[iteration] || null,
              sensorDataResults,
            },
          }).then();
        }, 1000);
      } else {
        addExerciseResult({
          exerciseId: exercise,
          score:
            scoresRef.current[iteration] *
              (iter.scoreMultiplication[iteration] || 1) || 0,
          relativeScore: scoresRef.current[iteration] || 0,
          sweatPoints: 0, // sp ? sp : 0,
          duration: 0, // observation.time,
          metrics: metricsRef.current[iteration] || null,
          patientId: patientInfo?.id || null,
          doctorId: patientInfo?.doctorId || user?.uid || null,
          userId: user?.uid || null,
          scheduleId: iter.scheduleId,
          sensorDataResults,
          additionalResultInfo: iter.results[iteration] || null,
        }).then();
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const useVideoLinks = ({
  demoType,
  exerciseConfig,
  vidIndex,
  setIntroLoaded,
}) => {
  const iteration = useSelector(({ iter }) => iter.values.iteration);
  const iter = useSelector(({ iter }) => iter.values);
  const schedule = useSelector(({ schedule }) => schedule.values);
  const [videoUrl, setVideoUrl] = useState(null);

  React.useEffect(() => {
    getVideoLinks();
  }, [demoType, iteration, schedule, exerciseConfig, iter]);

  const getVideoLinks = () => {
    let videoUrl;
    switch (demoType) {
      case 'fitness_assessment':
        videoUrl = fitnessAssessmentVideos[iteration];
        break;
      case 'exhibition_assessment':
        videoUrl = exhibitionAssessmentVideos[iteration];
        break;
      case 'berg_balance_assessment':
      case 'berg_balance_assessment_mini':
        videoUrl = videoMap[assessmentComponentsMap[demoType][iteration]];
        break;
      case 'school':
        videoUrl = schedule.modelsdata[vidIndex].modelFile;
        break;
      case 'exerciseConfig':
        videoUrl = exerciseConfig.videoUrl;
        break;
      case 'transitionConfig':
        videoUrl = iter.transitionVideoURL;
        break;
      default:
        videoUrl = videoMap[demoType];
    }
    if (!videoUrl) {
      setIntroLoaded(true);
    }
    setVideoUrl(videoUrl);
  };
  return videoUrl;
};

export const useCompleteSession = ({
  demoType,
  redirectTarget = '',
  introState,
  setIntroLoaded,
}) => {
  const navigate = useNavigate();
  const iter = useSelector(({ iter }) => iter.values);
  const iterationRef = useRef(iter.iteration);
  const dispatch = useDispatch();
  const addResultToDB = useAddResultToDB({ demoType });
  const addResultToDBRef = useRef(addResultToDB);
  const iterRef = useRef(iter);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lastIter = useRef(-1);
  const seconds = useTimer();
  const debounceDispatch = useRef(
    debounce(() => {
      dispatch({
        type: 'ADD-ITER',
      });
    }, 3000)
  );

  useEffect(() => {
    addResultToDBRef.current = addResultToDB;
    iterRef.current = iter;
    iterationRef.current = iter.iteration;
  }, [introState, iter, addResultToDB, iter.iteration]);

  return React.useCallback(async () => {
    console.log(iterationRef.current, 'iterationRef.current', 'adding result');
    if (lastIter.current >= seconds.current - 3) {
      console.log('aborting iteration');
      lastIter.current = seconds.current;
      return;
    } else {
      setIntroLoaded(false);
      dispatch({ type: 'RESET-INTRO' });
      lastIter.current = seconds.current;
    }
    addResultToDBRef.current();
    if (iterRef.current.showSummaries) {
      navigate(redirectTarget + '?' + queryParams, getSummary(demoType));
    } else {
      if (iterRef.current.exerciseIds.length === iterationRef.current + 1) {
        if (iterRef.current.overviewRedirect) {
          navigate(
            '/schedule_results/' +
              iterRef.current.overviewRedirect +
              '?' +
              queryParams
          );
        } else {
          navigate(redirectTarget + '?' + queryParams, getSummary(demoType));
        }
      } else {
        console.log('adding iter');
        debounceDispatch.current();
      }
    }
  }, [navigate, demoType, addResultToDBRef.current, iterRef.current]);
};

export const useFeedbackComponent = ({
  demoType,
  showWebcam,
  completeSession,
  handleExit,
  setLoad,
}) => {
  const dispatch = useDispatch();
  const iter = useSelector(({ iter }) => iter.values);
  const iteration = useSelector(({ iter }) => iter.values.iteration);
  const lastIteration = useRef(-1);
  const showWebcamRef = useRef(showWebcam);
  const navigate = useNavigate();
  const [feedbackComponent, setFeedbackComponent] = useState(<></>);
  const [splitScreen, setSplitScreen] = useState(true);
  const [exerciseConfig, setExerciseConfig] = useState({});
  const [observationComponents, setObservationComponents] = useState([]);
  const [observationComponent, setObservationComponent] = useState(
    <PoseDetection
      showWebcam={showWebcamRef}
      onResults={(par1, par2) => ({ par1, par2 })}
    />
  );
  const [transitionQTime, setTransitionQTime] = useState(100);
  const [loadIntroAudio, setLoadIntroAudio] = useState(() => {});
  const playIntroAudio = useIntroAudio({
    config: exerciseConfig,
    loadAudio: loadIntroAudio,
  });

  useEffect(() => {
    console.log('iteration update', iteration, lastIteration.current);
    if (lastIteration.current !== iteration) {
      lastIteration.current = iteration;
      setDemoComponents();
    }
  }, [demoType, iteration]);

  useEffect(() => {
    showWebcamRef.current = showWebcam;
  }, [showWebcam]);

  const handleSetObservationComponent = (comp) => {
    if (demoType === 'neverEver') {
      setObservationComponent(null);

      if (observationComponents.length > 0) {
        setObservationComponents((prev) => [...prev, comp]);
        setTimeout(() => {
          setObservationComponents((prev) => prev.slice(1));
        }, 300);
      } else {
        setObservationComponents([comp]);
      }
    } else {
      setObservationComponent(comp);
    }
  };

  function setExerciseSpecs(exercise) {
    console.log(exercise);
    setExerciseConfig(exercise);
    dispatch({
      type: 'SET-ITER-EXERCISE-TYPE',
      payload: exercise.type,
    });
    dispatch({
      type: 'SET-ITER-SCORE-MULTIPLICATION',
      payload: Number(exercise.scoreConfig?.maxScore),
    });
    if (exercise.type === 'balance') {
      dispatch({
        type: 'RESET_BALANCE_OBSERVATION',
      });
      setFeedbackComponent(<Feedback FeedbackComponent={BalanceFeedbacks} />);
      setObservationComponent(
        <BalanceFeedbackGenerator
          key={JSON.stringify(exercise)}
          config={exercise}
          onComplete={completeSession}
          showWebcam={showWebcamRef}
        />
      );
    } else if (exercise.type === 'stretch') {
      dispatch({
        type: 'RESET_STRETCH',
      });
      setFeedbackComponent(<Feedback FeedbackComponent={StretchFeedbacks} />);
      handleSetObservationComponent(
        <StretchFeedbackGenerator
          key={JSON.stringify(exercise)}
          config={exercise}
          onComplete={completeSession}
          showWebcam={showWebcamRef}
        />
      );
    } else if (exercise.type === 'stretchCount') {
      dispatch({
        type: 'RESET_STRETCH_COUNT',
      });
      setFeedbackComponent(
        <Feedback FeedbackComponent={StretchCountFeedbacks} />
      );
      handleSetObservationComponent(
        <StretchCountFeedbackGenerator
          key={JSON.stringify(exercise)}
          config={exercise}
          onComplete={completeSession}
          showWebcam={showWebcamRef}
        />
      );
    } else if (exercise.type === 'count') {
      dispatch({
        type: 'RESET-COUNT-TIME-OBSERVATION',
      });
      setFeedbackComponent(
        <Feedback FeedbackComponent={CountsInTimeFeedbacks} />
      );
      handleSetObservationComponent(
        <CountsFeedbackGenerator
          key={JSON.stringify(exercise)}
          // key={JSON.stringify(exercise)}
          config={exercise}
          onComplete={completeSession}
          showWebcam={showWebcamRef}
        />
      );
    }
  }

  const setDemoComponents = () => {
    const demo = Object.keys(assessmentComponentsMap).includes(demoType)
      ? assessmentComponentsMap[demoType] &&
        assessmentComponentsMap[demoType][iteration]
      : demoType;
    let exercise;
    if (['exerciseConfig', 'transitionConfig'].includes(demo)) {
      exercise = iter.exerciseIds[iteration];
      if (exercise === undefined && iter.overviewRedirect) {
        navigate('/schedule_results/' + iter.overviewRedirect);
        return;
      }
      setTransitionQTime(parseTimeToSeconds(exercise.endTime));
      getExercise({ exerciseId: exercise.id ? exercise.id : exercise }).then(
        async (exercise) => {
          const scheduleSpecificExerciseConfigs =
            iter.scheduleSpecificExerciseConfigs || {};
          exercise = await getVideoUrl(
            scheduleSpecificExerciseConfigs,
            exercise
          );
          exercise = await getIntroAudioUrl(
            scheduleSpecificExerciseConfigs,
            exercise
          );
          if (exercise.useAngleConfigs) {
            const angleObjects = [];
            const promises = exercise.angles.map(async (angle) => {
              if (typeof angle === 'string') {
                const angleDoc = await getAngle({ angleId: angle });
                if (angleDoc) {
                  angleObjects.push(angleDoc);
                }
              } else {
                angleObjects.push(angle);
              }
            });
            Promise.all(promises).then(() => {
              exercise.feedback.forEach((feedbackConfig) => {
                feedbackConfig.conditions.forEach((condition) => {
                  if (!condition.ref.id) {
                    condition.ref = angleObjects.filter(
                      (angle) => angle.id === condition.ref
                    )[0];
                  }
                });
              });
              exercise.metrics.forEach((metric) => {
                metric.subMetrics.forEach((subMetric) => {
                  if (!subMetric.ref.id) {
                    subMetric.ref = angleObjects.filter(
                      (angle) => angle.id === subMetric.ref
                    )[0];
                  }
                });
              });
              exercise.states.forEach((stateConfig) => {
                stateConfig.conditions.forEach((condition) => {
                  if (!condition.ref.id) {
                    condition.ref = angleObjects.filter(
                      (angle) => angle.id === condition.ref
                    )[0];
                  }
                });
              });
              setExerciseSpecs({
                ...exercise,
                ...scheduleSpecificExerciseConfigs[exercise.id],
              });
            });
          } else {
            console.log(scheduleSpecificExerciseConfigs[exercise.id]);
            setExerciseSpecs({
              ...exercise,
              ...scheduleSpecificExerciseConfigs[exercise.id],
              coreAudioFeedbackConfig: iter.coreAudioFeedbackConfig,
            });
          }
        }
      );
    } else {
      setHardCodedComponents({
        demoName: demo,
        demoType,
        setObservationComponent,
        setFeedbackComponent,
        completeSession,
        showWebcam,
        setSplitScreen,
        handleExit,
        setLoad,
        setLoadAudio: setLoadIntroAudio,
      });
    }
  };

  return {
    setDemoComponents,
    observationComponents,
    observationComponent,
    exerciseConfig,
    splitScreen,
    feedbackComponent,
    transitionQTime,
    playIntroAudio,
  };
};
