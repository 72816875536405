import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useCountsInTimeCoreFeedback } from '../../../../../hooks/Demo/feedbackTypes';
import { safeIterScore } from '../../../../../hooks/Demo/sharedLogic';
import {
  checkIfRightSide,
  checkIfSide,
} from '../../../../../utilities/Demo/angles';
import { loadPlaceAlternateFootOnStepAudio } from '../../../../../utilities/Demo/audioFeedback';
import {
  findAdjustedAngle,
  findDependentAngle,
} from '../../../../../utilities/Demo/physio';
import PoseDetection from '../../../PoseDetection';
import {
  backRomMetrics,
  computeMetrics,
  leftUpperLegRomMetrics,
  rightUpperLegRomMetrics,
} from '../../../../../utilities/Demo/Physio/BergBalance/metrics';

export default function PlaceAlternateFootOnStep({
  onComplete,
  showWebcam = false,
}) {
  const dispatch = useDispatch();
  const timeThresh = 20;
  const requiredReps = 5;
  const isLeft = useRef(false);
  const footSideLeft = useRef(false);
  const lastCount = useRef(0);
  const {
    correctCount,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    startingTime,
    raiseLevel,
    setSide,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadPlaceAlternateFootOnStepAudio,
    maxScore: 1,
  });
  const metrics = useRef(
    leftUpperLegRomMetrics('Flexion', 'Extension')
      .concat(rightUpperLegRomMetrics('Flexion', 'Extension'))
      .concat(backRomMetrics())
  );
  cptScore.current = () => {
    const secondsCount = seconds.current - startingTime.current;
    if (secondsCount <= 20 && correctCount.current >= 5) return 1;
    if (secondsCount > 20 && correctCount.current >= 5) return 2 / 3;
    if (correctCount.current >= 3) return 1 / 3;
    return 0;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      lastCount.current += 1;
    }, 10);

    return () => clearInterval(intervalId);
  }, []);

  function observation(backAngle, kneeAngleLeft, kneeAngleRight) {
    if (backAngle > 70 && backAngle < 100) {
      updateFeedback('');
      let usedRightFoot = false;
      if (
        kneeAngleRight < 155 &&
        kneeAngleLeft > 160 &&
        (footSideLeft.current || correctCount.current === 0)
      ) {
        usedRightFoot = true;
        footSideLeft.current = false;
      } else if (
        kneeAngleLeft < 155 &&
        kneeAngleRight > 160 &&
        (!footSideLeft.current || correctCount.current === 0)
      ) {
        usedRightFoot = true;
        footSideLeft.current = true;
      }
      if (usedRightFoot && lastCount.current >= 20) {
        setStartingTime(seconds);
        correctCount.current += 1;
        lastCount.current = 0;
        updateCounts(correctCount.current);
        const score = cptScore.current();
        if (!raiseLevel(score)) {
          playAudio(audioFeedback.current?.achievement, true);
        }
        safeIterScore(dispatch, score);
        if (correctCount.current >= requiredReps) {
          onComplete();
        }
      }
    } else {
      updateFeedback('Stand straight!');
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    if (!checkIfSide(results)) {
      playAudio(audioFeedback.current?.turnToSide, false, 6);
      updateFeedback('Turn to the side!');
      setSide(false);
      return;
    } else {
      updateFeedback('');
      setSide(true);
    }
    isLeft.current = checkIfRightSide(results, 'left');
    let kneeAngleLeft;
    let kneeAngleRight;
    let backAngle;
    let hipKneeAngleLeft;
    let hipKneeAngleRight;
    let displayConfigs;

    if (isLeft.current) {
      kneeAngleLeft = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      kneeAngleRight = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      backAngle = findAdjustedAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        180
      );
      hipKneeAngleLeft = findAdjustedAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25]
      );
      hipKneeAngleRight =
        360 -
        findAdjustedAngle(results.poseLandmarks[24], results.poseLandmarks[26]);
      displayConfigs = {
        hipKneeAngleLeft: {
          coord: results.poseLandmarks[25],
          left: true,
        },
        hipKneeAngleRight: {
          coord: results.poseLandmarks[26],
          left: true,
        },
        backAngle: {
          coord: results.poseLandmarks[11],
          left: true,
        },
      };
    } else {
      kneeAngleRight = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      if (kneeAngleRight > 180) {
        kneeAngleRight = 360 - kneeAngleRight;
      }
      kneeAngleLeft = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      if (kneeAngleLeft > 180) {
        kneeAngleLeft = 360 - kneeAngleLeft;
      }
      backAngle =
        360 -
        findAdjustedAngle(
          results.poseLandmarks[12],
          results.poseLandmarks[24],
          0
        );
      hipKneeAngleLeft = findAdjustedAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25]
      );
      hipKneeAngleRight =
        360 -
        findAdjustedAngle(results.poseLandmarks[24], results.poseLandmarks[26]);
      displayConfigs = {
        hipKneeAngleLeft: {
          coord: results.poseLandmarks[25],
          left: false,
        },
        hipKneeAngleRight: {
          coord: results.poseLandmarks[26],
          left: false,
        },
        backAngle: {
          coord: results.poseLandmarks[12],
          left: false,
        },
      };
    }
    computeMetrics({
      metrics: metrics.current,
      angles: {
        current: {
          backAngle,
          hipKneeAngleLeft,
          hipKneeAngleRight,
        },
      },
      dispatch,
      displayConfigs,
      canvasCtx,
    });
    observation(backAngle, kneeAngleLeft, kneeAngleRight, results);
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
