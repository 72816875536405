import React, { useEffect, useState } from 'react';
import ProgressComponentBcg from '../../components/Background/ProgressComponentBcg';
import DrawerComponent from '../../components/DrawerComponent';
import HeaderComponent from '../../components/HeaderComponent';

import ProgressComponent from '../../components/Progress/Progress';
import Health from '../../components/Progress/Health';
import Rank from '../../components/Highlights/MyRank';
import ProgressCalendar from '../../components/Progress/Calendar';
import { getDashboardData } from '../../redux/actions/getDashboard';

import '../../components/Progress/progress.css';
import { useDispatch, useSelector } from 'react-redux';

const Progress = () => {
  const board = useSelector(({ board }) => board.values);
  const [dys, setDys] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (!board) getDashboardData(dispatch);
  }, []);
  return (
    <div className="progress-component-container">
      <HeaderComponent />
      <DrawerComponent />
      <ProgressComponentBcg />

      <div className="progress-grid-container">
        <ProgressComponent data={board} dys={dys} setDys={setDys} />
        <Rank
          pageName={'progress'}
          rank={board ? board.rank : ''}
          board={board}
        />
        <Health data={board} />
        <ProgressCalendar data={board} />
        {/* <div className="left-middle"><h1>Hello 1</h1></div>
                <div className="right-bottom"><h1>Hello 2</h1></div>
                <div className="left-bottom"><h1>Hello 3</h1></div> */}
      </div>
    </div>
  );
};

export default Progress;
