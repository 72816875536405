export function cptRWUDistance({
  stretchLengthPixels,
  personHeightRWU,
  personHeightPixels,
  results,
  refCallBack = () => {},
}) {
  const pixelRWU = personHeightRWU / personHeightPixels;
  console.log('pixelRWU', pixelRWU);
  if (refCallBack) refCallBack(personHeightRWU, personHeightPixels, results);
  return Math.round(stretchLengthPixels * pixelRWU);
}
