import AddIcon from '@mui/icons-material/Add';
import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React, { useEffect } from 'react';
import {
  getAudioFeedbacks,
  deleteAudioFeedback,
  getTags,
} from '../../../../utilities/Demo/ExerciseManagement/firestore';
import { deleteFile } from '../../../../utilities/Demo/ExerciseManagement/Generators/storage';
import AudioFeedbackConfigurator from '../Configurator/AudioFeedback/AudioFeedbackConfigurator';
import DeleteButton from '../DeleteButton';

export default function AudioFeedbacks({ searchValue, setTags, searchTag }) {
  const [audioFeedbacks, setAudioFeedbacks] = React.useState([]);
  const [config, setConfig] = React.useState(false);

  React.useEffect(() => {
    getAudioFeedbacks({ searchTerm: searchValue, searchTag }).then(
      (audioFeedbacks) => {
        console.log(audioFeedbacks);
        setAudioFeedbacks(audioFeedbacks || []);
      }
    );
  }, [searchValue, searchTag]);

  useEffect(() => {
    getTags({ type: 'audio' }).then((tags) => {
      setTags(tags?.map((tag) => tag));
    });
  }, []);

  const setConfigUpdate = (config) => {
    const index = audioFeedbacks.findIndex(
      (audioFeedback) => audioFeedback.id === config.id
    );
    if (index !== -1) {
      setAudioFeedbacks([
        ...audioFeedbacks.slice(0, index),
        config,
        ...audioFeedbacks.slice(index + 1),
      ]);
    } else {
      setAudioFeedbacks([...audioFeedbacks, config]);
    }
  };

  const removeAudioFeedback = (audioFeedbackId) => {
    setAudioFeedbacks(
      audioFeedbacks.filter(
        (audioFeedback) => audioFeedback.id !== audioFeedbackId
      )
    );
  };

  if (config) {
    return (
      <AudioFeedbackConfigurator
        config={config}
        setConfig={setConfigUpdate}
        onClose={() => setConfig(null)}
      />
    );
  }
  return (
    <Box sx={{ padding: 3 }}>
      <h1>All Audio Feedbacks</h1>
      <Grid container spacing={2}>
        {audioFeedbacks.map((audioFeedback) => {
          return (
            <Grid item xs={12} sm={6} md={3} key={audioFeedback.id}>
              <Card
                sx={{ padding: 2, '&:hover': { cursor: 'pointer' } }}
                onClick={() => {
                  setConfig(audioFeedback);
                }}
              >
                <h3>{audioFeedback.name}</h3>
                <p>{audioFeedback.description}</p>
                <DeleteButton
                  onDelete={() => {
                    deleteFile({
                      filePath: '/audios/' + audioFeedback.id,
                    }).then(() => {});
                    deleteAudioFeedback({
                      audioFeedbackId: audioFeedback.id,
                    }).then(() => {
                      removeAudioFeedback(audioFeedback.id);
                    });
                  }}
                  deletionTarget={'Audio Feedback' + audioFeedback.name}
                />
              </Card>
            </Grid>
          );
        })}
        <Grid item key={'createNew'} xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              padding: 2,
              border: 1,
              borderRadius: 5,
              m: 2,
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setConfig({ name: '', description: '', id: '' });
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
