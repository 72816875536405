import axios from 'axios';
import { listenForDocumentChanges } from '../../../utilities/Demo/Sports2D/firestore';

export const getProjects = (authToken) => {
  const apiBaseURL = process.env.REACT_APP_POSE_EVAL_SERVICE_URL;
  try {
    return axios.get(apiBaseURL + '/project', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createProject = ({ projectName, authToken }) => {
  const apiBaseURL = process.env.REACT_APP_POSE_EVAL_SERVICE_URL;
  try {
    return axios.post(
      apiBaseURL + '/project',
      { name: projectName, description: 'Created by Physio Platform' },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const uploadFile = ({ selectedFile, projectId, authToken }) => {
  const apiBaseURL = process.env.REACT_APP_POSE_EVAL_SERVICE_URL;
  const formData = new FormData();
  formData.append('file', selectedFile);
  formData.append('project_id', projectId);
  try {
    return axios.post(apiBaseURL + '/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const deleteFile = ({ fileId, authToken }) => {
  const apiBaseURL = process.env.REACT_APP_POSE_EVAL_SERVICE_URL;
  try {
    return axios.delete(apiBaseURL + `/file/${fileId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const listFiles = ({ projectId, authToken }) => {
  const apiBaseURL = process.env.REACT_APP_POSE_EVAL_SERVICE_URL;
  try {
    return axios.get(apiBaseURL + `/file?project_id=${projectId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const startSports2DTask = ({
  authToken,
  fileId,
  patientId,
  doctorId,
}) => {
  const apiBaseURL = process.env.REACT_APP_POSE_EVAL_SERVICE_URL;
  try {
    return axios.post(
      apiBaseURL + '/sports2dangles',
      {
        file_id: fileId,
        patient_id: patientId,
        doctor_id: doctorId,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  } catch (error) {
    console.error(error);
  }
};

export const handleTask = ({
  user,
  fileId,
  patientInfo,
  setLoading = () => {},
}) => {
  try {
    user.getIdToken().then((token) => {
      startSports2DTask({
        authToken: token,
        fileId,
        patientId: patientInfo.id,
        doctorId: patientInfo.doctorId,
      }).then((response) => {
        if (response.status === 200) {
          const asyncTaskId = response.data['async_task_id'];
          const unsubscribe = listenForDocumentChanges({
            collection: 'async_tasks',
            documentId: asyncTaskId,
            callback: (data) => {
              if (data.status === 'done') {
                alert('Task completed successfully');
                setLoading(false);
                unsubscribe();
              } else if (data.status === 'failed') {
                alert('Task failed');
                unsubscribe();
              }
            },
          });
        } else {
          alert('Task start failed');
          setLoading(false);
        }
      });
    });
  } catch (error) {
    setLoading(false);
    console.error('Error starting task', error);
  }
};
