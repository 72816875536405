import React from 'react';
import BgComponent from '../../../components/Background/BgComponent';
import { AssessmentPath } from '../../../components/Demo/Assessments/GeneralFitness/AssessmentPath';
import { LastTest } from '../../../components/Demo/Assessments/GeneralFitness/LastTest';
import '../../../components/Demo/Assessments/assessments.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeaderComponent from '../../../components/HeaderComponent';

export const GeneralFitness = () => {
  const matches = useMediaQuery('(max-width:600px)');
  return (
    <div className="teacher-home">
      <BgComponent pageName={'schedule'} />
      <HeaderComponent />
      <div className={matches ? 'container-assessment' : 'container'}>
        <AssessmentPath />
        {/* <ProgressComponent data={board} />
        <SessionComponent data={schedule} /> */}
        <LastTest />
      </div>
    </div>
  );
};
