import React from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
import { useState, useEffect } from 'react';
import moment from 'moment';
import BgComponent from '../../components/Background/BgComponent';
import CalendarBigComponent from '../../components/Schedule/CalendarBigComponent';
import TomSessionComponent from '../../components/Schedule/TomSessionComponent';
import TomSessionB2cComponent from '../../components/Schedule/TomSessionB2cComponent';
import TodaySessionBigComponent from '../../components/Schedule/TodaySessionBigComponent';
import TodaySessionB2cComponent from '../../components/Schedule/TodaySessionB2cComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getScheduleData } from '../../redux/actions/getDashboard';
import '../../components/Schedule/schedule.css';

const Schedule = () => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('Today');
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const schedule = useSelector(({ schedule }) => schedule.values);
  const tschedule = useSelector(({ tschedule }) => tschedule.values);
  const { user_details } = JSON.parse(localStorage.getItem('full_user'));
  const userType = user_details ? user_details.type : 'b2b';

  console.log('Schedules::', schedule);
  useEffect(() => {
    if (!schedule) {
      console.log('SCHEDULE EMPTY');
      getScheduleData(dispatch, moment().format('YYYY-MM-DD'), setLoader);
    }
    if (!tschedule)
      getScheduleData(
        dispatch,
        moment().add(1, 'day').format('YYYY-MM-DD'),
        setLoader,
        'TOM-SCHEDULE'
      );
  }, []);
  // if (!schedule) navigate("/", { state: { from: "schedule" } });
  return (
    <div className="schedule">
      <BgComponent pageName={'schedule'} />
      <HeaderComponent />
      <DrawerComponent open={open} setOpen={setOpen} />

      <div className="schedule-content">
        <CalendarBigComponent setTitle={setTitle} setLoader={setLoader} />
        {user_details && userType === 'b2c' ? (
          <TodaySessionB2cComponent data={schedule} />
        ) : (
          <TodaySessionBigComponent data={schedule} />
        )}
        {tschedule &&
          (user_details && userType === 'b2c' ? (
            <TomSessionB2cComponent
              title={title}
              schedule={tschedule}
              loader={loader}
            />
          ) : (
            <TomSessionComponent
              title={title}
              schedule={tschedule}
              loader={loader}
            />
          ))}
      </div>
    </div>
  );
};

export default Schedule;
