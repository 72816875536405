import { zonesLeft } from '../../../components/Demo/SensorResults/FootMap';
import _ from 'lodash';

export class SumPerKeyComputer {
  constructor({ sensorData }) {
    if (!sensorData) return;
    this.sensorData = sensorData;
    this.left = this.computeSumPerKey(sensorData.left);
    this.right = this.computeSumPerKey(sensorData.right);
  }

  computeSumPerKey(sensorDataSide) {
    if (!sensorDataSide) return;
    const sumPerKey = {};
    Object.keys(zonesLeft).forEach((zone) => {
      sumPerKey[zone] = _.sum(
        sensorDataSide.map((step) => step.value?.result[zone])
      );
    });
    return sumPerKey;
  }
}

export class SumPerSideComputer {
  constructor({ sumPerKeyComputer }) {
    this.sumPerKeyComputer = sumPerKeyComputer;
    this.left = this.computeSumPerSide(this.sumPerKeyComputer.left);
    this.right = this.computeSumPerSide(this.sumPerKeyComputer.right);
  }
  computeSumPerSide(sumPerKey) {
    if (!sumPerKey) return;
    return _.sum(Object.values(sumPerKey));
  }
}
