import React from 'react';
import './ProgressBar.css';
import { useSelector } from 'react-redux';

const ProgressBar = () => {
  const iter = useSelector(({ iter }) => iter.values);
  const value = iter.scores[iter.iteration];
  const percentage = value * 100;

  return (
    <div
      className="progress-vertical"
      style={{
        height: '50vh',
        display: 'flex',
        flexDirection: 'column-reverse',
        // marginBottom: '-12%',
        borderRadius: '10px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: `${percentage}%`,
          background: `linear-gradient(to top, #F1730CFF , #0AC20AFF)`,
          borderRadius: '10px',
          color: '#0ac20a',
        }}
        className="progress-bar"
      ></div>
    </div>
  );
};

export default ProgressBar;
