export function removeElements(landmarks, elements) {
  for (const element of elements) {
    if (landmarks[element]) {
      delete landmarks[element];
    }
  }
}

export function removeLandmarks(results) {
  if (results.poseLandmarks) {
    removeElements(
      results.poseLandmarks,
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 17, 18, 19, 20, 21, 22]
    );
  }
}

export function selectLandmarks(results) {
  const selected = structuredClone(results.poseLandmarks);
  const toExclude = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 17, 18, 19, 20, 21, 22];
  for (const element of results.poseLandmarks.keys()) {
    if (toExclude.includes(element)) {
      delete selected[element];
    }
  }
  return selected;
}

export function selectLandmarksFromObject(poseData) {
  const selected = structuredClone(poseData);
  const toExclude = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 17, 18, 19, 20, 21, 22];
  for (const element of Object.keys(poseData)) {
    if (String(toExclude).includes(element)) {
      delete selected[element];
    }
  }
  return selected;
}

function drawConnector(ctx, from, to) {
  ctx.beginPath();
  ctx.moveTo(from.x, from.y);
  ctx.lineTo(to.x, to.y);
  ctx.strokeStyle = '#FFFFFF';
  ctx.lineWidth = 8;
  ctx.stroke();
}

export function drawFilteredConnectors(ctx, landmarks, connections = {}) {
  connections.forEach((connection, index) => {
    if (
      index > 8 &&
      ![12, 13, 14, 15, 18, 19, 20, 21].includes(index) &&
      index < 29
    ) {
      const from = landmarks[connection[0]];
      const to = landmarks[connection[1]];
      drawConnector(ctx, from, to);
    }
  });
}

export function drawFilteredLandmarks(ctx, landmarks = {}, width, height) {
  landmarks.forEach((landmark, index) => {
    if (index > 10 && ![17, 18, 19, 20, 21, 22].includes(index) && index < 29) {
      drawLandmark(ctx, landmark, width, height);
    }
  });
}

export function drawLandmark(ctx, landmark, width, height) {
  if (!landmark) return;
  ctx.beginPath();
  ctx.arc(landmark.x * width, landmark.y * height, 6, 0, 2 * Math.PI);
  ctx.strokeStyle = '#F37637';
  ctx.lineWidth = 1;
  ctx.fillStyle = '#F37637';
  ctx.fill();
  ctx.stroke();
}
