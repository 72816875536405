const initialState = {};
export const boardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'BOARD': {
      return { ...state, values: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SCHEDULE': {
      console.log('Brd is::' + JSON.stringify(action));
      return { ...state, values: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const tomscheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TOM-SCHEDULE': {
      console.log(
        'Tom session is::' +
          JSON.stringify({ ...state, values: action.payload })
      );
      return { ...state, values: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER': {
      console.log(
        'User is::' + JSON.stringify({ ...state, values: action.payload })
      );
      return { ...state, values: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const valReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VAL': {
      return { ...state, values: action.payload };
    }
    default: {
      return state;
    }
  }
};
