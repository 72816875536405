import * as React from 'react';
import {
  logInWithEmailAndPassword,
  logInWithEmailLink,
} from '../../../utilities/Demo/auth';
import InputBarComponent from '../../InputBarComponent';
import { Snackbar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { CircularProgress } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/system';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function LoginComponent({ open, setOpen, setFp, setLoading }) {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();
  const [type, setType] = useState('');
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const signInType = queryParams.get('signInType');

  const onSubmit = async (data) => {
    setLoad(true);
    let loginSuccess = false;
    if (signInType === 'emailLink') {
      loginSuccess = await logInWithEmailLink(data.email);
    } else {
      loginSuccess = await logInWithEmailAndPassword(data.email, data.password);
    }
    if (loginSuccess) {
      setMsg('Login Successful');
      setType('success');
      setLoading(true);
    } else {
      setMsg('Login Failed');
      setType('error');
    }
    setLoad(false);
  };

  return (
    <Box>
      <Snackbar
        open={msg !== ''}
        autoHideDuration={60000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => {
          setMsg('');
        }}
      >
        <Alert severity={type}>{msg}</Alert>
      </Snackbar>
      {type !== 'success' && (
        <Dialog
          PaperProps={{
            sx: {
              borderRadius: 10,
              maxheight: '53%',
              width: '95vw',
              padding: 2,
            },
          }}
          scroll="body"
          BackdropComponent={styled(Backdrop, {
            name: 'MuiModal',
            slot: 'Backdrop',
            overridesResolver: (props, styles) => {
              return styles.backdrop;
            },
          })({
            zIndex: -1,
            backgroundColor: 'rgb(242, 247, 247,0.5)',
          })}
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle sx={{ fontSize: 18 }}>
            {t('welcomeView.login')}
            <span
              style={{ float: 'right', color: 'grey', cursor: 'pointer' }}
              onClick={() => setOpen(false)}
            >
              &#x2715;
            </span>
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent style={{ overflow: 'hidden' }}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputBarComponent
                    name="email"
                    label={t('ProfileComponent.email')}
                    type="email"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {signInType !== 'emailLink' && (
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputBarComponent
                      name="password"
                      label={t('welcomeView.password')}
                      type="password"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              )}
            </DialogContent>
            <DialogActions
              disableSpacing={true}
              sx={{ justifyContent: 'center', height: '10%' }}
            >
              {load ? (
                <CircularProgress color="inherit" />
              ) : (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      borderRadius: 20,
                      backgroundColor: '#FF592C',
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {t('welcomeView.login')}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 20,
                      backgroundColor: '#FF592C',
                      marginLeft: '5%',
                    }}
                    onClick={() => {
                      setOpen(false);
                      setFp(true);
                    }}
                  >
                    {t('welcomeView.forgetPass')}
                  </Button>
                </>
              )}
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Box>
  );
}
