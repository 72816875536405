import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
// import ApexCharts from 'apexcharts';

export default function AnglesChart({
  data,
  chartKey,
  height = '350%',
  slice = 300,
  title = 'Angles',
  // symmetryIndex = true,
  numericX = true,
  strokeWidth = 1,
  chartType = 'line',
  colors = [],
}) {
  const [series, setSeries] = useState(data);

  // const getSI = () => {
  //   const symmetryIndex = cptROMSymmetryIndex(series);
  //   // if (symmetryIndex.length === 0) return symmetryIndex;
  //   return `${symmetryIndex}%`;
  // };
  // const getMeanSI = () => {
  //   const symmetryIndex = cptSymmetryIndex(series);
  //   // if (!symmetryIndex) return symmetryIndex;
  //   return `${symmetryIndex}%`;
  // };

  const options = {
    chart: {
      id: 'realtime',
      height: height,
      type: chartType,
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000,
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: strokeWidth,
    },
    title: {
      text: title,
      align: 'left',
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: numericX ? 'numeric' : 'object',
      labels: {
        show: true,
        formatter: function (value) {
          if (!numericX) {
            const date = new Date(value);
            return `${date.toLocaleDateString()} ${date.getHours()}:${date.getMinutes()}`;
          }
          return value?.toFixed(1);
        },
      },
    },
    yaxis: {
      // min: min,
      // max: max,
      forceNiceScale: true,
      labels: {
        formatter: function (value) {
          const fixed = value?.toFixed(1);
          if (fixed) {
            return fixed;
          }
          return 0;
        },
      },
    },
    legend: {
      show: true,
    },
  };

  // if (symmetryIndex && series && series.length > 0) {
  //   options.subtitle = {
  //     text: `ROM SI: ${getSI()} | Mean SI: ${getMeanSI()}`,
  //     align: 'center',
  //     style: {
  //       fontSize: '16px',
  //       color: '#263238',
  //     },
  //   };
  // }

  useEffect(() => {
    let newData;
    if (slice) {
      newData = data
        .slice(0, 2)
        .map((item) => ({ ...item, data: [...item.data] }));
      newData[0].data = newData[0].data.slice(newData[0].data.length - 300);
      newData[1].data = newData[1].data.slice(newData[1].data.length - 300);
    } else {
      newData = data;
    }
    setSeries(newData);
    // ApexCharts.exec(chartKey, 'updateSeries', newData, true);
  }, [data]);

  if (!series || series.length === 0) return <CircularProgress />;

  try {
    return (
      <ReactApexChart
        key={chartKey}
        options={options}
        series={series}
        type={chartType}
        height={height}
      />
    );
  } catch (e) {
    console.log(e);
    return null;
  }
}
