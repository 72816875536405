import React, { useEffect } from 'react';
import {
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
} from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import InputBarComponent from '../../InputBarComponent';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';

const UserSignUpDetails = (props) => {
  const { nextStep, open, setOpen, setName, setEmail, setPassword } = props;
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      'parents-name': '',
      email: '',
      password: '',
      'repeat-password': '',
    },
  });

  const [progress] = React.useState(30);
  const [buffer] = React.useState(65);

  const name = watch('parents-name');
  const email = watch('email');
  const password = watch('password');
  const repeatPassword = watch('repeat-password');

  const continueNext = () => {
    setEmail(email);
    setName(name);
    setPassword(password);
    nextStep();
  };

  useEffect(() => {
    if (password !== repeatPassword) {
      setError('repeat-password', {
        type: 'mismatch',
        message: 'Passwords should match!',
      });
    } else {
      clearErrors('repeat-password');
    }
  }, [password, repeatPassword, setError, clearErrors]);

  const getErrorMessages = () => {
    return Object.values(errors)
      .map(({ message }) => message)
      .join(', ');
  };

  return (
    <Box>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 10,
            maxheight: '53%',
            width: '95vw',
            padding: 2,
          },
        }}
        scroll="body"
        BackdropComponent={styled(Backdrop, {
          name: 'MuiModal',
          slot: 'Backdrop',
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({
          zIndex: -1,
          backgroundColor: 'rgb(242, 247, 247,0.5)',
        })}
        open={open}
        onClose={() => setOpen(false)}
      >
        <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
          color="secondary"
        />

        <form onSubmit={handleSubmit(continueNext)}>
          <DialogContent style={{ overflow: 'hidden' }}>
            <DialogTitle sx={{ fontSize: 18 }}>
              {t('signUpComponent.signup-exar')}
              <span
                style={{ float: 'right', color: 'grey', cursor: 'pointer' }}
                onClick={() => setOpen(false)}
              >
                &#x2715;
              </span>
            </DialogTitle>
            <Controller
              name="parents-name"
              control={control}
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <InputBarComponent
                  name="parents-name"
                  label={t('ProfileComponent.name')}
                  type="text"
                  {...field}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <InputBarComponent
                  name="email"
                  label={t('ProfileComponent.email')}
                  type="email"
                  {...field}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password should be at least 8 characters',
                },
              }}
              render={({ field }) => (
                <InputBarComponent
                  name="password"
                  label={t('welcomeView.password')}
                  type="password"
                  {...field}
                />
              )}
            />
            <Controller
              name="repeat-password"
              control={control}
              rules={{ required: 'Please repeat your password' }}
              render={({ field }) => (
                <InputBarComponent
                  name="repeat-password"
                  label={t('welcomeView.repeatpass')}
                  type="password"
                  {...field}
                />
              )}
            />
          </DialogContent>
          <DialogActions
            disableSpacing={true}
            sx={{ justifyContent: 'center', height: '10%' }}
          >
            <Button
              type="submit"
              variant="contained"
              disabled={Object.keys(errors).length > 0}
              sx={{
                borderRadius: 20,
                backgroundColor: '#FF592C',
              }}
            >
              {t('signUpComponent.continue')}
            </Button>
            {Object.keys(errors).length > 0 && (
              <Tooltip title={getErrorMessages()}>
                <span>
                  <ErrorIcon
                    style={{ float: 'centre', color: 'red', cursor: 'pointer' }}
                  />
                </span>
              </Tooltip>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default UserSignUpDetails;
