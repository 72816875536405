import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAverageScoreByAge } from '../../../constants/Demo/sitAndStand';
import { useCountsInTimeCoreFeedback } from '../../../hooks/Demo/feedbackTypes';
import { safeIterScore } from '../../../hooks/Demo/sharedLogic';
import {
  checkIfRightSide,
  checkIfSide,
  drawAngle,
} from '../../../utilities/Demo/angles';
import { loadPhysioAudio } from '../../../utilities/Demo/audioFeedback';
import {
  findCounterAngle,
  findDependentAngle,
  horizontalAngle,
} from '../../../utilities/Demo/physio';
import { checkArmsUsed } from '../../../utilities/Demo/Physio/basics';
import PoseDetection from '../PoseDetection';

export default function SitAndStand({ onComplete, showWebcam = false }) {
  const patientInfo = useSelector((state) => state.gaitPatientInfo.values);
  const dispatch = useDispatch();
  const collecting = useRef(false);
  const leanedForward = useRef(false);
  const peaked = useRef(false);
  const prePeaked = useRef(false);
  const isLeft = useRef(false);
  const timeThresh = 30;
  const {
    correctCount,
    updateFeedback,
    // incorrectCount,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    raiseLevel,
    setSide,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadPhysioAudio,
  });
  cptScore.current = () => {
    return (
      correctCount.current /
      getAverageScoreByAge({ age: patientInfo.age, gender: patientInfo.gender })
    );
  };

  function giveSittingFeedback(backAngle, kneeAngle) {
    if (backAngle > 100) {
      playAudio(audioFeedback.current?.leanBackwards);
      updateFeedback('Lean forward!');
      return;
    } else if (backAngle < 80) {
      playAudio(audioFeedback.current?.leanForward);
      updateFeedback('Lean forward!');
      return;
    } else if (kneeAngle < 80) {
      playAudio(audioFeedback.current?.kneesFallingOverToes);
      updateFeedback('Knees falling over toes!');
      return;
    } else if (kneeAngle > 100) {
      playAudio(audioFeedback.current?.knee90Degrees);
      updateFeedback('Hold your knee at 90 degrees!');
      return;
    }
    updateFeedback('');
  }

  function observation(backAngle, kneeAngle, results) {
    if (kneeAngle < 100 && backAngle < 100) {
      if (checkArmsUsed(results, isLeft.current)) {
        updateFeedback('Do not use your arms!');
        collecting.current = false;
        prePeaked.current = false;
        peaked.current = false;
        leanedForward.current = false;
        return;
      } else {
        if (
          backAngle > 80 &&
          backAngle < 100 &&
          kneeAngle > 80 &&
          kneeAngle < 100
        ) {
          if (peaked.current) {
            correctCount.current += 1;
            updateCounts(correctCount.current);
            const score = cptScore.current();
            if (!raiseLevel(score)) {
              playAudio(audioFeedback.current?.achievement, true);
            } else {
              safeIterScore(dispatch, score);
            }
          }
          prePeaked.current = false;
          peaked.current = false;
          collecting.current = true;
          leanedForward.current = false;
        } else {
          if (backAngle < 100) {
            giveSittingFeedback(backAngle, kneeAngle);
          }
        }
      }
    }
    if (collecting.current) {
      if (backAngle > 100) {
        leanedForward.current = true;
      }
      // standing up
      if (kneeAngle > 120 && !prePeaked.current && leanedForward.current) {
        prePeaked.current = true;
        setStartingTime(seconds);
      } else if (prePeaked.current && kneeAngle > 160 && !peaked.current) {
        peaked.current = true;
      }
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    if (!checkIfSide(results)) {
      playAudio(audioFeedback.current?.turnToSide, false, 6);
      updateFeedback('Turn to the side!');
      setSide(false);
      return;
    } else {
      updateFeedback('');
      setSide(true);
    }
    isLeft.current = checkIfRightSide(results, 'left');
    let kneeAngle;
    let backAngle;

    if (isLeft.current) {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      backAngle = findCounterAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        180
      );
      drawAngle(canvasCtx, kneeAngle, results.poseLandmarks[25]);
      drawAngle(canvasCtx, backAngle, results.poseLandmarks[11]);
    } else {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      if (kneeAngle > 180) {
        kneeAngle = 360 - kneeAngle;
      }
      backAngle = horizontalAngle(
        results.poseLandmarks[12],
        results.poseLandmarks[24]
      );
      drawAngle(canvasCtx, kneeAngle, results.poseLandmarks[26]);
      drawAngle(canvasCtx, backAngle, results.poseLandmarks[12]);
    }
    observation(backAngle, kneeAngle, results);
  }

  return (
    <PoseDetection
      onResults={onResults}
      showWebcam={showWebcam}
      showBackbones={true}
    />
  );
}
