import React from 'react';
import { useState, useEffect } from 'react';
import av1 from '../../assets/av1.png';
import av4 from '../../assets/av4.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TopThreeClubStudentsComponent = ({ teacher, topthree }) => {
  console.log('in TopThreeClubStudentsComponent', teacher);
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log('TOpthree::', topthree);
  const [user] = useState(JSON.parse(localStorage.getItem('user_data')));
  useEffect(() => {
    console.log('home user::', user);
    //setUser(JSON.parse(localStorage.getItem('user_data')));
  });
  return (
    <div className="top-performers-container">
      {
        <div className="top">
          <span>{t('TopThreeComponent.title')}</span>
          <div
            className="find-me-box"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/club-ranking')}
          >
            {t('TopThreeComponent.findMe')}
          </div>
        </div>
      }
      <div className="bottom">
        {topthree &&
          topthree.map(
            (item, index) =>
              index < 3 && (
                <div className="item" key={'topthree-item-' + index}>
                  <img
                    alt="Cindy Baker"
                    src={item ? item.profile_pic && item.profile_pic : av1}
                  />
                  <span> {item ? item.name : 'Babu'} </span>
                </div>
              )
          )}
        <div className="item">
          {
            <>
              <img
                alt="Cindy Baker"
                style={{ objectFit: 'cover' }}
                src={user && user.profile_pic ? user.profile_pic : av4}
              />
              <span> {t('TopThreeComponent.me')} </span>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default TopThreeClubStudentsComponent;
