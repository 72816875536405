import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useCountsInTimeCoreFeedback } from '../../../../../hooks/Demo/feedbackTypes';
import { safeIterScore } from '../../../../../hooks/Demo/sharedLogic';
import {
  checkIfRightSide,
  checkIfSide,
} from '../../../../../utilities/Demo/angles';
import { loadPickUpOffOfTheFloorAudio } from '../../../../../utilities/Demo/audioFeedback';
import {
  findAdjustedAngle,
  findDependentAngle,
} from '../../../../../utilities/Demo/physio';
import PoseDetection from '../../../PoseDetection';
import {
  backRomMetrics,
  computeMetrics,
  upperLegRomMetrics,
} from '../../../../../utilities/Demo/Physio/BergBalance/metrics';

export default function PickUpOffOfTheFloor({
  onComplete,
  showWebcam = false,
}) {
  const dispatch = useDispatch();
  const leanedForward = useRef(false);
  const reachedDown = useRef(false);
  const startStand = useRef(false);
  const isLeft = useRef(false);
  const timeThresh = 60;
  const {
    correctCount,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    setSide,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadPickUpOffOfTheFloorAudio,
    maxScore: 1,
  });
  cptScore.current = () => {
    if (correctCount.current === 0) return 0;
    if (correctCount.current === 0.5) return 1 / 3;
    if (correctCount.current === 1) return 1;
  };

  const metrics = useRef(
    backRomMetrics().concat(upperLegRomMetrics('Extension', 'Flexion'))
  );

  function observation(backAngle, kneeAngle, thump, ankle) {
    if (kneeAngle > 160 && backAngle < 100 && backAngle > 80) {
      if (!leanedForward.current && !reachedDown.current) {
        startStand.current = true;
        setStartingTime(seconds);
        updateFeedback(
          `Reach down with your ${isLeft.current ? 'left' : 'right'} hand!`
        );
      } else if (startStand.current && reachedDown.current) {
        if (leanedForward.current) {
          correctCount.current = 1;
        } else {
          correctCount.current = 0.5;
        }
        leanedForward.current = false;
        reachedDown.current = false;
        updateCounts(correctCount.current);
        const score = cptScore.current();
        playAudio(audioFeedback.current?.achievement, true);
        safeIterScore(dispatch, score);
        onComplete();
      }
    }
    if (!leanedForward.current && startStand.current) {
      if (thump.y > ankle.y * 0.95) {
        updateFeedback('Now come up again!');
        leanedForward.current = true;
      } else if (thump.y > ankle.y * 0.9) {
        updateFeedback('Reach down further!');
        reachedDown.current = true;
      }
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    if (!checkIfSide(results)) {
      playAudio(audioFeedback.current?.turnToSide, false, 6);
      updateFeedback('Turn to the side!');
      setSide(false);
      return;
    } else {
      updateFeedback('');
      setSide(true);
    }
    isLeft.current = checkIfRightSide(results, 'left');
    let kneeAngle;
    let backAngle;
    let thump;
    let ankle;
    let hipKneeAngle;
    let displayConfigs;

    if (isLeft.current) {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      backAngle = findAdjustedAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        180
      );
      hipKneeAngle = findAdjustedAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25]
      );
      thump = results.poseLandmarks[21];
      ankle = results.poseLandmarks[27];
      displayConfigs = {
        hipKneeAngle: {
          coord: results.poseLandmarks[23],
          left: false,
        },
        backAngle: {
          coord: results.poseLandmarks[11],
          left: false,
        },
      };
    } else {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      if (kneeAngle > 180) {
        kneeAngle = 360 - kneeAngle;
      }
      backAngle =
        360 -
        findAdjustedAngle(
          results.poseLandmarks[12],
          results.poseLandmarks[24],
          0
        );
      hipKneeAngle =
        360 -
        findAdjustedAngle(results.poseLandmarks[24], results.poseLandmarks[26]);
      thump = results.poseLandmarks[22];
      ankle = results.poseLandmarks[28];
      displayConfigs = {
        hipKneeAngle: {
          coord: results.poseLandmarks[24],
          left: false,
        },
        backAngle: {
          coord: results.poseLandmarks[12],
          left: false,
        },
      };
    }
    computeMetrics({
      metrics: metrics.current,
      angles: {
        current: {
          backAngle,
          hipKneeAngle,
        },
      },
      dispatch,
      canvasCtx,
      displayConfigs,
    });
    observation(backAngle, kneeAngle, thump, ankle, results);
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
