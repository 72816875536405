import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import '@tensorflow/tfjs-backend-webgl';
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import { POSE_CONNECTIONS } from '@mediapipe/pose';
import { useSweatPoints } from '../../hooks/sweatPoints';
import { checkIfRightSide, checkIfSide } from '../../utilities/Demo/angles';
import { drawLinearConnectors } from '../../utilities/Demo/BackboneDetection/visualisation';
import {
  drawLandmark,
  selectLandmarks,
} from '../../utilities/Demo/poseDetection';
import PoseModelContext from '../../contexts/pose/PoseModelContext';
import * as cam from '@mediapipe/camera_utils';

function PoseDetection({
  onResults,
  showWebcam = true,
  showBackbones = false,
  position = 'relative',
  videoUrl = null,
}) {
  const webcamSettings = useSelector(({ webcam }) => webcam.values);
  const isLeft = useRef(true);
  const webcamRef = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const backbones = useRef({});
  const { pose, backboneWorker } = useContext(PoseModelContext);
  const { computeSweatPoints } = useSweatPoints();
  const iter = useSelector(({ iter }) => iter.values);
  const dispatch = useDispatch();
  const requestIdRef = useRef(null);

  // Wait for pose to be ready
  useEffect(() => {
    if (!pose || videoUrl) {
      return;
    }

    const element = webcamRef.current?.video || videoRef.current; // Adjust based on whether webcamRef is a video or webcam element
    let camera = new cam.Camera(element, {
      onFrame: async () => {
        try {
          await pose.send({ image: element });
        } catch (e) {
          console.log(e);
        }
      },
      width: webcamSettings.useOffset
        ? element.offsetWidth
        : element.videoWidth,
      height: webcamSettings.useOffset
        ? element.offsetHeight
        : element.videoHeight,
    });

    camera.start();
    if (!iter.cameraDeviceId) {
      if (webcamRef.current && webcamRef.current?.stream) {
        const activeDeviceId = webcamRef.current?.stream
          .getVideoTracks()[0]
          .getSettings().deviceId;

        dispatch({
          type: 'SET-ITER-CAMERA-DEVICE-ID',
          payload: activeDeviceId,
        });
      }
    }

    // Stop the camera when the component is unmounted
    return () => {
      console.log('stopping cam');
      camera.stop();
    };
  }, [pose, videoUrl]); // Only run this effect when pose changes

  if (!pose) return null;

  const prepCanvas = (results, canvasRef, videoWidth, videoHeight) => {
    const canvasCtx = canvasRef.current.getContext('2d');
    canvasCtx.globalCompositeOperation = 'source-over';

    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    // Draw the video on the canvas
    canvasCtx.clearRect(0, 0, canvasRef.width, canvasRef.height);
    if (showWebcam === true || showWebcam.current === true) {
      canvasCtx.drawImage(
        webcamRef.current?.video || videoRef.current,
        0,
        0,
        videoWidth,
        videoHeight
      );
    }
    const selectedLandmarks = selectLandmarks(results);
    drawConnectors(canvasCtx, selectedLandmarks, POSE_CONNECTIONS, {
      color: 'white',
      fillColor: 'rgb(255,138,0)',
      lineWidth: 6,
    });
    drawLandmarks(canvasCtx, selectedLandmarks, {
      color: '#F37637',
      lineWidth: 5,
      radius: 4,
    });
    // results = addBackbones(results, 'left');
    results.poseLandmarks.forEach((landmark, index) => {
      results.poseLandmarks[index].x = landmark.x * videoWidth;
      results.poseLandmarks[index].y = landmark.y * videoHeight;
    });
    try {
      // Check if the browser supports Web Workers
      if (window.Worker && showBackbones) {
        if (checkIfSide(results)) {
          // canvasCtx.drawImage(
          //   results.segmentationMask,
          //   0,
          //   0,
          //   canvasRef.current.width,
          //   canvasRef.current.height
          // );
          let left = checkIfRightSide(results, 'left');
          const switchedSide = isLeft.current !== left;
          isLeft.current = left;

          if (!switchedSide) {
            Object.keys(backbones.current).forEach((key) => {
              const backbone = backbones.current[key];
              drawLandmark(canvasCtx, backbone, 1, 1);
            });
            drawLinearConnectors(canvasCtx, backbones.current);
          }
          backboneWorker.postMessage({
            segmentationMask: results.segmentationMask,
            poseLandmarks: results.poseLandmarks,
            canvasWidth: canvasRef.current.width,
            canvasHeight: canvasRef.current.height,
            side: left ? 'left' : 'right',
            switchedSide,
          });
          backboneWorker.onmessage = function (e) {
            backbones.current = e.data.backbones;
          };
        }
      } else {
        if (!window.Worker) {
          console.log("Your browser doesn't support web workers.");
        }
      }
    } catch (e) {
      console.log(e);
      alert('Error drawing left side');
    }
    results.backbones = backbones.current;
    return [canvasCtx, results];
  };

  const onFrame = async () => {
    if (
      !videoRef.current ||
      videoRef.current.paused ||
      videoRef.current.ended
    ) {
      return; // Stop the loop if the video is not playing
    }

    // Draw the video frame to the canvas
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    try {
      // Send the canvas to BlazePose for pose detection
      await pose.send({ image: canvas });
    } catch (e) {
      console.error(e);
    }

    // Request the next animation frame
    requestIdRef.current = requestAnimationFrame(onFrame);
  };

  useEffect(() => {
    if (!videoUrl) return;
    // Set the canvas size once the video metadata is loaded
    const setCanvasSize = () => {
      const video = videoRef.current;
      canvasRef.current.width = video.videoWidth;
      canvasRef.current.height = video.videoHeight;
    };

    // Start processing frames once the video starts playing
    const startProcessing = () => {
      requestIdRef.current = requestAnimationFrame(onFrame);
    };

    const video = videoRef.current;
    video.addEventListener('loadedmetadata', setCanvasSize);
    video.addEventListener('play', startProcessing);

    return () => {
      // Clean up event listeners and cancel the animation frame request
      video.removeEventListener('loadedmetadata', setCanvasSize);
      video.removeEventListener('play', startProcessing);
      if (requestIdRef.current) {
        cancelAnimationFrame(requestIdRef.current);
      }
    };
  }, [videoUrl]);

  const detect = async (results) => {
    // console.log(results);
    const element = webcamRef.current?.video || videoRef.current;
    if (
      typeof element !== 'undefined' &&
      element !== null &&
      element?.readyState === 4
    ) {
      const videoWidth = webcamSettings.useOffset
        ? element.offsetWidth
        : element.videoWidth;
      const videoHeight = webcamSettings.useOffset
        ? element.offsetHeight
        : element.videoHeight;
      element.width = videoWidth;
      element.height = videoHeight;
      if (results.poseLandmarks) {
        const relativeLandmarks = results.poseLandmarks
          .slice(0, 33)
          .map((landmark) => {
            return { ...landmark };
          });
        const [canvasCtx, resultsAdj] = prepCanvas(
          results,
          canvasRef,
          videoWidth,
          videoHeight
        );
        onResults(resultsAdj, canvasCtx, relativeLandmarks);
        computeSweatPoints(resultsAdj, canvasRef);
      }
    }
  };

  pose.onResults(detect);
  return (
    <div
      id="pose-detection"
      style={{
        position: position, // or 'fixed'
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none',
        // transform: 'scaleX(-1)',
      }}
    >
      {videoUrl ? (
        <video
          ref={videoRef}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            textAlign: 'center',
            zIndex: 1,
            width: '100%',

            height: '100%',
            objectFit: 'cover',
            opacity: 1,
          }}
          autoPlay
          loop={false}
          muted
          src={videoUrl} // URL to your video file
        />
      ) : (
        <Webcam
          ref={webcamRef}
          videoConstraints={{ deviceId: iter.cameraDeviceId }}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            textAlign: 'center',
            zIndex: 1,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: 0, // showWebcam ? 1 : 0,
            // transform: 'scaleX(-1)',
          }}
        ></Webcam>
      )}
      {
        <div style={{ position: 'relative', height: '100%' }}>
          <canvas
            id={'pose-canvas'}
            ref={canvasRef}
            style={{
              position: 'relative',
              left: 0,
              right: 0,
              zIndex: 2,
              opacity: 1,
              width: '100%',
              height: '100%',
              // transform: 'scaleX(-1)',
            }}
          />
        </div>
      }
    </div>
  );
}

export default React.memo(PoseDetection);
