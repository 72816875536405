const initialState = {
  values: {
    stepsRight: 0,
    stepsLeft: 0,
    strideTimeLeft: [],
    strideTimeRight: [],
    stanceTimeLeft: [],
    stanceTimeRight: [],
    centerOfMassData: [],
    graphData: [],
    feedback: '',
    stepValidityLeft: [],
    stepValidityRight: [],
  },
};

export const gaitFrontReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE-GAIT-FRONT-OBSERVATION': {
      return { values: { ...state.values, ...action.payload } };
    }
    case 'GAIT-FRONT-FEEDBACK': {
      return { values: { ...state.values, ...action.payload } };
    }
    case 'APPEND-COM-GAIT-FRONT-OBSERVATION': {
      state.values.centerOfMassData.push(action.payload);
      return { values: { ...state.values } };
    }
    case 'RESET-GAIT-FRONT-OBSERVATION':
      return initialState;
    default: {
      return state;
    }
  }
};
