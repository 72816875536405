import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import './highlights.css';
import av4 from '../../assets/av4.png';
import ProgressArticleNavigation from '../Progress/ProgressArticleNavigation';
import { useTranslation } from 'react-i18next';

const MyRank = ({ rank, pageName, board, from = '' }) => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user_data'));

  const getImageSrc = () => {
    if (from === 'highlights') {
      return user.profile_pic;
    }

    if (user) {
      return user.role === 'student' ? user.profile_pic : board.profile_pic;
    }

    return av4;
  };

  const getUserName = () => {
    if (from === 'highlights') {
      return user.name;
    }

    if (user) {
      return user.role === 'student' ? user.name : board.name;
    }

    return '';
  };

  console.log('Form::', from);
  return (
    <div className="my-rank box--container progress-article progress-rank">
      {pageName == 'progress' ? (
        <ProgressArticleNavigation
          title={t('MyRankComponent.title')}
          selectVal={'Today'}
        />
      ) : (
        <div className="top-bar">
          <div className="left">
            <div className="orange-box"></div>
            <h2>{t('MyRankComponent.title')}</h2>
          </div>
          <div className="sel-wrapper">
            <Select
              sx={{
                height: '40px',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                color: '#6f767e',
                borderRadius: '.5rem',
              }}
              className="my-rank-top-select"
              defaultValue={10}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>{t('MyRankComponent.today')}</MenuItem>
            </Select>
          </div>
        </div>
      )}
      <div className="my-rank-content">
        <div className="left">
          <img src={getImageSrc()} alt="Me Image" />
          <p>
            <center>{getUserName()}</center>
          </p>
        </div>
        <div className="right">
          <h1>{rank}</h1>
          {/* <h1>{rank}</h1> */}
        </div>
      </div>
    </div>
    // <div className='box1'>
    //   <Grid container padding={2} className="gridder">
    //     <Grid item xs={1}>
    //       <div className='orange'></div>
    //     </Grid>
    //     <Grid item xs={11}>
    //       <h3>
    //         <b>My Rank</b>
    //       </h3>
    //     </Grid>
    //   </Grid>
    //   <Grid container padding={2} className="gridder">
    //     <Grid item xs={6}>
    //       <div className='colflex'>
    //         <Avatar
    //           alt="Cindy Baker"
    //           src={av4}
    //           sx={{ width: '35%', height: '30%' }}
    //         />
    //         <div className='avName'>{namer}</div>
    //       </div>
    //     </Grid>
    //     <Grid item xs={6} sx={{ float: 'right' }}>
    //       <Box className='greybox'>
    //         <h2 className='rank'>
    //           <b>{rank}</b>
    //         </h2>
    //       </Box>
    //     </Grid>
    //   </Grid>
    // </div>
  );
};

export default MyRank;
