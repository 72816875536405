import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const useCountFeedback = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'RESET_COUNTS',
      payload: {},
    });
  }, []);

  const updateFeedback = (feedback) => {
    dispatch({
      type: 'FEEDBACK',
      payload: {
        feedback: feedback,
      },
    });
  };

  const updateCounts = (correct) => {
    dispatch({
      type: 'UPDATE_COUNT',
      payload: {
        correct: correct,
      },
    });
  };

  const updateIncorrectCounts = (incorrect) => {
    dispatch({
      type: 'UPDATE_COUNT',
      payload: {
        incorrect,
      },
    });
  };

  const updateTime = (time) => {
    dispatch({
      type: 'UPDATE_COUNT',
      payload: {
        time,
      },
    });
  };

  return {
    updateFeedback,
    updateCounts,
    updateIncorrectCounts,
    updateTime,
  };
};

export const useCountsInTimeFeedback = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'RESET-COUNT-TIME-OBSERVATION',
      payload: {},
    });
  }, []);

  const updateFeedback = (feedback) => {
    dispatch({
      type: 'FEEDBACK',
      payload: {
        feedback: feedback,
      },
    });
  };

  const updateCounts = (correct) => {
    dispatch({
      type: 'UPDATE-COUNT-TIME-OBSERVATION',
      payload: {
        correct: correct,
      },
    });
  };

  const updateTime = (time) => {
    dispatch({
      type: 'UPDATE-COUNT-TIME-OBSERVATION',
      payload: {
        time: time,
      },
    });
  };

  return {
    updateFeedback,
    updateCounts,
    updateTime,
  };
};

export const useLivesFeedback = (lives = 3) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'RESET-LIVES-OBSERVATION',
      payload: {},
    });
    updateLives(lives);
  }, []);

  const updateLives = (lives) => {
    dispatch({
      type: 'UPDATE-LIVES-OBSERVATION',
      payload: {
        lives: lives,
      },
    });
  };

  return {
    updateLives,
  };
};

export const useBalanceFeedback = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'RESET_BALANCE_OBSERVATION',
      payload: {},
    });
  }, []);

  const updateFeedback = (feedback) => {
    dispatch({
      type: 'BALANCE_FEEDBACK',
      payload: {
        feedback: feedback,
      },
    });
  };

  const updateSecondsPoseHeld = (secondsPoseHeld) => {
    dispatch({
      type: 'UPDATE_BALANCE_OBSERVATION',
      payload: {
        secondsPoseHeld: secondsPoseHeld,
      },
    });
  };

  const updateInterruptions = (interruptions) => {
    dispatch({
      type: 'UPDATE_BALANCE_OBSERVATION',
      payload: {
        interruptions: interruptions,
      },
    });
  };

  const updateTime = (time) => {
    dispatch({
      type: 'UPDATE_BALANCE_OBSERVATION',
      payload: {
        time,
      },
    });
  };

  return {
    updateFeedback,
    updateSecondsPoseHeld,
    updateInterruptions,
    updateTime,
  };
};

export const useStretchFeedback = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'RESET_STRETCH',
      payload: {},
    });
  }, []);

  const updateFeedback = (feedback) => {
    dispatch({
      type: 'STRETCH_FEEDBACK',
      payload: {
        feedback: feedback,
      },
    });
  };

  const updateBestValue = (bestValue) => {
    dispatch({
      type: 'UPDATE_STRETCH_OBSERVATION',
      payload: {
        bestValue: bestValue,
      },
    });
  };

  const updateTime = (time) => {
    dispatch({
      type: 'UPDATE_STRETCH_OBSERVATION',
      payload: {
        time,
      },
    });
  };

  return {
    updateFeedback,
    updateBestValue,
    updateTime,
  };
};

export const useStretchCountFeedback = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'RESET_STRETCH_COUNT',
      payload: {},
    });
  }, []);

  const updateFeedback = (feedback) => {
    dispatch({
      type: 'STRETCH_COUNT_FEEDBACK',
      payload: {
        feedback: feedback,
      },
    });
  };

  const updateBestValue = (bestValue) => {
    dispatch({
      type: 'UPDATE_STRETCH_COUNT_OBSERVATION',
      payload: {
        bestValue: bestValue,
      },
    });
  };

  const updateCounts = (correntCount) => {
    dispatch({
      type: 'UPDATE_STRETCH_COUNT_OBSERVATION',
      payload: {
        correct: correntCount,
      },
    });
  };

  const updateTime = (time) => {
    dispatch({
      type: 'UPDATE_STRETCH_COUNT_OBSERVATION',
      payload: {
        time,
      },
    });
  };
  const updateResults = (results) => {
    dispatch({
      type: 'SET-ITER-RESULTS',
      payload: results,
    });
  };

  return {
    updateFeedback,
    updateBestValue,
    updateTime,
    updateCounts,
    updateResults,
  };
};

export function useSide() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'RESET-SIDE',
      payload: {},
    });

    return () => {
      dispatch({
        type: 'RESET-SIDE',
        payload: {},
      });
    };
  });

  function setSide(correct) {
    dispatch({ type: 'SET-SIDE', payload: { correct: correct } });
  }

  return {
    setSide,
  };
}
