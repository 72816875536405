import Badge from '@mui/material/Badge';
import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FootMap from '../../../SensorResults/FootMap';
import CloseBadge from '../../Configurator/CloseBadge';

export default function FootMapDialog({ sensorData, open, onClose }) {
  if (!sensorData) return null;
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Badge
        badgeContent={<CloseBadge setConfig={onClose} sx={{ mt: 5, mr: 5 }} />}
      >
        <DialogTitle sx={{ mr: 5 }}>Foot Pressure Map</DialogTitle>
      </Badge>
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <FootMap
          sensorData={{
            meanLeft: sensorData.left,
            meanRight: sensorData.right,
          }}
        />
      </div>
    </Dialog>
  );
}
