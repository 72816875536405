import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { getAudioFeedback } from '../../../../../utilities/Demo/ExerciseManagement/firestore';
import AudioFeedbackDialog from '../AudioFeedbackDialog';
import CloseBadge from '../CloseBadge';

const coreKeys = [
  'faceCamera',
  'thatIsPerfect',
  'turnToSide',
  'turnToOtherSide',
  'makeSureRightSide',
  'makeSureLeftSide',
  'achievement',
];
export default function CoreAudioConfig({ config, setConfig }) {
  const [selectionDialogOpen, setSelectionDialogOpen] = React.useState(false);
  const [selectedKey, setSelectedKey] = React.useState(null);
  const [audios, setAudios] = React.useState({});

  useEffect(() => {
    Object.keys(config).forEach((key) => {
      if (typeof config[key] === 'string') {
        getAudioFeedback({ audioFeedbackId: config[key] }).then((audioDoc) => {
          audios[key] = audioDoc;
        });
      }
    });
  }, [config]);

  return (
    <Grid
      container
      spacing={2}
      sx={{ padding: 2, margin: 1, boxShadow: 2, width: '50%' }}
    >
      <AudioFeedbackDialog
        setAudioFeedback={(audioFeedback) => {
          setAudios({ ...audios, [selectedKey]: audioFeedback });
          setConfig({ ...config, [selectedKey]: audioFeedback.id });
        }}
        open={selectionDialogOpen}
        onClose={() => {
          setSelectionDialogOpen(false);
          setSelectedKey(null);
        }}
      />
      <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
        Core Audio Configuration
      </Typography>
      {coreKeys.map((coreKey) => (
        <Grid
          container
          spacing={2}
          key={coreKey}
          sx={{ padding: 1, boxShadow: 1, mb: 0.5, ml: 0.5 }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            onClick={() => {
              setSelectedKey(coreKey);
              setSelectionDialogOpen(true);
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              {coreKey}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5} sx={{ m: 0 }}>
            {audios[coreKey] ? (
              <Badge
                badgeContent={
                  <CloseBadge
                    setConfig={() => {
                      setConfig({ ...config, [coreKey]: null });
                      setAudios({ ...audios, [coreKey]: null });
                    }}
                  />
                }
              >
                <Typography variant="subtitle1" gutterBottom>
                  {audios[coreKey].name}
                </Typography>
              </Badge>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedKey(coreKey);
                  setSelectionDialogOpen(true);
                }}
                sx={{ mr: 2 }}
              >
                SELECT AUDIO FEEDBACK
              </Button>
            )}
          </Grid>
          {audios[coreKey] && (
            <ReactAudioPlayer src={audios[coreKey]?.audioUrl} controls />
          )}
        </Grid>
      ))}
    </Grid>
  );
}
