import * as React from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import LogoutIcon from '@mui/icons-material/Logout';
import LightModeIcon from '@mui/icons-material/LightMode';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

export default function DrawerComponent({ open, setOpen }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user_data'));
  const [load, setLoad] = useState(false);

  const [openSm, setOpenSm] = React.useState(false);
  const handleLogout = () => {
    setLoad(true);
    setTimeout(() => {
      dispatch({
        type: 'LOGOUT',
        payload: {},
      });
      setLoad(false);
      navigate('/');
      window.location.reload();
    }, [6000]);
  };
  const icons = [
    {
      icon: <HomeIcon htmlColor="#6F767E" />,
      name: t('DrawerComponent.home'),
      navigate: user?.role === 'student' ? 'home' : 'teacherhome',
    },
    {
      icon: <TrendingUpIcon htmlColor="#6F767E" />,
      name: t(
        user?.role === 'student'
          ? 'ProgressComponent.progress'
          : 'DrawerComponent.classProgress'
      ),
      navigate: user?.role === 'student' ? 'progress' : 'class-progress',
    },
    {
      icon: <CalendarMonthOutlinedIcon htmlColor="#6F767E" />,
      name: t('DrawerComponent.assessments'),

      navigate: 'fitness_assessment',
    },
    {
      icon: <ChatIcon htmlColor="#6F767E" />,
      name: t('DrawerComponent.chat'),
      navigate: user?.role === 'student' ? 'chat' : 'teacherchat',
    },
    {
      icon: <AssignmentOutlinedIcon htmlColor="#6F767E" />,
      name: t(
        user?.role === 'student'
          ? 'DrawerComponent.highlights'
          : 'DrawerComponent.classHome'
      ),
      navigate: user?.role === 'student' ? 'highlights' : 'class-home',
    },
    user?.role === 'student' && {
      icon: <EmojiEventsIcon htmlColor="#6F767E" />,
      name: t('RewardComponent.title'),
      navigate: 'rewards',
    },
    {
      icon: <PersonIcon htmlColor="#6F767E" />,
      name: t('ProfileComponent.title'),
      navigate: 'profile',
    },
  ].filter(Boolean);

  return (
    <div className="menu-container">
      <div className="menu-switcher">
        <IconButton
          sx={{
            position: 'fixed',
            top: '20px',
            left: openSm ? '60px' : '0',
            backgroundColor: '#fff',
            boxShadow: '0 10px 20px rgba(0, 0, 0, .3)',
            borderRadius: '0 50vw 50vw 0',
            display: 'none',
            zIndex: '200',
            [theme.breakpoints.down('sm')]: {
              display: 'block',
            },
            transition: '200ms ease-in-out left',
          }}
          onClick={() => setOpenSm(!openSm)}
        >
          {openSm ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Drawer
        variant="permanent"
        open={open}
        elevation={28}
        PaperProps={{
          sx: {
            width: open ? '200px' : '60px',
            [theme.breakpoints.down('sm')]: {
              width: open ? '60px' : '60px',
              transform: openSm ? 'translateX(0)' : 'translateX(-100%)',
            },
            overflow: 'hidden',
            borderRadius: '24px',
            boxShadow: '0px 40px 64px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: '2rem',
            transition: '200ms ease-in-out width, 200ms ease-in-out transform',
          },
        }}
      >
        <IconButton
          sx={{
            [theme.breakpoints.down('sm')]: {
              opacity: 0,
              pointerEvents: 'none',
            },
          }}
          onClick={() => setOpen(!open)}
        >
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
        <div>
          {icons.map((item, index) => (
            <ListItem
              key={'l' + index}
              disablePadding
              sx={{ display: 'block' }}
            >
              <ListItemButton
                sx={{
                  justifyContent: open ? 'center' : 'center',
                  px: 2.5,
                }}
                // eslint-disable-next-line no-useless-escape
                onClick={() => navigate(`\/${item.navigate}`)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </div>
        <div>
          {!open ? (
            <>
              <ListItem disablePadding sx={{ display: 'block' }}>
                {load ? (
                  <CircularProgress size={20} color="success" />
                ) : (
                  <ListItemButton
                    sx={{
                      justifyContent: open ? 'center' : 'center',
                      px: 2.5,
                    }}
                    onClick={() => handleLogout()}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={'Logout'}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                )}
              </ListItem>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    // minHeight: 48,
                    justifyContent: open ? 'center' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <LightModeIcon htmlColor="black" />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Logout'}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            <>
              <Divider />
              <center>
                <ListItem
                  disablePadding
                  sx={{ display: 'block', marginLeft: '15%', marginTop: '10%' }}
                >
                  {load ? (
                    <CircularProgress size={20} color="success" />
                  ) : (
                    <ListItemButton
                      sx={{
                        // minHeight: 48,
                        justifyContent: 'center',
                      }}
                      onClick={() => handleLogout()}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <LogoutIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t('DrawerComponent.logout')}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  )}
                </ListItem>
              </center>
              <ToggleButtonGroup
                color="primary"
                fullWidth
                sx={{
                  border: '1px solid #F4F4F4',
                  borderRadius: 20,
                  width: '90%',
                  margin: 'auto',
                }}
              >
                <ToggleButton
                  value="light"
                  sx={{ border: '1px solid #F4F4F4', borderRadius: 20 }}
                >
                  <LightModeIcon htmlColor="black" />
                  &nbsp;{t('DrawerComponent.light')}
                </ToggleButton>
                <ToggleButton
                  value="dark"
                  sx={{ border: '1px solid #F4F4F4', borderRadius: 20 }}
                >
                  <NightlightOutlinedIcon htmlColor="black" />
                  &nbsp;{t('DrawerComponent.dark')}
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
}
