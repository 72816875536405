import { CircularProgress, TextField } from '@mui/material';
import Badge from '@mui/material/Badge';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { getAngles } from '../../../../../utilities/Demo/ExerciseManagement/firestore';
import CloseBadge from '../CloseBadge';

export default function AngleDialog({ setAngle, open, onClose }) {
  const [searchValue, setSearchValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [angles, setAngles] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);
    getAngles({ searchTerm: searchValue }).then((angles) => {
      setAngles(angles || []);
      setLoading(false);
    });
  }, [searchValue]);

  const handleListItemClick = (value) => {
    setAngle(value);
  };

  return (
    <Dialog open={open}>
      <Badge
        badgeContent={<CloseBadge setConfig={onClose} sx={{ mt: 5, mr: 5 }} />}
      >
        <DialogTitle sx={{ mr: 5 }}>Select Angle</DialogTitle>
      </Badge>
      <TextField
        autoFocus
        margin="dense"
        id="search"
        label="Search"
        type="search"
        fullWidth
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
      />
      {loading && <CircularProgress />}
      <List sx={{ pt: 0 }}>
        {angles.map((angle) => (
          <ListItem disableGutters key={angle.id}>
            <ListItemButton
              onClick={() => handleListItemClick(angle)}
              key={angle.id}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={angle.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
