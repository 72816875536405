import React from 'react';
import MyRank from './MyRank';
import Duration from './Duration';
import SweatPoints from './SweatPoints';
import './highlights.css';
import { useTranslation } from 'react-i18next';

const contentStyle = { display: 'flex', justifyContent: 'center', margin: 1 };

const TodayHighlights = ({ points, data }) => {
  const { t } = useTranslation();
  let ind1 = [
    { color: '#CABDFF', points: points ? points.sweatpoints : 0 },
    { color: '#B1E5FC', points: 85 },
  ];
  let ind2 = [
    {
      color: '#CABDFF',
      duration: points ? parseFloat(points.duration / 60).toFixed(2) : 0,
    },
    { color: '#B1E5FC', duration: parseFloat((27.5 * 60) / 60).toFixed(2) },
  ];
  console.log('Data is::', data);
  return (
    <div className="today-highlights-container">
      <div className="top-bar">
        <div className="orange-box"></div>
        <h2>{t('MyRankComponent.todaysHighlightTitle')}</h2>
      </div>
      <div className="today-highlights-content">
        <div className="top" style={contentStyle}>
          <MyRank rank={data ? data.rank : 1} from="highlights" />
        </div>
        <div className="bottom" style={contentStyle}>
          <Duration
            points={points ? parseFloat(points.duration / 60).toFixed(2) : 0}
            indi={ind2}
          />
          <SweatPoints points={points ? points.sweatpoints : 0} indi={ind1} />
        </div>
      </div>
    </div>
  );
};

export default TodayHighlights;
