import React, { useEffect, useRef } from 'react';
import '@tensorflow/tfjs-backend-webgl';
import { useCountsInTimeCoreFeedback } from '../../../hooks/Demo/feedbackTypes';
import { useLivesFeedback } from '../../../hooks/Demo/visualFeedback';
import { loadPhysioAudio } from '../../../utilities/Demo/audioFeedback';
import PoseDetection from '../PoseDetection';
import { safeIterScore } from '../../../hooks/Demo/sharedLogic';
import { createKickBoxingGame } from '../../../utilities/Demo/Kickboxing/animations';
import { useDispatch } from 'react-redux';

function Kickboxing({ onComplete, showWebcam = false }) {
  const sceneInstance = useRef(null);
  const game = useRef(null);
  const dispatch = useDispatch();
  const timeThresh = 60;
  const {
    correctCount,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadPhysioAudio,
  });
  const { updateLives } = useLivesFeedback();

  cptScore.current = () => {
    return correctCount.current / 200;
  };

  useEffect(() => {
    createKickBoxingGame().then((g) => {
      setTimeout(() => {
        game.current = g;
        sceneInstance.current = game.current.scene.keys.KickboxingGame;
        game.current.scene.start('KickboxingGame');
      }, 100);
    });
    return () => game.current?.destroy(true);
  }, []);

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;
    // console.log(results);
    setStartingTime(seconds);
    sceneInstance.current?.updateCoordinates(
      {
        x: results.poseLandmarks[15].x / canvasCtx.canvas.width,
        y: results.poseLandmarks[15].y / canvasCtx.canvas.height,
      },
      {
        x: results.poseLandmarks[16].x / canvasCtx.canvas.width,
        y: results.poseLandmarks[16].y / canvasCtx.canvas.height,
      }
    );
    if (sceneInstance.current?.score > correctCount.current) {
      correctCount.current = sceneInstance.current?.score;
      playAudio(audioFeedback.current?.achievement, true);
    }
    updateCounts(sceneInstance.current?.score);
    const score = cptScore.current();
    safeIterScore(dispatch, score);
    if (sceneInstance.current?.lostLives === 3) {
      onComplete();
    }
    updateLives(3 - sceneInstance.current?.lostLives);
    // if (sceneInstance.current.score > incorrectCount.current)
    //   updateIncorrect(sceneInstance.current.score);
  }

  return (
    <PoseDetection
      onResults={onResults}
      showWebcam={showWebcam}
      position={'absolute'}
    />
  );
}

export default React.memo(Kickboxing);
