import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGaitActions } from '../../../../../hooks/GaitAnalysis/actions';
import {
  checkPersonOutOfFrame,
  useTimer,
} from '../../../../../hooks/GaitAnalysis/shared';
import {
  checkIfRightSide,
  checkIfSide,
} from '../../../../../utilities/Demo/angles';
import {
  findGaitAngles,
  getStepLength,
  updateTimeSeriesData,
} from '../../../../../utilities/Demo/gaitAnalysis';
import {
  anyPressure,
  noPressure,
} from '../../../../../utilities/Demo/Physio/GaitAnalysis/observation';
import PoseDetection from '../../../PoseDetection';
import { useStridalizerEventListener } from '../../../../../hooks/Demo/stridalizer';

function GaitAnalysis({
  setLegAngleData,
  showWebcam,
  sensorData,
  recordingEnabled,
  relativeLandmarksRef,
  useSensorForCycle,
  videoUrl = null,
}) {
  const dispatch = useDispatch();
  const { time, stopTimeDiffLeft, stopTimeDiffRight, timeRunning } = useTimer();
  const peaked = useRef(false);
  const switched = useRef(false);
  const startedStride = useRef(false);
  const gaitSide = useRef('left');
  const recordingFromLeftSide = useRef(true);
  const patientHeight = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values.height
  );
  const swingLegAngles = useRef([]);
  const stanceToeStrideHeelXDiff = useRef([]);
  const validStep = useRef(true);
  const validStride = useRef(true);
  const sensorCandidate = React.useRef({
    left: null,
    right: null,
  });
  const bpResults = useRef(null);
  const personOutOfFrame = useRef(true);
  useStridalizerEventListener({
    sensorCandidate,
    sensorData,
    time,
    timeRunning,
    callBack: () => {
      if (timeRunning.current) {
        // observeGaitSensors(bpResults.current);
        console.log(sensorData.current, 'sensor data', dispatch);
        // dispatch({
        //   type: `UPDATE-GAIT-OBSERVATION`,
        //   payload: {
        //     sensorData: sensorData.current,
        //   },
        // });
      }
    },
  });

  const {
    updateSteps,
    updateStrideTime,
    updateStanceTime,
    updateStepLength,
    updateStepTimeLength,
    updateFeedback,
    safeHipShoulderRWU,
    appendCenterOfMassData,
    updateStepValidity,
    updateStanceTimeLength,
    updateStanceTimeWindow,
    updateStrideTimeWindow,
    updateOverPronated,
    updateInvalidMarkerEvents,
    updateValidMarkerEvents,
    updateStrideValidity,
  } = useGaitActions();

  useEffect(() => {
    console.log(observeGaitSensors);
  }, []);

  const observeGaitSensors = (results) => {
    if (gaitSide.current === 'left') {
      if (
        anyPressure(sensorCandidate.current.left?.result) &&
        anyPressure(sensorCandidate.current.right?.result) &&
        !peaked.current
      ) {
        peaked.current = true;
        switched.current = false;
        const validStepFromLeftSide = validStep.current; // && recordingFromLeftSide.current;
        if (timeRunning.current) {
          const stepLength = getStepLength(
            patientHeight,
            results,
            safeHipShoulderRWU
          );
          updateStepLength(stepLength, 'left', time.current);
          const timeDiff = stopTimeDiffLeft();
          updateStrideTime(timeDiff, 'left');
          updateStepTimeLength('left');
          updateStanceTimeWindow(time.current, true, 'left');
          updateStrideTimeWindow(time.current, false, 'left');
          updateOverPronated(sensorCandidate.current.left?.result.overpronate);
          // updateFeedback(
          //   `${validStep.current} ${recordingFromLeftSide.current} ${gaitSide.current}`
          // );
          updateStepValidity(validStepFromLeftSide, 'left');
          validStep.current = true;
          updateValidMarkerEvents(time.current);
        } else {
          timeRunning.current = true;
          console.log(time.current, 'time on continue');
          // stopTimeDiffLeft();
          // stopTimeDiffRight();
        }
      }
      if (
        // peaked.current &&
        !startedStride.current
      ) {
        if (noPressure(sensorCandidate.current.right?.result)) {
          if (timeRunning.current) {
            startedStride.current = true;
            const timeDiff = stopTimeDiffRight();
            updateStanceTime(timeDiff, 'right');
            updateStanceTimeLength('right');
            updateStanceTimeWindow(time.current, false, 'right');
            updateSteps('right');
            updateStrideTimeWindow(time.current, true, 'right');
            updateStrideValidity(validStride.current, 'right');
            validStride.current = true;
          }
        }
      }
      if (peaked.current) {
        if (noPressure(sensorCandidate.current.left?.result)) {
          console.log('no pressure', time.current, 'time');
          // validStride.current = false;
          // validStep.current = false;
        }
      }
      if (peaked.current && startedStride.current && !switched.current) {
        switched.current = true;
        gaitSide.current = 'right';
        peaked.current = false;
        swingLegAngles.current = [];
        startedStride.current = false;
      }
    } else {
      if (
        anyPressure(sensorCandidate.current.left?.result) &&
        anyPressure(sensorCandidate.current.right?.result) &&
        !peaked.current
      ) {
        if (timeRunning.current) {
          const validStepFromRightSide = validStep.current; // && !recordingFromLeftSide.current;
          peaked.current = true;
          switched.current = false;
          const stepLength = getStepLength(patientHeight, results);
          updateStepLength(stepLength, 'right', time.current);
          const timeDiff = stopTimeDiffRight();
          updateStrideTime(timeDiff, 'right');
          updateStepTimeLength('right');
          updateStanceTimeWindow(time.current, true, 'right');
          updateStrideTimeWindow(time.current, false, 'right');
          updateOverPronated(sensorCandidate.current.right?.result.overpronate);
          // updateFeedback(
          //   `${validStep.current} ${!recordingFromLeftSide.current} ${
          //     gaitSide.current
          //   }`
          // );
          updateStepValidity(validStepFromRightSide, 'right');
          validStep.current = true;
          updateValidMarkerEvents(time.current);
        } else {
          timeRunning.current = true;
          console.log(time.current, 'time on continue');
          // stopTimeDiffRight();
          // stopTimeDiffLeft();
        }
      }
      if (
        // peaked.current &&
        !startedStride.current
      ) {
        if (noPressure(sensorCandidate.current.left?.result)) {
          if (timeRunning.current) {
            startedStride.current = true;
            const timeDiff = stopTimeDiffLeft();
            updateStanceTime(timeDiff, 'left');
            updateStanceTimeLength('left');
            updateStanceTimeWindow(time.current, false, 'left');
            updateSteps('left');
            updateStrideTimeWindow(time.current, true, 'left');
            updateStrideValidity(validStride.current, 'left');
            validStride.current = true;
          }
        }
      }
      if (peaked.current) {
        if (noPressure(sensorCandidate.current.left?.result)) {
          console.log('no pressure', time.current, 'time');
          // validStride.current = false;
          // validStep.current = false;
        }
      }
      if (peaked.current && !switched.current && startedStride.current) {
        switched.current = true;
        gaitSide.current = 'left';
        peaked.current = false;
        swingLegAngles.current = [];
        startedStride.current = false;
      }
    }
  };

  const observeGait = (leftHipAnkleAngle, rightHipAnkleAngle, results) => {
    if (gaitSide.current === 'left') {
      swingLegAngles.current.push(leftHipAnkleAngle);
      const maxSwingLegAngle = Math.max(...swingLegAngles.current);
      if (leftHipAnkleAngle + 3 < maxSwingLegAngle && !peaked.current) {
        peaked.current = true;
        switched.current = false;
        const validStepFromLeftSide = validStep.current; // && recordingFromLeftSide.current;
        if (timeRunning.current) {
          const stepLength = getStepLength(
            patientHeight,
            results,
            safeHipShoulderRWU
          );
          updateStepLength(stepLength, 'left', time.current);
          const timeDiff = stopTimeDiffLeft();
          updateStrideTime(timeDiff, 'left');
          updateStepTimeLength('left');
          updateStanceTimeWindow(time.current, true, 'left');
          updateStrideTimeWindow(time.current, false, 'left');
          // updateFeedback(
          //   `${validStep.current} ${recordingFromLeftSide.current} ${gaitSide.current}`
          // );
          updateStepValidity(validStepFromLeftSide, 'left');
          validStep.current = true;
          updateValidMarkerEvents(time.current);
          stanceToeStrideHeelXDiff.current = Math.abs(
            results.poseLandmarks[30].x - results.poseLandmarks[31].x
          );
        } else {
          timeRunning.current = true;
          // stopTimeDiffLeft();
          // stopTimeDiffRight();
        }
      }
      if (peaked.current && !startedStride.current) {
        const newDiff = Math.abs(
          results.poseLandmarks[30].x - results.poseLandmarks[31].x
        );
        const pctChange = Math.abs(
          1 - newDiff / stanceToeStrideHeelXDiff.current
        );
        if (pctChange > 0.02) {
          if (timeRunning.current) {
            startedStride.current = true;
            const timeDiff = stopTimeDiffRight();
            updateStanceTime(timeDiff, 'left');
            updateStanceTimeLength('left');
            updateStanceTimeWindow(time.current, false, 'right');
            updateSteps('right');
            updateStrideTimeWindow(time.current, true, 'right');
            updateStrideValidity(validStride.current, 'right');
            validStride.current = true;
          }
        }
      }
      if (
        peaked.current &&
        startedStride.current &&
        !switched.current &&
        leftHipAnkleAngle + 10 < rightHipAnkleAngle
      ) {
        switched.current = true;
        gaitSide.current = 'right';
        peaked.current = false;
        swingLegAngles.current = [];
        startedStride.current = false;
      }
    } else {
      swingLegAngles.current.push(rightHipAnkleAngle);
      const maxSwingLegAngle = Math.max(...swingLegAngles.current);
      if (rightHipAnkleAngle + 3 < maxSwingLegAngle && !peaked.current) {
        if (timeRunning.current) {
          const validStepFromRightSide = validStep.current; // && !recordingFromLeftSide.current;
          peaked.current = true;
          switched.current = false;
          const stepLength = getStepLength(patientHeight, results);
          updateStepLength(stepLength, 'right', time.current);
          const timeDiff = stopTimeDiffRight();
          updateStrideTime(timeDiff, 'right');
          updateStepTimeLength('right');
          updateStanceTimeWindow(time.current, true, 'right');
          updateStrideTimeWindow(time.current, false, 'right');
          // updateFeedback(
          //   `${validStep.current} ${!recordingFromLeftSide.current} ${
          //     gaitSide.current
          //   }`
          // );
          updateStepValidity(validStepFromRightSide, 'right');
          validStep.current = true;
          updateValidMarkerEvents(time.current);
          stanceToeStrideHeelXDiff.current = Math.abs(
            results.poseLandmarks[32].x - results.poseLandmarks[29].x
          );
        } else {
          timeRunning.current = true;
          // stopTimeDiffRight();
          // stopTimeDiffLeft();
        }
      }
      if (peaked.current && !startedStride.current) {
        const newDiff = Math.abs(
          results.poseLandmarks[32].x - results.poseLandmarks[29].x
        );
        const pctChange = Math.abs(
          1 - newDiff / stanceToeStrideHeelXDiff.current
        );
        if (pctChange > 0.02) {
          if (timeRunning.current) {
            startedStride.current = true;
            const timeDiff = stopTimeDiffLeft();
            updateStanceTime(timeDiff, 'right');
            updateStanceTimeLength('right');
            updateStanceTimeWindow(time.current, false, 'left');
            updateSteps('left');
            updateStrideTimeWindow(time.current, true, 'left');
            updateStrideValidity(validStride.current, 'left');
            validStride.current = true;
          }
        }
      }
      if (
        peaked.current &&
        !switched.current &&
        rightHipAnkleAngle + 10 < leftHipAnkleAngle
      ) {
        switched.current = true;
        gaitSide.current = 'left';
        peaked.current = false;
        swingLegAngles.current = [];
        startedStride.current = false;
      }
    }
  };
  const onResults = (results, canvasCtx, relativeLandmarks) => {
    recordingFromLeftSide.current = checkIfRightSide(results, 'left');
    const {
      leftHipAnkleAngle,
      rightHipAnkleAngle,
      leftKneeAngle,
      rightKneeAngle,
      leftKneeAnkleToeAngle,
      rightKneeAnkleToeAngle,
      leftHipKneeAngle,
      rightHipKneeAngle,
      midHipMidShoulderAngle,
    } = findGaitAngles(results, recordingFromLeftSide.current);

    if (!checkIfSide(results, 2)) {
      updateFeedback('Please turn to the side');
      timeRunning.current = false;
      validStep.current = false;
      validStride.current = false;
      startedStride.current = false;
      peaked.current = false;
      updateInvalidMarkerEvents(time.current);
      personOutOfFrame.current = true;
    } else {
      personOutOfFrame.current = checkPersonOutOfFrame(results, canvasCtx);
      if (personOutOfFrame.current) {
        timeRunning.current = false;
        validStep.current = false;
        validStride.current = false;
        startedStride.current = false;
        peaked.current = false;
        updateInvalidMarkerEvents(time.current);
        updateFeedback('Person out of frame');
      } else if (timeRunning.current || !personOutOfFrame.current) {
        updateFeedback('');
        if (recordingEnabled) {
          // observeGait(leftHipAnkleAngle, rightHipAnkleAngle, results);
          if (
            sensorCandidate.current.left?.result &&
            sensorCandidate.current.right?.result
          ) {
            bpResults.current = results;
            if (useSensorForCycle) {
              console.log('sensor based observation');
              observeGaitSensors(results);
            } else {
              console.log('video based observation');
              observeGait(leftHipAnkleAngle, rightHipAnkleAngle, results);
            }
            // observeGaitSensors(results);
          } else {
            observeGait(leftHipAnkleAngle, rightHipAnkleAngle, results);
          }
          if (timeRunning.current) {
            relativeLandmarksRef.current.push(relativeLandmarks);
            updateTimeSeriesData({
              setLegAngleData,
              leftHipAnkleAngle,
              rightHipAnkleAngle,
              leftKneeAngle,
              rightKneeAngle,
              leftKneeAnkleToeAngle,
              rightKneeAnkleToeAngle,
              leftHipKneeAngle,
              rightHipKneeAngle,
              hipRotationAngle: midHipMidShoulderAngle,
              time: time.current,
            });
            appendCenterOfMassData(time.current, results);
          }
        }
      }
    }
  };
  return (
    <PoseDetection
      onResults={onResults}
      showWebcam={showWebcam}
      videoUrl={videoUrl}
    />
  );
}

export default React.memo(GaitAnalysis);
