import {
  drawAdjustedAngles,
  metricsComputations,
} from '../../ExerciseManagement/Generators/metrics';

export const computeMetrics = ({
  metrics,
  angles,
  dispatch,
  displayConfigs = undefined,
  canvasCtx,
}) => {
  try {
    const metricAngles = {
      current: {},
    };
    metrics.forEach((metric) => {
      metric.subMetrics?.forEach((subMetric) => {
        metricsComputations[subMetric.computation](subMetric, angles);
        metricAngles.current[subMetric.ref] = {
          metric: subMetric,
        };
      });
    });
    if (displayConfigs) {
      Object.keys(displayConfigs).forEach((metricAngle) => {
        metricAngles.current[metricAngle] = {
          ...displayConfigs[metricAngle],
          ...metricAngles.current[metricAngle],
        };
      });
      drawAdjustedAngles(canvasCtx, metricAngles, angles, true);
    }
    dispatch({
      type: 'SET-ITER-METRICS',
      payload: metrics,
    });
  } catch (error) {
    console.log(error);
  }
};

export const backRomMetrics = () => [
  {
    name: 'Lumbar Spine ROM',
    // computation: "rangeOfMotion",
    // ref: "hipKneeAngleLeft",
    subMetrics: [
      {
        name: 'backward',
        ref: 'backAngle',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 90,
        adjustment: -90,
        revertAngle: true,
      },
      {
        name: 'forward',
        ref: 'backAngle',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 150,
        adjustment: -90,
        revertAngle: false,
      },
    ],
  },
];

export const kneeRomMetrics = () => [
  {
    name: 'Knee ROM',
    // computation: "rangeOfMotion",
    // ref: "hipKneeAngleLeft",
    subMetrics: [
      {
        name: 'extension',
        ref: 'kneeAngle',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 180,
        adjustment: 0,
        revertAngle: false,
      },
      {
        name: 'flexion',
        ref: 'kneeAngle',
        type: 'min',
        computation: 'rangeOfMotion',
        maxValue: 180,
        adjustment: 0,
        revertAngle: false,
      },
    ],
  },
];

export const kneeRomMetricsRight = () => [
  {
    name: 'Right Knee ROM',
    // computation: "rangeOfMotion",
    // ref: "hipKneeAngleLeft",
    subMetrics: [
      {
        name: 'extension',
        ref: 'kneeAngleRight',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 180,
        adjustment: 0,
        revertAngle: false,
      },
      {
        name: 'flexion',
        ref: 'kneeAngleRight',
        type: 'min',
        computation: 'rangeOfMotion',
        maxValue: 180,
        adjustment: 0,
        revertAngle: false,
      },
    ],
  },
];

export const kneeRomMetricsLeft = () => [
  {
    name: 'Left Knee ROM',
    // computation: "rangeOfMotion",
    // ref: "hipKneeAngleLeft",
    subMetrics: [
      {
        name: 'extension',
        ref: 'kneeAngleLeft',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 180,
        adjustment: 0,
        revertAngle: false,
      },
      {
        name: 'flexion',
        ref: 'kneeAngleLeft',
        type: 'min',
        computation: 'rangeOfMotion',
        maxValue: 180,
        adjustment: 0,
        revertAngle: false,
      },
    ],
  },
];

export const leftUpperLegRomMetrics = (
  nameRevert = 'Flexion',
  nameForward = 'Extension'
) => [
  {
    name: 'Left upper leg ROM',
    // computation: "rangeOfMotion",
    // ref: "hipKneeAngleLeft",
    subMetrics: [
      {
        name: nameRevert,
        ref: 'hipKneeAngleLeft',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 90,
        adjustment: 0,
        revertAngle: true,
      },
      {
        name: nameForward,
        ref: 'hipKneeAngleLeft',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 90,
        adjustment: 0,
        revertAngle: false,
      },
    ],
  },
];

export const rightUpperLegRomMetrics = (
  nameRevert = 'Flexion',
  nameForward = 'Extension'
) => [
  {
    name: 'Right upper leg ROM',
    // computation: "rangeOfMotion",
    // ref: "hipKneeAngleLeft",
    subMetrics: [
      {
        name: nameRevert,
        ref: 'hipKneeAngleRight',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 90,
        adjustment: 0,
        revertAngle: true,
      },
      {
        name: nameForward,
        ref: 'hipKneeAngleRight',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 90,
        adjustment: 0,
        revertAngle: false,
      },
    ],
  },
];

export const upperLegRomMetrics = (
  nameRevert = 'Flexion',
  nameForward = 'Extension'
) => [
  {
    name: 'upper leg ROM',
    // computation: "rangeOfMotion",
    // ref: "hipKneeAngleLeft",
    subMetrics: [
      {
        name: nameRevert,
        ref: 'hipKneeAngle',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 90,
        adjustment: 0,
        revertAngle: true,
      },
      {
        name: nameForward,
        ref: 'hipKneeAngle',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 90,
        adjustment: 0,
        revertAngle: false,
      },
    ],
  },
];

export const upperArmRomMetrics = () => [
  {
    name: 'upper arm ROM',
    subMetrics: [
      {
        name: 'Flexion',
        ref: 'shoulderElbowAngle',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 90,
        adjustment: -180,
        revertAngle: true,
      },
      {
        name: 'Extension',
        ref: 'shoulderElbowAngle',
        type: 'max',
        computation: 'rangeOfMotion',
        maxValue: 90,
        adjustment: -180,
        revertAngle: false,
      },
    ],
  },
];
