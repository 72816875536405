import React from 'react';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function Counts({ config, setConfig, inTime = false }) {
  const maxCounts = React.useRef(config.maxCounts);
  const time = React.useRef(config.time);
  const [timeComponent, setTimeComponent] = React.useState(<> </>);

  React.useEffect(() => {
    if (inTime) {
      setTimeComponent(
        <Grid item xs={6}>
          <TextField
            id="outlined-time"
            label="Time"
            variant="outlined"
            type="number"
            value={time.current}
            onChange={(e) => {
              time.current = e.target.value;
              setConfig({ ...config, time: Number(e.target.value) });
            }}
          />
        </Grid>
      );
    }
  }, [inTime, config]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id="outlined-time"
          label="Max Counts"
          variant="outlined"
          type="number"
          value={maxCounts.current}
          onChange={(e) => {
            maxCounts.current = e.target.value;
            setConfig({ ...config, maxCounts: Number(e.target.value) });
          }}
        />
      </Grid>
      {timeComponent}
    </Grid>
  );
}
