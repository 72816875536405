const initialState = {};

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLASS-HOME': {
      console.log('CLASS-HOME is::' + JSON.stringify(action));
      return { values: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const classStudsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLASS_STUDS': {
      console.log('CLASS-HOME is::' + JSON.stringify(action));
      return { values: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const classProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLASS_PROGRESS': {
      console.log('CLASS_PROGRESS is::' + JSON.stringify(action));
      return { values: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const classTeacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLASS_TEACHER': {
      return { values: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STUD_ATTENDANCE': {
      return { values: action.payload };
    }
    default: {
      return state;
    }
  }
};
