import { collectForEachWindow, flattenSensorDataByKeys } from '../results';
import _ from 'lodash';
import { anyPressure } from '../observation';
import { zonesLeft } from '../../../../../components/Demo/SensorResults/FootMap';

export class TimeWindowCollector {
  constructor({ gaitResults }) {
    this.gaitResults = gaitResults;
    this.collectTimeWindows('left');
    this.collectTimeWindows('right');
  }

  collectTimeWindows(side) {
    let sensorData;
    if (side === 'left') {
      sensorData = this.gaitResults.sensorData?.left;
    } else if (side === 'right') {
      sensorData = this.gaitResults.sensorData?.right;
    }

    if (!sensorData) return;
    let stanceTimeWindows = [];
    let strideTimeWindows = [];
    let phase = 'none';
    let currentPhase;
    let stanceTimeWindow = {};
    let strideTimeWindow = {};

    for (let i = 0; i < sensorData.length; i++) {
      currentPhase = anyPressure(sensorData[i].value?.result)
        ? 'stance'
        : 'swing';
      if (currentPhase !== phase) {
        if (phase === 'stance') {
          strideTimeWindow.start = sensorData[i].time;
          stanceTimeWindow.end = sensorData[i].time;
          stanceTimeWindows.push(stanceTimeWindow);
          stanceTimeWindow = {};
        } else {
          stanceTimeWindow.start = sensorData[i].time;
          strideTimeWindow.end = sensorData[i].time;
          strideTimeWindows.push(strideTimeWindow);
          strideTimeWindow = {};
        }
        phase = currentPhase;
      }
    }
    stanceTimeWindows = stanceTimeWindows.filter(
      (window) => window.start && window.end
    );
    strideTimeWindows = strideTimeWindows.filter(
      (window) => window.start && window.end
    );

    if (side === 'left') {
      this.gaitResults.stanceTimeWindowsLeft = stanceTimeWindows;
      this.gaitResults.strideTimeWindowsleft = strideTimeWindows;
      this.gaitResults.stepValidityLeft = stanceTimeWindows.map(() => true);
      this.gaitResults.strideValidityLeft = strideTimeWindows.map(() => true);
    } else if (side === 'right') {
      this.gaitResults.stanceTimeWindowsRight = stanceTimeWindows;
      this.gaitResults.strideTimeWindowsRight = strideTimeWindows;
      this.gaitResults.stepValidityRight = stanceTimeWindows.map(() => true);
      this.gaitResults.strideValidityRight = strideTimeWindows.map(() => true);
    }
  }
}

export class SensorDataCollector {
  constructor({ validityChecker }) {
    this.validityChecker = validityChecker;
    this.rightSensorDataCollection = [];
    this.leftSensorDataCollection = [];
    this.collectSensorData();
  }

  collectSensorData() {
    let leftSensorDataCollection = collectForEachWindow({
      windowArray: this.validityChecker.gaitResults.stanceTimeWindowsLeft,
      graphData: this.validityChecker.gaitResults.sensorData?.left,
      xAxis: 'time',
      yAxis: 'value',
    });
    this.leftSensorDataCollection =
      this.validityChecker.stanceTimeLeftIndexes.map(
        (index) => leftSensorDataCollection[index]
      );
    let rightSensorDataCollection = collectForEachWindow({
      windowArray: this.validityChecker.gaitResults.stanceTimeWindowsRight,
      graphData: this.validityChecker.gaitResults.sensorData?.right,
      xAxis: 'time',
      yAxis: 'value',
    });
    this.rightSensorDataCollection =
      this.validityChecker.stanceTimeRightIndexes.map(
        (index) => rightSensorDataCollection[index]
      );
  }
}

export class SumPerStepPerKeyComputer {
  constructor({ sensorDataCollector }) {
    this.sensorDataCollector = sensorDataCollector;
    this.leftSumPerStepPerKey = this.computeSumPerStepPerKey(
      this.sensorDataCollector.leftSensorDataCollection
    );
    this.rightSumPerStepPerKey = this.computeSumPerStepPerKey(
      this.sensorDataCollector.rightSensorDataCollection
    );
  }

  computeSumPerStepPerKey(sensorDataCollection) {
    return sensorDataCollection.map((step) => {
      const sumPerKey = {};
      const stepFlattened = flattenSensorDataByKeys([step]);
      Object.keys(stepFlattened).forEach((key) => {
        sumPerKey[key] = _.sum(stepFlattened[key]);
      });
      return sumPerKey;
    });
  }
}

export class MeanSumPerStepPerKeyComputer {
  constructor({ sumPerStepPerKeyComputer }) {
    this.sumPerStepPerKeyComputer = sumPerStepPerKeyComputer;
    this.leftMeanSumPerStepPerKey = this.computeMeanSumPerStepPerKey(
      this.sumPerStepPerKeyComputer.leftSumPerStepPerKey
    );
    this.rightMeanSumPerStepPerKey = this.computeMeanSumPerStepPerKey(
      this.sumPerStepPerKeyComputer.rightSumPerStepPerKey
    );
  }

  computeMeanSumPerStepPerKey(sumPerStepPerKey) {
    const meanSumPerKey = {};
    if (!sumPerStepPerKey[0]) return meanSumPerKey;
    Object.keys(sumPerStepPerKey[0]).forEach((key) => {
      meanSumPerKey[key] = _.meanBy(sumPerStepPerKey, key);
    });
    return meanSumPerKey;
  }
}

export class MeanSumPerStepPerSideComputer {
  constructor({ meanSumPerStepPerKeyComputer }) {
    this.meanSumPerStepPerKeyComputer = meanSumPerStepPerKeyComputer;
    this.leftMeanSumPerStep = this.computeMeanSumPerStep(
      this.meanSumPerStepPerKeyComputer.leftMeanSumPerStepPerKey
    );
    this.rightMeanSumPerStep = this.computeMeanSumPerStep(
      this.meanSumPerStepPerKeyComputer.rightMeanSumPerStepPerKey
    );
  }

  computeMeanSumPerStep(meanSumPerStepPerKey) {
    return _.mean(
      Object.keys(zonesLeft).map((key) => meanSumPerStepPerKey[key])
    );
  }
}

export class MeanPerKeyComputer {
  constructor({ sensorDataCollector }) {
    this.sensorDataCollector = sensorDataCollector;
    this.leftMeanPerKey = this.computeMeanPerKey(
      this.sensorDataCollector.leftSensorDataCollection
    );
    this.rightMeanPerKey = this.computeMeanPerKey(
      this.sensorDataCollector.rightSensorDataCollection
    );
  }

  computeMeanPerKey(sensorDataCollection) {
    const meanPerKey = {};
    const sensorDataCollectionFlattened =
      flattenSensorDataByKeys(sensorDataCollection);
    Object.keys(sensorDataCollectionFlattened).forEach((key) => {
      meanPerKey[key] = _.mean(sensorDataCollectionFlattened[key]);
    });
    return meanPerKey;
  }
}

export class SumZonesComputer {
  constructor({ meanPerKeyComputer }) {
    this.meanPerKeyComputer = meanPerKeyComputer;
    this.leftSumZones = this.computeSumZones(
      this.meanPerKeyComputer.leftMeanPerKey
    );
    this.rightSumZones = this.computeSumZones(
      this.meanPerKeyComputer.rightMeanPerKey
    );
  }

  computeSumZones(meanPerKey) {
    return _.sum(Object.keys(zonesLeft).map((key) => meanPerKey[key]));
  }
}

export class MeanMaxPerStepComputer {
  constructor({ sensorDataCollector }) {
    this.sensorDataCollector = sensorDataCollector;
    this.leftMeanMaxPerStep = this.computeMeanMaxPerStep(
      this.sensorDataCollector.leftSensorDataCollection
    );
    this.rightMeanMaxPerStep = this.computeMeanMaxPerStep(
      this.sensorDataCollector.rightSensorDataCollection
    );
  }

  computeMeanMaxPerStep(sensorDataCollection) {
    const maxPerStepPerKey = sensorDataCollection.map((step) => {
      const maxPerKey = {};
      const stepFlattened = flattenSensorDataByKeys([step]);
      Object.keys(stepFlattened).forEach((key) => {
        maxPerKey[key] = _.max(stepFlattened[key]);
      });
      return maxPerKey;
    });
    const meanMaxPerKey = {};
    Object.keys(maxPerStepPerKey[0]).forEach((key) => {
      meanMaxPerKey[key] = _.meanBy(maxPerStepPerKey, key);
    });
    return meanMaxPerKey;
  }
}

export class SumZonesFromAllSensorDataComputer {
  constructor({ gaitResults }) {
    this.gaitResults = gaitResults;
    this.leftSumZonesFromAllSensorData = this.computeSumZonesFromAllSensorData(
      this.gaitResults.sensorData?.left
    );
    this.rightSumZonesFromAllSensorData = this.computeSumZonesFromAllSensorData(
      this.gaitResults.sensorData?.right
    );
  }

  computeSumZonesFromAllSensorData(sensorDataCollection) {
    const sumPerKey = {};
    const sensorDataPrepared = sensorDataCollection.map((observation) => {
      return observation.value;
    });
    const sensorDataCollectionFlattened = flattenSensorDataByKeys([
      sensorDataPrepared,
    ]);
    Object.keys(sensorDataCollectionFlattened).forEach((key) => {
      sumPerKey[key] = _.sum(sensorDataCollectionFlattened[key]);
    });
    return sumPerKey;
  }
}

export class SumZonesObjectComputer {
  constructor({ sumZonesFromAllSensorDataComputer }) {
    this.sumZonesFromAllSensorDataComputer = sumZonesFromAllSensorDataComputer;
    this.leftSumZones = this.computeSumZones(
      this.sumZonesFromAllSensorDataComputer.leftSumZonesFromAllSensorData
    );
    this.rightSumZones = this.computeSumZones(
      this.sumZonesFromAllSensorDataComputer.rightSumZonesFromAllSensorData
    );
  }

  computeSumZones(meanPerKey) {
    return _.sum(Object.keys(zonesLeft).map((key) => meanPerKey[key]));
  }
}
