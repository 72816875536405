import React, { useEffect } from 'react';
import { useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
import ClassProgressBcg from '../../components/Background/ClassProgressBcg';

import TopThreePerformers from '../../components/TDash/TopPerformers';
import Progress from '../../components/Progress/Progress';
import '../../components/ClassProgress/classprogress.css';
import { useDispatch, useSelector } from 'react-redux';
import { getClassProgress } from '../../redux/actions/getClassHome';
import { CircularProgress } from '@mui/material';

const ClassProgress = () => {
  const dispatch = useDispatch();
  const progress = useSelector(({ cprogress }) => cprogress.values);
  const classes = useSelector(({ thome }) => thome.values);
  const [dys, setDys] = useState({});
  useEffect(() => {
    if (!progress) getClassProgress(dispatch, (a) => a);
  }, []);
  return (
    <div className="class-progress">
      <ClassProgressBcg />
      <HeaderComponent />
      <DrawerComponent />

      <div className="class-progress-content">
        {!progress ? (
          <CircularProgress size="small" color="primary" />
        ) : (
          <>
            <TopThreePerformers
              pageName={'class-progress'}
              topthree={progress && progress.top_three}
              classes={classes ? classes.data : null}
            />
            <Progress data={progress && progress} dys={dys} setDys={setDys} />
          </>
        )}
      </div>
    </div>
  );
};

export default ClassProgress;
