import { CircularProgress, Dialog } from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { getAudioFeedback } from '../../../../../utilities/Demo/ExerciseManagement/firestore';
import DeleteButton from '../../DeleteButton';
import AudioFeedbackDialog from '../AudioFeedbackDialog';
import CloseBadge from '../CloseBadge';

export default function IntroAudioDialog({
  onClose,
  open,
  config,
  setIntroAudioFeedbackId,
  exercise,
}) {
  const introAudioFeedbackId = config?.introAudioFeedbackId
    ? config?.introAudioFeedbackId
    : exercise?.introAudioFeedbackId;
  const [loading, setLoading] = React.useState(false);
  const [audioFeedback, setAudioFeedback] = React.useState(null);
  const [selectionDialogOpen, setSelectionDialogOpen] = React.useState(false);

  React.useEffect(() => {
    if (!introAudioFeedbackId) {
      setAudioFeedback(null);
    }
    if (!audioFeedback) {
      if (introAudioFeedbackId) {
        setLoading(true);
        getAudioFeedback({ audioFeedbackId: introAudioFeedbackId }).then(
          (audioFeedback) => {
            setAudioFeedback(audioFeedback);
            setLoading(false);
          }
        );
      } else {
        setAudioFeedback(null);
      }
    }
  }, [introAudioFeedbackId, audioFeedback]);
  return (
    <Dialog
      maxWidth="lg" // 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
      fullWidth={true}
      onClose={() => {
        onClose();
      }}
      open={open}
    >
      <AudioFeedbackDialog
        setAudioFeedback={(audioFeedback) => {
          setIntroAudioFeedbackId(audioFeedback.id);
          setAudioFeedback(audioFeedback);
        }}
        open={selectionDialogOpen}
        onClose={() => setSelectionDialogOpen(false)}
      />
      <Badge
        badgeContent={<CloseBadge setConfig={onClose} sx={{ mt: 5, mr: 5 }} />}
      >
        <DialogTitle sx={{ mr: 5, mb: 5 }}>Edit Video</DialogTitle>
        <Box sx={{ mt: 5, mb: 5, mr: 5, width: '100%', padding: 5 }}>
          <Grid container spacing={2} sx={{ margin: 0 }}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'space-between',
              }}
              sx={{ boxShadow: 5, padding: 3, borderRadius: 2 }}
            >
              <Button
                variant="contained"
                onClick={() => setSelectionDialogOpen(true)}
                sx={{ mr: 2 }}
              >
                SELECT AUDIO FEEDBACK
              </Button>
              <DeleteButton
                onDelete={() => {
                  setIntroAudioFeedbackId(null);
                  setAudioFeedback(null);
                }}
                deletionTarget={'Video'}
              />
            </Grid>
            {loading && <CircularProgress />}
            {audioFeedback && (
              <div style={{ marginTop: 5, marginBottom: 20 }}>
                <Typography variant={'h2'}>{audioFeedback.name}</Typography>
                <ReactAudioPlayer src={audioFeedback.audioUrl} controls />
              </div>
            )}
          </Grid>
        </Box>
      </Badge>
    </Dialog>
  );
}
