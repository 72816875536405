import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RecordingSide from '../../../../components/Demo/Physio/GaitAnalysis/recording/RecordingSide';
import { useWebcamSettings } from '../../../../hooks/webcam';
import ExitButton from '../../../../components/ExitButton';
import Container from '@mui/material/Container';

export default function RecordingView() {
  const webcamSettings = useSelector(({ webcam }) => webcam.values);
  const { updateWebcamSettings } = useWebcamSettings();
  const [front, setFront] = useState(false);
  const [component, setComponent] = useState(<></>);

  useEffect(() => {
    updateWebcamSettings(false);
    if (webcamSettings.useOffset) return;
    if (front) {
      setComponent(<RecordingSide front={front} setFront={setFront} />);
    } else {
      setComponent(<RecordingSide front={front} setFront={setFront} />);
    }
  }, [webcamSettings.useOffset, front]);
  return (
    <div>
      <Container
        maxWidth="xxl"
        sx={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignContent: 'center',
          position: 'relative',
          padding: '.5rem 0',
          zIndex: 1000,
          mt: 5,
        }}
      >
        <ExitButton redirectTarget={'/all_patients'} />
      </Container>
      <Box sx={{ pt: 2, pr: 2, pl: 2, pb: 0 }}>{component}</Box>
    </div>
  );
}
