import Grid from '@mui/material/Grid';
import React from 'react';
import { TextField } from '@mui/material';

export default function MaxStretch({ config, setConfig }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id="outlined-max-stretch"
          label="Max Stretch"
          variant="outlined"
          type="number"
          value={config.maxStretch}
          onChange={(e) => setConfig({ ...config, maxStretch: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="outlined-min-stretch"
          label="Min Stretch"
          variant="outlined"
          type="number"
          value={config.minStretch}
          onChange={(e) => setConfig({ ...config, minStretch: e.target.value })}
        />
      </Grid>
    </Grid>
  );
}
