import React from 'react';
import astro from '../../assets/right-min.png';
import '../ClassProgress/classprogress.css';

const ChatBgComponent = () => {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        zIndex: '-100',
        backgroundColor: '#FCFCF9',
        overflow: 'hidden',
      }}
    >
      <img
        className="clas-progress-bcg-1"
        src={astro}
        style={{
          objectFit: 'contain',
          position: 'fixed',
          bottom: '0',
          zIndex: '-100',
          left: '-20%',
          maxWidth: '1500px',
          minWidth: '500px',
          width: '50%',
        }}
      />
    </div>
  );
};

export default ChatBgComponent;
