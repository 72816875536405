import * as React from 'react';
import { sendPasswordReset } from '../../../utilities/Demo/auth';
import InputBarComponent from '../../InputBarComponent';
import { Snackbar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { CircularProgress } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/system';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FpComponent({ fp, setFp, setOpen }) {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();
  const [type, setType] = useState('');
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState('');
  const { state } = useLocation();
  console.log('Params::', state);
  const onSubmit = async (data) => {
    setLoad(true);
    const success = await sendPasswordReset(data.email);
    if (success) {
      setMsg('Reset Link Sent');
      setType('success');
    } else {
      setMsg('Reset Link Failed');
      setType('error');
    }
    setLoad(false);
    setOpen(false);
  };

  return (
    <Box>
      <Snackbar
        open={msg !== ''}
        autoHideDuration={60000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => {
          setMsg('');
        }}
      >
        <Alert severity={type}>{msg}</Alert>
      </Snackbar>

      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 10,
            maxheight: '53%',
            width: '95vw',
            padding: 2,
          },
        }}
        scroll="body"
        BackdropComponent={styled(Backdrop, {
          name: 'MuiModal',
          slot: 'Backdrop',
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({
          zIndex: -1,
          backgroundColor: 'rgb(242, 247, 247,0.5)',
        })}
        open={fp}
        onClose={() => setFp(false)}
      >
        <>
          <DialogTitle sx={{ fontSize: 18 }}>
            {t('fpComponent.title')}
            <span
              style={{ float: 'right', color: 'grey', cursor: 'pointer' }}
              onClick={() => setFp(false)}
            >
              &#x2715;
            </span>
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent style={{ overflow: 'hidden' }}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputBarComponent
                    name="email"
                    label={t('ProfileComponent.email')}
                    type="email"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </DialogContent>
            <DialogActions
              disableSpacing={true}
              sx={{ justifyContent: 'center', height: '10%' }}
            >
              {load ? (
                <CircularProgress color="inherit" />
              ) : (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      borderRadius: 20,
                      backgroundColor: '#FF592C',
                    }}
                  >
                    {t('ChatScreen.send')}
                  </Button>
                </>
              )}
            </DialogActions>
          </form>
        </>
      </Dialog>
    </Box>
  );
}
