import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React from 'react';
import OneSideConfig from './OneSideConfig';

export default function DoubleSideConfig({ config, setConfig, colors = [] }) {
  return (
    <Grid container>
      <Typography variant={'h8'} sx={{ ml: 2, mt: 2 }}>
        Left
      </Typography>
      <OneSideConfig
        config={config.left || {}}
        setConfig={(value) => {
          setConfig({ ...config, left: value });
        }}
        sx={{ backgroundColor: colors[0] }}
      />
      <Divider />
      <Typography variant={'h8'} sx={{ ml: 2, mt: 2 }}>
        Right
      </Typography>
      <OneSideConfig
        config={config.right || {}}
        setConfig={(value) => {
          setConfig({ ...config, right: value });
        }}
        sx={{ backgroundColor: colors[1] }}
      />
    </Grid>
  );
}
