const initialState = { values: { correct: 0, time: 0, feedback: '' } };

export const countsInTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE-COUNT-TIME-OBSERVATION': {
      return { values: { ...state.values, ...action.payload } };
    }
    case 'FEEDBACK': {
      return { values: { ...state.values, ...action.payload } };
    }
    case 'RESET-COUNT-TIME-OBSERVATION':
      return initialState;
    default: {
      return state;
    }
  }
};
