import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import '../../components/Arena/exercisearena.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DemoAnimation from '../../components/Arena/Animation/DemoAnimation.js';
import CalibrationComponent from '../../components/Arena/CalibrationComponent';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useSelector } from 'react-redux';

const Exercise = ({ redirectTarget = '/summary' }) => {
  const { demoType } = useParams();
  const navigate = useNavigate();
  const [yes, setYes] = useState(0);
  const [load, setLoad] = React.useState(false);
  const [textVisible, setTextVisible] = useState(true);
  const iter = useSelector(({ iter }) => iter.values);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    checkPermissions();
  }, []);

  const handleExit = useCallback(
    (setLoad) => {
      setLoad(true);
      navigate(redirectTarget + '?' + queryParams, {
        state: { demoType },
      });
    },
    [redirectTarget, queryParams, demoType]
  );

  const checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    });
    permissions
      .then(() => {
        setTextVisible(false);
        setLoad(true);
        console.log('accepted the permissions');
      })
      .catch((err) => {
        console.log(`${err.name} : ${err.message}`);
        alert(
          'Please allow camera for calibration. Seems you have blocked camera access'
        );
      });
  };

  const handleJoin = () => {
    setYes(3);
  };

  return (
    <div className="exercise-container" style={{ overFlow: 'hidden' }}>
      {textVisible && (
        <div className="camera-access-desc">
          <h2>
            {' '}
            <KeyboardBackspaceIcon style={{ paddingTop: '0.5rem' }} /> Hit allow
            for
            <br /> camera access, if asked!
          </h2>
        </div>
      )}
      {/* Custom component for yes no */}
      {yes === 0 && !iter.calibrated && (
        <div className="yesno">
          <h1>Click yes to enable audio</h1>
          <div style={{ display: 'flex', marginTop: '4%' }}>
            <Button
              type="submit"
              variant="contained"
              id="yesno-btn"
              onClick={() => setYes(1)}
            >
              Yes
            </Button>
            <Button
              type="submit"
              variant="contained"
              id="yesno-btn2"
              style={{ background: 'white', color: 'black', marginLeft: '5%' }}
              onClick={() => setYes(2)}
            >
              No
            </Button>
          </div>
        </div>
      )}

      {((yes > 0 && yes < 3) || !iter.calibrated) && (
        <CalibrationComponent
          load={load}
          setLoad={setLoad}
          handleJoin={handleJoin}
        />
      )}
      {(yes === 3 || iter.calibrated) && (
        <DemoAnimation
          demoType={demoType}
          redirectTarget={redirectTarget}
          handleExit={handleExit}
        />
      )}
    </div>
  );
};

export default Exercise;
