import React from 'react';
import balance_left from '../../../assets/GaitAnalysis/balance_red.png';
import balance_right from '../../../assets/GaitAnalysis/balance_red_flip.png';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card } from '@mui/material';

export default function BalanceInfo({ balance, sx }) {
  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        margin: 5,
        ...sx,
      }}
    >
      <Card sx={{ minWidth: 100, padding: 10 }}>
        <img
          alt={'Balance'}
          src={balance < 0 ? balance_left : balance_right}
          style={{
            height: '50px',
            marginLeft: '-40px',
          }}
        />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {`${Math.abs(balance * 100).toFixed(1)}% overloading on ${
            balance < 0 ? 'Left' : 'Right'
          }`}
        </Typography>
      </Card>
    </Box>
  );
}
