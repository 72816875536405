import { PRESSURE_THRESHOLD } from '../../../../constants/Demo/gaitAnalysis';
import { zonesLeft } from '../../../../components/Demo/SensorResults/FootMap';

export function anyPressure(sensorResult) {
  if (!sensorResult) return false;
  return Object.keys(zonesLeft).some((zone) => {
    return sensorResult[zone] > PRESSURE_THRESHOLD;
  });
}

export function noPressure(sensorResult) {
  if (!sensorResult) return false;
  return Object.keys(zonesLeft).every((zone) => {
    return sensorResult[zone] < PRESSURE_THRESHOLD;
  });
}
