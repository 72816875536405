import * as React from 'react';
import './highlights.css';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import ScoreboardRoundedIcon from '@mui/icons-material/ScoreboardRounded';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SessionComponentHi = () => {
  const { t } = useTranslation();
  const moment = require('moment');
  const data = useSelector(({ schedule }) => schedule.values);
  return (
    <div className="today-highlights-session">
      <div className="box-container">
        <div className="today-session-box">{t('SessionComponent.title')}</div>
      </div>
      <h1>
        <EventNoteRoundedIcon />
        <span>{t('SessionComponent.workoutHighlights')}</span>
      </h1>

      <div className="black-div">
        {moment().locale('de').format('dddd, MMMM Do YYYY')}
      </div>

      <div className="white-div-container">
        <div className="white-div">
          {' '}
          {data && data.start
            ? moment(data.start.dateTime).format('h:mm:a')
            : 'NA'}
        </div>
      </div>

      <div className="list">
        <div className="left">
          <EventNoteRoundedIcon />
          <span>{t('SessionComponent.class')}.</span>
        </div>
        <div className="right">
          <span>{data ? '1A' : 'NA'}</span>
        </div>
        <div className="left">
          <AccessTimeRoundedIcon />
          <span>{t('SessionComponent.averageDuration')}</span>
        </div>
        <div className="right">
          <span>{data ? '15 mins' : 'N/A'}</span>
        </div>
        <div className="left">
          <ScoreboardRoundedIcon />
          <span>{t('SessionComponent.Difficulty')}</span>
        </div>
        <div className="right">
          <span>{data ? t('SessionComponent.Beginner') : 'N/A'}</span>
        </div>
        <div className="left">
          <CandlestickChartOutlinedIcon />
          <span>{t('SessionComponent.Intensity')}</span>
        </div>
        <div className="right">
          <span>{t('SessionComponent.level')} 1</span>
        </div>
      </div>
      <div className="divider"></div>
      <p>{data ? data.session_desc : 'NA'}</p>
    </div>
  );
};

export default SessionComponentHi;
