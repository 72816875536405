import AddIcon from '@mui/icons-material/Add';
import { Card, CircularProgress, TextField } from '@mui/material';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React from 'react';
import { getAudioFeedback } from '../../../../../utilities/Demo/ExerciseManagement/firestore';
import DeleteButton from '../../DeleteButton';
import OptionsBadge from '../OptionsBadge';
import AssignedReferenceBox from './AssignedReferenceBox';
import AudioFeedbackDialog from '../AudioFeedbackDialog';
import ConditionBox from './ConditionBox';
import Grid from '@mui/material/Grid';
import BasicSelect from '../BasicSelect';

function FeedbackConfig({
  config,
  setConfig,
  refOptions,
  assignedRefOptions,
  copyConfig,
  useAngleConfigs,
  stateOptions,
}) {
  const name = React.useRef(config.name);
  const message = React.useRef(config.message);
  const [audioFeedbackDoc, setAudioFeedbackDoc] = React.useState(null);
  const conditions = React.useRef(config.conditions || []);
  const [audioFeedbackDialogOpen, setAudioFeedbackDialogOpen] =
    React.useState(false);
  const [loadingAudioFeedback, setLoadingAudioFeedback] = React.useState(false);
  const assignedReferences = React.useRef(config.assignedReferences || []);

  const setNewAudioFeedback = (audioFeedback) => {
    // setAudioFeedback(audioFeedback);
    setConfig({ ...config, audioFeedback: audioFeedback.id });
    setAudioFeedbackDialogOpen(false);
  };

  React.useEffect(() => {
    if (!config.audioFeedback) return;
    setLoadingAudioFeedback(true);
    getAudioFeedback({ audioFeedbackId: config.audioFeedback }).then(
      (audioFeedbackDoc) => {
        setAudioFeedbackDoc(audioFeedbackDoc);
        setLoadingAudioFeedback(false);
      }
    );
  }, [config.audioFeedback]);

  return (
    <Badge
      badgeContent={
        <OptionsBadge setConfig={setConfig} copyConfig={copyConfig} />
      }
      sx={{ m: 0, width: '100%' }}
    >
      <AudioFeedbackDialog
        setAudioFeedback={setNewAudioFeedback}
        open={audioFeedbackDialogOpen}
        onClose={() => setAudioFeedbackDialogOpen(false)}
      />
      <Card
        sx={{
          overflow: 'auto',
          height: '70vh',
          width: '100%',
          paddingLeft: 2,
          paddingTop: 4,
          backgroundColor: 'rgba(55,164,251,0.43)',
        }}
      >
        <TextField
          name={config.name}
          label="name"
          type="text"
          variant="outlined"
          // className="inputRounded"
          InputLabelProps={{ disableAnimation: true }}
          shrink={'true'}
          sx={{ width: '70%', marginBottom: 1 }}
          value={name.current}
          onChange={(event) => {
            name.current = event.target.value;
            setConfig({ ...config, name: event.target.value });
          }}
        />
        <TextField
          name={config.message}
          label="message"
          type="text"
          variant="outlined"
          // className="inputRounded"
          InputLabelProps={{ disableAnimation: true }}
          shrink={'true'}
          sx={{ width: '70%', marginBottom: 1 }}
          value={message.current}
          onChange={(event) => {
            message.current = event.target.value;
            setConfig({ ...config, message: event.target.value });
          }}
        />
        <Grid item xs={12}>
          <BasicSelect
            sx={{ width: '100%' }}
            label="State"
            value={config.state}
            setValue={(value) => {
              setConfig({
                ...config,
                state: value?.filter((item) => item !== undefined) || null,
              });
            }}
            options={stateOptions.concat([null])}
            multiple={true}
          />
        </Grid>
        <h5>Audio Feedback</h5>
        <div>
          {loadingAudioFeedback && <CircularProgress />}
          {audioFeedbackDoc ? (
            <div>
              <h6>{audioFeedbackDoc.name}</h6>
              <DeleteButton
                onDelete={() => {
                  setConfig({ ...config, audioFeedback: null });
                  setAudioFeedbackDoc(null);
                }}
                deletionTarget={'Audio Feedback' + audioFeedbackDoc.name}
              />
            </div>
          ) : (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="delete patient"
              onClick={(e) => {
                e.stopPropagation();
                setAudioFeedbackDialogOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          )}
        </div>
        <Typography variant="h6" sx={{ mt: 3 }}>
          Conditions
        </Typography>
        {conditions.current.map((condition, index) => (
          <ConditionBox
            key={index}
            useAngleConfigs={useAngleConfigs}
            config={condition}
            refOptions={refOptions}
            assignedRefOptions={assignedRefOptions}
            copyConfig={() => {
              // eslint-disable-next-line no-undef
              const copiedCondition = _.cloneDeep(condition);
              conditions.current.push(copiedCondition);
              setConfig({
                ...config,
                conditions: conditions.current,
              });
            }}
            setConfig={(value) => {
              if (value) {
                conditions.current[index] = value;
              } else {
                conditions.current.splice(index, 1);
              }
              setConfig({ ...config, conditions: conditions.current });
            }}
          />
        ))}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="delete patient"
          onClick={(e) => {
            e.stopPropagation();
            config.conditions.push({
              ref: undefined,
              operator: undefined,
              value: 0,
            });
            setConfig({ ...config, conditions: config.conditions });
          }}
          sx={{ marginLeft: 3 }}
        >
          <AddIcon />
        </IconButton>
        <Divider />
        <Typography variant="h6" sx={{ mt: 3 }}>
          Assign References
        </Typography>
        {assignedReferences.current.map((reference, index) => (
          <AssignedReferenceBox
            refOptions={assignedRefOptions}
            key={'assigned-ref-' + index}
            config={reference}
            copyConfig={() => {
              const copiedReference = _.cloneDeep(reference);
              copiedReference.name += ' (copy)';
              assignedReferences.current.push(copiedReference);
              setConfig({
                ...config,
                assignedReferences: assignedReferences.current,
              });
            }}
            setConfig={(value) => {
              if (value) {
                Object.keys(value).forEach((key) => {
                  if (!value[key]) delete value[key];
                });
                assignedReferences.current[index] = value;
              } else {
                assignedReferences.current.splice(index, 1);
              }
              setConfig({
                ...config,
                assignedReferences: assignedReferences.current,
              });
            }}
          />
        ))}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="delete patient"
          onClick={(e) => {
            e.stopPropagation();
            assignedReferences.current.push({
              ref: undefined,
            });
            setConfig({
              ...config,
              assignedReferences: assignedReferences.current,
            });
          }}
          sx={{ marginLeft: 3 }}
        >
          <AddIcon />
        </IconButton>
      </Card>
    </Badge>
  );
}

export default FeedbackConfig;
