import Grid from '@mui/material/Grid';
import React from 'react';
import { TextField } from '@mui/material';

export default function PoseHeld({ config, setConfig, inTime = false }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id="outlined-pose-held"
          label="Seconds Pose Held"
          variant="outlined"
          type="number"
          value={config.secondsPoseHeld}
          onChange={(e) =>
            setConfig({ ...config, secondsPoseHeld: e.target.value })
          }
        />
      </Grid>
      {inTime && (
        <Grid item xs={6}>
          <TextField
            id="outlined-time"
            label="Time"
            variant="outlined"
            type="number"
            value={config.time}
            onChange={(e) => setConfig({ ...config, time: e.target.value })}
          />
        </Grid>
      )}
    </Grid>
  );
}
