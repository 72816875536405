import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import { getAssignments } from '../../../../../utilities/Demo/Physio/Patient/Overview/assignments';
import FirebaseAuthContext from '../../../../../contexts/auth/FirebaseAuthContext';
import Button from '@mui/material/Button';
import ProtocolConfig from './ProtocolConfig';
import { CircularProgress, List } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import DeleteButton from '../../../ExerciseManagement/DeleteButton';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useStartSchedule } from '../../../../../hooks/Demo/exerciseManagement/startEntity';
import Typography from '@mui/material/Typography';

export default function Assignments({ patientId }) {
  const navigate = useNavigate();
  const { user } = useContext(FirebaseAuthContext);
  const [assignments, setAssignments] = React.useState([]);
  const [selectedAssignment, setSelectedAssignment] = React.useState(null);
  const startSchedule = useStartSchedule();
  const [loadingAssignments, setLoadingAssignments] = React.useState(true);

  const onClose = () => {
    navigate('/all_patients');
  };
  useEffect(() => {
    const doctorId = user.claims.doctorId || user.uid;
    patientId = user.claims.patientId || patientId;
    setLoadingAssignments(true);
    getAssignments({ doctorId, patientId }).then((assignments) => {
      setAssignments(assignments);
      setLoadingAssignments(false);
    });
  }, [patientId]);

  const addAssignment = (assignment) => {
    setAssignments([...assignments, assignment]);
  };

  const updateAssignment = (assignment) => {
    setAssignments(
      assignments.map((a) => {
        if (a.id === assignment.id) {
          return assignment;
        }
        return a;
      })
    );
  };

  if (selectedAssignment) {
    return (
      <ProtocolConfig
        patientId={patientId}
        config={selectedAssignment}
        onClose={() => setSelectedAssignment(null)}
        addProtocolToList={addAssignment}
        updateProtocolInList={updateAssignment}
      />
    );
  }

  return (
    <Box style={{ margin: 5, padding: 5 }}>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <h1>Assignments</h1>
      <List>
        {loadingAssignments ? (
          <CircularProgress />
        ) : assignments.length === 0 ? (
          <Typography>No assignments</Typography>
        ) : null}
        {assignments.map((assignment, index) => (
          <ListItemButton
            key={assignment.id}
            style={{ padding: 10 }}
            onClick={() => {
              if (user.claims.patientId) {
                startSchedule({
                  scheduleDoc: assignment,
                  queryParams: 'type=assignment',
                });
              } else {
                setSelectedAssignment(assignment);
              }
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <h2>{assignment.name}</h2>
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="delete patient"
                  onClick={(e) => {
                    e.stopPropagation();
                    startSchedule({
                      scheduleDoc: assignment,
                      queryParams: 'type=assignment',
                    });
                  }}
                >
                  <PlayCircleOutlineIcon />
                </IconButton>
              </Grid>
              {!user.claims.patientId && (
                <Grid item xs={2}>
                  <DeleteButton
                    onDelete={() => {
                      setAssignments(assignments.filter((_, i) => i !== index));
                    }}
                    deletionTarget={'Protocol'}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography>{`${assignment.days} days, ${assignment.sessions} sessions`}</Typography>
              </Grid>
            </Grid>
          </ListItemButton>
        ))}
      </List>
      {!user.claims.patientId && (
        <Button
          onClick={() => {
            console.log('Add protocol');
            setSelectedAssignment({});
          }}
        >
          Add Protocol
        </Button>
      )}
    </Box>
  );
}
