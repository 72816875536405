import * as React from 'react';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import './schedule.css';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import ScoreboardRoundedIcon from '@mui/icons-material/ScoreboardRounded';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { socketo } from '../../service/socket';
import { getSweatPointsThresh } from '../../redux/actions/getDashboard';
import { clearaisession } from '../../redux/actions/aiserver';
import { useTranslation } from 'react-i18next';

const TodaySessionBigComponent = ({ data }) => {
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const navigate = useNavigate();
  let { user_id } = JSON.parse(localStorage.getItem('user_data'));
  const handleJoin = async () => {
    setLoad(true);
    //check if 50 points crossed via new api
    let thresh = await getSweatPointsThresh(moment().format('DD-MM'));
    let limitex = false;
    //pass the limitex to exercise or save it in local storage so enable/disable ai
    localStorage.setItem('points', thresh.points);
    localStorage.setItem('limitex', thresh.thresh);
    if (thresh.points >= thresh.thresh) {
      limitex = true;
      setShowAlert(true);
      setMsg('Limit is exceeded. You cannot join');
      setLoad(false);
      //await new Promise((resolve) => setTimeout(resolve, 6000));
      return;
    }
    console.log('Sajod::');
    if (!limitex) {
      socketo.emit('joinedTraining', localStorage.getItem('user_data'));
      socketo.on('newJoin', async (data) => {
        let dataObj = JSON.parse(data);
        var users = Object.keys(dataObj).map(function (key) {
          return JSON.parse(dataObj[key]);
        });

        let usrs = users.filter((item) => item.user_id == user_id);
        console.log('SESS_URS', usrs);
        if (usrs.length > 1) {
          setMsg('You have already joined this session');
          setShowAlert(true);
          setLoad(false);
          socketo.off('newJoin');
          return;
        } else {
          socketo.off('newJoin');
          socketo.emit('leftTraining', localStorage.getItem('user_data'));
          await clearaisession(user_id);
          setLoad(false);
          navigate('/exercise');
        }
      });
    }
  };
  React.useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, [10000]);
    }
  }, [showAlert]);

  return (
    <div className="today-session-container">
      {showAlert && (
        <Alert
          severity="error"
          variant="filled"
          onClose={() => setShowAlert(false)}
        >
          <strong>{msg}</strong>
        </Alert>
      )}
      <div className="box-container">
        <div className="today-session-box">{t('SessionComponent.title')}</div>
      </div>

      <div className="list list-container">
        <div className="schedule-left left">
          <EventNoteRoundedIcon />
          <span>{t('SessionComponent.class')}</span>
        </div>
        <div className="right">
          <span>{data && data.class_no ? data.class_no : 'NA'}</span>
        </div>
        <div className="schedule-left left">
          <AccessTimeRoundedIcon />
          <span>{t('SessionComponent.Duration')}</span>
        </div>
        <div className="right">
          <span>{data ? `${data.duration} minutes` : 'N/A'}</span>
        </div>
        <div className="schedule-left left">
          <ScoreboardRoundedIcon />
          <span>{t('SessionComponent.Difficulty')}</span>
        </div>
        <div className="right">
          <span>{data ? t('SessionComponent.Beginner') : 'N/A'}</span>
        </div>
        <div className="schedule-left left">
          <CandlestickChartOutlinedIcon />
          <span>{t('SessionComponent.Intensity')}</span>
        </div>
        <div className="right">
          <span>{t('SessionComponent.level')} 1</span>
        </div>
        <div className="schedule-left left">
          <CandlestickChartOutlinedIcon />
          <span>{t('SessionComponent.Start')}</span>
        </div>
        <div className="right">
          <span>
            {' '}
            {data && data.start
              ? moment
                  .tz(data.start.dateTime, 'Europe/Berlin')
                  .local()
                  .format('HH:mm')
              : 'NA'}
          </span>
        </div>
      </div>
      <p>
        <div className="schedule-divider"></div>
        {data ? data.session_desc : 'NA'}
      </p>
      <div className="join-session-btn-container">
        {load ? (
          <CircularProgress size={25} color="success" />
        ) : (
          <Button
            type="submit"
            variant="contained"
            className="join-session-btn"
            sx={{
              borderRadius: 2,
              fontSize: '1.5rem',
              backgroundColor: '#FF592C',
            }}
            onClick={() => handleJoin()}
          >
            {t('SessionComponent.join')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TodaySessionBigComponent;
