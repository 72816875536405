import React, { useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
import { useState } from 'react';
import HighlightBg from '../../components/Highlights/HighlightBg';
import SessionComponentHi from '../../components/Highlights/SessionComponentHi';
import TodayHighlights from '../../components/Highlights/TodayHighlights';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

const Highlights = () => {
  const [open, setOpen] = useState(false);
  const points = useSelector(({ points }) => points.values);
  const board = useSelector(({ board }) => board.values);
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location::', location);
  let tout;
  if (
    location &&
    location.state &&
    location.state.from &&
    location.state.from === 'header'
  ) {
    tout = setTimeout(() => {
      navigate('/ranking');
    }, [20000]);
  }
  useEffect(() => {
    console.log('POINTS is::', points);
    return () => {
      // componentwillunmount in functional component.
      // Anything in here is fired on component unmount.
      clearTimeout(tout);
    };
  }, []);
  return (
    <div className="hightlights">
      <HighlightBg />
      <HeaderComponent />
      <DrawerComponent open={open} setOpen={setOpen} />

      <div className="highlights-grid">
        <SessionComponentHi />
        <TodayHighlights points={points} data={board} />
      </div>
    </div>
  );
};

export default Highlights;
