import React from 'react';
import '@tensorflow/tfjs-backend-webgl';
import PoseDetection from './PoseDetection';

function PoseCalibration({ setVisiblepts, isPointsDetected }) {
  function onResults(results) {
    let keycount = 0;
    const wantedJoints = [11, 12, 13, 14, 15, 16, 23, 24, 25, 26, 27, 28];
    try {
      for (let i = 0; i < results.poseLandmarks.length; i++) {
        if (wantedJoints.includes(i)) {
          if (results.poseLandmarks[i].visibility > 0.4) {
            keycount = keycount + 1;
          }
        }
      }
    } catch (err) {
      keycount = 0;
    }
    if (!isPointsDetected) setVisiblepts(keycount);
  }

  return <PoseDetection onResults={onResults} />;
}

export default React.memo(PoseCalibration);
