import { SumPerKeyComputer, SumPerSideComputer } from './ResultsComputer';

export function commonSensorDataProcessing({ sensorData }) {
  const sumPerKeyComputer = new SumPerKeyComputer({ sensorData });
  const sumPerSideComputer = new SumPerSideComputer({ sumPerKeyComputer });

  return {
    left: {
      ...sumPerKeyComputer.left,
      balance_sum: sumPerSideComputer.left || null,
    },
    right: {
      ...sumPerKeyComputer.right,
      balance_sum: sumPerSideComputer.right || null,
    },
  };
}
