import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GraphComponent = ({ dys, coins }) => {
  return (
    <div className="graph-container">
      <Bar
        type="bar"
        data={{
          labels: Object.keys(dys),
          datasets: [
            {
              label: '',
              data: Object.values(dys),
              backgroundColor: [
                '#cabdff',
                '#b1e5fc',
                '#cabdff',
                '#b1e5fc',
                '#cabdff',
                '#b1e5fc',
                '#cabdff',
              ],
            },
            {
              label: '',
              data: Object.values(coins),
              backgroundColor: [
                '#FF592C',
                '#f53602',
                '#FF592C',
                '#f53602',
                '#FF592C',
                '#f53602',
                '#FF592C',
              ],
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              grid: { display: false },
            },
            x: {
              grid: { display: false },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default GraphComponent;
