let emptyInitialState = {
  values: {
    id: '',
    name: '',
    age: '',
    weight: '',
    footSize: '',
    arch: '',
    height: '',
  },
};

const savedState = localStorage.getItem('gaitPatientInfo');
const initialState = savedState ? JSON.parse(savedState) : emptyInitialState;

export const gaitPatientInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE-GAIT-PATIENT-INFO': {
      const newState = { values: { ...state.values, ...action.payload } };
      localStorage.setItem('gaitPatientInfo', JSON.stringify(newState));
      return newState;
    }
    case 'RESET-GAIT-PATIENT-INFO':
      localStorage.removeItem('gaitPatientInfo');
      return emptyInitialState;
    default: {
      return state;
    }
  }
};
