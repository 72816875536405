import Grid from '@mui/material/Grid';
import React from 'react';
import BasicSelect from '../BasicSelect';
import CptFunctionConfig from './cptFunctionConfig';

export default function OneSideConfig({ config, setConfig, sx }) {
  if (!config.functionConfig) {
    config.functionConfig = {};
  }
  const setFunctionConfig = (value) => {
    setConfig({ ...config, functionConfig: value });
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        ml: 2,
        mt: 4,
        paddingBottom: 2,
        border: 1,
        borderRadius: 5,
        paddingRight: 2,
        ...sx,
      }}
    >
      <Grid item xs={4}>
        <BasicSelect
          value={config.function}
          options={['simpleAngle', 'dependentAngle']}
          setValue={(value) => setConfig({ ...config, function: value })}
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12}>
        <CptFunctionConfig
          config={config.functionConfig}
          setConfig={setFunctionConfig}
          dependent={config.function === 'dependentAngle'}
        />
      </Grid>
    </Grid>
  );
}
