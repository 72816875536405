import React, { useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
import ClassHomeBcg from '../../components/Background/ClassHomeBcg';
import ClassHomeComponent from '../../components/ClassHome/ClassHomeComponent';

import '../../components/ClassHome/classhome.css';
import { useDispatch, useSelector } from 'react-redux';
import { getClassHome } from '../../redux/actions/getClassHome';
import { CircularProgress } from '@mui/material';

const ClassHome = () => {
  const dispatch = useDispatch();
  const classes = useSelector(({ thome }) => thome.values);
  useEffect(() => {
    if (!classes) getClassHome(dispatch);
  }, []);
  return (
    <div className="class-home">
      <ClassHomeBcg />
      <HeaderComponent />
      <DrawerComponent />

      <div className="class-home-content">
        {!classes ? (
          <center>
            <CircularProgress
              size={60}
              color="success"
              sx={{ justifyContent: 'center' }}
            />
          </center>
        ) : (
          <ClassHomeComponent classes={classes} />
        )}
      </div>
    </div>
  );
};

export default ClassHome;
