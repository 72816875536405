// Plagenhoef et al., 1983
// https://exrx.net/Kinesiology/Segments
export const blazePoseJointWeights = {
  0: 0.0823, // head
  11: 0.1856 / 2, // thorax / 2
  12: 0.1856 / 2, // thorax / 2
  23: 0.1481 / 2 + 0.1224 / 2, // pelvis / 2 + abdomen / 2
  24: 0.1481 / 2 + 0.1224 / 2, // pelvis / 2 + abdomen / 2
  13: 0.03075, // upper arm
  14: 0.03075, // upper arm
  15: 0.0172, // forearm
  16: 0.0172, // forearm
  19: 0.00575, // hand
  20: 0.00575, // hand
  25: 0.11125, // thigh
  26: 0.11125, // thigh
  27: 0.0505, // leg
  28: 0.0505, // leg
  29: 0.0138, // foot
  30: 0.0138, // foot
};
