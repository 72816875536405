import React, { useState, useEffect } from 'react';
import {
  isBrowserSupported,
  supportedBrowsers,
} from '../utilities/Demo/browser';

const BrowserSupport = ({ children }) => {
  const [browserSupported, setBrowserSupported] = useState(null);

  useEffect(() => {
    setBrowserSupported(isBrowserSupported());
  }, []);

  if (browserSupported === null) {
    return <div>Detecting browser...</div>;
  } else if (browserSupported) {
    return children;
  } else {
    return (
      <div style={{ padding: '20px' }}>
        <h3>
          Sorry, your browser is not supported. Only desktop versions of
          Chrome/Firefox is supported for now. Try the following:
        </h3>
        <div style={{ padding: '20px' }}>
          <ul>
            {supportedBrowsers.map((browser) => (
              <li key={browser}>{browser}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
};

export default BrowserSupport;
