import React from 'react';
import Grid from '@mui/material/Grid';
import PieChart from './PieChart';

export default function Overpronation({ meanLeft = {}, meanRight = {} }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <PieChart
          series={[
            Math.round(meanLeft.overpronate) / 100,
            Math.round(100 - meanLeft.overpronate) / 100,
          ]}
          labels={['Overpronate', 'Normal']}
          title={'Left over pronation'}
        />
      </Grid>
      <Grid item xs={6}>
        <PieChart
          series={[
            Math.round(meanRight.overpronate) / 100,
            Math.round(100 - meanRight.overpronate) / 100,
          ]}
          labels={['Overpronate', 'Normal']}
          title={'Right over pronation'}
        />
      </Grid>
    </Grid>
  );
}
