import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderComponentDemo from '../../components/HeaderComponentDemo';
import { useState } from 'react';
import HighlightBg from '../../components/Highlights/HighlightBg';
import SummaryHighlights from '../../components/Demo/SummaryHighlights';
import { useLocation } from 'react-router';
import '../../views/Demo/summary.css';

const Highlights = () => {
  const dispatch = useDispatch();
  const [duration, setDuration] = useState('0');
  const [sp, setSp] = useState(0);
  const [squatCount, setSquatCount] = useState(0);
  const [incorrectSquatCount, setIncorrectSquatCount] = useState(0);
  const [workOutScore, setWorkOutScore] = useState(0);
  const iter = useSelector(({ iter }) => iter.values);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  function getRedirectTarget() {
    if (location.state?.demoType === 'fitness_assessment') {
      return '/fitness_assessment';
    } else if (location.state?.demoType === 'exhibition_assessment') {
      return '/exhibition_assessment';
    } else if (location.state?.demoType === 'sit_and_stand_assessment') {
      return '/sit_and_stand_result';
    } else if (location.state?.demoType.startsWith('berg_balance_assessment')) {
      dispatch({
        type: 'RESET-BERG-BALANCE-RESULTS',
        payload: {},
      });
      dispatch({
        type: 'RESET-LAST-BERG-BALANCE-RESULTS',
        payload: {},
      });
      return (
        '/berg_balance_result/' +
        (location.state?.demoType.endsWith('mini') ? 'mini' : 'full')
      );
    } else if (iter.overviewRedirect) {
      return '/schedule_results/' + iter.overviewRedirect;
    } else {
      return '/';
    }
  }

  useEffect(() => {
    if (location && location.state) {
      setDuration(location.state.duration);
      setSp(location.state.sp);
      setSquatCount(location.state.squatCount);
      setIncorrectSquatCount(location.state.incorrectSquatCount);
      setWorkOutScore(location.state.workOutScore);
    }
  });

  return (
    <div className="hightlights">
      <HighlightBg />
      <HeaderComponentDemo
        redirectTarget={getRedirectTarget() + '?' + queryParams}
      />
      <div className="highlights-grid">
        <SummaryHighlights
          duration={duration}
          sp={sp}
          squatCount={squatCount}
          incorrectSquatCount={incorrectSquatCount}
          workOutScore={workOutScore}
          demoType={location.state?.demoType}
        />
      </div>
    </div>
  );
};

export default Highlights;
