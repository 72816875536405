import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import ProgressArticleNavigation from './ProgressArticleNavigation';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentAttendance } from '../../redux/actions/getClassHome';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Progress = ({ data }) => {
  const { t } = useTranslation();
  const progress = useSelector(({ attnd }) => attnd.values);
  const dispatch = useDispatch();
  const [load, setLoad] = useState(false);
  useEffect(() => {
    getStudentAttendance(dispatch, data.user_id, setLoad);
  }, []);
  const checkAttendance = (date) => {
    if (
      progress &&
      progress.attendance.filter(
        (item) => item.date == moment(date).format('DD-MM')
      ).length >= 1
    )
      return <div style={{ color: 'green' }}>{moment(date).format('DD')}</div>;
    else return <div style={{ color: 'red' }}>{moment(date).format('DD')}</div>;
  };
  return (
    <div className="progress-component-container progress-article progress-calendar">
      <ProgressArticleNavigation title={t('progressCalendarComponent.title')} />

      {load ? (
        <center>
          {' '}
          <CircularProgress
            size={30}
            color="success"
            sx={{ justifyContent: 'center' }}
          />
        </center>
      ) : (
        <Calendar
          locale={t('CalendarComponent.locale')}
          formatDay={(locale, date) =>
            moment(date).format('dd') === 'Su' ||
            moment(date).diff(moment(), 'days') >= 1 ? (
              <div style={{ color: '#B0B0B0' }}>
                {moment(date).format('DD')}
              </div>
            ) : (
              checkAttendance(date)
            )
          }
        ></Calendar>
      )}
    </div>
  );
};

export default Progress;
