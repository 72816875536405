import React from 'react';
import { useState, useEffect } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import './profile.css';
import Settings from './Settings';
import Goals from './Goals';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReferAFriend from './ReferAFriend';

const ProfileComponent = ({ board }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState('settings');
  const [parse, setParse] = useState(false);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user_data'))
  );
  const handleLogout = () => {
    setLoad(true);
    setTimeout(() => {
      dispatch({
        type: 'LOGOUT',
        payload: {},
      });
      setLoad(false);
      navigate('/');
      window.location.reload();
    }, [5000]);
  };
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user_data')));
    if (parse) setParse(false);
  }, [parse]);
  return (
    <div className="profile-page-container">
      <div className="profile-title">
        <h1>{t('ProfileComponent.title')}</h1>
      </div>
      <div className="profile-page-content">
        <div className="profile-page-navigation">
          <ListItemButton
            onClick={() => setSelected('settings')}
            selected={selected === 'settings' ? true : false}
          >
            {t('ProfileComponent.settings')}
          </ListItemButton>
          {user.role === 'student' && (
            <ListItemButton
              onClick={() => setSelected('goals')}
              selected={selected === 'goals' ? true : false}
            >
              {t('ProfileComponent.goals')}
            </ListItemButton>
          )}
          <ListItemButton
            onClick={() => setSelected('referfriend')}
            selected={selected === 'referfriend' ? true : false}
          >
            {t('ProfileComponent.refer')}
          </ListItemButton>
          {load ? (
            <center>
              <CircularProgress
                size={20}
                color="success"
                sx={{ justifyContent: 'center' }}
              />
            </center>
          ) : (
            <ListItemButton onClick={() => handleLogout()}>
              {t('ProfileComponent.logout')}
            </ListItemButton>
          )}
        </div>
        <div className="profile-page-component">
          {selected === 'settings' ? (
            <Settings
              name={user.name}
              email={user.email}
              dp={user.profile_pic}
              setParse={setParse}
            />
          ) : selected === 'goals' ? (
            <Goals board={board} />
          ) : (
            <ReferAFriend />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;
