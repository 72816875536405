import React from 'react';

export const VersionTag = () => {
  return (
    <span>
      {process.env.REACT_APP_GIT_TAG ? process.env.REACT_APP_GIT_TAG + ' ' : ''}
      <a>{process.env.REACT_APP_GIT_SHA}</a>
    </span>
  );
};
