export function useBalanceScore({
  secondsPoseHeldComplete,
  secondsPoseHeld,
  usedArms,
  timeThresh,
}) {
  return () => {
    // console.log(
    //   'usedArms',
    //   usedArms.current,
    //   secondsPoseHeldComplete.current,
    //   secondsPoseHeld.current,
    //   timeThresh
    // );

    const secondsCounted =
      secondsPoseHeldComplete.current + secondsPoseHeld.current;
    if (!usedArms.current && secondsCounted >= timeThresh - 1) return 1;
    if (!usedArms.current && secondsCounted >= timeThresh / 2) return 2 / 3;
    if (usedArms.current && secondsCounted < timeThresh / 2) return 0;
    if (usedArms.current && secondsCounted >= timeThresh / 2) return 1 / 3;
  };
}
