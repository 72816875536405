import { useRef } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { usePersonPixelHeight } from '../../../../hooks/Demo/exerciseManagement/rwu';
import { useSide } from '../../../../hooks/Demo/visualFeedback';
import {
  checkIfRightSide,
  checkIfSide,
} from '../../../../utilities/Demo/angles';
import { angleConfigComputation } from '../../../../utilities/Demo/ExerciseManagement/Generators/angleConfig';
import { angleFrontComputations } from '../../../../utilities/Demo/ExerciseManagement/Generators/angleFront';
import {
  angleSideComputations,
  angleSideForceComputations,
} from '../../../../utilities/Demo/ExerciseManagement/Generators/anglesSide';
import {
  blazePoseCoordinateNames,
  refComputations,
} from '../../../../utilities/Demo/ExerciseManagement/Generators/coordinates';
import { conditionMet } from '../../../../utilities/Demo/ExerciseManagement/Generators/generic';
import {
  drawAdjustedAngles,
  metricsComputations,
} from '../../../../utilities/Demo/ExerciseManagement/Generators/metrics';
import {
  faceCamera,
  turnToOtherSide,
} from '../../../../utilities/Demo/feedbackLang';
import PoseDetection from '../../PoseDetection';
import _ from 'lodash';
import { useStridalizerEventListener } from '../../../../hooks/Demo/stridalizer';

export default function FeedbackGenerator({
  config,
  finishedIntro,
  updateFeedback,
  onCorrect,
  playAudio,
  audioFeedback,
  feedbackCallback = () => {},
  endCallback = () => {},
  showWebcam = false,
}) {
  const dispatch = useDispatch();
  const { setSide } = useSide();
  const angles = useRef({});
  const angleConfigs = useRef({});
  const states = useRef([]);
  const feedbacks = useRef([]);
  const confirmativeFeedback = useRef({});
  const isLeft = useRef(false);
  const metrics = useRef([]);
  const metricAngles = useRef({});
  const assignedReferences = useRef({});
  const anyActiveReedback = useRef(false);
  const { setPixelHeight, personHeightPixels } = usePersonPixelHeight();
  const sensorDataCollectionStates = useRef([]);
  const collectSensorData = useRef(false);
  const { sensorData } = useStridalizerEventListener({
    callBack: () => {
      dispatch({
        type: 'SET-ITER-SENSOR-DATA',
        payload: sensorData.current,
      });
    },
    timeRunning: collectSensorData,
  });

  React.useEffect(() => {
    console.log('config changed', config);
    angles.current = {};
    if (config.useAngleConfigs) {
      config.angleConfigs = [...config.angles];
      config.angles = [];
    }
    config.angles.forEach((angle) => {
      angles.current[angle] = 0;
    });
    angleConfigs.current = {};
    config.angleConfigs?.forEach((angle) => {
      angleConfigs.current[angle.name] = 0;
    });
    states.current = [];
    config.states.forEach((state) => {
      states.current.push({ ...state, passed: false });
    });
    feedbacks.current = [];
    config.feedback
      .filter((fb) => !fb.state)
      .forEach((feedback) => {
        feedbacks.current.push({ ...feedback, active: false });
      });
    confirmativeFeedback.current = [];
    config.feedback
      .filter((fb) => fb.state)
      .forEach((feedback) => {
        confirmativeFeedback.current.push({ ...feedback, active: false });
      });
    isLeft.current = false;
    metrics.current = [];
    config.metrics?.forEach((metric) => {
      metrics.current.push(_.cloneDeep(metric));
      metric.subMetrics?.forEach((subMetric) => {
        metricAngles.current[subMetric.ref] = {
          coord: null,
          left: null,
          metric: null,
        };
      });
    });
    assignedReferences.current = {};

    if (!config.sensorDataCollectionStates) {
      collectSensorData.current = true;
    } else {
      sensorDataCollectionStates.current = _.cloneDeep(
        config.sensorDataCollectionStates
      );
    }

    dispatch({
      type: 'SET-ITER-METRICS',
      payload: metrics.current,
    });
  }, [config]);

  const endObservation = (canvasCtx) => {
    const displayMetricAngles = {};
    Object.keys(metricAngles.current).forEach((angle) => {
      if (config.angleDisplay[angle]) {
        displayMetricAngles[angle] = metricAngles.current[angle];
      }
    });
    drawAdjustedAngles(canvasCtx, { current: displayMetricAngles }, angles);
    dispatch({
      type: 'SET-ITER-METRICS',
      payload: metrics.current,
    });
    endCallback();
  };

  function cptAssignedRefs(reference, results) {
    try {
      assignedReferences.current[reference.name] = refComputations[
        reference.type
      ](
        blazePoseCoordinateNames[reference.ref](results, isLeft.current),
        blazePoseCoordinateNames[reference.secondRef](results, isLeft.current)
      );
    } catch (error) {
      console.log(error);
    }
  }

  function cptMetricsForState(state) {
    metrics.current.forEach((metric) => {
      metric.subMetrics?.forEach((subMetric) => {
        if (subMetric.state?.includes(state.name)) {
          metricsComputations[subMetric.computation](subMetric, angles);
          metricAngles.current[subMetric.ref].metric = subMetric;
          // console.log(subMetric.name, subMetric.value);
        }
      });
    });
    if (sensorDataCollectionStates.current.includes(state.id)) {
      collectSensorData.current = true;
    }
  }

  function onResults(results, canvasCtx) {
    try {
      if (!finishedIntro.current) return;
      if (config.view === 'side') {
        if (checkIfSide(results, config.sideThreshold || 2)) {
          setSide(true);
          // anyActiveReedback.current = false;
          // updateFeedback('');
          isLeft.current = checkIfRightSide(results, 'left');

          // force side
          if (
            (isLeft.current && config.side === 'right') ||
            (!isLeft.current && config.side === 'left')
          ) {
            updateFeedback(turnToOtherSide(config.language));
            anyActiveReedback.current = true;
            if (isLeft.current && config.side === 'right') {
              playAudio(audioFeedback.current?.makeSureRightSide);
            } else if (!isLeft.current && config.side === 'left') {
              playAudio(audioFeedback.current?.makeSureLeftSide);
            }
            // playAudio(audioFeedback.current?.turnOtherSide);
            return;
          }

          if (config.side) {
            if (!config.useAngleConfigs) {
              config.angles.forEach((angle) => {
                let displayConfig;
                [angles.current[angle], displayConfig] =
                  angleSideForceComputations[angle]({
                    results,
                    canvasCtx,
                    isLeft: isLeft.current,
                    display:
                      config.angleDisplay[angle] &&
                      !metricAngles.current[angle],
                  });
                if (metricAngles.current[angle] && displayConfig) {
                  metricAngles.current[angle] = displayConfig;
                }
              });
            }
          } else {
            if (!config.useAngleConfigs) {
              config.angles.forEach((angle) => {
                let angleFunction =
                  angleSideComputations[angle][
                    isLeft.current ? 'left' : 'right'
                  ];
                if (!angleFunction) {
                  angleFunction = angleSideComputations[angle];
                }
                let displayConfig;
                [angles.current[angle], displayConfig] = angleFunction({
                  results,
                  canvasCtx,
                  isLeft: isLeft.current,
                  display:
                    config.angleDisplay[angle] && !metricAngles.current[angle],
                });
                if (metricAngles.current[angle] && displayConfig) {
                  metricAngles.current[angle] = displayConfig;
                }
              });
            }
          }
        } else {
          setSide(false);
          playAudio(audioFeedback.current?.turnToSide);
          anyActiveReedback.current = true;
          return;
        }

        // new Angle configs
        config.angleConfigs?.forEach((angleConfig) => {
          angles.current[angleConfig.name] = angleConfigComputation({
            config: angleConfig,
            canvasCtx,
            display: !metricAngles.current[angleConfig.name],
            results,
            isLeft: isLeft.current,
          });
        });
      } else if (config.view === 'front') {
        if (!checkIfSide(results, config.sideThreshold || 4)) {
          // new Angle configs
          config.angleConfigs?.forEach((angleConfig) => {
            angles.current[angleConfig.name] = angleConfigComputation({
              config: angleConfig,
              canvasCtx,
              display:
                config.angleDisplay[angleConfig.name] &&
                !metricAngles.current[angleConfig.name],
              results,
            });
          });

          if (!config.useAngleConfigs) {
            config.angles.forEach((angle) => {
              let displayConfig;
              [angles.current[angle], displayConfig] = angleFrontComputations[
                angle
              ]({
                canvasCtx,
                results,
                display:
                  config.angleDisplay &&
                  config.angleDisplay[angle] &&
                  !metricAngles.current[angle],
              });
              if (metricAngles.current[angle] && displayConfig) {
                metricAngles.current[angle] = displayConfig;
              }
            });
          }
        } else {
          updateFeedback(faceCamera(config.language));
          anyActiveReedback.current = true;
          playAudio(audioFeedback.current?.faceCamera);
          return;
        }
      }

      try {
        feedbacks.current.forEach((feedback) => {
          feedback.assignedReferences?.forEach((reference) => {
            cptAssignedRefs(reference, results, canvasCtx);
          });

          if (
            feedback.conditions.every((condition) => {
              return conditionMet(
                condition,
                results,
                assignedReferences,
                angles
              );
            })
          ) {
            feedback.active = true;
            anyActiveReedback.current = true;
            updateFeedback(feedback.message);
            playAudio(
              feedback.audioFeedback &&
                audioFeedback.current[feedback.audioFeedback]
            );
            states.current.forEach((state) => {
              state.passed = false;
            });
          } else {
            feedback.active = false;
          }
        });
        if (feedbacks.current.every((feedback) => !feedback.active)) {
          if (anyActiveReedback.current) {
            updateFeedback('');
            anyActiveReedback.current = false;
          }
        } else {
          feedbackCallback();
          endObservation(canvasCtx);
          return;
        }
      } catch (error) {
        console.log(error);
      }

      metrics.current.forEach((metric) => {
        metric.subMetrics?.forEach((subMetric) => {
          if (
            !subMetric.state ||
            subMetric.state?.length === 0 ||
            subMetric.state === [null]
          ) {
            metricsComputations[subMetric.computation](subMetric, angles);
            metricAngles.current[subMetric.ref].metric = subMetric;
          }
        });
      });

      let passedOnSide = '';
      states.current.forEach((state, index) => {
        if (!state.passed) {
          state.assignedReferences?.forEach((reference) => {
            cptAssignedRefs(reference, results, canvasCtx);
          });

          let check = false;
          let sideLinkIndex;
          if (state.sideLink) {
            sideLinkIndex = states.current.findIndex((linkState) => {
              return linkState.id === state.sideLink;
            });
            if (states.current[sideLinkIndex - 1]) {
              if (states.current[sideLinkIndex - 1].passed) {
                check = true;
              }
            }
          }
          if (index === 0) {
            check = true;
          } else if (states.current[index - 1].passed) {
            check = true;
          }
          if (check) {
            const conditionsMet = state.conditions.every((condition) => {
              return conditionMet(
                condition,
                results,
                assignedReferences,
                angles
              );
            });
            if (conditionsMet) {
              if (
                ['stretch', 'stretchCount', 'RWUStretch'].includes(
                  config.type
                ) &&
                states.current.length > 1 &&
                index === 0
              ) {
                states.current.slice(1).forEach((state) => {
                  state.passed = false;
                });
              }
              state.passed = true;
              updateFeedback(state.name);
              if (state.cptPixelHeight) {
                // console.log('setting pixel height');
                setPixelHeight(results);
              } else {
                // console.log(state.name, state);
              }
              if (state.sideLink) {
                states.current[sideLinkIndex].passed = true;
                passedOnSide = state.side;
              }
            } else {
              confirmativeFeedback.current.forEach((feedback) => {
                if (!feedback.state) return;
                if (!feedback.state.includes(state.id)) return;
                feedback.assignedReferences?.forEach((reference) => {
                  cptAssignedRefs(reference, results, canvasCtx);
                });

                if (
                  feedback.conditions.every((condition) => {
                    return conditionMet(
                      condition,
                      results,
                      assignedReferences,
                      angles
                    );
                  })
                ) {
                  updateFeedback(feedback.message);
                  playAudio(
                    feedback.audioFeedback &&
                      audioFeedback.current[feedback.audioFeedback]
                  );
                }
              });
            }
          }
          if (index === states.current.length - 1 && state.passed) {
            updateFeedback('');
            let targetRef;
            if (
              ['stretch', 'stretchCount', 'RWUStretch'].includes(config.type)
            ) {
              if (config.stretchTarget) {
                if (config.stretchTargetType === 'angle') {
                  targetRef = angles.current[config.stretchTarget];
                } else if (config.stretchTargetType === 'assignedRef') {
                  targetRef = assignedReferences.current[config.stretchTarget];
                } else if (config.stretchTargetType === 'coordinate') {
                  targetRef = blazePoseCoordinateNames[config.stretchTarget](
                    results,
                    isLeft.current
                  );
                }
              } else {
                targetRef =
                  assignedReferences.current[
                    state.assignedReferences && state.assignedReferences[0].name
                  ];
              }
            }
            const doneStretch = onCorrect({
              results,
              refValue: targetRef,
              personHeightPixels: personHeightPixels.current,
              axis: state.pixelHeightAxis,
              framingMotionAxis: config.stretchTargetFramingMotionAxis,
              stretchTargetAxis: config.stretchTargetAxis,
              passedOnSide,
            });
            // reset states
            states.current.forEach((state) => {
              state.passed = false;
            });
            if (
              ['stretch', 'stretchCount', 'RWUStretch'].includes(config.type)
            ) {
              if (!doneStretch) {
                cptMetricsForState(state);
                // set pre last state to passed to still check last state
                if (states.current[states.current.length - 2])
                  states.current[states.current.length - 2].passed = true;
              }
            }
          }
        } else {
          const nextState = states.current[index + 1];
          if (nextState && !nextState.passed) {
            cptMetricsForState(state);
          }
          // updateFeedback(state.name);
        }
      });
      endObservation(canvasCtx);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <PoseDetection
      showWebcam={showWebcam}
      onResults={onResults}
      showBackbones={config.showBackbones}
    />
  );
}
