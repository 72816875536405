import Box from '@mui/material/Box';
import React from 'react';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function Headline({
  text,
  backgroundColor,
  height,
  fontColor,
  fontSize = null,
}) {
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: backgroundColor,
        height: height,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Typography
          fontSize={fontSize}
          component="div"
          sx={{ color: fontColor }}
        >
          {String(text)}
        </Typography>
      </Box>
    </Paper>
  );
}
