import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import _ from 'lodash';

export default function BasicSelect({
  value,
  setValue,
  label,
  options,
  sx,
  defaultValue,
  multiple = false,
}) {
  const handleChange = (event) => {
    let val = event.target.value;
    if (multiple) {
      if (
        _.every(
          val,
          (element) =>
            element === null || element === undefined || element === ''
        )
      )
        val = null;
      console.log(val);
    }
    setValue(val);
  };

  React.useEffect(() => {
    if (!options) return;
    if (options.includes(undefined)) {
      options = _.uniq(
        options.filter((item) => item !== undefined).concat([null])
      );
    }
  }, [options]);

  const handleValue = (value) => {
    if (multiple) {
      if (Array.isArray(value)) return value;
      if (typeof value === 'string' || !value) value = [value];
      else if (typeof value === 'object') value = [value?.name];
      return value.map((item) => item?.toString() || defaultValue);
    } else {
      if (value === undefined) return defaultValue || '';
      if (typeof value === 'object') {
        return value?.id || defaultValue;
      } else {
        return value?.toString() || defaultValue;
      }
    }
  };

  return (
    <Box sx={{ width: '70%', marginBottom: 1, ...sx }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={handleValue(value)}
          label={label}
          multiple={multiple}
          onChange={handleChange}
        >
          {options?.map((option) => {
            if (option?.name && option?.id) {
              return (
                <MenuItem key={label + '-' + option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem key={label + '-' + option} value={option}>
                  {option?.toString()}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
