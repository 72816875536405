import Grid from '@mui/material/Grid';
import React from 'react';
import CloseBadge from './CloseBadge';
import CopyBadge from './CopyBadge';

export default function OptionsBadge({ setConfig, copyConfig }) {
  return (
    <Grid
      container
      sx={{ marginRight: 10, marginTop: 10, alignItems: 'right' }}
    >
      <Grid item xs={4}>
        <CopyBadge copyConfig={copyConfig} />
      </Grid>
      <Grid item xs={4}>
        <CloseBadge setConfig={setConfig} />
      </Grid>
    </Grid>
  );
}
