import _ from 'lodash';
import {
  GAIT_TIME_INTERVAL,
  OPTIMAL_GAIT_CYCLE_PCT,
  PRESSURE_THRESHOLD,
} from '../../../../constants/Demo/gaitAnalysis';
import { blazePoseJointWeights } from '../../../../constants/JointWeights';
import { resampleAndMean } from '../../../../service/Demo/Physio/GaitAnalysis/results';
import { zonesLeft } from '../../../../components/Demo/SensorResults/FootMap';

export function filterUntilTime(time, data, startIndex, xAxis, yAxis) {
  for (let i = startIndex; i < data.length; i++) {
    if (data[i][xAxis] >= time) {
      return data.slice(startIndex, i).map((value) => value[yAxis]);
    }
  }
}

export function collectForEachStep(
  stepArray,
  graphData,
  stepValidity,
  xAxis = 'x',
  yAxis = 'y'
) {
  if (!stepArray || !graphData || !stepValidity) return [];
  let time = 0;
  // let startIndex = 0;
  const collection = [];
  stepArray.forEach((value, index) => {
    const timeCut = value + time;
    const stepData = filterUntilTime(timeCut, graphData, time, xAxis, yAxis);
    if (stepData) {
      if (stepValidity[index]) {
        collection.push(stepData);
      }
      // startIndex += stepData.length;
      time = timeCut;
    } else {
      console.log('stepData is undefined', index, timeCut, time);
    }
  });
  return collection;
}

export function findClosestMinimumIndex(array, index, start = true) {
  let min = 100000000;
  let minIndex = index;
  const searchIndexes = [
    index - 2,
    index - 1,
    index + 1,
    index + 2,
    index + 3,
    index + 4,
  ];
  searchIndexes.forEach((searchIndex) => {
    if (searchIndex < 0 || searchIndex >= array.length) return;
    const sumPressure = _.sum(
      Object.keys(zonesLeft).map((key) => {
        return array[searchIndex].value[key];
      })
    );
    if (sumPressure < min) {
      min = sumPressure;
      minIndex = searchIndex;
    }
  });
  console.log('minIndex', minIndex, index);
  return minIndex + (start ? -1 : -1);
}

export function collectForEachWindow({
  windowArray,
  graphData,
  xAxis = 'x',
  yAxis = 'y',
}) {
  if (!windowArray || !graphData) return [];
  let collection = [];
  windowArray.forEach((window) => {
    // find window in graphdata where x is between window.start and window.end
    if (!window.start || !window.end) {
      collection.push([]);
      return;
    }
    let startIndex = graphData.findIndex(
      (value) => value[xAxis] >= window.start
    );
    // startIndex = findClosestMinimumIndex(graphData, startIndex);
    let endIndex = graphData.findIndex((value) => value[xAxis] >= window.end);
    endIndex = endIndex === -1 ? 0 : endIndex;
    // endIndex = findClosestMinimumIndex(graphData, endIndex);
    const windowData = graphData
      .slice(startIndex, endIndex)
      .map((value) => value[yAxis]);
    collection.push(windowData);
  });
  return collection;
}

const mirrorSeries = (series) => {
  return series.map((s) => -s);
};

export function useGraphSideSlices(collectionData) {
  if (!collectionData) return;

  const leftLegMean = resampleAndMean(collectionData.leftLegCollection);
  const rightLegMean = resampleAndMean(collectionData.rightLegCollection);
  const leftKneeMean = resampleAndMean(collectionData.leftKneeCollection);
  const rightKneeMean = resampleAndMean(collectionData.rightKneeCollection);
  const leftAnkleMean = resampleAndMean(collectionData.leftAnkleCollection);
  const rightAnkleMean = resampleAndMean(collectionData.rightAnkleCollection);
  const leftHipMean = resampleAndMean(collectionData.leftHipCollection);
  const rightHipMean = resampleAndMean(collectionData.rightHipCollection);
  const leftHipRotationMean = resampleAndMean(
    collectionData.leftHipRotationCollection
  );
  const rightHipRotationMean = resampleAndMean(
    collectionData.rightHipRotationCollection
  );
  const leftHipBalanceMean = resampleAndMean(
    collectionData.leftHipBalanceCollection
  );
  const rightHipBalanceMean = resampleAndMean(
    collectionData.rightHipBalanceCollection
  );

  return {
    leftLegMean,
    rightLegMean,
    leftKneeMean,
    rightKneeMean,
    leftAnkleMean,
    rightAnkleMean,
    leftHipMean,
    rightHipMean,
    leftHipRotationMean,
    rightHipRotationMean,
    leftHipBalanceMean,
    rightHipBalanceMean,
  };
}

export const resolveMeanAndResampledArrays = async (graphSlices) => {
  if (!graphSlices) return;
  const leftLegMean = (await graphSlices.leftLegMean).data.series;
  const rightLegMean = (await graphSlices.rightLegMean).data.series;
  const leftKneeMean = mirrorSeries(
    (await graphSlices.leftKneeMean).data.series
  );
  const rightKneeMean = (await graphSlices.rightKneeMean).data.series;
  const leftAnkleMean = (await graphSlices.leftAnkleMean).data.series;
  const rightAnkleMean = (await graphSlices.rightAnkleMean).data.series;
  const leftHipMean = mirrorSeries((await graphSlices.leftHipMean).data.series);
  const rightHipMean = (await graphSlices.rightHipMean).data.series;
  const leftHipRotationMean = (await graphSlices.leftHipRotationMean).data
    .series;
  const rightHipRotationMean = (await graphSlices.rightHipRotationMean).data
    .series;
  const leftHipBalanceMean = (await graphSlices.leftHipBalanceMean).data.series;
  const rightHipBalanceMean = (await graphSlices.rightHipBalanceMean).data
    .series;

  return {
    leftLegMean,
    rightLegMean,
    leftKneeMean,
    rightKneeMean,
    leftAnkleMean,
    rightAnkleMean,
    leftHipMean,
    rightHipMean,
    leftHipRotationMean,
    rightHipRotationMean,
    leftHipBalanceMean,
    rightHipBalanceMean,
  };
};

function computeCenter(data, seriesFactor) {
  const leftHip = data[23];
  const rightHip = data[24];

  return {
    x:
      (_.sum(applyRWUDelta(leftHip.x, seriesFactor)) +
        _.sum(applyRWUDelta(rightHip.x, seriesFactor))) /
      2,
    y:
      (_.sum(applyRWUDelta(leftHip.y, seriesFactor)) +
        _.sum(applyRWUDelta(rightHip.y, seriesFactor))) /
      2,
    z:
      (_.sum(applyRWUDelta(leftHip.z, seriesFactor)) +
        _.sum(applyRWUDelta(rightHip.z, seriesFactor))) /
      2,
  };
}

function applyRWUDelta(series, seriesFactor) {
  const result = [];
  for (let i = 0; i < series.length; i++) {
    result.push(series[i] / seriesFactor[i]);
  }
  return result;
}

export function computeCenterOfMass(data, hipShoulderDelta) {
  if (Object.keys(data).length === 0) return;
  const rwuFactor = hipShoulderDelta.pixels / hipShoulderDelta.RWU;
  const seriesFactor = [];
  for (let i = 0; i < data[23].y.length; i++) {
    const currentHipShoulderDelta = Math.abs(data[11].y[i] - data[23].y[i]);
    seriesFactor.push(currentHipShoulderDelta * rwuFactor);
  }
  const center = computeCenter(data, seriesFactor);

  const centerOfMass = { x: 0, y: 0, z: 0 };
  let totalMass = 0;

  Object.keys(blazePoseJointWeights).forEach((joint) => {
    joint = parseInt(joint);
    const position = {
      x: _.sum(applyRWUDelta(data[joint].x, seriesFactor)) - center.x,
      y: _.sum(applyRWUDelta(data[joint].y, seriesFactor)) - center.y,
      z: _.sum(applyRWUDelta(data[joint].z, seriesFactor)) - center.z,
    };
    centerOfMass.x += blazePoseJointWeights[joint] * position.x;
    centerOfMass.y += blazePoseJointWeights[joint] * position.y;
    centerOfMass.z += blazePoseJointWeights[joint] * position.z;
    totalMass += blazePoseJointWeights[joint];
  });

  centerOfMass.x /= totalMass;
  centerOfMass.y /= totalMass;
  centerOfMass.z /= totalMass;

  centerOfMass.x /= data[23].x.length;
  centerOfMass.y /= data[23].y.length;
  centerOfMass.z /= data[23].z.length;

  centerOfMass.x = parseFloat(centerOfMass.x.toFixed(2));
  centerOfMass.y = parseFloat(centerOfMass.y.toFixed(2));
  centerOfMass.z = parseFloat(centerOfMass.z.toFixed(2));

  return centerOfMass;
}

export function cptSymmetryIndex(series) {
  if (series.length !== 2) return undefined;
  if (!series[0].data || !series[1].data) return undefined;
  const leftMean = _.mean(series[0].data);
  const rightMean = _.mean(series[1].data);
  return Math.abs(
    parseFloat(
      (((leftMean - rightMean) / (leftMean + rightMean)) * 100).toFixed(2)
    )
  );
}

export function cptROMSymmetryIndex(series) {
  if (series.length !== 2) return undefined;
  if (!series[0].data || !series[1].data) return undefined;
  const leftMin = Math.min(...series[0].data);
  const leftMax = Math.max(...series[0].data);
  const rightMin = Math.min(...series[1].data);
  const rightMax = Math.max(...series[1].data);
  return Math.abs(
    Number((((leftMax - leftMin) / (rightMax - rightMin)) * 100).toFixed(2))
  );
}

export function removeOutliers({ dataArray, getIndex = false }) {
  if (!dataArray || dataArray.length === 0) return [];
  // Sort the data
  const data = dataArray.slice();

  // Calculate Q1 (25th percentile) and Q3 (75th percentile)
  const dataWithoutNulls = data.filter((dataPoint) => dataPoint);
  const lowerBound = Math.max(
    1.1,
    simplePercentileBoundary(dataWithoutNulls, 0.5)
  );
  const upperBound = simplePercentileBoundary(dataWithoutNulls, 1.5);

  // Filter out any values more than 1.5 * IQR below Q1 or above Q3
  if (getIndex) {
    const res = dataArray
      .map((x, i) => (x >= lowerBound && x <= upperBound && x ? i : -1))
      .filter((x) => x !== -1);
    if (res.length === 0) {
      console.error(
        `res.length === 0 when cutting outliers: ${lowerBound} ${upperBound}, ${data}`
      );
      const lowerValues = dataArray
        .map((x, i) => (x <= upperBound && x ? i : -1))
        .filter((x) => x !== -1);
      return removeOutliers({ dataArray: lowerValues, getIndex: true });
    }
    return res;
  } else {
    const res = data.filter((x) => x >= lowerBound && x <= upperBound && x);
    if (res.length === 0) {
      console.error(
        `res.length === 0 when cutting outliers: ${lowerBound} ${upperBound}, ${data}`
      );
      const lowerValues = data.filter((x) => x <= upperBound && x);
      return removeOutliers({ dataArray: lowerValues });
    } else {
      return res;
    }
  }
}

export function removeOutliersOld({
  dataArray,
  getIndex = false,
  useQ1AsLower = true,
  outlierThresholdMin = GAIT_TIME_INTERVAL * 0.03,
  outlierThreshold = GAIT_TIME_INTERVAL * 0.2,
  log = false,
}) {
  if (!dataArray || dataArray.length === 0) return [];
  // Sort the data
  const data = dataArray
    .slice()
    .filter(
      (dataPoint) =>
        dataPoint <= outlierThreshold && dataPoint >= outlierThresholdMin
    ); // copy the array so we don't change the original
  data.sort((a, b) => a - b);
  if (log) console.log(dataArray, data);

  // Calculate Q1 (25th percentile) and Q3 (75th percentile)
  const q1 = percentile(data, 0.25);
  const q3 = percentile(data, 0.75);

  // Calculate the IQR
  const iqr = q3 - q1;

  // Define thresholds for lower and upper bound
  let lowerBoundComp = Math.max(q1 - 1.5 * iqr, 0); // lower bound can't be less than 0
  let lowerBound = useQ1AsLower ? q1 : lowerBoundComp;
  let upperBound = q3 + 1.5 * iqr;
  if (upperBound === lowerBound) {
    // lowerBound -= 1;
  }

  // Filter out any values more than 1.5 * IQR below Q1 or above Q3
  if (getIndex) {
    const res = dataArray
      .map((x, i) => (x >= lowerBound && x <= upperBound && x ? i : -1))
      .filter((x) => x !== -1);
    if (res.length === 0) {
      return dataArray.map((_, index) => index);
    } else {
      return res;
    }
  } else {
    if (log)
      console.log(
        'lowerBound, upperBound',
        outlierThreshold,
        lowerBound,
        upperBound,
        q1,
        q3,
        iqr,
        data
      );
    const res = data.filter((x) => x >= lowerBound && x <= upperBound && x);
    if (res.length === 0) {
      throw new Error(
        `res.length === 0 when cutting outliers: ${lowerBound} ${upperBound}, ${data}`
      );
    } else {
      return res;
    }
  }
}

function percentile(arr, p) {
  if (arr.length === 0) return 0;
  if (p <= 0) return arr[0];
  if (p >= 1) return arr[arr.length - 1];

  const sorted = arr.slice().sort((a, b) => a - b);
  const index = (sorted.length - 1) * p;
  const lower = Math.floor(index);
  const upper = lower + 1;
  const weight = index % 1;

  if (upper >= sorted.length) return sorted[lower];
  return sorted[lower] * (1 - weight) + sorted[upper] * weight;
}

export function simplePercentileBoundary(arr, p) {
  if (arr.length === 0) return 0;
  if (p <= 0) return arr[0];

  const mean = _.mean(arr);
  return mean * p;
}

export function standardDeviation(values) {
  let avg = _.mean(values);

  let squareDiffs = values.map(function (value) {
    let diff = value - avg;
    return diff * diff;
  });

  let avgSquareDiff = _.mean(squareDiffs);
  return parseFloat(((Math.sqrt(avgSquareDiff) / avg) * 100).toFixed(2));
}

export function divideSeries(series0, series1) {
  const dividedSeries = [];
  series0.forEach((item, index) => {
    if (item && series1[index]) dividedSeries.push(item / series1[index]);
  });
  return dividedSeries;
}

export function sumSeries(series0, series1) {
  const summedSeries = [];
  series0.forEach((item, index) => {
    if (item && series1[index]) summedSeries.push(item + series1[index]);
  });
  return summedSeries;
}

export const filterValidSteps = (
  targetArray,
  stepValidityArray,
  getIndex = false
) => {
  const filteredValues = targetArray.filter((_, i) => stepValidityArray[i]);
  if (getIndex) {
    const indexes = targetArray
      .map((_, i) => (stepValidityArray[i] ? i : -1))
      .filter((x) => x !== -1);
    return [filteredValues, indexes];
  }
  return filteredValues;
};

export function cptROMFromArrays(validStepCollection) {
  if (!validStepCollection) return 0;
  let min = 1000;
  let max = -1000;
  validStepCollection.forEach((stepArray) => {
    min = Math.min(min, Math.min(...stepArray));
    max = Math.max(max, Math.max(...stepArray));
  });
  return Math.abs(max - min).toFixed(2);
}

function cptROM(array) {
  if (!array) return;
  return Math.abs(Math.max(...array) - Math.min(...array)).toFixed(2);
}

export function cptGaitCycleOptimumDeviation(
  strideTimeLeft,
  stanceTimeLeft,
  strideTimeRight,
  stanceTimeRight
) {
  const pctLeft = stanceTimeLeft / (strideTimeLeft + stanceTimeLeft);
  const pctRight = stanceTimeRight / (strideTimeRight + stanceTimeRight);
  const stanceDeviationLeft = Math.abs(pctLeft - OPTIMAL_GAIT_CYCLE_PCT) * 100;
  const stanceDeviationRight =
    Math.abs(pctRight - OPTIMAL_GAIT_CYCLE_PCT) * 100;
  const stanceDeviation = (stanceDeviationRight + stanceDeviationLeft) / 2;
  return stanceDeviation.toFixed(2);
}

export function cptROMAll(collectionData) {
  return {
    rightLegROM: cptROMFromArrays(collectionData?.rightLegCollection),
    leftLegROM: cptROMFromArrays(collectionData?.leftLegCollection),
    rightKneeROM: cptROMFromArrays(collectionData?.rightKneeCollection),
    leftKneeROM: cptROMFromArrays(collectionData?.leftKneeCollection),
    rightAnkleROM: cptROMFromArrays(collectionData?.rightAnkleCollection),
    leftAnkleROM: cptROMFromArrays(collectionData?.leftAnkleCollection),
    rightHipROM: cptROMFromArrays(collectionData?.rightHipCollection),
    leftHipROM: cptROMFromArrays(collectionData?.leftHipCollection),
    rightHipRotationROM: cptROMFromArrays(
      collectionData?.rightHipRotationCollection
    ),
    leftHipRotationROM: cptROMFromArrays(
      collectionData?.leftHipRotationCollection
    ),
    rightHipBalanceROM: cptROMFromArrays(
      collectionData?.rightHipBalanceCollection
    ),
    leftHipBalanceROM: cptROMFromArrays(
      collectionData?.leftHipBalanceCollection
    ),
  };
}

export function cptMeanROMAll(graphData) {
  if (!graphData || graphData === {}) return;
  return {
    leftLegMeanROM: cptROM(graphData.leftLegMean),
    rightLegMeanROM: cptROM(graphData.rightLegMean),
    leftKneeMeanROM: cptROM(graphData.leftKneeMean),
    rightKneeMeanROM: cptROM(graphData.rightKneeMean),
    leftAnkleMeanROM: cptROM(graphData.leftAnkleMean),
    rightAnkleMeanROM: cptROM(graphData.rightAnkleMean),
    leftHipMeanROM: cptROM(graphData.leftHipMean),
    rightHipMeanROM: cptROM(graphData.rightHipMean),
    leftHipRotationMeanROM: cptROM(graphData.leftHipRotationMean),
    rightHipRotationMeanROM: cptROM(graphData.rightHipRotationMean),
    leftHipBalanceMeanROM: cptROM(graphData.leftHipBalanceMean),
    rightHipBalanceMeanROM: cptROM(graphData.rightHipBalanceMean),
  };
}

export function cptSeriesSIs(graphData) {
  return {
    kneeROMSI: cptROMSymmetryIndex(graphData.kneeChartData),
    kneeMeanSI: cptSymmetryIndex(graphData.kneeChartData),
    hipROMSI: cptROMSymmetryIndex(graphData.hipChartData),
    hipMeanSI: cptSymmetryIndex(graphData.hipChartData),
    kneeROMSIFront: cptROMSymmetryIndex(graphData.kneeChartDataFront),
    kneeMeanSIFront: cptSymmetryIndex(graphData.kneeChartDataFront),
    hipROMSIFront: cptROMSymmetryIndex(graphData.hipChartDataFront),
    hipMeanSIFront: cptSymmetryIndex(graphData.hipChartDataFront),
    hipRotationROMSI: cptROMSymmetryIndex(graphData.hipRotationChartData),
    hipRotationMeanSI: cptSymmetryIndex(graphData.hipRotationChartData),
    ankleROMSI: cptROMSymmetryIndex(graphData.ankleChartData),
    ankleMeanSI: cptSymmetryIndex(graphData.ankleChartData),
    hipBalanceROMSIFront: cptROMSymmetryIndex(
      graphData.hipBalanceChartDataFront
    ),
    hipBalanceMeanSIFront: cptSymmetryIndex(graphData.hipBalanceChartDataFront),
    hipRotationROMSIFront: cptROMSymmetryIndex(
      graphData.hipRotationChartDataFront
    ),
    hipRotationMeanSIFront: cptSymmetryIndex(
      graphData.hipRotationChartDataFront
    ),
  };
}

export const flattenSensorDataByKeys = (sensorData) => {
  try {
    const sensorByKey = { time: [] };
    sensorData.forEach((stepData) => {
      const stanceData = stepData.filter((data) => {
        if (!data.result) return false;
        return Object.keys(zonesLeft).some((key) => {
          if (data.result[key] === undefined) {
            console.warn(
              `Key ${key} not found in data.result`,
              Object.keys(data)
            );
            return false;
          }
          return Number(data.result[key]) !== 0;
        });
      });
      stanceData.forEach((observation) => {
        Object.keys(observation.result).forEach((key) => {
          if (!sensorByKey[key]) {
            sensorByKey[key] = [];
          }
          sensorByKey[key].push(observation.result[key]);
        });
        sensorByKey.time.push(observation.time);
      });
    });
    return sensorByKey;
  } catch (e) {
    console.log(e, sensorData);
  }
};

export const getMeanSensorDataByKeys = (sensorDataByKey) => {
  const sensorMeanByKey = {};
  Object.keys(sensorDataByKey).forEach((key) => {
    if (key !== 'time') {
      sensorMeanByKey[key] = _.mean(sensorDataByKey[key]);
    }
  });
  return sensorMeanByKey;
};

export const getTimeWindowList = (timeWindows) => {
  const timeWindowList = [];
  timeWindows.forEach((window) => {
    if (Number(window.end - window.start)) {
      timeWindowList.push(window.end - window.start);
    } else {
      timeWindowList.push(null);
    }
  });
  return timeWindowList;
};

export const pressureOnZonesOnly = (
  sensorObservation,
  targets,
  tolerance = 1
) => {
  let fulfilled = 0;
  Object.keys(sensorObservation).forEach((key) => {
    if (zonesLeft[key]) {
      if (targets.includes(key)) {
        if (sensorObservation[key] > PRESSURE_THRESHOLD) {
          fulfilled++;
        }
      } else {
        if (sensorObservation[key] <= PRESSURE_THRESHOLD) {
          fulfilled++;
        } else {
          fulfilled -= 1;
        }
      }
    }
  });
  return fulfilled >= Object.keys(zonesLeft).length - tolerance;
};

export const getStrikeDistribution = (sensorData) => {
  const heel = [];
  const mid = [];
  const front = [];

  sensorData.forEach((stepData) => {
    let stepHeel = 0;
    let stepMid = 0;
    let stepFront = 0;
    stepData.forEach((observation) => {
      if (observation.result === undefined) return;
      if (pressureOnZonesOnly(observation.result, ['heel', 'heel2'])) {
        stepHeel++;
        return;
      } else if (
        pressureOnZonesOnly(
          observation.result,
          ['front', 'front_2', 'toes', 'hallux'],
          3
        )
      ) {
        stepFront++;
        return;
      } else if (
        pressureOnZonesOnly(observation.result, Object.keys(zonesLeft), 6)
      ) {
        stepMid++;
        return;
      }
      // console.log(
      //   observation.result,
      //   'not found',
      //   pressureOnZonesOnly(observation.result, Object.keys(zonesLeft))
      // );
    });
    const countingObservations = stepHeel + stepMid + stepFront;
    heel.push(stepHeel / countingObservations);
    mid.push(stepMid / countingObservations);
    front.push(stepFront / countingObservations);
  });
  return { heel: _.mean(heel), mid: _.mean(mid), front: _.mean(front) };
};
