import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useHistoricalBergResults } from '../../../../../hooks/BergBalance/results';
import ControlledDateRangePicker from '../../ControlledDateRangePicker';
import { HistoricalResultsBox } from '../../HistoricalResultsBox';

HistoricalResultsBox.propTypes = {
  dataKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  name: PropTypes.string,
};
export default function HistoricalResults() {
  const [dateRange, setDateRange] = React.useState([
    dayjs().subtract(1, 'month'),
    dayjs(),
  ]);
  const { type } = useParams();
  const { bergBalanceLastResults } = useHistoricalBergResults({
    startDate: dateRange[0],
    endDate: dateRange[1],
    type: type,
  });

  return (
    <div>
      <Paper sx={{ paddingBottom: 2, marginBottom: -2 }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '50%',
              border: 0.5,
              borderRadius: 5,
              padding: 3,
              boxShadow: 3,
              mt: 1,
            }}
          >
            <ControlledDateRangePicker
              value={dateRange}
              setValue={setDateRange}
            />
          </Box>
        </div>
        <Box sx={{ height: '75vh' }}>
          <Grid container spacing={2} sx={{ padding: 2, height: '50%' }}>
            <Grid item xs={12}>
              <HistoricalResultsBox
                data={bergBalanceLastResults}
                name={'Score'}
                dataKey={'score'}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
