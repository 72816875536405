const initialState = {};

export const chatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHATS': {
      return { values: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const chatsstudsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHAT_STUDS': {
      return { values: action.payload };
    }
    default: {
      return state;
    }
  }
};
