import * as React from 'react';
import { useSelector } from 'react-redux';
import { useBalanceCoreFeedback } from '../../../../hooks/Demo/feedbackTypes';
import { loadExerciseConfigAudio } from '../../../../utilities/Demo/audioFeedback';
import { balanceScore } from '../../../../utilities/Demo/ExerciseManagement/Generators/scoreComputations';
import FeedbackGenerator from './FeedbackGenerator';

export default function BalanceFeedbackGenerator({
  onComplete,
  config,
  showWebcam = false,
}) {
  const poseState = React.useRef(false);
  const iterLanguage = useSelector(({ iter }) => iter.values.language);
  const {
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateSecondsPoseHeld,
    playAudio,
    audioFeedback,
    seconds,
    cptScore,
    updatePoseHeldDeps,
    finishedIntro,
  } = useBalanceCoreFeedback({
    onComplete,
    timeThresh: config.timeThresh,
    loadAudio: () => loadExerciseConfigAudio(config, iterLanguage),
    maxScore: config.scoreConfig?.maxScore || 10,
  });

  cptScore.current = balanceScore({
    config,
    secondsPoseHeldComplete,
    secondsPoseHeld,
  });

  const feedbackCallback = () => {
    holdsPose.current = false;
  };

  const onCorrect = () => {
    updateFeedback('correct!');
    console.log(
      'correct',
      seconds.current,
      secondPoseHeldStarted.current,
      seconds.current - secondPoseHeldStarted.current
    );
    holdsPose.current = true;
  };

  const endCallback = () => {
    updatePoseHeldDeps(poseState.current);
    updateSecondsPoseHeld(
      secondsPoseHeldComplete.current + secondsPoseHeld.current
    );
    poseState.current = Boolean(holdsPose.current);
  };

  return (
    <FeedbackGenerator
      updateFeedback={updateFeedback}
      config={config}
      finishedIntro={finishedIntro}
      onCorrect={onCorrect}
      feedbackCallback={feedbackCallback}
      endCallback={endCallback}
      playAudio={playAudio}
      audioFeedback={audioFeedback}
      showWebcam={showWebcam}
    />
  );
}
