import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function InsoleConnectionStatus({ deviceConnected, side }) {
  return (
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'left',
        display: 'flex',
      }}
    >
      <Typography>{`${side} connected`}</Typography>
      <FiberManualRecordIcon
        sx={{ color: deviceConnected ? 'green' : 'red' }}
      />
    </Box>
  );
}
