import _ from 'lodash';

export const countScore = ({ config, correctCount }) => {
  if (config.scoreConfig?.scoreType === 'counts') {
    return simpleCountsScore({ config, correctCount });
  } else if (config.scoreConfig?.scoreType === 'countsInTime') {
    return countsInTimeScore({ config, correctCount });
  } else {
    return () => (correctCount.current / config.timeThresh) * 2;
  }
};

const simpleCountsScore = ({ config, correctCount }) => {
  return () => {
    return correctCount.current / config.scoreConfig.cptConfig.maxCounts;
  };
};

const countsInTimeScore = ({ config, correctCount }) => {
  return () => {
    const fullScore =
      config.scoreConfig.cptConfig.maxCounts /
      config.scoreConfig.cptConfig.time;
    const score = correctCount.current / config.scoreConfig.cptConfig.time;
    return score / fullScore;
  };
};
export const balanceScore = ({
  config,
  secondsPoseHeldComplete,
  secondsPoseHeld,
}) => {
  if (config.scoreConfig?.scoreType === 'poseHeld') {
    return simplePoseHeldScore({
      config,
      secondsPoseHeldComplete,
      secondsPoseHeld,
    });
  } else {
    return () =>
      (secondsPoseHeldComplete.current + secondsPoseHeld.current) /
      (config.timeThresh * 2);
  }
};

const simplePoseHeldScore = ({
  config,
  secondsPoseHeldComplete,
  secondsPoseHeld,
}) => {
  return () =>
    (secondsPoseHeldComplete.current + secondsPoseHeld.current) /
    config.scoreConfig.cptConfig.secondsPoseHeld;
};

export const normStretch = ({ config, targetRef }) => {
  if (targetRef >= config.scoreConfig.cptConfig.maxStretch) return 0;
  return _.clamp(
    targetRef - config.scoreConfig.cptConfig.minStretch,
    0,
    config.scoreConfig.cptConfig.maxStretch
  );
};

export const stretchScore = ({ config, targetRef }) => {
  const normedStretch = normStretch({ config, targetRef });
  if (normedStretch === 0) return 0;
  const fullStretch = Math.abs(
    config.scoreConfig.cptConfig.maxStretch -
      config.scoreConfig.cptConfig.minStretch
  );
  if (config.stretchTargetExtreme === 'max') return normedStretch / fullStretch;
  else return 1 - normedStretch / fullStretch;
};
