import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 10,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 12,
    fontWeight: 'extrabold',
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
  tableCellGreen: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    color: 'green', // Green text color
  },
  tableCellRed: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    color: 'red', // Red text color
  },
  section: {
    margin: 10,
    padding: 10,
    height: 'auto',
    // flexGrow: 1,
  },
});

const getSubMetricValueStyle = (color) => {
  switch (color) {
    case 'green':
      return styles.tableCellGreen;
    case 'red':
      return styles.tableCellRed;
    default:
      return styles.tableCell;
  }
};

// Create Document Component
const ResultsPdf = ({ tableData, personInfo, images }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Name: {personInfo.name} </Text>
          <Text>Age: {personInfo.age} </Text>
        </View>
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Exercise</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Metric</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Sub Metric</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Value</Text>
            </View>
          </View>

          {/* Table Rows */}
          {tableData.map((rowData, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{rowData.exerciseName}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{rowData.metricName}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{rowData.subMetricName}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={getSubMetricValueStyle(rowData.subMetricColor)}>
                  {rowData.subMetricValue}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
      {/* Images */}
      {Object.keys(images).map((key) => {
        return (
          <Page key={key} style={styles.section}>
            <Text>{key}</Text>
            <Image
              src={images[key]}
              style={{ marginTop: '50pt', padding: '20px' }}
            />
          </Page>
        );
      })}
    </Document>
  );
};

export default ResultsPdf;
