import {
  addDoc,
  collection,
  doc,
  getDocs,
  deleteDoc,
  limit,
  orderBy,
  getDoc,
  query,
  Timestamp,
  updateDoc,
  where,
  setDoc,
} from 'firebase/firestore';
import { db } from '../../../../firebase';

export const filterUndefined = (payload) => {
  const filteredPayload = Array.isArray(payload) ? [] : {};
  Object.keys(payload).forEach((key) => {
    if (payload[key] !== undefined) {
      if (payload[key] !== null && typeof payload[key] === 'object') {
        // if the current property is an object or array, recurse
        filteredPayload[key] = filterUndefined(payload[key]);
      } else {
        // if it's not an object or array, just copy the value
        filteredPayload[key] = payload[key];
      }
    } else {
      filteredPayload[key] = null;
    }
  });
  return filteredPayload;
};

export const addPatient = async ({ userId, patientData }) => {
  patientData.id = patientData.id || null;
  try {
    const timeStamp = Timestamp.now();
    patientData = {
      doctorId: userId,
      createdBy: userId,
      createdAt: timeStamp,
      updatedAt: timeStamp,
      updatedBy: userId,
      ...patientData,
    };
    const docRef = await addDoc(collection(db, 'patients'), patientData);
    console.log('Patient added with ID: ', docRef.id);
    return { timeStamp, userId, ...patientData, id: docRef.id };
  } catch (e) {
    console.error('Error adding patient: ', e, patientData);
  }
};

export const updatePatient = async ({ userId, patientData }) => {
  patientData.updatedAt = Timestamp.now();
  patientData.updatedBy = userId;
  try {
    const docRef = doc(db, 'patients', patientData.id);
    await updateDoc(docRef, patientData);
    console.log('Patient updated with ID: ', patientData.id, patientData);
    return patientData;
  } catch (e) {
    console.error('Error updating patient: ', e, patientData);
  }
};

export const deleteAllPatientResults = async ({ patientId, userId }) => {
  try {
    const physioResultsQuery = query(
      collection(db, 'physio_results'),
      where('doctorId', '==', userId),
      where('patientId', '==', patientId)
    );
    const physioResultsSnapshot = await getDocs(physioResultsQuery);
    physioResultsSnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });
    console.log('Patient results deleted with ID: ', patientId);
    return true;
  } catch (e) {
    console.error('Error deleting patient results: ', e);
  }
};

export const deletePatient = async ({ id }) => {
  try {
    await deleteDoc(doc(db, 'patients', id));
    console.log('Patient deleted with ID: ', id);
    return true;
  } catch (e) {
    console.error('Error deleting patient: ', e);
  }
};

export const getPatient = async ({ id }) => {
  try {
    const docRef = doc(db, 'patients', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      return { ...docSnap.data(), id: docSnap.id };
    } else {
      console.log('No such document!');
    }
  } catch (e) {
    console.error('Error getting patient: ', e);
  }
};

export const getPatients = async ({ userId }) => {
  try {
    const snapshot = await getDocs(
      query(collection(db, 'patients'), where('doctorId', '==', userId))
    );
    if (snapshot.empty) {
      console.log('No matching documents.');
      return;
    }
    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt.toDate().toString(),
        updatedAt: doc.data().updatedAt.toDate().toString(),
      };
    });
  } catch (e) {
    console.error('Error getting patients: ', e);
  }
};

export const addResult = async ({
  userId,
  patientId,
  exerciseType,
  data = {},
  id = null,
}) => {
  const timeStamp = Timestamp.now();
  data = {
    doctorId: userId,
    patientId: patientId,
    timeStamp,
    createdBy: userId,
    exerciseType: exerciseType,
    ...data,
  };
  console.log('addResult with', data);
  try {
    let docRef;
    if (id === null) {
      docRef = await addDoc(collection(db, 'physio_results'), data);
    } else {
      docRef = doc(db, 'physio_results', id);
      await setDoc(docRef, data);
    }
    console.log('Document written with ID: ', docRef.id);
    return { id: docRef.id, timeStamp, userId };
  } catch (e) {
    console.error('Error adding document: ', e, data);
  }
};

export const updateResult = async ({ id, payload }) => {
  if (!payload) return;
  try {
    const docRef = doc(db, 'physio_results', id);
    await updateDoc(docRef, filterUndefined(payload));
    console.log('Document updated with ID: ', id, payload);
  } catch (e) {
    console.error('Error updating document: ', e, id, payload);
  }
};

export const getLastResults = async ({
  patientId,
  userId,
  exerciseType,
  startDate = undefined,
  endDate = undefined,
  n = 1,
}) => {
  console.log(
    'getLastResults',
    patientId,
    startDate,
    endDate,
    n,
    exerciseType,
    userId
  );
  try {
    const snapshot = await getDocs(
      query(
        collection(db, 'physio_results'),
        where('exerciseType', '==', exerciseType),
        where('doctorId', '==', userId),
        where('patientId', '==', patientId),
        startDate &&
          where('timeStamp', '>=', Timestamp.fromDate(new Date(startDate))),
        endDate &&
          where('timeStamp', '<=', Timestamp.fromDate(new Date(endDate))),
        orderBy('timeStamp', 'desc'),
        limit(n)
      )
    );
    if (snapshot.empty) {
      console.log('No matching documents.');
      return;
    }
    return snapshot.docs
      .map((doc) => {
        console.log('getLastResults', doc.id, doc.data());
        return {
          id: doc.id,
          ...doc.data(),
          timeStamp: doc.data().timeStamp.toDate().toString(),
        };
      })
      .filter((doc) => doc.metrics);
  } catch (e) {
    console.error('Error getting document: ', e);
  }
};
