//#F0ECEB
import React, { useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
// import div from "@mui/material/Container";
import { useState } from 'react';
import RankingBg from '../../components/Ranking/RankingBg';
import RankingComponent from '../../components/Ranking/RankingComponent';
import AllStudents from '../../components/Ranking/AllStudents';
import '../../components/Ranking/ranking.css';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

const Students = () => {
  const [open, setOpen] = useState(false);
  const [load] = useState(false);
  // getRankings(dispatch,(a)=>a);
  const cstuds = useSelector(({ cstuds }) => cstuds.values);
  useEffect(() => {
    console.log('Cstuds::', cstuds);
    //getClassStuds(dispatch,class_id,'ALL', setLoad)
  }, []);
  return (
    <div className="ranking-container">
      <HeaderComponent bgcol={'#F0ECEB'} />
      <RankingBg />
      <DrawerComponent open={open} setOpen={setOpen} />

      <div className="ranking-grid">
        {/* <div item lg={1} xs={1} md={1} sm={1}></div> */}
        {/* <div item lg={10} xs={10} md={10} sm={10}> */}
        {load ? (
          <center>
            <CircularProgress
              size={40}
              color="success"
              sx={{ justifyContent: 'center' }}
            />
          </center>
        ) : (
          <>
            <RankingComponent
              details={cstuds && cstuds.usr_ar}
              page={'Students'}
            />
            <AllStudents details={cstuds && cstuds.usr_ar} />
          </>
        )}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Students;
