import { drawAngle } from '../../angles';
import { leftShoulderArc, rightShoulderArc } from '../../arcs';
import {
  drawExtendedLine,
  findAdjustedAngle,
  findAngle,
  findCounterAngle,
  findDependentAngle,
} from '../../physio';
import { blazePoseCoordinateNames } from './coordinates';

const leftKnee = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const leftKneeAngle = findDependentAngle(
    results.poseLandmarks[23],
    results.poseLandmarks[25],
    results.poseLandmarks[27]
  );
  if (display) {
    drawAngle(canvasCtx, leftKneeAngle, results.poseLandmarks[25], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[25],
      left: true,
    };
  }
  return [leftKneeAngle, displayInfo];
};

const rightKnee = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const rightKneeAngle = findDependentAngle(
    results.poseLandmarks[24],
    results.poseLandmarks[26],
    results.poseLandmarks[28]
  );
  if (display) {
    drawAngle(canvasCtx, rightKneeAngle, results.poseLandmarks[26], false);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[26],
      left: false,
    };
  }
  return [rightKneeAngle, displayInfo];
};

const neck = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const midShoulder = blazePoseCoordinateNames.midShoulder(results);
  // const midHip = blazePoseCoordinateNames.midHip(results);
  // const neckAngle =
  //   findDependentAngle(midHip, midShoulder, results.poseLandmarks[0], true) -
  //   90;
  const neckAngle = findAngle(midShoulder, results.poseLandmarks[0], 0);
  if (display) {
    drawAngle(canvasCtx, neckAngle, midShoulder, false);
  } else {
    displayInfo = {
      coord: midShoulder,
      left: false,
    };
  }
  return [neckAngle, displayInfo];
};

const spine = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const midShoulder = blazePoseCoordinateNames.midShoulder(results);
  const midHip = blazePoseCoordinateNames.midHip(results);
  const midBody = {
    x: (midShoulder.x + midHip.x) / 2,
    y: (midShoulder.y + midHip.y) / 2,
    z: (midShoulder.z + midHip.z) / 2,
  };

  const spineAngle = findAngle(midShoulder, midHip, 0);
  if (display) {
    drawAngle(canvasCtx, spineAngle, midBody, false);
  } else {
    displayInfo = {
      coord: midBody,
      left: false,
    };
  }
  return [spineAngle, displayInfo];
};

const hipBalance = ({ canvasCtx, results, display }) => {
  return drawExtendedLine(
    canvasCtx,
    results.poseLandmarks[23],
    results.poseLandmarks[24],
    100,
    display
  );
};

const elbowBalance = ({ canvasCtx, results, display }) => {
  return drawExtendedLine(
    canvasCtx,
    results.poseLandmarks[13],
    results.poseLandmarks[14],
    100,
    display
  );
};

const shoulderBalance = ({ canvasCtx, results, display }) => {
  return drawExtendedLine(
    canvasCtx,
    results.poseLandmarks[11],
    results.poseLandmarks[12],
    100,
    display
  );
};

const kneeBalance = ({ canvasCtx, results, display }) => {
  return drawExtendedLine(
    canvasCtx,
    results.poseLandmarks[25],
    results.poseLandmarks[26],
    100,
    display
  );
};

const leftThumpWrist = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findAngle(results.poseLandmarks[21], results.poseLandmarks[15]);
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[21], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[21],
      left: true,
    };
  }
  return [angle, displayInfo];
};

const rightThumpWrist = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findAngle(results.poseLandmarks[22], results.poseLandmarks[16]);
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[22], false);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[22],
      left: false,
    };
  }
  return [angle, displayInfo];
};

const leftElbowWrist = ({ canvasCtx, results, display }) => {
  let displayInfo;
  let angle =
    360 -
    findAdjustedAngle(results.poseLandmarks[13], results.poseLandmarks[15], 90);
  angle = Math.abs(angle);
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[15], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[15],
      left: true,
    };
  }
  return [angle, displayInfo];
};

const rightElbowWrist = ({ canvasCtx, results, display }) => {
  let displayInfo;
  let angle = findAdjustedAngle(
    results.poseLandmarks[14],
    results.poseLandmarks[16],
    90
  );
  angle = Math.abs(angle);
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[16], false);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[16],
      left: false,
    };
  }
  return [angle, displayInfo];
};

const leftShoulderElbowWrist = ({ canvasCtx, results, display }) => {
  let displayInfo;
  let angle = findDependentAngle(
    results.poseLandmarks[11],
    results.poseLandmarks[13],
    results.poseLandmarks[15]
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[13], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[13],
      left: true,
    };
  }
  return [angle, displayInfo];
};

const rightShoulderElbowWrist = ({ canvasCtx, results, display }) => {
  let displayInfo;
  let angle = findDependentAngle(
    results.poseLandmarks[12],
    results.poseLandmarks[14],
    results.poseLandmarks[16],
    true
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[14], false);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[14],
      left: false,
    };
  }
  return [angle, displayInfo];
};

const rightShoulder = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = rightShoulderArc(canvasCtx, results, display);
  if (!display) {
    displayInfo = {
      coord: results.poseLandmarks[14],
      left: false,
    };
  }
  return [angle, displayInfo];
};

const leftShoulder = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = leftShoulderArc(canvasCtx, results, display);
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[13], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[13],
      left: true,
    };
  }
  return [angle, displayInfo];
};

const leftShoulderElbow = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findAdjustedAngle(
    results.poseLandmarks[11],
    results.poseLandmarks[13]
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[11], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[11],
      left: true,
    };
  }
  return [angle, displayInfo];
};

const rightShoulderElbow = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle =
    360 -
    findAdjustedAngle(results.poseLandmarks[12], results.poseLandmarks[14]);
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[12], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[12],
      left: false,
    };
  }
  return [angle, displayInfo];
};

const leftHipKnee = ({ canvasCtx, results, display }) => {
  let displayInfo;
  // const angle = leftHipArc(canvasCtx, results, display);
  const angle = findAdjustedAngle(
    results.poseLandmarks[23],
    results.poseLandmarks[25],
    90
  );
  if (!display) {
    displayInfo = {
      coord: results.poseLandmarks[23],
      left: true,
    };
  } else {
    drawAngle(canvasCtx, angle, results.poseLandmarks[23], true);
  }
  return [angle, displayInfo];
};

const rightHipKnee = ({ canvasCtx, results, display }) => {
  let displayInfo;
  // const angle = rightHipArc(canvasCtx, results, display);
  const angle =
    360 -
    findAdjustedAngle(results.poseLandmarks[24], results.poseLandmarks[26], 90);
  if (!display) {
    displayInfo = {
      coord: results.poseLandmarks[24],
      left: false,
    };
  } else {
    drawAngle(canvasCtx, angle, results.poseLandmarks[24], false);
  }
  return [angle, displayInfo];
};

const leftShoulderShoulderElbow = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findDependentAngle(
    results.poseLandmarks[12],
    results.poseLandmarks[11],
    results.poseLandmarks[13],
    false,
    -90
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[13], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[13],
      left: true,
    };
  }
  return [angle, displayInfo];
};

const rightShoulderShoulderElbow = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findDependentAngle(
    results.poseLandmarks[11],
    results.poseLandmarks[12],
    results.poseLandmarks[14],
    true,
    90
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[14], false);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[14],
      left: false,
    };
  }
  return [angle, displayInfo];
};

const shouldersMidEars = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findDependentAngle(
    results.poseLandmarks[11],
    blazePoseCoordinateNames.midEar(results),
    results.poseLandmarks[12]
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[0], false);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[0],
      left: false,
    };
  }
  return [angle, displayInfo];
};

const leftElbowMidShoulder = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findAngle(
    results.poseLandmarks[11],
    blazePoseCoordinateNames.midShoulder(results)
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[11], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[11],
      left: true,
    };
  }
  return [angle, displayInfo];
};

const rightElbowMidShoulder = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findCounterAngle(
    results.poseLandmarks[12],
    blazePoseCoordinateNames.midShoulder(results)
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[12], false);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[12],
      left: false,
    };
  }
  return [angle, displayInfo];
};

const leftHipKneeAnkle = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findDependentAngle(
    results.poseLandmarks[23],
    results.poseLandmarks[25],
    results.poseLandmarks[27]
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[25], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[25],
      left: true,
    };
  }
  return [angle, displayInfo];
};

const rightHipKneeAnkle = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findDependentAngle(
    results.poseLandmarks[24],
    results.poseLandmarks[26],
    results.poseLandmarks[28],
    true
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[26], false);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[26],
      left: false,
    };
  }
  return [angle, displayInfo];
};

const leftShoulderElbowHip = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findDependentAngle(
    results.poseLandmarks[11],
    results.poseLandmarks[13],
    results.poseLandmarks[23]
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[13], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[13],
      left: true,
    };
  }
  return [angle, displayInfo];
};

const rightShoulderElbowHip = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findDependentAngle(
    results.poseLandmarks[12],
    results.poseLandmarks[14],
    results.poseLandmarks[24],
    true
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[14], false);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[14],
      left: false,
    };
  }
  return [angle, displayInfo];
};

const leftKneeAnkle = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle =
    360 -
    findAdjustedAngle(results.poseLandmarks[27], results.poseLandmarks[29]);
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[29], true);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[29],
      left: true,
    };
  }
  return [angle, displayInfo];
};

const rightKneeAnkle = ({ canvasCtx, results, display }) => {
  let displayInfo;
  const angle = findAdjustedAngle(
    results.poseLandmarks[28],
    results.poseLandmarks[30]
  );
  if (display) {
    drawAngle(canvasCtx, angle, results.poseLandmarks[30], false);
  } else {
    displayInfo = {
      coord: results.poseLandmarks[30],
      left: false,
    };
  }
  return [angle, displayInfo];
};

export const angleFrontComputations = {
  rightShoulder: rightShoulder,
  leftShoulder: leftShoulder,
  rightHipKnee: rightHipKnee,
  leftHipKnee: leftHipKnee,
  leftShoulderElbow,
  rightShoulderElbow,
  leftShoulderShoulderElbow,
  rightShoulderShoulderElbow,
  leftKnee,
  rightKnee,
  neck,
  spine,
  kneeBalance,
  hipBalance,
  shoulderBalance,
  elbowBalance,
  leftThumpWrist,
  rightThumpWrist,
  leftElbowWrist,
  rightElbowWrist,
  leftShoulderElbowWrist,
  rightShoulderElbowWrist,
  shouldersMidEars,
  leftElbowMidShoulder,
  rightElbowMidShoulder,
  leftHipKneeAnkle,
  rightHipKneeAnkle,
  leftShoulderElbowHip,
  rightShoulderElbowHip,
  leftKneeAnkle,
  rightKneeAnkle,
};
