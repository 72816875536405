import * as React from 'react';

import Button from '@mui/material/Button';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import './schedule.css';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import { baseUrl } from '../../constants';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CustomDialog from '../Dialog';

const TomSessionB2cComponent = () => {
  const { t } = useTranslation();
  let { email, user_details } = JSON.parse(localStorage.getItem('user_data'));
  const [load] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [msg] = React.useState('');
  const [eventDate, setEventDate] = React.useState('');
  const [eventstartDate, setEventStartDate] = React.useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [allEvents, setAllEvents] = useState([]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchEvent = async (data) => {
    const url = `${baseUrl}/event-details-user`;
    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        let fetchedDate =
          response.data.event_details_calendar_fetch[0].meeting_start;
        const newStartDate = fetchedDate.split('T')[0];
        const newEventStartDate = fetchedDate.split('T')[1].split('.')[0];
        setEventDate(newStartDate);
        setEventStartDate(newEventStartDate);
        setAllEvents(response.data.event_details_calendar_fetch);
      } else {
        console.log('Something went wrong while fetching the event!!');
      }
    } catch (error) {
      console.log('Error', error ? error.message : error);
    }
  };

  useEffect(() => {
    fetchEvent({ email });
  }, []);

  React.useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, [10000]);
    }
  }, [showAlert]);

  return (
    <div className="tomorrow-session-container">
      {showAlert && (
        <Alert
          severity="error"
          variant="filled"
          onClose={() => setShowAlert(false)}
        >
          <strong>{msg}</strong>
        </Alert>
      )}
      <div className="box-container">
        <div className="today-session-box">
          {t('TomSessionComponent.upcoming')}
        </div>
      </div>

      <div className="list list-container">
        <div className="nextsesleft">
          <EventNoteRoundedIcon />
          <span>{t('TomSessionComponent.date')}</span>
        </div>
        <div className="right rightdate">{eventDate}</div>
        <div className="nextsesleft">
          <AccessTimeRoundedIcon />
          <span className="left">{t('TomSessionComponent.start')}</span>
        </div>
        <div className="right rightstart">{eventstartDate}</div>
      </div>
      <p>
        <div className="schedule-divider"></div>
      </p>
      <div className="join-session-btn-container">
        {load ? (
          <CircularProgress size={45} color="success" />
        ) : (
          <Button
            type="submit"
            variant="contained"
            className="join-session-btn"
            disabled={user_details.membership === 'trial'}
            // onClick={() => fetchEvent({ email }, "join")}
            onClick={handleOpenDialog}
            sx={{
              width: 400,
              borderRadius: 2,
              fontSize: '1.5rem',
              backgroundColor: '#FF592C',
            }}
          >
            {t('SessionComponent.join')}
          </Button>
        )}
      </div>
      <div className="custom-dialog">
        <CustomDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          title="Join from booked sessions!"
          actions={[{ label: 'Cancel', onClick: handleCloseDialog }]}
        >
          {allEvents &&
            allEvents.map((e, index) => (
              <Button
                key={'join-session-btn' + index}
                type="submit"
                variant="contained"
                className="join-session-btn"
                sx={{
                  width: 'auto',
                  height: 'auto',
                  borderRadius: 2,
                  fontSize: '0.8rem',
                  backgroundColor: '#FF592C',
                }}
              >
                <a target="_blank" href={e.meeting_link} rel="noreferrer">
                  {e.meeting_start.split('T')[0]} -{' '}
                  {e.meeting_start.split('T')[1].split('.')[0]}
                </a>
              </Button>
            ))}
          {allEvents.length === 0 && <p>No session available to Join!</p>}
        </CustomDialog>
      </div>
    </div>
  );
};

export default TomSessionB2cComponent;
