//#F0ECEB
import React, { useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
// import div from "@mui/material/Container";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RankingBg from '../../components/Ranking/RankingBg';
import RankingComponent from '../../components/Ranking/RankingComponent';
import AllStudents from '../../components/Ranking/AllStudents';
import { getRankings } from '../../redux/actions/getDashboard';
import '../../components/Ranking/ranking.css';

const Ranking = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const ranks = useSelector(({ ranks }) => ranks.values);
  useEffect(() => {
    console.log('Ranks::', ranks);
    if (!ranks) {
      getRankings(dispatch, (a) => a);
    }
  }, []);
  return (
    <div className="ranking-container">
      <HeaderComponent bgcol={'#F0ECEB'} />
      <RankingBg />
      <DrawerComponent open={open} setOpen={setOpen} />

      <div className="ranking-grid">
        <RankingComponent details={ranks} />
        <AllStudents details={ranks} />
      </div>
    </div>
  );
};

export default Ranking;
