import Phaser from 'phaser';

class KickboxingScene extends Phaser.Scene {
  constructor() {
    super({ key: 'KickboxingGame' });
    this.pointsLeft = [];
    this.pointsRight = [];
    this.goodObjects = [];
    this.badObjects = [];
    this.slashesLeft = undefined;
    this.slashesRight = undefined;
    this.score = 0;
    this.lostLives = 0;
    this.fireRate = 100;
    this.nextFire = 0;
    this.contactPoint = new Phaser.Math.Vector2();
  }

  preload() {
    let graphics = this.make.graphics({ x: 0, y: 0, add: false });
    graphics.fillStyle(0x00ff00, 1); // color, alpha
    graphics.fillCircle(50, 50, 50); // x, y, radius
    graphics.generateTexture('good', 200, 100); // key, width, height

    // Create a red circle texture
    graphics.clear();
    graphics.fillStyle(0xff0000, 1);
    graphics.fillCircle(32, 32, 32);
    graphics.generateTexture('bad', 64, 64);
  }

  create() {
    this.events.emit('ready');
    const obj = this.physics.add.image(
      Phaser.Math.Between(100, 700),
      600,
      'toast'
    );
    obj.setVelocity(
      Phaser.Math.Between(-200, 200),
      Phaser.Math.Between(-200, 200)
    );
    this.physics.world.setBounds(
      0,
      0,
      Number(this.sys.game.config.width),
      Number(this.sys.game.config.height)
    );

    this.goodObjects = this.createGroup(4, 'good');
    this.badObjects = this.createGroup(2, 'bad');
    this.slashesLeft = this.add.graphics();
    this.slashesRight = this.add.graphics();

    this.add.graphics({ fillStyle: { color: 0xff0000 } });
    // graphics.fillRect(this.input.x, this.input.y, 50, 50);

    this.throwObject();
  }

  createGroup(amount, textureKey) {
    return this.physics.add.group({
      key: textureKey,
      repeat: amount - 1, // Phaser 3 counts the initial sprite + repeat amount.
      setXY: { y: 600, stepX: 70 },
    });
  }

  updateCoordinates(leftPoint, rightPoint) {
    try {
      this.pointsRight.push({
        x: rightPoint.x * this.sys.game.config.width,
        y: rightPoint.y * this.sys.game.config.height,
      });
      this.pointsRight = this.pointsRight.splice(
        this.pointsRight.length - 10,
        this.pointsRight.length
      );
      this.pointsLeft.push({
        x: leftPoint.x * this.sys.game.config.width,
        y: leftPoint.y * this.sys.game.config.height,
      });
      this.pointsLeft = this.pointsLeft.splice(
        this.pointsLeft.length - 10,
        this.pointsLeft.length
      );
    } catch (error) {
      console.error('Error in updateCoordinates:', error);
    }
  }

  updateSide(points, slashes) {
    console.log('points', points);
    try {
      // points = points.splice(points.length - 10, points.length);

      if (points.length < 1 || points[0].x === 0) {
        return;
      }

      slashes.clear();

      slashes.fillStyle(0xffffff, 1);
      slashes.beginPath();
      slashes.moveTo(points[0].x, points[0].y);
      for (let i = 1; i < points.length; i++) {
        slashes.lineTo(points[i].x, points[i].y);
      }

      slashes.closePath();
      slashes.fillPath();

      for (let i = 1; i < points.length; i++) {
        slashes.lineBetween(
          points[i - 1].x,
          points[i - 1].y,
          points[i].x,
          points[i].y
        );

        this.goodObjects.getChildren().forEach((fruit) => {
          if (fruit.active) {
            this.checkIntersects(fruit, points[i - 1], points[i], 'good');
          }
          console.log(this.sys.game.config.height, fruit.y);
          if (
            fruit.y > this.sys.game.config.height ||
            fruit.y < 0 ||
            fruit.x > this.sys.game.config.width ||
            fruit.x < 0
          ) {
            fruit.setActive(false).setVisible(false);
            // console.log('fruit', fruit, goodObjects);
          }
        });

        this.badObjects.getChildren().forEach((fruit) => {
          if (fruit.active) {
            this.checkIntersects(fruit, points[i - 1], points[i], 'bad');
          }
          if (
            fruit.y > this.sys.game.config.height ||
            fruit.y < 0 ||
            fruit.x > this.sys.game.config.width ||
            fruit.x < 0
          ) {
            fruit.setActive(false).setVisible(false);
          }
        });
      }
    } catch (error) {
      console.error('Error in update:', error);
    }
  }

  update() {
    try {
      this.throwObject();
      this.updateSide(this.pointsLeft, this.slashesLeft);
      this.updateSide(this.pointsRight, this.slashesRight);
    } catch (error) {
      console.error('Error in update:', error);
    }
  }

  throwObject() {
    if (
      this.time.now > this.nextFire &&
      (this.goodObjects.countActive(true) <
        this.goodObjects.getChildren().length ||
        this.badObjects.countActive(true) <
          this.badObjects.getChildren().length)
    ) {
      this.nextFire = this.time.now + this.fireRate;
      this.throwGoodObject();
      if (Math.random() > 0.5) {
        this.throwBadObject();
      }
    }
  }

  throwGoodObject() {
    const obj = this.goodObjects.getFirstDead(false);
    if (obj) {
      obj.setPosition(
        this.sys.game.config.width / 2 +
          Math.random() * 100 -
          Math.random() * 100,
        600
      );

      // Activate and show the object
      obj.setActive(true).setVisible(true);

      // This moves the object toward a position with a certain speed
      this.physics.moveTo(
        obj,
        this.sys.game.config.width / 2,
        this.sys.game.config.height / 2,
        530
      );
    }
  }

  throwBadObject() {
    const obj = this.badObjects.getFirstDead(false);

    if (!obj) return;

    obj.setPosition(
      this.sys.game.config.width / 2 + Phaser.Math.Between(-100, 100),
      600
    );

    // Activate and show the object
    obj.setActive(true).setVisible(true);

    // Phaser 3 doesn't use anchor. Instead, you'd use the origin property.
    obj.setOrigin(0.5, 0.5);

    // For setting physics properties in Phaser 3:
    obj.body.setAngularAcceleration(100);

    // moveToXY also does not exist in Phaser 3 for arcade physics. Instead, you can manually calculate the velocity to move the object towards a target.
    const angle = Phaser.Math.Angle.Between(
      obj.x,
      obj.y,
      this.sys.game.config.width / 2,
      this.sys.game.config.height / 2
    );
    obj.setVelocityX(530 * Math.cos(angle));
    obj.setVelocityY(530 * Math.sin(angle));
  }

  killFruit(fruit) {
    this.emitter = this.add.particles(fruit.x, fruit.y, null, {
      speed: 100,
      gravity: { x: 0, y: 300 },
      scale: { start: 1, end: 1 },
      lifespan: 1000,
      active: true,
      stopAfter: 10,
    });
    this.time.delayedCall(1000, () => {
      this.emitter.stop();
    });
    fruit.setActive(false).setVisible(false);
    this.pointsLeft = [];
    this.pointsRight = [];
    this.score++;
  }

  checkIntersects(fruit, point1, point2, type) {
    if (!point1 || !point2) return;
    const l1 = new Phaser.Geom.Line(
      fruit.x + fruit.width / 2,
      fruit.y + fruit.height / 2,
      fruit.x - fruit.width / 2,
      fruit.y - fruit.height / 2
    );

    const l2 = new Phaser.Geom.Line(
      fruit.x + fruit.width / 2,
      fruit.y - fruit.height / 2,
      fruit.x - fruit.width / 2,
      fruit.y + fruit.height / 2
    );

    const mainLine = new Phaser.Geom.Line(
      point1.x,
      point1.y,
      point2.x,
      point2.y
    );

    if (
      Phaser.Geom.Intersects.LineToLine(mainLine, l1) ||
      Phaser.Geom.Intersects.LineToLine(mainLine, l2)
    ) {
      this.contactPoint.set(this.sys.game.input.x, this.sys.game.input.y);

      let fruitPoint = new Phaser.Math.Vector2(fruit.x, fruit.y);

      if (
        Phaser.Math.Distance.BetweenPoints(this.contactPoint, fruitPoint) > 110
      ) {
        return;
      }
      if (type === 'good') {
        this.killFruit(fruit);
      } else {
        this.resetScore();
        this.lostLives++;
      }
    }
  }

  resetScore() {
    // const highscore = Math.max(this.score, localStorage.getItem('highscore'));
    // localStorage.setItem('highscore', highscore);

    this.goodObjects.getChildren().forEach((fruit) => this.killFruit(fruit));
    this.badObjects.getChildren().forEach((fruit) => this.killFruit(fruit));

    // this.score = 0;
  }
}

const config = {
  type: Phaser.AUTO,
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },
  // width: w,
  // height: h,
  scene: KickboxingScene,
  parent: '__test__-demo',
  physics: {
    default: 'arcade',
    arcade: {
      gravity: { y: 300 },
    },
  },
};

function waitForScenesToBeAvailable(game) {
  return new Promise((resolve) => {
    const checkInterval = setInterval(() => {
      if (Object.keys(game.scene.keys).length > 0) {
        clearInterval(checkInterval);
        resolve();
      }
    }, 100); // Check every 100ms
  });
}

// function waitForSceneInitialization(game, sceneKey) {
//   return new Promise((resolve) => {
//     const scene = game.scene.getScene(sceneKey);
//     if (scene) {
//       scene.events.once('ready', resolve);
//     } else {
//       console.error(`Scene with key ${sceneKey} not found!`);
//     }
//   });
// }

export async function createKickBoxingGame() {
  const game = new Phaser.Game(config);
  await waitForScenesToBeAvailable(game);
  return game;
}
