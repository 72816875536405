import React from 'react';

const LegendComponent = ({ title, gcolor, pts }) => {
  return (
    <div>
      <div>{title}</div>
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          paddingTop: '0.3rem',
        }}
      >
        <div
          style={{
            width: '50%',
            height: '0.75rem',
            marginLeft: '0.1rem',
            borderRadius: '0.4rem',
            background: gcolor,
          }}
        />
        <div style={{ marginLeft: '3px', alignSelf: 'center' }}>{pts}</div>
      </div>
    </div>
  );
};

export default LegendComponent;
