import React from 'react';

import './notification.css';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const Notification = ({ isNotificationOpen, notif }) => {
  const { t } = useTranslation();
  const moment = require('moment-timezone');
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user_data'));
  return (
    <div
      className={
        isNotificationOpen
          ? 'notification-container notification-container-open'
          : 'notification-container'
      }
    >
      <div className="notification-title">
        <h2>{t('notificationComponent.notification')}</h2>
      </div>
      {notif.map((item, index) => (
        <div
          key={'notification-row-workout-' + index}
          className="notification-row workout"
          onClick={() =>
            item.data.title.includes('Message')
              ? navigate(user.role === 'teacher' ? '/teacherchat' : '/chat')
              : navigate('/schedule')
          }
        >
          <div className="notification-row-left">
            <div className="notification-image">
              <img src={item.icon} />
            </div>
            <div className="notification-details">
              <h3>{item.data.title}</h3>
              <p>{item.data.description}</p>
            </div>
          </div>
          <div className="notification-time">
            {moment().local().diff(moment(item.data.time), 'hours')}{' '}
            {t('notificationComponent.hoursAgo')}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Notification;
