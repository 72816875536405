import React, { useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import { createVideoStreamFromGS } from '../../../utilities/Demo/Sports2D/storage';

export default function VideoPlayer({ fileId }) {
  console.log(fileId, 'video');
  const [videoUrl, setVideoUrl] = React.useState(null);

  useEffect(() => {
    if (!fileId) return;
    createVideoStreamFromGS('files/' + fileId).then((url) => {
      setVideoUrl(url);
    });
  }, [fileId]);
  if (!videoUrl) return <div>No Video available...</div>;

  return (
    <ReactPlayer
      url={videoUrl}
      controls={true}
      playing={true}
      loop={false}
      className="react-player"
      width="100%"
      height="100%"
      config={{ file: { attributes: { preload: 'auto' } } }}
    />
  );
}
