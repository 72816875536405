import './calendar.css';
import React, { useState } from 'react';
import av1 from '../../assets/av1.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const TopThreeClubComponent = ({ top, topthree, title }) => {
  const [arr, setArr] = useState();
  useEffect(() => {
    if (topthree) {
      setArr(topthree[0]);
    }
  }, [arr]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="article-navigation top-right article"
      style={{ marginBottom: !top && '1rem' }}
    >
      <div
        className="top-performers-container"
        style={{ marginBottom: !top && '1rem' }}
      >
        {
          <div className="top">
            <div className="left">
              <div className="orange-rect"></div>
              <h1>{title}</h1>
            </div>
            <div
              className="find-me-box"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/all-clubs')}
            >
              {t('TopThreeComponent.more')}
            </div>
          </div>
        }
        <div className="bottom">
          {arr &&
            arr.map(
              (item, index) =>
                index < 3 && (
                  <div key={item ? item.club : 'Babu'} className="diamond-grid">
                    <div className="diamond item">
                      <img alt="Cindy Baker" src={av1} />
                      <span> {item ? item.club : 'Babu'} </span>
                    </div>
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  );
};
export default TopThreeClubComponent;
