import React, { useRef } from 'react';
import '@tensorflow/tfjs-backend-webgl';
import { useCountsInTimeCoreFeedback } from '../../../hooks/Demo/feedbackTypes';
import {
  findAngle,
  findCounterAngle,
  findDependentAngle,
} from '../../../utilities/Demo/physio';
import PoseDetection from '../PoseDetection';
import {
  checkIfSide,
  findSideFromHorizontalPose,
} from '../../../utilities/Demo/angles';
import { loadPushUpAudio } from '../../../utilities/Demo/audioFeedback';
import { safeIterScore } from '../../../hooks/Demo/sharedLogic';
import { useDispatch } from 'react-redux';

function PushUpDemo({ onComplete, timeThresh = 60 }) {
  const dispatch = useDispatch();
  const collecting = useRef(false);
  const {
    correctCount,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    raiseLevel,
    setSide,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadPushUpAudio,
  });
  cptScore.current = () => {
    return correctCount.current / timeThresh;
  };

  function giveFeedback(ankleShoulderEarAngle, hipAngle) {
    if (ankleShoulderEarAngle <= 135 || ankleShoulderEarAngle > 205) {
      updateFeedback('Straighten your neck!');
      playAudio(audioFeedback.current?.straightenNeck);
      return;
    }
    if (hipAngle > 195) {
      updateFeedback('Keep your hips up!');
      playAudio(audioFeedback.current?.hipsUp);
    }
  }

  function observeBalance(ankleShoulderEarAngle, shoulderAngle, hipAngle) {
    if (
      ankleShoulderEarAngle > 130 &&
      ankleShoulderEarAngle <= 195 &&
      shoulderAngle > 85 &&
      hipAngle <= 195
    ) {
      updateFeedback('');
      if (collecting.current) {
        correctCount.current++;
        playAudio(audioFeedback.current?.achievement, true);
        updateCounts(correctCount.current);
        const score = cptScore.current();
        if (!raiseLevel(score)) {
          playAudio(audioFeedback.current?.achievement, true);
        } else {
          safeIterScore(dispatch, score);
        }
        collecting.current = false;
        setStartingTime(seconds);
      }
    } else {
      if (shoulderAngle <= 85 && collecting.current) {
        updateFeedback('Go lower!');
        // playAudio(audioFeedback.current?.goLower);
      }
      if (shoulderAngle <= 85 && shoulderAngle >= 50) {
        if (
          ankleShoulderEarAngle <= 130 ||
          ankleShoulderEarAngle > 195 ||
          hipAngle > 195
        ) {
          giveFeedback(ankleShoulderEarAngle, hipAngle);
          collecting.current = false;
        }
      }
      if (
        ankleShoulderEarAngle > 130 &&
        ankleShoulderEarAngle < 195 &&
        shoulderAngle <= 85 &&
        shoulderAngle >= 50 &&
        hipAngle <= 195
      ) {
        collecting.current = true;
        updateFeedback('');
      }
    }
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    const side = findSideFromHorizontalPose(results);

    let shoulderAnkleAngle;
    let ankleShoulderEarAngle;
    let hipAngle;
    if (side === 'left') {
      ankleShoulderEarAngle = findDependentAngle(
        results.poseLandmarks[7],
        results.poseLandmarks[11],
        results.poseLandmarks[27]
      );
      shoulderAnkleAngle = findAngle(
        [results.poseLandmarks[11].x, results.poseLandmarks[11].y],
        [results.poseLandmarks[27].x, results.poseLandmarks[27].y],
        90
      );
      hipAngle = findDependentAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        results.poseLandmarks[27]
      );
    } else {
      ankleShoulderEarAngle = findDependentAngle(
        results.poseLandmarks[8],
        results.poseLandmarks[12],
        results.poseLandmarks[28]
      );
      shoulderAnkleAngle = findCounterAngle(
        [results.poseLandmarks[12].x, results.poseLandmarks[12].y],
        [results.poseLandmarks[28].x, results.poseLandmarks[28].y],
        90
      );
      hipAngle = findDependentAngle(
        results.poseLandmarks[12],
        results.poseLandmarks[24],
        results.poseLandmarks[28]
      );
    }

    let isSide = checkIfSide(results);
    if (isSide) {
      setSide(true);
      updateFeedback('');
      observeBalance(
        ankleShoulderEarAngle,
        shoulderAnkleAngle,
        hipAngle,
        canvasCtx
      );
    } else {
      setSide(false);
      updateFeedback('Turn to the side!');
      playAudio(audioFeedback.current?.turnToSide);
    }
  }

  return <PoseDetection onResults={onResults} showWebcam={false} />;
}

export default React.memo(PushUpDemo);
