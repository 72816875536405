import React from 'react';
import { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import './profile.css';
import av4 from '../../assets/av4.png';
import ReadyPlayerMe from './ReadyPlayerMe';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Settings = (props) => {
  const { t } = useTranslation();
  const [, setDpurl] = useState(av4);
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  return (
    <>
      <ReadyPlayerMe
        show={show}
        setShow={setShow}
        setDpurl={setDpurl}
        setLoad={setLoad}
        setParse={props.setParse}
      />
      <div className="profile-settings-container">
        <div className="profile-settings-title">
          <div className="profile-page-orange-rect"></div>
          <h2>{t('ProfileComponent.profile')}</h2>
        </div>
        <div className="profile-settings-content">
          {load ? (
            <CircularProgress color="success" size={20} />
          ) : (
            <img src={props.dp} className="avtar"></img>
          )}
          <div className="form-group">
            <label>{t('ProfileComponent.name')}</label>
            <input value={props.name} type="text" />
          </div>
          <div className="form-group">
            <label>{t('ProfileComponent.email')}</label>
            <input disabled type="text" value={props.email} />
          </div>
          <div className="form-group">
            <label>{t('ProfileComponent.avatar')}</label>
            <ListItemButton
              className="profile-settings-btn"
              type="submit"
              onClick={() => setShow(true)}
            >
              <div>{t('ProfileComponent.createAvatar')}</div>
            </ListItemButton>
          </div>
        </div>
      </div>
      {/* <Grid container padding={2} className="gridder">
        <Grid item xs={0.5}>
          <div className='orange'></div>
        </Grid>
        <Grid item xs={11}>
          <h4>Profile Information</h4>
          <div className='profset'>
            {load ? (
              <CircularProgress color="success" size={20} />
            ) : (
              <img src={props.dp} className='avtar'></img>
            )}
            <div className='name'>Name</div>
            <input
              className='inputRounded'
              value={props.name}
              type="text"
            />
            <div className='name'>Email</div>
            <input
              className='inputRounded'
              disabled
              type="text"
              value={props.email}
            />
            <div className='name'>Avatar</div>
            <button
              type="submit"
              className='buttonRounded'
              onClick={() => setShow(true)}
            >
              Create My Avatar
            </button>
            <button
              type="submit"
              className='buttonRounded'
              onClick={() => setShow(true)}
            >
              Edit My Avatar
            </button>
            <button type="submit" className='save'>
              Save
            </button>
          </div>
        </Grid>
      </Grid> */}
    </>
  );
};

export default Settings;
