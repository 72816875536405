import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import AdminUiContainer from '../../components/Admin/AdminUiContainer';
import './../../components/Admin/AdminUiComponent.css';
import { Navigate } from 'react-router-dom';
import AdminBgComponent from '../../components/Background/AdminBgComponent';
import { AdminDrawer } from '../../components/Admin/AdminDrawer';
import { CreateExerciseSets } from '../../components/Admin/CreateExerciseSets';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClasses, getAllSets } from '../../redux/actions/superadmin';
import { ScheduleExerciseSet } from '../../components/Admin/ScheduleExerciseSet';

const AdminDashboard = () => {
  const user = JSON.parse(localStorage.getItem('user_data'));
  if (!user) return <Navigate to="/" replace />;
  console.log('User role::', typeof user.role);
  if (user && user.role !== 'superadmin') {
    return <Navigate to="/error_page" replace />;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    getAllClasses(dispatch);
    getAllSets(dispatch);
  }, []);
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState('admin-dashboard');
  const classes = useSelector(({ classes }) => classes.values);
  const sets = useSelector(({ sets }) => sets.values);
  const getComponent = () => {
    console.log('Component::', component);
    switch (component) {
      case 'admin-dashboard':
        return <AdminUiContainer classes={classes || []} />;
      case 'create-set':
        return <CreateExerciseSets />;
      case 'update-set':
        return <CreateExerciseSets mode="edit" sets={sets || []} />;
      case 'admin-schedule':
        return (
          <ScheduleExerciseSet sets={sets || []} classes={classes || []} />
        );
      default:
        return <AdminUiContainer />;
    }
  };
  return (
    <div>
      <HeaderComponent pageName={'admin'} />
      <AdminBgComponent />
      <AdminDrawer open={open} setOpen={setOpen} setComponent={setComponent} />
      <div className="admin-page-content-container">{getComponent()}</div>
    </div>
  );
};

export default AdminDashboard;
