const initialState = { values: { bestValue: 0, time: 0, feedback: '' } };

export const stretchCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_STRETCH_COUNT_OBSERVATION': {
      return { values: { ...state.values, ...action.payload } };
    }
    case 'STRETCH_COUNT_FEEDBACK': {
      return { values: { ...state.values, ...action.payload } };
    }
    case 'RESET_STRETCH_COUNT':
      return initialState;
    default: {
      return state;
    }
  }
};
