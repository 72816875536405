import React, { useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RankingBg from '../../components/Ranking/RankingBg';
import AllAgeClubs from '../../components/Ranking/AgeClub';
import { getAgeClubs } from '../../redux/actions/getDashboard';
import '../../components/Ranking/ranking.css';

const AgeClub = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const ageclub = useSelector(({ ageclub }) => ageclub.values);
  // const [ageclub, setAgeclub] = useState();
  // const ageclubs = async () => {
  //     const res = await getAgeClubs(dispatch, (a) => a);
  //     console.log("ageclubsrespo", res)
  // }
  // getRankings(dispatch,(a)=>a);
  // const ranks = useSelector(({ ranks }) => ranks.values)

  useEffect(() => {
    console.log('ageclub::', ageclub);
    if (!ageclub) {
      getAgeClubs(dispatch, (a) => a);
    }
  }, []);
  // useEffect(() => {
  // }, [ageclub])
  // console.log("ageclub::", ageclub)
  return (
    <div className="ranking-container">
      <HeaderComponent bgcol={'#F0ECEB'} />
      <RankingBg />
      <DrawerComponent open={open} setOpen={setOpen} />

      <div className="ranking-grid">
        {/* <div item lg={1} xs={1} md={1} sm={1}></div> */}
        {/* <div item lg={10} xs={10} md={10} sm={10}> */}
        {/* <RankingComponent details={club} /> */}
        <AllAgeClubs details={ageclub} />
        {/* </div> */}
      </div>
    </div>
  );
};

export default AgeClub;
