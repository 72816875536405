import AddIcon from '@mui/icons-material/Add';
import { Card, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React, { useEffect } from 'react';
import { emitToast } from '../../../../utilities/Demo/ExerciseManagement/Configurators/toasts';
import {
  deleteVideo,
  getVideos,
} from '../../../../utilities/Demo/ExerciseManagement/firestore';
import { deleteFile } from '../../../../utilities/Demo/ExerciseManagement/Generators/storage';
import VideoConfigurator from '../Configurator/Video/VideoConfigurator';
import DeleteButton from '../DeleteButton';

export default function Videos({ searchTerm }) {
  const videos = React.useRef([]);
  const [loading, setLoading] = React.useState(false);
  const [videoData, setVideoData] = React.useState({});
  const [configuratorOpen, setConfiguratorOpen] = React.useState(false);
  const [rerender, setRerender] = React.useState(false);

  const removeVideo = (videoId) => {
    videos.current = videos.current.filter((video) => video.id !== videoId);
    setRerender(true);
  };

  const addVideoToList = (video) => {
    videos.current = [...videos.current, video];
    setRerender(true);
  };

  const updateVideoInList = (video) => {
    videos.current = videos.current.map((v) => {
      if (v.id === video.id) {
        return video;
      }
      return v;
    });
    setRerender(true);
  };

  useEffect(() => {
    setLoading(true);
    getVideos({ searchTerm }).then((videosRetrieved) => {
      videos.current = videosRetrieved || [];
      setLoading(false);
    });
  }, [searchTerm]);

  useEffect(() => {
    setRerender(false);
  }, [rerender]);

  if (configuratorOpen) {
    return (
      <VideoConfigurator
        onClose={() => setConfiguratorOpen(false)}
        addVideoToList={addVideoToList}
        updateVideoInList={updateVideoInList}
        videoData={videoData}
      />
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h1>Videos</h1>
        </Grid>
        <Grid item key={'createNew'} xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              padding: 2,
              border: 1,
              borderRadius: 5,
              m: 2,
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setVideoData({});
                setConfiguratorOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
        {loading && <CircularProgress />}
        {videos.current.map((video) => {
          return (
            <Grid
              item
              key={video.name}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => {
                setVideoData(video);
                setConfiguratorOpen(true);
              }}
            >
              <Card sx={{ padding: 2, cursor: 'pointer' }}>
                <Box
                  sx={{
                    padding: 2,
                    border: 1,
                    borderRadius: 5,
                    m: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3>{video.name}</h3>
                    </Grid>
                    <Grid item xs={12}>
                      <DeleteButton
                        onDelete={() => {
                          deleteFile({ filePath: '/videos/' + video.id })
                            .catch((e) => {
                              emitToast({
                                render: 'Cannot delete Video File!',
                                type: 'error',
                              });
                              console.log(e);
                            })
                            .then(() => {
                              deleteVideo({ videoId: video.id }).then(() => {
                                removeVideo(video.id);
                              });
                            });
                        }}
                        deletionTarget={'Video ' + video.name}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
