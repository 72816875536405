import AddIcon from '@mui/icons-material/Add';
import { Card, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import FirebaseAuthContext from '../../../../contexts/auth/FirebaseAuthContext';
import {
  deleteAngle,
  getAngles,
} from '../../../../utilities/Demo/ExerciseManagement/firestore';
import AngleConfigurator from '../Configurator/Angles/AngleConfigurator';
import DeleteButton from '../DeleteButton';

export default function Angles({ searchValue }) {
  const [configuratorOpen, setConfiguratorOpen] = React.useState(false);
  const [angleData, setAngleData] = React.useState({});
  const [angles, setAngles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { user } = React.useContext(FirebaseAuthContext);

  const removeAngle = (angleId) => {
    setAngles(angles.filter((angle) => angle.id !== angleId));
  };

  const addAngle = (angle) => {
    setAngles([...angles, angle]);
  };

  const updateAngle = (angle) => {
    setAngles(
      angles.map((a) => {
        if (a.id === angle.id) {
          return angle;
        }
        return a;
      })
    );
  };

  React.useEffect(() => {
    setLoading(true);
    getAngles({ searchTerm: searchValue, userId: user.uid }).then((angles) => {
      setLoading(false);
      setAngles(angles || []);
    });
  }, [searchValue]);

  if (configuratorOpen) {
    return (
      <AngleConfigurator
        angleData={angleData}
        onClose={() => setConfiguratorOpen(false)}
        addAngleToList={addAngle}
        updateAngleInList={updateAngle}
      />
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <h1>Angles</h1>
      <Grid container spacing={2}>
        <Grid item key={'createNew'} xs={12} sm={6} md={4} lg={3}>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              padding: 2,
              border: 1,
              borderRadius: 5,
              m: 2,
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setAngleData({});
                setConfiguratorOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Grid>
        {loading && <CircularProgress />}
        {angles.map((angle) => {
          return (
            <Grid
              item
              key={angle.id}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => {
                setAngleData(angle);
                setConfiguratorOpen(true);
              }}
              sx={{ ':hover': { cursor: 'pointer' } }}
            >
              <Card sx={{ padding: 2 }}>
                <h1>{angle.name}</h1>
                <Grid
                  container
                  spcacing={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <DeleteButton
                    onDelete={() => {
                      deleteAngle({ angleId: angle.id }).then(() => {
                        removeAngle(angle.id);
                      });
                    }}
                    deletionTarget={'Angle ' + angle.name}
                  />
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
