import React, { useRef } from 'react';
import { useBalanceScore } from '../../../../../hooks/BergBalance/scores';
import { useBalanceCoreFeedback } from '../../../../../hooks/Demo/feedbackTypes';
import {
  checkIfRightSide,
  checkIfSide,
} from '../../../../../utilities/Demo/angles';
import { loadSitUnsupportedAudio } from '../../../../../utilities/Demo/audioFeedback';
import {
  findAdjustedAngle,
  findDependentAngle,
} from '../../../../../utilities/Demo/physio';
import { checkArmsUsed } from '../../../../../utilities/Demo/Physio/basics';
import PoseDetection from '../../../PoseDetection';
import {
  backRomMetrics,
  computeMetrics,
  upperLegRomMetrics,
} from '../../../../../utilities/Demo/Physio/BergBalance/metrics';
import { useDispatch } from 'react-redux';

export default function SitUnsupported({ onComplete, showWebcam = false }) {
  const isLeft = useRef(false);
  const dispatch = useDispatch();
  const usedArms = useRef(false);
  const timeThresh = 120;
  const {
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateSecondsPoseHeld,
    seconds,
    playAudio,
    setSide,
    started,
    audioFeedback,
    cptScore,
    updatePoseHeldDeps,
    finishedIntro,
  } = useBalanceCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadSitUnsupportedAudio,
    maxScore: 1,
  });
  const metrics = useRef(upperLegRomMetrics().concat(backRomMetrics()));
  cptScore.current = useBalanceScore({
    secondsPoseHeldComplete,
    secondsPoseHeld,
    timeThresh,
    usedArms,
  });

  function observation(backAngle, kneeAngle, results) {
    if (started.current) {
      if (checkArmsUsed(results, isLeft.current)) {
        updateFeedback('Do not use your arms!');
        usedArms.current = true;
      }
    }
    const state = holdsPose.current;
    if (kneeAngle < 100 && backAngle < 100) {
      holdsPose.current = true;
      secondsPoseHeld.current = seconds.current - secondPoseHeldStarted.current;
    } else {
      updateFeedback('Keep your back straight and your knees bent!');
      holdsPose.current = false;
    }
    updatePoseHeldDeps(state);
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    if (!checkIfSide(results)) {
      playAudio(audioFeedback.current?.turnToSide, false, 6);
      updateFeedback('Turn to the side!');
      setSide(false);
      return;
    } else {
      updateFeedback('');
      setSide(true);
    }
    isLeft.current = checkIfRightSide(results, 'left');
    let kneeAngle;
    let backAngle;
    let hipKneeAngle;
    let displayConfigs;

    if (isLeft.current) {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      backAngle = findAdjustedAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        180
      );
      hipKneeAngle = findAdjustedAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25]
      );
      displayConfigs = {
        hipKneeAngle: {
          coord: results.poseLandmarks[23],
          left: false,
        },
        backAngle: {
          coord: results.poseLandmarks[11],
          left: false,
        },
      };
    } else {
      kneeAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      if (kneeAngle > 180) {
        kneeAngle = 360 - kneeAngle;
      }
      backAngle =
        360 -
        findAdjustedAngle(
          results.poseLandmarks[12],
          results.poseLandmarks[24],
          0
        );
      hipKneeAngle =
        360 -
        findAdjustedAngle(results.poseLandmarks[24], results.poseLandmarks[26]);
      displayConfigs = {
        hipKneeAngle: {
          coord: results.poseLandmarks[24],
          left: false,
        },
        backAngle: {
          coord: results.poseLandmarks[12],
          left: false,
        },
      };
    }
    computeMetrics({
      metrics: metrics.current,
      angles: {
        current: {
          backAngle,
          hipKneeAngle,
        },
      },
      dispatch,
      displayConfigs,
      canvasCtx,
    });
    observation(backAngle, kneeAngle, results);
    updateSecondsPoseHeld(
      secondsPoseHeldComplete.current + secondsPoseHeld.current
    );
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
