import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { getDownloadURL } from 'firebase/storage';
import React from 'react';
import FirebaseAuthContext from '../../../../../contexts/auth/FirebaseAuthContext';
import {
  addVideo,
  updateVideo,
} from '../../../../../utilities/Demo/ExerciseManagement/firestore';
import { uploadFile } from '../../../../../utilities/Demo/ExerciseManagement/Generators/storage';
import TagConfig from '../TagConfig';
import VideoUpload from '../VideoUpload';
import { emitToast } from '../../../../../utilities/Demo/ExerciseManagement/Configurators/toasts';

export default function VideoConfigurator({
  onClose,
  videoData,
  addVideoToList,
  updateVideoInList,
}) {
  const [name, setName] = React.useState(videoData.name || '');
  const [tags, setTags] = React.useState(videoData.tags || []);
  const [description, setDescription] = React.useState(
    videoData.description || ''
  );
  const [saving, setSaving] = React.useState(false);
  const [buttonText] = React.useState(videoData.id ? 'Update' : 'Save');
  const videoUrl = React.useRef(videoData.url || '');
  const id = React.useRef(videoData.id);
  const { user } = React.useContext(FirebaseAuthContext);

  const handleVideoUpload = async (videoFile) => {
    const fileRef = await uploadFile({
      file: videoFile,
      filePath: '/videos/' + id.current,
    });
    videoUrl.current = await getDownloadURL(fileRef);
    emitToast({ render: 'Uploaded Video File!', type: 'success' });
  };

  const saveVideo = async () => {
    setSaving(true);
    const videoDataToSet = {
      name,
      description,
      id: id.current || '',
      url: videoUrl.current,
    };
    if (videoDataToSet.id) {
      updateVideo({ videoData: videoDataToSet, user }).then(() => {
        updateVideoInList(videoDataToSet);
        emitToast({ render: 'Updated Video!', type: 'success' });
        setSaving(false);
      });
    } else {
      const videoDataSet = await addVideo({ videoData: videoDataToSet, user });
      id.current = videoDataSet.id;
      addVideoToList(videoDataSet);
      setSaving(false);
      emitToast({ render: 'Added Video!', type: 'success' });
      return videoDataSet;
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <h1>Video Configurator</h1>
      <Grid container spacing={2} direction={'column'} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ height: '50%', width: '50%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            id="outlined-multiline-flexible"
            label="Description"
            multiline
            maxRows={4}
            minRows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ height: '50%', width: '50%' }}
          />
        </Grid>
      </Grid>
      <TagConfig
        tags={tags}
        setTags={(tags) => {
          setTags(tags);
        }}
        type={'video'}
      />
      <Box sx={{ padding: 5, paddingRight: 7 }}>
        <VideoUpload
          uploadId={name.length !== 0 && description.length !== 0}
          videoUrl={videoUrl.current}
          handleVideoUpload={async (videoFile) => {
            if (videoData.id) {
              handleVideoUpload(videoFile).then(() => saveVideo());
            } else {
              const videoDataSet = await saveVideo();
              if (!videoDataSet) return;
              setSaving(true);
              return handleVideoUpload(videoFile).then(() => saveVideo());
            }
          }}
        />
      </Box>
      <Button
        variant={'contained'}
        disabled={
          saving ||
          name.length === 0 ||
          description.length === 0 ||
          videoUrl.current.length === 0
        }
        onClick={saveVideo}
      >
        {saving ? <CircularProgress /> : buttonText}
      </Button>
    </div>
  );
}
