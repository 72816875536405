import React from 'react';
import { useSelector } from 'react-redux';
import { useStretchCoreFeedback } from '../../../../hooks/Demo/feedbackTypes';
import { loadExerciseConfigAudio } from '../../../../utilities/Demo/audioFeedback';
import { cptRWUDistance } from '../../../../utilities/Demo/ExerciseManagement/Generators/rWUDistance';
import FeedbackGenerator from './FeedbackGenerator';

function calculatePoints(reachedCentimeters) {
  return reachedCentimeters / 14;
}

export default function StretchRWUFeedbackGenerator({
  onComplete,
  config,
  showWebcam,
}) {
  const patientHeight = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values.height
  );
  const iterLanguage = useSelector(({ iter }) => iter.values.language);
  const {
    updateFeedback,
    candidates,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    setSide,
    finishedIntro,
  } = useStretchCoreFeedback({
    onComplete,
    timeThresh: config.timeThresh,
    loadAudio: () => loadExerciseConfigAudio(config, iterLanguage),
    min: false,
    maxScore: config.scoreConfig?.maxScore || 10,
    calculatePoints,
  });

  const onCorrect = ({ results, refValue, personHeightPixels, axis }) => {
    const stretchLength = cptRWUDistance({
      stretchLengthPixels: refValue[axis],
      personHeightRWU: Number(patientHeight) || 180,
      personHeightPixels: personHeightPixels,
      results,
    });
    candidates.current.push(stretchLength);
    setStartingTime(seconds);
  };

  return (
    <FeedbackGenerator
      updateFeedback={updateFeedback}
      config={config}
      finishedIntro={finishedIntro}
      onCorrect={onCorrect}
      setSide={setSide}
      playAudio={playAudio}
      audioFeedback={audioFeedback}
      showWebcam={showWebcam}
    />
  );
}
