import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FirebaseAuthContext from '../../contexts/auth/FirebaseAuthContext';
import {
  bergBalanceAssessmentComponents,
  bergBalanceAssessmentMiniComponents,
} from '../../utilities/Demo/demoComponents';
import { getLastResults } from '../../utilities/Demo/Physio/GaitAnalysis/firestore';

export function useHistoricalBergResults({
  startDate,
  endDate,
  type = 'full',
}) {
  const dispatch = useDispatch();
  const { user } = useContext(FirebaseAuthContext);
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );
  const bergBalanceLastResults = useSelector(
    ({ bergBalanceLastResults }) => bergBalanceLastResults.docs
  );
  const bergBalanceLastResultsType = useSelector(
    ({ bergBalanceLastResults }) => bergBalanceLastResults.type
  );

  useEffect(() => {
    if (
      bergBalanceLastResultsType === type &&
      bergBalanceLastResults?.length > 0
    ) {
      console.log(bergBalanceLastResults, 'lastResults');
    } else {
      const results = getLastResults({
        patientId: patientInfo.id,
        userId: user?.uid,
        exerciseType:
          'berg_balance_assessment' + (type === 'mini' ? '_mini' : ''),
        startDate,
        endDate,
        n: 10,
      });
      results.then((docs) => {
        dispatch({
          type: 'SET-LAST-BERG-BALANCE-RESULTS',
          payload: {
            docs: docs,
            type: type,
          },
        });
      });
    }
  }, [bergBalanceLastResults]);

  return { bergBalanceLastResults };
}

export function useLastBergResults(type = 'full') {
  const dispatch = useDispatch();
  const components =
    type === 'full'
      ? bergBalanceAssessmentComponents
      : bergBalanceAssessmentMiniComponents;
  const { user } = useContext(FirebaseAuthContext);
  const bergBalanceResults = useSelector(
    ({ bergBalanceResults }) => bergBalanceResults.values
  );
  const bergBalanceResultsType = useSelector(
    ({ bergBalanceResults }) => bergBalanceResults.type
  );
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );

  useEffect(() => {
    if (bergBalanceResultsType === type) {
      console.log(bergBalanceResults, 'bergBalanceResults');
    } else {
      const results = components.map((component) => {
        return getLastResults({
          patientId: patientInfo.id,
          userId: user.uid,
          exerciseType: component,
          n: 1,
        });
      });
      Promise.all(results)
        .then((res) => {
          dispatch({
            type: 'SET-BERG-BALANCE-RESULTS',
            payload: {
              values: res.map((docs) => (docs ? docs[0] : null)),
              type: type,
            },
          });
        })
        .catch((err) => console.log(err));
    }
  }, [bergBalanceResults]);
  return { bergBalanceResults };
}
