import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useContext } from 'react';
import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import OverviewAppBar from '../../../../components/Demo/OverviewAppBar';
import PatientBox from '../../../../components/Demo/Physio/Overview/PatientBox';
import PatientInfoDialog from '../../../../components/Demo/Physio/Overview/PatientInfoDialog';
import FirebaseAuthContext from '../../../../contexts/auth/FirebaseAuthContext';
import { useGaitActions } from '../../../../hooks/GaitAnalysis/actions';
import { emitToast } from '../../../../utilities/Demo/ExerciseManagement/Configurators/toasts';
import {
  addPatient,
  deletePatient,
  deleteAllPatientResults,
  getPatients,
  getPatient,
  updatePatient,
} from '../../../../utilities/Demo/Physio/GaitAnalysis/firestore';

export default function AllPatientsView() {
  const [loading, setLoading] = React.useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [patients, setPatients] = React.useState([]);
  const [patientDialogData, setPatientDialogData] = React.useState({});
  const [filteredPatients, setFilteredPatients] = React.useState(patients);
  const { user } = useContext(FirebaseAuthContext);

  // reset gait redux state to force load historical results
  useGaitActions();
  useGaitActions(true);

  async function setNewPatient(patient) {
    const patientDoc = await addPatient({
      userId: user.uid,
      patientData: patient,
    });
    if (!patientDoc) return;
    setPatients([...patients, patientDoc]);
    return patientDoc;
  }

  async function updateExistingPatient(patient) {
    const patientDoc = await updatePatient({
      userId: user.uid,
      patientData: patient,
    });
    if (patientDoc) {
      setPatients(patients.map((p) => (p.id === patient.id ? patientDoc : p)));
    }
    return patientDoc;
  }

  function removePatient(patientId) {
    deleteAllPatientResults({ userId: user.uid, patientId })
      .then((sucess) => {
        if (sucess) {
          deletePatient({ id: patientId }).then((success) => {
            if (success) {
              setPatients(
                patients.filter((patient) => patient.id !== patientId)
              );
              emitToast({
                type: 'success',
                render: 'Patient removed successfully',
              });
            } else {
              throw new Error('Failed to remove patient');
            }
          });
        } else {
          throw new Error('Failed to remove patient results');
        }
      })
      .catch((error) => {
        console.log(error);
        emitToast({ type: 'error', render: 'Failed to remove patient' });
      });
  }

  React.useEffect(() => {
    setFilteredPatients(
      patients
        .filter((patient) =>
          patient?.name?.toLowerCase().includes(searchValue.toLowerCase())
        )
        .sort((a, b) => a?.name.localeCompare(b?.name))
    );
  }, [searchValue, patients]);

  React.useEffect(() => {
    async function fetchPatients() {
      setLoading(true);
      if (user.claims.patientId) {
        getPatient({ id: user.claims.patientId }).then((patient) => {
          if (!patient) {
            getPatients({ userId: user.uid }).then((date) => {
              setPatients(date || []);
              setLoading(false);
            });
            return;
          }
          setPatients([patient] || []);
          setLoading(false);
        });
      } else {
        getPatients({ userId: user.uid }).then((date) => {
          setPatients(date || []);
          setLoading(false);
        });
      }
    }

    if (user) fetchPatients().then();
  }, [user]);

  return (
    <div>
      <PatientInfoDialog
        open={infoDialogOpen}
        onClose={() => {
          setInfoDialogOpen(false);
          setPatientDialogData({});
        }}
        addPatient={setNewPatient}
        updatePatient={updateExistingPatient}
        patientData={patientDialogData}
      />
      <OverviewAppBar
        setSearchValue={setSearchValue}
        setInfoDialogOpen={setInfoDialogOpen}
      />
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {!loading ? (
          filteredPatients.map((patient) => (
            <Grid item xs={12} sm={6} md={3} key={patient.id}>
              <PatientBox
                patientData={{ ...patient, id: patient.id }}
                removePatient={removePatient}
                setPatientDialogData={(data) => {
                  setPatientDialogData(data);
                  setInfoDialogOpen(true);
                }}
              />
            </Grid>
          ))
        ) : (
          <CircularProgress />
        )}
      </Grid>
      <ToastContainer />
    </div>
  );
}
