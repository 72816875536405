import React, { useRef } from 'react';
import '@tensorflow/tfjs-backend-webgl';
import { useCountsInTimeCoreFeedback } from '../../../hooks/Demo/feedbackTypes';
import {
  findCounterAngle,
  findDependentAngle,
  horizontalAngle,
} from '../../../utilities/Demo/physio';
import PoseDetection from './../PoseDetection';
import {
  checkIfRightSide,
  checkIfSide,
  drawAngle,
} from '../../../utilities/Demo/angles';
import { safeIterScore } from '../../../hooks/Demo/sharedLogic';
import { loadSquatsAudio } from '../../../utilities/Demo/audioFeedback';
import { useDispatch } from 'react-redux';

function LungesDemo({ onComplete }) {
  const dispatch = useDispatch();
  const collecting = useRef(false);
  const peaked = useRef(false);
  const lungeSide = useRef('left');
  const timeThresh = 60;
  const {
    correctCount,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    raiseLevel,
    setSide,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadSquatsAudio,
  });
  cptScore.current = () => {
    return (correctCount.current / timeThresh) * 2;
  };

  function observeBalance(backAngle, angleFront, angleBack, yKneeDiff) {
    // incorrect posture
    if (backAngle > 140 || angleFront < 50 || angleBack < 60) {
      if (backAngle > 140) {
        if (collecting.current) {
          collecting.current = false;
        }
        playAudio(audioFeedback.current?.keepBackStraight);
        updateFeedback('Keep your back straight!');
      } else if (angleFront < 50) {
        collecting.current = false;
        playAudio(audioFeedback.current?.tooDeep);
        updateFeedback('Too deep!');
      } else if (angleBack < 60) {
        collecting.current = false;
        playAudio(audioFeedback.current?.tooDeep);
        updateFeedback('Too deep!');
      }
      peaked.current = true;
      // correct posture
    } else {
      if (collecting.current) {
        if (angleFront < 120 && angleBack < 130) {
          peaked.current = true;
          updateFeedback('');
        }
        if (
          peaked.current &&
          angleFront < 110 &&
          angleBack < 120 &&
          yKneeDiff > 0
        ) {
          collecting.current = false;
          correctCount.current += 1;
          lungeSide.current = lungeSide.current === 'left' ? 'right' : 'left';
          updateCounts(correctCount.current);
          if (!raiseLevel(cptScore.current())) {
            playAudio(audioFeedback.current?.achievement, true);
          } else {
            safeIterScore(dispatch, (correctCount.current / timeThresh) * 2);
          }
          updateFeedback('');
          setStartingTime(seconds);
        } else if (!peaked.current) {
          updateFeedback('Go deeper!');
        }
      } else {
        if (angleFront < 130 && angleBack < 140 && !peaked.current) {
          collecting.current = true;
          updateFeedback('');
        } else if (angleFront > 160 && angleBack > 160) {
          peaked.current = false;
          updateFeedback('');
        }
      }
    }
    // updateFeedback(angleFront);
  }

  function onResults(results, canvasCtx) {
    if (!finishedIntro.current) return;

    if (!checkIfSide(results)) {
      // playAudio(audioFeedback.current?.turnToSide, false, 6);
      updateFeedback('Turn to the side!');
      setSide(false);
      return;
    } else {
      setSide(true);
    }
    const isLeft = checkIfRightSide(results, 'left');
    let leftAngle;
    let rightAngle;
    let backAngle;
    let yKneeDiff;

    if (isLeft) {
      leftAngle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      rightAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      backAngle = findCounterAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        180
      );
      drawAngle(canvasCtx, leftAngle, results.poseLandmarks[25]);
      drawAngle(canvasCtx, rightAngle, results.poseLandmarks[26]);
      observeBalance(backAngle, leftAngle, rightAngle);
    } else {
      rightAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
      if (rightAngle > 180) {
        rightAngle = 360 - rightAngle;
      }
      leftAngle = findDependentAngle(
        results.poseLandmarks[23],
        results.poseLandmarks[25],
        results.poseLandmarks[27]
      );
      if (leftAngle > 180) {
        leftAngle = 360 - leftAngle;
      }
      backAngle = horizontalAngle(
        results.poseLandmarks[12],
        results.poseLandmarks[24]
      );
      // drawAngle(canvasCtx, rightAngle, results.poseLandmarks[26]);
      // drawAngle(canvasCtx, leftAngle, results.poseLandmarks[25]);
    }
    if (lungeSide.current === 'right') {
      yKneeDiff = results.poseLandmarks[25].y - results.poseLandmarks[26].y;
      observeBalance(backAngle, rightAngle, leftAngle, yKneeDiff);
    } else {
      yKneeDiff = results.poseLandmarks[26].y - results.poseLandmarks[25].y;
      observeBalance(backAngle, leftAngle, rightAngle, yKneeDiff);
    }
  }

  return <PoseDetection onResults={onResults} showWebcam={false} />;
}

export default React.memo(LungesDemo);
