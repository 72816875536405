const initialState = {};

export const ranksReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RANKINGS': {
      console.log('PTS is::' + JSON.stringify(action));
      return { values: action.payload };
    }
    default: {
      return state;
    }
  }
};
