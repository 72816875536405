import { useEffect, useRef } from 'react';
import { STRIDALIZER_MESSAGE_EVENT } from '../../constants/Demo/stridalizer';

export function useStridalizerEventListener({
  sensorCandidate = null,
  timeRunning = null,
  sensorData = null,
  time = null,
  callBack = null,
}) {
  if (!timeRunning) timeRunning = useRef(true);
  if (!sensorCandidate) sensorCandidate = useRef({ left: null, right: null });
  if (!sensorData) sensorData = useRef({ left: [], right: [] });
  if (!time) time = useRef('not set');

  useEffect(() => {
    function listenForInsolesData(event) {
      // Ensure you validate the origin for security reasons!
      // if (event.origin !== 'http://localhost:8080') return;

      const dataFromWasm = event.data;
      // console.log(dataFromWasm, 'data');

      // Handle or display the data in your React app
      if (dataFromWasm.isLeft) {
        sensorCandidate.current.left = dataFromWasm;
        if (timeRunning.current) {
          // console.log(dataFromWasm, 'data');
          sensorData.current.left.push({
            value: dataFromWasm,
            time: time.current,
          });
        }
      } else if (!dataFromWasm.isLeft && dataFromWasm.result) {
        sensorCandidate.current.right = dataFromWasm;
        if (timeRunning.current) {
          // console.log(dataFromWasm, 'data');
          sensorData.current.right.push({
            value: dataFromWasm,
            time: time.current,
          });
        }
      } else {
        // console.log(dataFromWasm, 'data');
      }
      if (callBack) callBack();
    }
    // timeRunning.current = true;
    window.addEventListener(STRIDALIZER_MESSAGE_EVENT, listenForInsolesData);
    return () =>
      window.removeEventListener(
        STRIDALIZER_MESSAGE_EVENT,
        listenForInsolesData
      );
  }, []);

  return {
    sensorCandidate,
    sensorData,
  };
}
