import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import FirebaseAuthContext from '../../../contexts/auth/FirebaseAuthContext';
import { deleteFile, handleTask } from '../../../service/Demo/Sports2D/tasks';
import { useSelector } from 'react-redux';
import {
  getFile,
  getLatestSports2DResults,
} from '../../../utilities/Demo/Sports2D/firestore';

export default function FileList({ projectId }) {
  const [files, setFiles] = useState([]);
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );
  const { user } = React.useContext(FirebaseAuthContext);

  // Fetch files when component mounts
  useEffect(() => {
    if (!projectId) return;
    const filePromises = [];
    getLatestSports2DResults({
      doctorId: patientInfo.doctorId,
      patientId: patientInfo.id,
      limit: 15,
    }).then((lastResults) => {
      lastResults.forEach((lastResult) =>
        filePromises.push(getFile({ fileId: lastResult.file_id }))
      );
      Promise.all(filePromises).then((files) => {
        setFiles(files);
      });
    });
  }, [projectId, patientInfo]);

  const handleDelete = async (fileId) => {
    try {
      user.getIdToken().then((token) => {
        deleteFile({ authToken: token, fileId }).then((response) => {
          if (response.status === 204) {
            setFiles(files.filter((file) => file.id !== fileId));
          } else {
            alert('Delete failed');
          }
        });
      });
    } catch (error) {
      console.error('Error deleting file', error);
    }
  };

  return (
    <div>
      {files.map((file) => (
        <Card key={file?.id} style={{ marginBottom: 10 }}>
          <CardContent
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="h6">{file?.name}</Typography>
            <IconButton
              onClick={() => handleDelete(file?.id)}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                handleTask({
                  fileId: file.id,
                  patientInfo,
                  user,
                })
              }
              aria-label="start task"
            >
              <PlayCircleOutlineIcon />
            </IconButton>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
