import { Button, CircularProgress } from '@mui/material';
import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import FirebaseAuthContext from '../../../../contexts/auth/FirebaseAuthContext';
import {
  useGaitActions,
  useGaitPatientActions,
  useGaitResultsActions,
} from '../../../../hooks/GaitAnalysis/actions';
import DeleteButton from '../../ExerciseManagement/DeleteButton';
import {
  SelectRecordingDialog,
  SelectResultsDialog,
} from './SelectNavigationDialog';
import { useNavigate } from 'react-router-dom';

const styles = {
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between', // positions items at the start and end of the container
    alignItems: 'center', // aligns items vertically in the center
    padding: 2, // provides some space around the content
    margin: 1, // provides some space around the box itself
    borderRadius: 2,
    width: '100%',
  },
};

function PatientInfo({ label, value }) {
  return (
    <Box sx={{ ...styles.box, border: 1 }}>
      <span>{label}</span>
      <span>{value}</span>
    </Box>
  );
}

export default function PatientBox({
  patientData,
  removePatient,
  setPatientDialogData,
}) {
  const [loading, setLoading] = React.useState(false);
  const [recordingDialogOpen, setRecordingDialogOpen] = React.useState(false);
  const [resultsDialogOpen, setResultsDialogOpen] = React.useState(false);
  const { updatePatientInfo } = useGaitPatientActions();
  const { resetGaitResults } = useGaitResultsActions();
  const { user } = useContext(FirebaseAuthContext);
  const navigate = useNavigate();
  useGaitActions();

  const setPatientInfo = () => {
    updatePatientInfo(patientData);
    resetGaitResults();
  };

  return (
    <div>
      <SelectRecordingDialog
        open={recordingDialogOpen}
        setOpen={setRecordingDialogOpen}
      />
      <SelectResultsDialog
        open={resultsDialogOpen}
        setOpen={setResultsDialogOpen}
      />
      <Card
        onClick={() => setPatientDialogData(patientData)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(226,212,212,0.1)',
          borderRadius: 2,
          boxShadow: 5,
          padding: 2,
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(185,163,163,0.1)',
          },
        }}
      >
        <Typography
          sx={{
            color: 'black',
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 5,
          }}
        >
          {patientData.name}
        </Typography>
        <PatientInfo label={'Age'} value={patientData.age} />
        <PatientInfo label={'Height'} value={patientData.height} />
        <PatientInfo label={'Gender'} value={patientData.gender} />
        <Box sx={styles.box}>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              setPatientInfo();
              setRecordingDialogOpen(true);
              // navigate(`/gait_analysis`);
            }}
          >
            Record
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              setPatientInfo();
              setResultsDialogOpen(true);
            }}
          >
            History
          </Button>
        </Box>
        <Button
          onClick={() =>
            navigate(`/patient_overview?patientId=${patientData.id}`)
          }
        >
          Assignments
        </Button>
        {!user.claims.patientId &&
          (!loading ? (
            <DeleteButton
              onDelete={() => {
                setLoading(true);
                removePatient(patientData.id);
              }}
              deletionTarget={'Patient ' + name}
            />
          ) : (
            <CircularProgress />
          ))}
      </Card>
    </div>
  );
}
