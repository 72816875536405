import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { useRedirectAfterSignIn } from '../../hooks/Demo/auth/redirect';
import FirebaseAuthContext from './FirebaseAuthContext';
import { useDispatch } from 'react-redux';

const FirebaseAuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const redirectAfterSignIn = useRedirectAfterSignIn({ prefix: '/login' });
  const params = new URLSearchParams(window.location.search);
  const patientId = params.get('patientId');
  const doctorId = params.get('doctorId');
  const signInType = params.get('signInType');
  let scheduleId;
  let roadmapId;

  // Define your protected paths
  const protectedRoutes = [
    'gait_analysis',
    'gait_result',
    'demos/berg_balance_assessment',
    'berg_balance_result',
    'all_patients',
    'exercise_management',
    'schedule_results',
    'roadmap',
  ];

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const forceRefresh = signInType === 'emailLink';
        if (forceRefresh) {
          setTimeout(() => {
            user.getIdToken(forceRefresh).then(() => {
              user.getIdTokenResult().then((token) => {
                user.claims = token.claims;
                setUser(user);
                redirectAfterSignIn(user);
              });
            });
          }, 2000);
        } else {
          user.getIdTokenResult().then((token) => {
            user.claims = token.claims;
            setUser(user);
            redirectAfterSignIn(user);
          });
        }
      } else {
        if (patientId) {
          console.log('patientId', patientId, doctorId);
          dispatch({
            type: 'UPDATE-GAIT-PATIENT-INFO',
            payload: {
              id: patientId,
              doctorId,
            },
          });
          setUser({ uid: doctorId });
        } else {
          setUser(null);
          // check if we are on a protected route
          if (
            protectedRoutes.includes(window.location.pathname.split('/')[1]) &&
            window.location.pathname !== '/demosignup'
          ) {
            if (window.location.pathname.includes('/schedule_results')) {
              scheduleId = window.location.pathname.split('/')[2];
              navigate('/login/schedule_results?scheduleId=' + scheduleId);
            } else if (window.location.pathname.includes('/roadmap')) {
              roadmapId = window.location.pathname.split('/')[2];
              navigate('/login/roadmap?roadmapId=' + roadmapId);
            } else {
              navigate('/login/physio');
            }
          }
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);
  if (!user) return;
  return (
    <FirebaseAuthContext.Provider value={{ user }}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export default FirebaseAuthProvider;
