import React, { useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RankingBg from '../../components/Ranking/RankingBg';
import { getDashboardData } from '../../redux/actions/getDashboard';
import PointsComponent from '../../components/Dashboard/PointsComponent';
import ArticleNavigation from '../../components/Dashboard/ArticleNavigation';
import RewardsCategory from './RewardsCategory';
import '../../components/Ranking/ranking.css';
import { useTranslation } from 'react-i18next';
const Rewards = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const board = useSelector(({ board }) => board.values);
  const rewards = useSelector(({ rewards }) => rewards.values);
  console.log('rewards', rewards);
  useEffect(() => {
    if (!board) getDashboardData(dispatch);
    getDashboardData(dispatch);
  }, []);
  return (
    <div className="ranking-container">
      <HeaderComponent bgcol={'#F0ECEB'} />
      <RankingBg />
      <DrawerComponent open={open} setOpen={setOpen} />

      <div className="all-students-container article ranking-article ranking-grid">
        <ArticleNavigation
          top={true}
          title={t('OverviewComponent.PatientOverview')}
        />
        <PointsComponent data={board} />
        <RewardsCategory board={board} />
      </div>
    </div>
  );
};

export default Rewards;
