import * as React from 'react';
import Bag from '../assets/Group_190.png';

const WelcomeComponent = () => {
  return (
    <div className="welcome-bag-container">
      <img src={Bag} alt="Bag Character" />
    </div>
  );
};

export default WelcomeComponent;
