import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import '../../components/Arena/exercisearena.css';
import ErrorPage from '../../components/ErrorPages/ErrorPage';
import { SchoolAnimationComponent } from '../../components/Arena/Animation/SchoolAnimationComponent';

const ExerciseArena = () => {
  const user = localStorage.getItem('user_data');
  let { role } = JSON.parse(localStorage.getItem('user_data'));
  const navigate = useNavigate();
  const [duration, setDuration] = useState(0);
  const [showOther, setShowOther] = useState(false);
  if (!user) navigate('/', { state: { from: 'exercise' } });
  const [joiners, setJoiners] = useState([]);
  useEffect(() => {
    //if (!user) navigate("/", { from: "exercise" });
    console.log('JOINERS::', joiners);
  }, [joiners]);
  return (
    // <Container
    //   maxWidth="xxl"
    //   className="bdy"
    //   sx={{ backgroundColor: "#FCFCF9" }}
    // >
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="exercise-container">
        {/* <HeaderComponent
          exer={true}
          sp={sp}
          duration={duration}
          pageName={'exercise'}
        /> */}
        <SchoolAnimationComponent
          setJoiners={setJoiners}
          setShowOther={setShowOther}
          role={role}
          duration={duration}
          showOther={showOther}
          joiners={joiners}
          setDuration={setDuration}
        />
      </div>
    </ErrorBoundary>
    // </Container>
  );
};

export default ExerciseArena;
