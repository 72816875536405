import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Card, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import Button from '@mui/material/Button';
import moment from 'moment-timezone';
import '../../../Assessments/assessments.css';
import '../../../../Schedule/schedule.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FirebaseAuthContext from '../../../../../contexts/auth/FirebaseAuthContext';
import { useStartSchedule } from '../../../../../hooks/Demo/exerciseManagement/startEntity';
import {
  getExercise,
  getRoadmap,
  getSchedule,
} from '../../../../../utilities/Demo/ExerciseManagement/firestore';
import { parseRecommendationConfig } from '../../../../../utilities/Demo/ExerciseManagement/Results/recommendations';
import { getLastResults } from '../../../../../utilities/Demo/ExerciseManagement/Results/shared';
import MetricBox, { getValueColor } from '../../../Summary/MetricBox';
import TestResult from './TestResult';
import _ from 'lodash';
// import * as XLSX from 'xlsx';
import ResultsPdf from '../ResultsPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useDispatch, useSelector } from 'react-redux';
import FootMapDialog from './FootMapDialog';
import Container from '@mui/material/Container';
import ExitButton from '../../../../ExitButton';
import FootMap from '../../../SensorResults/FootMap';
import html2canvas from 'html2canvas';
import SendResultPdfToPatientButton from './SendResultPdfToPatientButton';
import { ToastContainer } from 'react-toastify';

export const LastTest = () => {
  const dispatch = useDispatch();
  const { scheduleId } = useParams();
  const [loading, setLoading] = useState(false);
  const [scheduleDoc, setScheduleDoc] = useState({});
  const [exercises, setExercises] = useState([]);
  const [results, setResults] = useState([]);
  const [roadmapDoc, setRoadmapDoc] = useState(null);
  const [, setMeanScore] = useState(null);
  const [nextScheduleDoc, setNextScheduleDoc] = useState(null);
  const { user } = useContext(FirebaseAuthContext);
  const startSchedule = useStartSchedule();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const roadmapId = queryParams.get('roadmapId');
  const type = queryParams.get('type');
  const navigate = useNavigate();
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );
  const [sensorData, setSensorData] = useState(false);
  const pressureRefs = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 116, 17, 18, 19, 20,
  ].map(() => React.createRef());
  const [pressureMapImages, setPressureMapImages] = useState({});
  const [redirectTarget, setRedirectTarget] = useState('/all_patients');

  useEffect(() => {
    if (type === 'assignment') {
      setRedirectTarget('/patient_overview/' + patientInfo.id);
    }
  }, [type]);

  useEffect(() => {
    const score = _.meanBy(results, 'score');
    setMeanScore(score);
    if (!roadmapDoc?.recommendations) return;
    if (roadmapDoc.recommendations[scheduleId]) {
      roadmapDoc.recommendations[scheduleId].forEach((recommendation) => {
        if (parseRecommendationConfig(recommendation, score)) {
          getSchedule({ scheduleId: recommendation.schedule }).then(
            (scheduleDoc) => {
              if (scheduleDoc) {
                setNextScheduleDoc(scheduleDoc);
              }
            }
          );
        }
      });
    }
  }, [results, roadmapDoc?.recommendations]);

  useEffect(() => {
    setLoading(true);
    const collection = type === 'assignment' ? 'assignments' : 'schedules';
    getSchedule({ scheduleId, collection }).then((scheduleDoc) => {
      setScheduleDoc(scheduleDoc);
      // Array to hold promises for exercise objects
      const exercisePromises = scheduleDoc.exercises.map((exercise) =>
        getExercise({ exerciseId: exercise })
      );

      // Promise for last results
      const lastResultsPromise = getLastResults({
        exerciseIds: scheduleDoc.exercises,
        scheduleId,
        user,
        patientId: patientInfo?.id,
      });

      // Use Promise.all to wait for all promises to resolve
      Promise.all([...exercisePromises, lastResultsPromise]).then(
        (responses) => {
          // The last element in the responses array will be the results from getLastResults
          const resultsFromLastResults = responses.pop();

          // Now, map the results to the exercise IDs
          const mappedResults = scheduleDoc.exercises.map((id) =>
            resultsFromLastResults.find((obj) => obj.exerciseId === id)
          );

          // Set the results state
          setResults(mappedResults);

          // The rest of the elements are from exercisePromises
          const exerciseObjects = responses.filter(Boolean); // Filter out any undefined/null results

          // Set the exercises state
          setExercises(exerciseObjects);
          setLoading(false);
        }
      );
    });
    if (roadmapId) {
      getRoadmap({ roadmapId }).then((roadmapDoc) => {
        setRoadmapDoc(roadmapDoc);
      });
    }
  }, []);

  useEffect(() => {
    async function setImages() {
      const promises = exercises.map(async (exercise, index) => {
        if (!exercise?.name) return null;
        const canvas = await html2canvas(pressureRefs[index].current);
        const imgData = canvas.toDataURL(`image/pressureMap${index}.png`);
        return { name: exercise.name, imgData: imgData };
      });

      const results = await Promise.all(promises);
      const newPressureMapImages = results.reduce((acc, current) => {
        if (current) {
          acc[current.name] = current.imgData;
        }
        return acc;
      }, {});

      setPressureMapImages(newPressureMapImages);
    }

    setImages();
  }, [exercises]);

  const getMetricsTableData = () => {
    const data = [];
    results?.forEach((result) => {
      const exerciseConf = exercises.find(
        (exercise) => exercise?.id === result?.exerciseId
      );
      result?.metrics?.forEach((metric, metricIndex) => {
        metric.subMetrics.forEach((subMetric, subMetricIndex) => {
          data.push({
            exerciseName: exerciseConf?.name,
            metricName: metric?.name,
            subMetricName: subMetric?.name,
            subMetricValue: subMetric?.value,
            subMetricColor: getValueColor(
              subMetric,
              exerciseConf?.metrics &&
                exerciseConf?.metrics[metricIndex]?.subMetrics &&
                exerciseConf?.metrics[metricIndex]?.subMetrics[subMetricIndex]
            ),
          });
        });
      });
    });
    return data;
  };

  // const downloadExcel = () => {
  //   const data = getMetricsTableData();
  //   const ws = XLSX.utils.json_to_sheet(data);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Results');
  //   XLSX.writeFile(wb, scheduleDoc.name.replace(/\s/g, '_') + '.xlsx');
  // };

  if (!exercises) {
    return <CircularProgress />;
  }

  return (
    <Box
      className="today-session-container"
      sx={{
        // minHeight: '80vh',
        backgroundColor: 'white',
        padding: '4 1',
        marginTop: '1',
        // overflow: 'scroll',
      }}
    >
      <FootMapDialog
        sensorData={sensorData}
        open={Boolean(sensorData)}
        onClose={() => setSensorData(null)}
      />
      {exercises.map((exercise, index) => {
        const sensorData = results[index]?.sensorDataResults;
        return (
          <div
            key={JSON.stringify(exercise)}
            ref={pressureRefs[index]}
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              opacity: 1,
              zIndex: -1,
            }}
          >
            <FootMap
              sensorData={{
                meanLeft: sensorData?.left,
                meanRight: sensorData?.right,
              }}
            />
          </div>
        );
      })}
      {roadmapId && (
        <Grid container spacing={2} sx={{ mb: 4, boxShadow: 4 }}>
          <Grid
            item
            xs={12}
            sx={{
              padding: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'space-between',
              mt: 2,
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(`/roadmap/${roadmapId}`);
              }}
            >
              Roadmap Overview
            </Button>
            {nextScheduleDoc && (
              <Box>
                <Typography variant={'h4'} sx={{ padding: 0 }}>
                  {'Continue with ' + nextScheduleDoc?.name + '!'}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              onClick={() => {
                if (nextScheduleDoc) {
                  startSchedule({
                    scheduleDoc: nextScheduleDoc,
                    index: 0,
                    queryParams: `roadmapId=${roadmapId}&type=${type}`,
                    setCalibrated: false,
                  });
                } else {
                  const scheduleIndex =
                    roadmapDoc.schedules.findIndex(
                      (schedule) => schedule === scheduleId
                    ) + 1;
                  getSchedule({
                    scheduleId: roadmapDoc.schedules[scheduleIndex],
                  }).then((scheduleDoc) => {
                    startSchedule({
                      scheduleDoc,
                      index: 0,
                      queryParams: `roadmapId=${roadmapId}&type=${type}`,
                      setCalibrated: false,
                    });
                  });
                }
              }}
            >
              Next
            </Button>
          </Grid>
          <Divider />
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          sx={{ alignItems: 'right', display: 'flex', justifyContent: 'right' }}
        >
          <div className="box-container">
            <div className="today-session-box">Last Test</div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Container
            maxWidth="xxl"
            sx={{
              backgroundColor: 'transparent',
              display: 'flex',
              alignContent: 'center',
              position: 'relative',
              padding: '.5rem 0',
              zIndex: 1000,
            }}
          >
            <ExitButton
              redirectTarget={redirectTarget}
              callback={() => {
                dispatch({ type: 'RESET-GAIT-PATIENT-INFO' });
              }}
            />
          </Container>
        </Grid>
      </Grid>
      <TestResult name="Date" value={moment().format('DD MMM YYYY')} />

      <Card sx={{ padding: 2, mb: 2, mt: 2 }}>
        <Grid container spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
          <Grid item xs={scheduleDoc?.showScore ? 7 : 10}>
            <Typography variant={'h4'} sx={{ padding: 0 }}>
              Exercises
            </Typography>
          </Grid>
          {scheduleDoc?.showScore && (
            <Grid item xs={1}>
              <Typography variant={'h4'} sx={{ padding: 0 }}>
                Score
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={scheduleDoc?.showScore ? 4 : 2}
            sx={{
              display: 'flex',
              alignItems: 'right',
              justifyContent: 'right',
            }}
          >
            <Typography variant={'h4'} sx={{ padding: 0 }}>
              Restart
            </Typography>
          </Grid>
        </Grid>
        {loading && <CircularProgress />}
        {exercises.map((exercise, index) => {
          const exerciseName = () => {
            if (scheduleDoc.scheduleSpecificExerciseConfigs[exercise?.id]) {
              return (
                scheduleDoc.scheduleSpecificExerciseConfigs[exercise?.id]
                  ?.name || exercise?.name
              );
            }
            return exercise?.name;
          };
          return (
            <Card
              key={exercise?.id}
              onClick={() => {
                setSensorData(results[index]?.sensorDataResults);
              }}
              sx={{
                padding: 2,
                m: 2,
                '&:hover': {
                  backgroundColor: '#f1f1f1',
                  cursor: 'pointer',
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={scheduleDoc.showScore ? 7 : 10}>
                  <Typography variant={'h5'} sx={{ padding: 0 }}>
                    {exerciseName()}
                  </Typography>
                </Grid>
                {scheduleDoc.showScore && (
                  <Grid item xs={1}>
                    <Card
                      sx={{
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                      <Typography
                        variant={'h5'}
                        sx={{ padding: 2, alignText: 'right' }}
                      >
                        {results[index]?.score.toFixed(1)}
                      </Typography>
                    </Card>
                  </Grid>
                )}
                <Grid
                  item
                  xs={scheduleDoc.showScore ? 4 : 2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'right',
                  }}
                >
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    onClick={(e) => {
                      e.stopPropagation();
                      startSchedule({
                        scheduleDoc,
                        index,
                        queryParams: `${queryParams}`,
                      });
                    }}
                  >
                    <PlayCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Card>
          );
        })}
      </Card>
      <Card sx={{ padding: 2 }}>
        <Typography variant={'h4'} sx={{ padding: 0 }}>
          Metrics
        </Typography>
        {results.map((result, index) => {
          const exerciseConf = exercises.find(
            (exercise) => exercise?.id === result?.exerciseId
          );
          return (
            <Card sx={{ padding: 2, mt: 2 }} key={exerciseConf?.id + 'metrics'}>
              <Typography variant={'h6'}>{exerciseConf?.name}</Typography>
              <Grid container spacing={2}>
                {result?.metrics?.map((metric, metricIndex) => {
                  return (
                    <Grid item xs={6} key={metric?.name + index}>
                      <MetricBox
                        metric={metric}
                        metricExerciseConfig={
                          exerciseConf?.metrics &&
                          exerciseConf?.metrics[metricIndex]
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Card>
          );
        })}
      </Card>
      <div className="join-session-btn-container">
        <Button
          type="submit"
          variant="contained"
          className="join-session-btn"
          sx={{
            ...buttonStyle,
          }}
          onClick={() =>
            startSchedule({ scheduleDoc: scheduleDoc, setCalibrated: false })
          }
        >
          Try Again
        </Button>
        {/*<Button
          type="submit"
          variant="contained"
          className="join-session-btn"
          sx={{
            ...buttonStyle,
            ml: 3,
            backgroundColor: '#1ed61e',
          }}
          onClick={() => downloadExcel()}
        >
          Download as Excel
        </Button>*/}
        <Button
          type="submit"
          variant="contained"
          className="join-session-btn"
          sx={{
            ...buttonStyle,
            ml: 3,
            backgroundColor: '#1ed61e',
          }}
        >
          <PDFDownloadLink
            style={{ color: 'white' }}
            document={
              <ResultsPdf
                personInfo={patientInfo}
                tableData={getMetricsTableData()}
                images={pressureMapImages}
              />
            }
            fileName="report.pdf"
          >
            {({ loading }) =>
              loading ? 'Loading document...' : 'Download as PDF'
            }
          </PDFDownloadLink>
        </Button>
        <SendResultPdfToPatientButton
          patientInfo={patientInfo}
          tableData={getMetricsTableData()}
          images={{}}
          loadingResults={loading}
        />
      </div>
      <ToastContainer />
    </Box>
  );
};

const buttonStyle = {
  borderRadius: 2,
  fontSize: '1.5rem',
  backgroundColor: '#FF592C',
};

export default LastTest;
