import React from 'react';
import PointsComponent from './PointsComponent';
import TopThreeComponent from './TopThreeComponent';
import TopThreeClubStudentsComponent from './TopThreeClubStudentsComponent';
import ArticleNavigation from './ArticleNavigation';
import { useTranslation } from 'react-i18next';

const OverviewComponent = ({ data, topthree }) => {
  const { t } = useTranslation();
  const full_user = JSON.parse(localStorage.getItem('full_user'));
  const usertype = full_user.user_details ? full_user.user_details.type : 'b2b';

  return (
    // <StyleRoot>
    <div className="top-left article">
      <ArticleNavigation
        top={true}
        title={t('OverviewComponent.PatientOverview')}
      />
      <PointsComponent data={data} />
      {/* <TopThreeComponent topthree={topthree} /> */}
      {usertype && usertype === 'b2c' ? (
        <TopThreeClubStudentsComponent top={true} topthree={topthree} />
      ) : (
        <TopThreeComponent topthree={topthree} />
      )}
    </div>
    // </StyleRoot>
  );
};

export default OverviewComponent;
