import React from 'react';
import {
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';

const AgeGroup = (props) => {
  const { nextStep, prevStep, open, setOpen, setBirthdate } = props;
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [progress] = React.useState(65);
  const [buffer] = React.useState(100);

  const birthdate = watch('birthdate');

  const continueNext = () => {
    setBirthdate(birthdate);
    nextStep();
  };

  const backPrevious = () => {
    prevStep();
  };

  useEffect(() => {}, [birthdate]);

  return (
    <Box>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 10,
            maxheight: '53%',
            width: '95vw',
            padding: 2,
          },
        }}
        scroll="body"
        BackdropComponent={styled(Backdrop, {
          name: 'MuiModal',
          slot: 'Backdrop',
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({
          zIndex: -1,
          backgroundColor: 'rgb(242, 247, 247,0.5)',
        })}
        open={open}
        onClose={() => setOpen(false)}
      >
        <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
          color="secondary"
        />

        <form onSubmit={handleSubmit(continueNext)}>
          <DialogContent style={{ overflow: 'hidden' }}>
            <DialogTitle sx={{ fontSize: 18 }}>
              {t('signUpComponent.ageGroup')}
              <span
                style={{ float: 'right', color: 'grey', cursor: 'pointer' }}
                onClick={() => setOpen(false)}
              >
                &#x2715;
              </span>
            </DialogTitle>
            <Controller
              name="birthdate"
              control={control}
              defaultValue=""
              rules={{
                required: 'Birthdate is required',
                validate: (value) => {
                  const age =
                    new Date().getFullYear() - new Date(value).getFullYear();
                  console.log(age);
                  if (age >= 6 && age <= 17)
                    return 'Please enter a valid birthdate';
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  variant="outlined"
                  className="inputRounded"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.birthdate}
                  helperText={errors.birthdate?.message}
                />
              )}
            />
          </DialogContent>
          <DialogActions
            disableSpacing={true}
            sx={{ justifyContent: 'center', height: '10%' }}
          >
            <Button
              type="submit"
              disabled={!birthdate}
              variant="contained"
              sx={{
                borderRadius: 20,
                backgroundColor: '#FF592C',
                marginLeft: '5%',
              }}
            >
              {t('signUpComponent.continue')}
            </Button>
            {!birthdate && (
              <Tooltip title="Please enter your birthdate!">
                <span>
                  <ErrorIcon
                    style={{ float: 'centre', color: 'red', cursor: 'pointer' }}
                  />
                </span>
              </Tooltip>
            )}
            <Button
              type="button"
              variant="contained"
              sx={{
                borderRadius: 20,
                backgroundColor: '#FF592C',
                marginLeft: '5%',
              }}
              onClick={backPrevious}
            >
              {t('signUpComponent.back')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default AgeGroup;
