import { drawAngle } from '../../angles';
import { findAngle, findDependentAngle } from '../../physio';
import { blazePoseCoordinateNames } from './coordinates';

export function angleConfigComputation({
  config,
  canvasCtx,
  display,
  results,
  isLeft,
}) {
  if (config.type === 'Front') {
    return angleConfigComputationFront({
      config: config.angleCptConfig,
      canvasCtx,
      display,
      results,
    });
  } else if (config.type === 'Side') {
    return angleConfigComputationSide({
      config: config.angleCptConfig,
      canvasCtx,
      display,
      results,
      isLeft,
      forceSide: config.forceSide,
    });
  } else {
    throw new Error('Invalid Angle type', config.type);
  }
}

function angleConfigComputationSide({
  config,
  canvasCtx,
  display,
  results,
  isLeft,
  forceSide,
}) {
  let angle;
  let conf;
  if (forceSide === 'left') {
    if (isLeft) {
      conf = config.left.left;
    } else {
      conf = config.left.right;
    }
  } else {
    if (isLeft) {
      conf = config.right.left;
    } else {
      conf = config.right.right;
    }
  }
  angle = angleConfigComputationFront({
    config: conf,
    canvasCtx,
    display,
    results,
    isLeft,
  });
  return angle;
}

function angleConfigComputationFront({
  config,
  canvasCtx,
  display,
  results,
  displayLeft,
}) {
  let angle = 0;
  let displayJointAnchor;
  if (config.function === 'simpleAngle') {
    [angle, displayJointAnchor] = computeSimpleAngle({
      config: config.functionConfig,
      results,
    });
  } else if (config.function === 'dependentAngle') {
    [angle, displayJointAnchor] = computeDependentAngle({
      config: config.functionConfig,
      results,
    });
  } else {
    throw new Error('Invalid Angle function', config.function);
  }
  if (display) {
    drawAngle(canvasCtx, angle, displayJointAnchor, displayLeft);
  }
  return angle;
}

function computeSimpleAngle({ config, results }) {
  const coord0 = blazePoseCoordinateNames[config.coord0](results);
  const coord1 = blazePoseCoordinateNames[config.coord1](results);
  return [findAngle(coord0, coord1, Number(config.respect || 0)), coord1];
}

function computeDependentAngle({ config, results }) {
  const coord0 = blazePoseCoordinateNames[config.coord0](results);
  const coord1 = blazePoseCoordinateNames[config.coord1](results);
  const coord2 = blazePoseCoordinateNames[config.coord2](results);
  return [
    findDependentAngle(coord0, coord1, coord2, false, Number(config.respect)),
    coord1,
  ];
}
