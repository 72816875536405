import React from 'react';
import '@tensorflow/tfjs-backend-webgl';
import { useBalanceCoreFeedback } from '../../../hooks/Demo/feedbackTypes';
import {
  findAngle,
  findCounterAngle,
  findDependentAngle,
} from '../../../utilities/Demo/physio';
import PoseDetection from '../PoseDetection';
import {
  checkIfSide,
  findSideFromHorizontalPose,
} from '../../../utilities/Demo/angles';
import { loadPushUpAudio } from '../../../utilities/Demo/audioFeedback';
import { safeIterScore } from '../../../hooks/Demo/sharedLogic';
import { useDispatch } from 'react-redux';

function PlankPoseDemo({ onComplete }) {
  const dispatch = useDispatch();
  const timeThresh = 60;
  const {
    holdsPose,
    secondsPoseHeld,
    secondPoseHeldStarted,
    secondsPoseHeldComplete,
    updateFeedback,
    updateSecondsPoseHeld,
    seconds,
    playAudio,
    audioFeedback,
    setSide,
    cptScore,
    updatePoseHeldDeps,
    finishedIntro,
  } = useBalanceCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadPushUpAudio,
  });

  cptScore.current = () => {
    return (
      (secondsPoseHeldComplete.current + secondsPoseHeld.current) / timeThresh
    );
  };

  function giveFeedback(
    shoulderAngle,
    kneeAngle,
    shoulderElbowAngle,
    ankleShoulderEarAngle,
    hipAngle
  ) {
    if (shoulderAngle <= 80) {
      updateFeedback('Go lower!');
      playAudio(audioFeedback.current?.goLower);
      return;
    }
    if (kneeAngle >= 200) {
      updateFeedback('Keep your legs straight!');
      playAudio(audioFeedback.current?.straightenLegs);
      return;
    }
    if (shoulderElbowAngle > 20) {
      updateFeedback('Keep your elbows in!');
      playAudio(audioFeedback.current?.elbowsIn);
      return;
    }
    if (shoulderAngle <= 95 && shoulderAngle >= 50) {
      if (ankleShoulderEarAngle <= 145 || hipAngle > 195 || hipAngle < 165) {
        if (ankleShoulderEarAngle <= 145) {
          updateFeedback('Straighten your neck!');
          playAudio(audioFeedback.current?.straightenNeck);
          return;
        }
        if (hipAngle > 195) {
          updateFeedback('Keep your hips up!');
          playAudio(audioFeedback.current?.hipsUp);
          return;
        }
        if (hipAngle < 165) {
          updateFeedback('Lower your hips!');
          playAudio(audioFeedback.current?.hipsLower);
        }
      }
    }
  }

  function observeBalance(
    ankleShoulderEarAngle,
    shoulderAngle,
    hipAngle,
    shoulderElbowAngle,
    kneeAngle
  ) {
    const state = holdsPose.current;
    if (
      ankleShoulderEarAngle > 145 &&
      shoulderAngle > 80 &&
      hipAngle <= 195 &&
      hipAngle >= 165 &&
      shoulderElbowAngle < 20 &&
      kneeAngle < 200
    ) {
      updateFeedback('');
      holdsPose.current = true;
      secondsPoseHeld.current = seconds.current - secondPoseHeldStarted.current;
    } else {
      holdsPose.current = false;
      giveFeedback(
        shoulderAngle,
        kneeAngle,
        shoulderElbowAngle,
        ankleShoulderEarAngle,
        hipAngle
      );
    }
    updatePoseHeldDeps(state);
  }

  function onResults(results) {
    if (!finishedIntro.current) return;

    const side = findSideFromHorizontalPose(results);

    let shoulderAnkleAngle;
    let ankleShoulderEarAngle;
    let hipAngle;
    let shoulderElbowAngle;
    let kneeAngle;

    if (side === 'left') {
      ankleShoulderEarAngle = findDependentAngle(
        results.poseLandmarks[7],
        results.poseLandmarks[11],
        results.poseLandmarks[27]
      );
      shoulderAnkleAngle = findCounterAngle(
        [results.poseLandmarks[11].x, results.poseLandmarks[11].y],
        [results.poseLandmarks[27].x, results.poseLandmarks[27].y],
        90
      );
      hipAngle = findDependentAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[23],
        results.poseLandmarks[27]
      );
      shoulderElbowAngle = findAngle(
        results.poseLandmarks[11],
        results.poseLandmarks[13],
        90
      );
      kneeAngle = findDependentAngle(
        results.poseLandmarks[27],
        results.poseLandmarks[25],
        results.poseLandmarks[23]
      );
    } else {
      ankleShoulderEarAngle = findDependentAngle(
        results.poseLandmarks[8],
        results.poseLandmarks[12],
        results.poseLandmarks[28]
      );
      shoulderAnkleAngle = findAngle(
        [results.poseLandmarks[12].x, results.poseLandmarks[12].y],
        [results.poseLandmarks[28].x, results.poseLandmarks[28].y],
        90
      );
      hipAngle = findDependentAngle(
        results.poseLandmarks[12],
        results.poseLandmarks[24],
        results.poseLandmarks[28]
      );
      shoulderElbowAngle = findAngle(
        results.poseLandmarks[12],
        results.poseLandmarks[14],
        90
      );
      kneeAngle = findDependentAngle(
        results.poseLandmarks[24],
        results.poseLandmarks[26],
        results.poseLandmarks[28]
      );
    }

    let isSide = checkIfSide(results);
    if (isSide) {
      setSide(true);
      updateFeedback('');
      observeBalance(
        ankleShoulderEarAngle,
        shoulderAnkleAngle,
        hipAngle,
        shoulderElbowAngle,
        kneeAngle
      );
    } else {
      setSide(false);
      updateFeedback('Turn to the side!');
      playAudio(audioFeedback.current?.turnToSide);
    }

    // Display the counts
    updateSecondsPoseHeld(
      secondsPoseHeldComplete.current + secondsPoseHeld.current
    );
    safeIterScore(dispatch, cptScore());
  }

  return <PoseDetection onResults={onResults} showWebcam={false} />;
}

export default React.memo(PlankPoseDemo);
