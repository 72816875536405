import React from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import BgComponent from '../../components/Background/BgComponent';
import DrawerComponent from '../../components/DrawerComponent';
import { TrainerContainer } from '../../components/Trainer/TrainerContainer';

export const Trainers = () => {
  return (
    <div className="teacher-home">
      <BgComponent pageName={'schedule'} />
      <HeaderComponent />
      <DrawerComponent />
      <TrainerContainer />
    </div>
  );
};
