import Grid from '@mui/material/Grid';
import React from 'react';
import './ranking.css';
import av1 from '../../assets/av1.png';
import viewAll from '../../assets/view-all.png';
import { useNavigate } from 'react-router';

const TopThree = ({ ranks, pageName }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      {ranks &&
        ranks.map(
          (item, index) =>
            index < 3 && (
              // eslint-disable-next-line react/jsx-key
              <Grid item xs={4}>
                <div className={'item'}>
                  <img
                    className="avtar"
                    src={item.profile_pic ? item.profile_pic : av1}
                    alt="avatar"
                  />
                  <span>{item && item.name}</span>
                </div>
              </Grid>
            )
        )}
      {pageName === 'class-progress' && (
        <div className="item hoverable" onClick={() => navigate('/class-home')}>
          <img src={viewAll} alt="avatar" />
          <span>View All</span>
        </div>
      )}
    </Grid>
  );
};

export default TopThree;
