import React from 'react';
import '../../../views/Demo/summary.css';

export default function HeaderBox({ children }) {
  return (
    <div
      className="box--container bottom-box"
      style={{ width: '100%', marginTop: '20px', paddingBottom: '20px' }}
    >
      <div className="top-bar">
        <div className="left">
          <div className="orange-box" />
          <h2>{children}</h2>
        </div>
      </div>
    </div>
  );
}
