const initialState = { values: { total: 0 } };

export const sweatPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SWEAT_POINTS_COUNT': {
      return { values: { ...state.values, ...action.payload } };
    }
    case 'RESET_SWEAT_POINTS_COUNT':
      return initialState;
    default: {
      return state;
    }
  }
};
