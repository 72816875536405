import React, { useEffect } from 'react';
import Button from '@mui/material/Button';

const VideoSelector = ({ videoUrl, setVideoUrl }) => {
  // Clean up the object URL
  useEffect(() => {
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, [videoUrl]);

  const handleFileChange = (event) => {
    // Clean up the previous object URL
    if (videoUrl) {
      URL.revokeObjectURL(videoUrl);
    }

    const file = event.target.files[0];
    if (file != null) {
      const url = URL.createObjectURL(file);
      setVideoUrl(url);
    }
  };

  return (
    <div>
      <input
        accept="video/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" color="secondary">
          Upload Video
        </Button>
      </label>
    </div>
  );
};

export default VideoSelector;
