import React from 'react';
import './trainers.css';
import heart from '../../assets/heart.png';
import badge from '../../assets/badge.png';
import tooltipCircle from '../../assets/tooltip_circle.png';
import Tooltip from '@mui/material/Tooltip';
import Slider from '@mui/material/Slider';

export const Stats = ({ level, streak }) => {
  return (
    <div className="stats_cont">
      <div className="levels_box">
        <div className="levels_cont">
          <div className="levels">
            <img src={badge} />
            <div>Level</div>
            <Tooltip
              title="Trainer Level"
              placement="right-start"
              arrow
              style={{ background: 'red' }}
            >
              <img src={tooltipCircle} style={{ cursor: 'pointer' }} />
            </Tooltip>
          </div>
          <div className="level_count">
            <div className="circle_count">{level}</div>
          </div>
        </div>
        <Slider
          // value={level}
          aria-label="Default"
          valueLabelDisplay="auto"
          defaultValue={level}
          max={20}
          sx={{
            '& .MuiSlider-thumb': {
              color: '#30b38c',
            },
            '& .MuiSlider-track': {
              color: '#30b38c',
            },
            '& .MuiSlider-rail': {
              color: '#E7E7E1',
            },
          }}
        />
      </div>
      <div className="levels_box">
        <div className="levels_cont">
          <div className="levels">
            <img src={heart} />
            <div>Streak</div>
            <Tooltip title="Exercise Streak" placement="right-start" arrow>
              <img src={tooltipCircle} style={{ cursor: 'pointer' }} />
            </Tooltip>
          </div>
          <div className="level_count">
            <div className="circle_count" style={{ background: '#E45F35' }}>
              {streak}
            </div>
          </div>
        </div>
        <Slider
          aria-label="Default"
          valueLabelDisplay="auto"
          max={150}
          defaultValue={streak}
          sx={{
            '& .MuiSlider-thumb': {
              color: '#E45F35',
            },
            '& .MuiSlider-track': {
              color: '#E45F35',
            },
            '& .MuiSlider-rail': {
              color: '#E7E7E1',
            },
          }}
        />
      </div>
    </div>
  );
};
