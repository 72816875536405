import Typography from '@mui/material/Typography';
import React from 'react';
// import { useTranslation } from 'react-i18next';
import '../assessments.css';
import cardio from '../../../../assets/cardio.png';
import mendurance from '../../../../assets/mendurance.png';
import mstrength from '../../../../assets/mstrength.png';
import sbalance1 from '../../../../assets/sbalance1.png';
import squats from '../../../../assets/squats.png';
import ninja from '../../../../assets/ninja.png';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import PlaceAlternateFootIcon from '../../../../assets/BergBalance/placeAlternateFoot.png';
import ReachForwardIcon from '../../../../assets/BergBalance/Reaching forward.png';
import RetrieveObjectFromFloorIcon from '../../../../assets/BergBalance/Retrieve object from floor.png';
import SitToStandIcon from '../../../../assets/BergBalance/Sitting to Standing.png';
import SitUnsupportedIcon from '../../../../assets/BergBalance/Sitting Unsupported.png';
import StandOnOneFootIcon from '../../../../assets/BergBalance/Standing on One Foot.png';
import StandUnsupportedIcon from '../../../../assets/BergBalance/Standing Unsupported.png';
import StandWithEyesClosedIcon from '../../../../assets/BergBalance/Standing eyes closed.png';
import StandWithFeetTogetherIcon from '../../../../assets/BergBalance/Standing with feet together.png';
import StandWithOneFootInFrontIcon from '../../../../assets/BergBalance/Standing with One Foot in Front.png';
import TransfersIcon from '../../../../assets/BergBalance/Transfers.png';
import Turn360DegreesIcon from '../../../../assets/BergBalance/Turning 360 Degrees.png';
import TurnToLookBehindIcon from '../../../../assets/BergBalance/Turning to Look Behind.png';
import StandToSitIcon from '../../../../assets/BergBalance/Standing to Sitting.png';
import './bergsAssessment.css';

const TitleText = ({ children }) => {
  return (
    <Typography fontSize={14} sx={{ paddingLeft: 3 }}>
      {children}
    </Typography>
  );
};

export const AssessmentPath = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:900px)');
  const handleExerciseClick = (iteration) => {
    return () => {
      dispatch({
        type: 'SET-ITER-VAlUES',
        payload: {
          iteration: iteration,
          maxScore: 1,
          overviewRedirect: null,
          exerciseIds: null,
        },
      });
      dispatch({ type: 'RESET-INTRO' });
      navigate('/exercise/berg_balance_assessment');
    };
  };
  return (
    <>
      {matches ? (
        <div className="assessment-cont">
          <div className="assessment-path">
            <ArcherContainer strokeColor="#000000" strokeDasharray="2,2,4,5,6">
              <div
                style={{
                  display: 'flex',
                  height: '50vh',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div className="many_items_row">
                  <ArcherElement
                    id="root"
                    relations={[
                      {
                        targetId: 'sb2',
                        targetAnchor: 'top',
                        sourceAnchor: 'bottom',
                        style: {
                          endMarker: false,
                        },
                      },
                    ]}
                  >
                    <div
                      className="img_container_berg"
                      id="flexi"
                      onClick={handleExerciseClick(0)}
                    >
                      <div>Squats</div>
                      <img src={squats} />
                    </div>
                  </ArcherElement>
                  <ArcherElement id="mstren">
                    <div
                      className="img_container_berg"
                      onClick={handleExerciseClick(2)}
                    >
                      <div>Muscular Strength</div>
                      <img src={mstrength} />
                    </div>
                  </ArcherElement>
                </div>
                <div
                  className="many_items_row"
                  style={{ justifyContent: 'center' }}
                >
                  <ArcherElement
                    id="sb2"
                    relations={[
                      {
                        targetId: 'cdio',
                        targetAnchor: 'bottom',
                        sourceAnchor: 'top',
                      },
                    ]}
                  >
                    <div
                      className="img_container_berg"
                      onClick={handleExerciseClick(4)}
                    >
                      <div>Static Balance 2</div>
                      <img src={ninja} />
                    </div>
                  </ArcherElement>
                </div>
              </div>
            </ArcherContainer>
          </div>
          <div className="assessment-path">
            <ArcherContainer strokeColor="#000000" strokeDasharray="2,2,4,5,6">
              <div
                style={{
                  display: 'flex',
                  height: '50vh',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <div className="many_items_row">
                  <ArcherElement
                    id="mend"
                    relations={[
                      {
                        targetId: 'cdio',
                        targetAnchor: 'top',
                        sourceAnchor: 'bottom',
                        style: {
                          endMarker: false,
                        },
                      },
                    ]}
                  >
                    <div
                      className="img_container_berg"
                      onClick={handleExerciseClick(1)}
                    >
                      <div>Muscular Endurance</div>
                      <img src={mendurance} />
                    </div>
                  </ArcherElement>
                  <ArcherElement id="sb1">
                    <div
                      className="img_container_berg"
                      onClick={handleExerciseClick(3)}
                    >
                      <div>Static Balance 1</div>
                      <img src={sbalance1} />
                    </div>
                  </ArcherElement>
                </div>
                <div
                  className="many_items_row"
                  style={{ justifyContent: 'center' }}
                >
                  <ArcherElement
                    id="cdio"
                    relations={[
                      {
                        targetId: 'sb2',
                        targetAnchor: 'bottom',
                        sourceAnchor: 'top',
                        style: {
                          endMarker: false,
                        },
                      },
                    ]}
                  >
                    <div
                      className="img_container_berg"
                      onClick={handleExerciseClick(5)}
                    >
                      <div>Cardio Vascular</div>
                      <img src={cardio} />
                    </div>
                  </ArcherElement>
                </div>
              </div>
            </ArcherContainer>
          </div>
        </div>
      ) : (
        <div
          className="top-left article"
          style={{
            padding: '5% 4px',
            height: '95vh',
            width: 'auto',
            marginTop: '1em',
            marginBottom: 4,
          }}
        >
          <ArcherContainer strokeColor="#000000" strokeDasharray="2,2,4,5,6">
            <div
              style={{
                display: 'flex',
                height: '60vh',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div className="many_items_row">
                <ArcherElement
                  id="root"
                  relations={[
                    {
                      targetId: 'standingunsupported',
                      targetAnchor: 'left',
                      sourceAnchor: 'right',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    id="flexi"
                    onClick={handleExerciseClick(0)}
                  >
                    <TitleText>Sit to Stand</TitleText>
                    <img src={SitToStandIcon} />
                  </div>
                </ArcherElement>
                <ArcherElement
                  id="standingunsupported"
                  relations={[
                    {
                      targetId: 'sittingunsupported',
                      targetAnchor: 'left',
                      sourceAnchor: 'right',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    id="flexi"
                    onClick={handleExerciseClick(1)}
                  >
                    <TitleText>Stand Unsupported</TitleText>
                    <img src={StandUnsupportedIcon} />
                  </div>
                </ArcherElement>
                <ArcherElement
                  id="sittingunsupported"
                  relations={[
                    {
                      targetId: 'standingtositting',
                      targetAnchor: 'left',
                      sourceAnchor: 'right',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(2)}
                  >
                    <TitleText>Sit Unsupported</TitleText>
                    <img src={SitUnsupportedIcon} />
                  </div>
                </ArcherElement>
                <ArcherElement
                  id="standingtositting"
                  relations={[
                    {
                      targetId: 'transfers',
                      targetAnchor: 'top',
                      sourceAnchor: 'bottom',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(3)}
                  >
                    <TitleText>Stand to Sit</TitleText>
                    <img src={StandToSitIcon} />
                  </div>
                </ArcherElement>
              </div>
              <div
                className="many_items_row"
                style={{ justifyContent: 'center' }}
              >
                <ArcherElement
                  id="reachingforward"
                  relations={[
                    {
                      targetId: 'retrieveobjectfromfloor',
                      targetAnchor: 'top',
                      sourceAnchor: 'bottom',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(7)}
                  >
                    <img src={ReachForwardIcon} />
                    <TitleText>Reach Forward</TitleText>
                  </div>
                </ArcherElement>
                <ArcherElement
                  id="standingwithfeettogether"
                  relations={[
                    {
                      targetId: 'reachingforward',
                      targetAnchor: 'right',
                      sourceAnchor: 'left',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(6)}
                  >
                    <img src={StandWithFeetTogetherIcon} />
                    <TitleText>Stand with Feet Together</TitleText>
                  </div>
                </ArcherElement>
                <ArcherElement
                  id="standingwitheyesclosed"
                  relations={[
                    {
                      targetId: 'standingwithfeettogether',
                      targetAnchor: 'right',
                      sourceAnchor: 'left',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(5)}
                  >
                    <img src={StandWithEyesClosedIcon} />
                    <TitleText>Stand with Eyes Closed</TitleText>
                  </div>
                </ArcherElement>
                <ArcherElement
                  id="transfers"
                  relations={[
                    {
                      targetId: 'standingwitheyesclosed',
                      targetAnchor: 'right',
                      sourceAnchor: 'left',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(4)}
                  >
                    <img src={TransfersIcon} />
                    <TitleText>Transfers</TitleText>
                  </div>
                </ArcherElement>
              </div>
              <div
                className="many_items_row"
                style={{ justifyContent: 'center' }}
              >
                <ArcherElement
                  id="retrieveobjectfromfloor"
                  relations={[
                    {
                      targetId: 'turningtolookbehind',
                      targetAnchor: 'left',
                      sourceAnchor: 'right',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(8)}
                  >
                    <img src={RetrieveObjectFromFloorIcon} />
                    <TitleText>Retrieve Object from Floor</TitleText>
                  </div>
                </ArcherElement>
                <ArcherElement
                  id="turningtolookbehind"
                  relations={[
                    {
                      targetId: 'turning360degrees',
                      targetAnchor: 'left',
                      sourceAnchor: 'right',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(9)}
                  >
                    <img src={TurnToLookBehindIcon} />
                    <TitleText>Turn to Look Behind</TitleText>
                  </div>
                </ArcherElement>
                <ArcherElement
                  id="turning360degrees"
                  relations={[
                    {
                      targetId: 'placingalternatefootonstep',
                      targetAnchor: 'left',
                      sourceAnchor: 'right',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(10)}
                  >
                    <img src={Turn360DegreesIcon} />
                    <TitleText>Turn 360 Degrees</TitleText>
                  </div>
                </ArcherElement>
                <ArcherElement
                  id="placingalternatefootonstep"
                  relations={[
                    {
                      targetId: 'standingwithonefootinfront',
                      targetAnchor: 'right',
                      sourceAnchor: 'bottom',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(11)}
                  >
                    <img src={PlaceAlternateFootIcon} />
                    <TitleText>Place Alternate Foot on Step</TitleText>
                  </div>
                </ArcherElement>
              </div>
              <div
                className="many_items_row"
                style={{ justifyContent: 'right', width: '75%' }}
              >
                <ArcherElement id="standingononefoot">
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(13)}
                  >
                    <img src={StandOnOneFootIcon} />
                    <TitleText>Stand on One Foot</TitleText>
                  </div>
                </ArcherElement>
                <ArcherElement
                  id="standingwithonefootinfront"
                  relations={[
                    {
                      targetId: 'standingononefoot',
                      targetAnchor: 'right',
                      sourceAnchor: 'left',
                      style: {
                        endMarker: false,
                      },
                    },
                  ]}
                >
                  <div
                    className="img_container_berg"
                    onClick={handleExerciseClick(12)}
                  >
                    <img src={StandWithOneFootInFrontIcon} />
                    <TitleText>Stand with One Foot in Front</TitleText>
                  </div>
                </ArcherElement>
              </div>
            </div>
          </ArcherContainer>
        </div>
      )}
    </>
  );
};
