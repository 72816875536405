import { baseUrl } from '../../constants';
import axios from 'axios';
import { getRankings } from './getDashboard';

export const updateStudentDp = async (
  profile_pic,
  setDpurl,
  setLoader,
  setParse,
  dispatch
) => {
  setLoader(true);
  let token = await localStorage.getItem('token');
  try {
    let response = await axios.put(
      `${baseUrl}/profilepic`,
      { profile_pic },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log('Status is ::' + JSON.stringify(response));
    setDpurl(response.data.user.profile_pic);
    dispatch({ type: 'USER', payload: response.data.user });
    localStorage.removeItem('user_data');
    localStorage.setItem('user_data', JSON.stringify(response.data.user));
    await getRankings(dispatch, (a) => a);
    setLoader(false);
    setParse(true);
  } catch (error) {
    console.log('eror::' + JSON.stringify(error));
    setLoader(false);
  }
};
