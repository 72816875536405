import React from 'react';
import {
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';

const AgeGroup = (props) => {
  const { nextStep, prevStep, open, setOpen, ageGroup, setAgeGroup } = props;
  console.log('propspropsprops from age', props);
  const { t } = useTranslation();
  const { control } = useForm();
  const [progress] = React.useState(65);
  const [buffer] = React.useState(100);

  const continueNext = () => {
    // e.preventDefault();
    nextStep();
  };
  const backPrevious = () => {
    // e.preventDefault();
    prevStep();
  };

  const handleClick = (e) => {
    let selectedAge = e.target.value;
    setAgeGroup(selectedAge);
  };

  useEffect(() => {}, [ageGroup]);

  return (
    <Box>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: 10,
            maxheight: '53%',
            width: '95vw',
            padding: 2,
          },
        }}
        scroll="body"
        BackdropComponent={styled(Backdrop, {
          name: 'MuiModal',
          slot: 'Backdrop',
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({
          zIndex: -1,
          backgroundColor: 'rgb(242, 247, 247,0.5)',
        })}
        open={open}
        onClose={() => setOpen(false)}
      >
        <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
          color="secondary"
        />

        <form>
          <DialogContent style={{ overflow: 'hidden' }}>
            <DialogTitle sx={{ fontSize: 18 }}>
              {t('signUpComponent.ageGroup')}
              <span
                style={{ float: 'right', color: 'grey', cursor: 'pointer' }}
                onClick={() => setOpen(false)}
              >
                &#x2715;
              </span>
            </DialogTitle>
            <Controller
              name="age-group-6to9"
              control={control}
              render={() => (
                <TextField
                  id="standard-read-only-input"
                  name="age-group-6to9"
                  type="button"
                  defaultValue={t('signUpComponent.6-9')}
                  onClick={handleClick}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  sx={{ width: '100%', height: '50%', marginBottom: 1 }}
                  className="inputRounded"
                  InputLabelProps={{ disableAnimation: true }}
                  shrink={true}
                />
              )}
              value={ageGroup}
            />
            <Controller
              name="age-group-10to13"
              control={control}
              render={() => (
                <TextField
                  id="standard-read-only-input"
                  name="age-group-10to13"
                  type="button"
                  defaultValue={t('signUpComponent.10-13')}
                  onClick={handleClick}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  sx={{ width: '100%', height: '50%', marginBottom: 1 }}
                  className="inputRounded"
                  InputLabelProps={{ disableAnimation: true }}
                  shrink={true}
                />
              )}
              value={ageGroup}
            />
            <Controller
              name="age-group-14to17"
              control={control}
              render={() => (
                <TextField
                  id="standard-read-only-input"
                  name="age-group-14to17"
                  type="button"
                  defaultValue={t('signUpComponent.14-17')}
                  onClick={handleClick}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  sx={{ width: '100%', height: '50%', marginBottom: 1 }}
                  className="inputRounded"
                  InputLabelProps={{ disableAnimation: true }}
                  shrink={true}
                />
              )}
              value={ageGroup}
            />
            <div
              className="selected-age-group"
              style={{ float: 'left', color: 'grey', cursor: 'pointer' }}
            >
              <p>Selected : {ageGroup}</p>
            </div>
          </DialogContent>
          <DialogActions
            disableSpacing={true}
            sx={{ justifyContent: 'center', height: '10%' }}
          >
            <Button
              // type="submit"
              disabled={!ageGroup.length}
              variant="contained"
              sx={{
                borderRadius: 20,
                backgroundColor: '#FF592C',
                marginLeft: '5%',
              }}
              onClick={() => continueNext()}
            >
              {t('signUpComponent.continue')}
            </Button>
            {!ageGroup.length && (
              <Tooltip title="Please select the age group!">
                <span>
                  <ErrorIcon
                    style={{ float: 'centre', color: 'red', cursor: 'pointer' }}
                  />
                </span>
              </Tooltip>
            )}
            <Button
              // type="submit"
              variant="contained"
              sx={{
                borderRadius: 20,
                backgroundColor: '#FF592C',
                marginLeft: '5%',
              }}
              onClick={() => backPrevious()}
            >
              {t('signUpComponent.back')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default AgeGroup;
