import React, { useEffect } from 'react';
import { useState } from 'react';
import './ranking.css';
import SearchBar from './SearchBar';
import { Divider } from '@mui/material';
import StudentRow from './StudentRow';
import { useTranslation } from 'react-i18next';

const AllStudents = ({ details }) => {
  const { t } = useTranslation();
  // let details = [
  //   { name: 'Rajat', bmi: 'healthy', sweatpoints: 450, rank: 1 },
  //   { name: 'Sajid', bmi: 'chubby', sweatpoints: 450, rank: 2 },
  //   { name: 'Aslam', bmi: 'healthy', sweatpoints: 45, rank: 3 },
  //   { name: 'Babu', bmi: 'healthy', sweatpoints: 50, rank: 4 },
  //   { name: 'Raman', bmi: 'chubby', sweatpoints: 450, rank: 5 },
  //   { name: 'Rajat', bmi: 'healthy', sweatpoints: 450, rank: 6 },
  // ];
  const [filter, setFilter] = useState('');
  const [ar, setAr] = useState(details);
  let det = details;
  useEffect(() => {
    console.log('details is::', ar);
    if (filter !== '')
      det = details.filter((item) =>
        new RegExp('^' + filter, 'i').test(item.name)
      );
    setAr(det);
  }, [filter]);
  useEffect(() => {
    console.log('details is::', details);
    setAr(details);
  }, [details]);
  return (
    <div className="all-students-container ranking-article">
      <div className="all-students-navigation">
        <div className="title">
          <div className="violet-rect" />
          <h1>{t('allStudentsRank.title')}</h1>
        </div>
        <SearchBar
          className="search-input"
          setFilter={setFilter}
          filter={filter}
        />
      </div>
      <div className="gridder">
        <span>{t('allStudentsRank.students')}</span>
        <span>{t('allStudentsRank.stages')}</span>
        <span>{t('PointsComponent.sweatPoints')}</span>
        <span>{t('allStudentsRank.rank')}</span>
        <span>{t('allStudentsRank.workoutCompleted')}</span>
        <span>{t('PointsComponent.xrCoins')}</span>
      </div>
      <Divider style={{ backgroundColor: 'rgba(0, 0, 0, .12)' }} />
      {ar &&
        ar.map((item, index) => (
          <StudentRow key={'student-row-' + index} details={item} />
        ))}
    </div>
  );
};

export default AllStudents;
