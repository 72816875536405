export const turnToSide = (lang) => {
  switch (lang) {
    case 'en':
      return 'Turn to the side!';
    case 'ger':
      return 'Drehen Sie sich zur Seite!';
    default:
      return 'Turn to the side!';
  }
};

export const turnToOtherSide = (lang) => {
  switch (lang) {
    case 'en':
      return 'Turn to the other side!';
    case 'ger':
      return 'Drehen Sie sich zur anderen Seite!';
    default:
      return 'Turn to the other side!';
  }
};

export const faceCamera = (lang) => {
  switch (lang) {
    case 'en':
      return 'Face the camera!';
    case 'ger':
      return 'Drehen Sie sich zur Kamera!';
    default:
      return 'Face the camera!';
  }
};
