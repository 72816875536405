import { useEffect } from 'react';
import * as React from 'react';
import { Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getAverageScoreByAge } from '../../../../../constants/Demo/sitAndStand';
import {
  useGaitResultsActions,
  useUpdatingGaitResultsActions,
} from '../../../../../hooks/GaitAnalysis/actions';
import { MetricsItem } from '../../GaitAnalysis/results/ResultsMetrics';

export default function ObservationResult() {
  const store = useStore();
  const patientInfo = useSelector((state) => state.gaitPatientInfo.values);
  const dispatch = useDispatch();
  const iter = useSelector(({ iter }) => iter.values);
  const countsInTime = useSelector(({ countsInTime }) => countsInTime.values);
  const [results, setResults] = React.useState();
  const { addResults } = useGaitResultsActions('sitAndStand');
  const { updateResults } = useUpdatingGaitResultsActions();

  useEffect(() => {
    console.log('rendering observation result');
    if (iter.scores[iter.iteration] !== undefined) {
      const observation = {
        score: iter.scores[iter.iteration] * 10,
        counts: countsInTime.correct,
      };
      setResults(observation);
      addResults(iter.scores[iter.iteration]).then((payload) => {
        updateResults({
          id: payload.id,
          payload: {
            metrics: observation,
          },
        }).then(() => {
          dispatch({
            type: 'RESET-ITER',
          });
        });
      });
    } else {
      const lastGaitResults = store.getState().lastGaitResults;
      // if (!lastGaitResults.docs) return;
      if (lastGaitResults.docs.length > 0) {
        setResults(lastGaitResults.docs[0].metrics);
      } else {
        console.log('no results');
      }
    }
  }, []);

  if (!results) return null;
  return (
    <div>
      <Paper sx={{ paddingBottom: 3, marginBottom: 2 }}>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 2,
          }}
        >
          <Grid item xs={12} style={styles.box}>
            <MetricsItem title={'SCORE'} value={results.score.toFixed(1)} />
          </Grid>
          <Grid item xs={12} style={styles.box}>
            <MetricsItem title={'COUNTS'} value={results.counts} />
          </Grid>
          <Grid item xs={12} style={styles.box}>
            <MetricsItem
              title={'AGE GROUP AVG'}
              value={getAverageScoreByAge({
                age: patientInfo.age,
                gender: patientInfo.gender,
              })}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    border: 1,
    borderRadius: 5,
    margin: 2,
    padding: 2,
  },
};
