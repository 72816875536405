import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { loginTargetMap } from '../../constants/Demo/auth';
import { auth } from '../../firebase';
import {
  AudioQueue,
  loadIntroAudio,
  loadLevelsAudio,
} from '../../utilities/Demo/audioFeedback';

export const useTimer = (callback = null, timeout = 1000) => {
  const seconds = useRef(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      seconds.current += 1;
      if (callback) {
        callback(seconds);
      }
    }, timeout);

    return () => clearInterval(intervalId);
  }, []);
  return seconds;
};

export const useTaskTimer = () => {
  const started = useRef(false);
  const startingTime = useRef(0);

  const setStartingTime = (seconds) => {
    if (!started.current) {
      console.log('setting starting time', seconds.current);
      startingTime.current = seconds.current;
      started.current = true;
    }
  };

  return {
    setStartingTime,
    startingTime,
    started,
  };
};

export const useIntroAudio = ({ loadAudio, config }) => {
  const dispatch = useDispatch();
  const introAudio = useRef({});
  const playASAP = useRef(false);

  function playAudio() {
    try {
      introAudio.current.play();
      introAudio.current.onended = () => {
        dispatch({ type: 'SET-INTRO-FINISHED' });
      };
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        playAudio();
      }, 500);
    }
  }

  useEffect(() => {
    if (config) {
      loadIntroAudio(config).then((feedback) => {
        introAudio.current = feedback;
        dispatch({ type: 'SET-INTRO-STARTED' });
      });
    }
    if (loadAudio) {
      loadAudio().then((feedback) => {
        introAudio.current = feedback?.intro;
        dispatch({ type: 'SET-INTRO-STARTED' });
        if (playASAP.current) playAudio();
      });
    }

    return () => {
      if (introAudio.current?.pause) introAudio.current.pause();
      dispatch({ type: 'RESET-INTRO' });
    };
  }, [loadAudio, config]);
  function playIntroAudio() {
    if (!introAudio.current) {
      playASAP.current = true;
      return;
    }
    playAudio();
  }

  return playIntroAudio;
};

export const useAudioFeedback = (
  side,
  loadAudio,
  seconds = undefined,
  maxScore = 5
) => {
  // const dispatch = useDispatch();
  const audioRef = useRef(null);
  const audioFeedback = useRef(null);
  const audioFeedbackPlayed = useRef(-100);
  const levelAudioFeedback = useRef(null);
  const level = useRef(0);
  const finishedIntro = useRef(
    true // Boolean(process.env.REACT_APP_SKIP_INTRO_SCREEN)
  );
  // const intro = useSelector(({ intro }) => intro.values);
  const audioQueue = new AudioQueue();
  const iter = useSelector(({ iter }) => iter.values);

  if (seconds === undefined) {
    seconds = useRef(0);
    useEffect(() => {
      const intervalId = setInterval(() => {
        seconds.current += 1;
      }, 1000);

      return () => clearInterval(intervalId);
    }, []);
  }

  useEffect(() => {
    const abortController = new AbortController();

    loadAudio(side, { signal: abortController }).then((feedback) => {
      // dispatch({ type: 'SET-INTRO-STARTED' });
      audioFeedback.current = feedback;
      // if (!intro.skip) {
      //   playAudio(audioFeedback.current?.intro, true);
      // } else {
      //   finishedIntro.current = true;
      //   dispatch({ type: 'SET-INTRO-FINISHED' });
      // }
      // if (audioRef.current && !intro.skip) {
      //   audioRef.current.onended = () => {
      //     finishedIntro.current = true;
      //     dispatch({ type: 'SET-INTRO-FINISHED' });
      //   };
      // } else {
      //   finishedIntro.current = true;
      //   dispatch({ type: 'SET-INTRO-FINISHED' });
      // }
    });
    return () => {
      abortController.abort();
      stopAudio();
      // dispatch({ type: 'RESET-INTRO' });
    };
  }, []);

  useEffect(() => {
    loadLevelsAudio().then((feedback) => {
      levelAudioFeedback.current = feedback;
    });
  }, []);

  function playAudio(newAudio, playAnytime = false, secondsToWait = 6) {
    try {
      if (newAudio === undefined) return;
      if (audioQueue.isPlaying) return;
      if (playAnytime) {
        stopAudio();
        audioRef.current = newAudio;
        audioRef.current.play();
        audioFeedbackPlayed.current = seconds.current - 4;
      } else if (audioRef.current === null || audioRef.current.ended) {
        if (seconds.current - audioFeedbackPlayed.current < secondsToWait)
          return;
        if (audioQueue.queue.length > 0) return;
        audioRef.current = newAudio;
        audioRef.current.play();
        audioFeedbackPlayed.current = seconds.current;
      }
    } catch (error) {
      console.log(error);
    }
  }

  function stopAudio() {
    if (audioRef.current && !audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }

  function raiseLevel(score) {
    score = Math.floor(score * maxScore);
    if (score > level.current && score <= maxScore) {
      level.current++;
      console.log(score, 'score');
      if (iter.raiseLevelSounds) {
        stopAudio();
        audioQueue.addAudio(levelAudioFeedback.current.newLevel);
        audioQueue.addAudio(levelAudioFeedback.current[level.current]);
        audioFeedbackPlayed.current = seconds.current;
      } else {
        return false;
      }
      return true;
    }
  }

  return {
    playAudio,
    secondsRef: seconds,
    audioFeedback,
    stopAudio,
    raiseLevel,
    level,
    audioQueue,
    finishedIntro,
  };
};

export function safeIterScore(dispatch, score) {
  dispatch({ type: 'SAFE-ITER-SCORE', payload: { score: score } });
}

export function useAuthCheck() {
  const navigate = useNavigate();
  const { loginTarget } = useParams();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      navigate('/' + loginTargetMap[loginTarget]);
    }
  }, [navigate]);
}
