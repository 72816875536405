import { computeRotationAngle, findMiddlePoint } from './angles';
import { findAngle, findCounterAngle, findDependentAngle } from './physio';
import { getHighestAndLowestPixels } from './segmentation';

export function getStepLength(
  personHeightRWU,
  results,
  refCallBack = () => {}
) {
  const leftToe = results.poseLandmarks[31];
  const rightToe = results.poseLandmarks[32];
  const stepLengthPixels = Math.abs(leftToe.x - rightToe.x);
  const { highestPixel, lowestPixel } = getHighestAndLowestPixels(
    results.segmentationMask
  );
  const personHeightPixels = Math.abs(highestPixel.y - lowestPixel.y);

  const pixelRWU = personHeightRWU / personHeightPixels;
  if (refCallBack) refCallBack(personHeightRWU, personHeightPixels, results);
  return Math.round(stepLengthPixels * pixelRWU);
  // return Math.round(stepLengthPixels * pixelRWU);
}

export function updateTimeSeriesData({
  setLegAngleData,
  leftHipAnkleAngle,
  rightHipAnkleAngle,
  leftKneeAngle,
  rightKneeAngle,
  leftKneeAnkleToeAngle,
  rightKneeAnkleToeAngle,
  leftHipKneeAngle,
  rightHipKneeAngle,
  hipRotationAngle,
  hipBalanceAngle,
  time,
}) {
  if (!setLegAngleData) return;
  setLegAngleData((prev) => {
    const newData = [...prev];
    newData[0].data.push({
      x: Number(time),
      y: Number(leftHipAnkleAngle) * 1 - 90,
    });
    newData[1].data.push({
      x: Number(time),
      y: Number(rightHipAnkleAngle) * 1 - 90,
    });
    newData[2].data.push({
      x: Number(time),
      y: Number(leftKneeAngle) * -1 + 180,
    });
    newData[3].data.push({
      x: Number(time),
      y: Number(rightKneeAngle) * -1 + 180,
    });
    newData[4].data.push({
      x: Number(time),
      y: Number(leftKneeAnkleToeAngle || 0) * 1 - 90,
    });
    newData[5].data.push({
      x: Number(time),
      y: Number(rightKneeAnkleToeAngle || 0) * 1 - 90,
    });
    newData[6].data.push({
      x: Number(time),
      y: Number(leftHipKneeAngle) * -1 + 90,
    });
    newData[7].data.push({
      x: Number(time),
      y: Number(rightHipKneeAngle) * -1 + 90,
    });
    newData[8].data.push({
      x: Number(time),
      y: Number(hipRotationAngle) * -1 + 90,
    });
    newData[9].data.push({
      x: Number(time),
      y: Number(hipBalanceAngle || 0) * -1 + 90,
    });

    return newData;
  });
}

export function movingAverage(arr, windowSize) {
  let result = [];

  for (let i = 0; i < arr.length - windowSize + 1; i++) {
    let sum = 0;
    for (let j = 0; j < windowSize; j++) {
      sum += arr[i + j].y;
    }
    result.push({ x: arr[i].x, y: sum / windowSize });
  }

  return result;
}

export function findGaitAngles(results, leftSide = true) {
  let leftHipAnkleAngle;
  let rightHipAnkleAngle;
  let leftHipKneeAngle;
  let rightHipKneeAngle;

  let midHipMidShoulderAngle;
  const midHip = findMiddlePoint(
    results.poseLandmarks[23],
    results.poseLandmarks[24]
  );
  const midShoulder = findMiddlePoint(
    results.poseLandmarks[11],
    results.poseLandmarks[12]
  );

  let midHipAngle;
  let hipRotationAngle = computeRotationAngle(
    midHip,
    results.poseLandmarks[23],
    results.poseLandmarks[24]
  );
  let leftKneeAngle = findDependentAngle(
    results.poseLandmarks[23],
    results.poseLandmarks[25],
    results.poseLandmarks[27]
  );
  let rightKneeAngle = findDependentAngle(
    results.poseLandmarks[24],
    results.poseLandmarks[26],
    results.poseLandmarks[28]
  );
  let leftKneeAnkleToeAngle = findDependentAngle(
    results.poseLandmarks[25],
    results.poseLandmarks[27],
    results.poseLandmarks[31]
  );
  let rightKneeAnkleToeAngle = findDependentAngle(
    results.poseLandmarks[26],
    results.poseLandmarks[28],
    results.poseLandmarks[32]
  );
  if (leftSide) {
    leftHipAnkleAngle = findCounterAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[27],
      360
    );
    rightHipAnkleAngle = findCounterAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[28],
      360
    );
    if (leftKneeAnkleToeAngle > 180) {
      leftKneeAnkleToeAngle = 360 - leftKneeAnkleToeAngle;
    }
    if (rightKneeAnkleToeAngle > 180) {
      rightKneeAnkleToeAngle = 360 - rightKneeAnkleToeAngle;
    }
    leftHipKneeAngle = findCounterAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      360
    );
    rightHipKneeAngle = findCounterAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      360
    );
    midHipMidShoulderAngle = findCounterAngle(midHip, midShoulder, 0);
    midHipAngle = findAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[24],
      270
    );
    if (midHipAngle > 360) {
      midHipAngle = midHipAngle - 360;
    }
  } else {
    leftHipAnkleAngle = findAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[27],
      180
    );
    rightHipAnkleAngle = findAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[28],
      180
    );
    if (leftKneeAngle > 180) {
      leftKneeAngle = 360 - leftKneeAngle;
    }
    if (rightKneeAngle > 180) {
      rightKneeAngle = 360 - rightKneeAngle;
    }
    leftHipKneeAngle = findAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      180
    );
    rightHipKneeAngle = findAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      180
    );
    midHipMidShoulderAngle = findCounterAngle(midHip, midShoulder, 180);
    midHipAngle = findCounterAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[24],
      90
    );
  }

  return {
    leftHipAnkleAngle,
    rightHipAnkleAngle,
    leftKneeAngle,
    rightKneeAngle,
    leftKneeAnkleToeAngle,
    rightKneeAnkleToeAngle,
    leftHipKneeAngle,
    rightHipKneeAngle,
    midHipMidShoulderAngle,
    midHipAngle,
    hipRotationAngle,
  };
}

export function findGaitAnglesFront(results, front = false) {
  let leftHipKneeAngle;
  let rightHipKneeAngle;
  let leftKneeAngle;
  let rightKneeAngle;
  let hipRotationAngle;
  let midHipMidShoulderAngle;
  const midHip = findMiddlePoint(
    results.poseLandmarks[23],
    results.poseLandmarks[24]
  );
  const midShoulder = findMiddlePoint(
    results.poseLandmarks[11],
    results.poseLandmarks[12]
  );

  let midHipAngle;
  if (front) {
    leftKneeAngle = findDependentAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      results.poseLandmarks[27]
    );
    rightKneeAngle = findDependentAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      results.poseLandmarks[28]
    );
    leftHipKneeAngle = findCounterAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      360
    );
    rightHipKneeAngle = findCounterAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      360
    );
    hipRotationAngle = computeRotationAngle(
      midHip,
      results.poseLandmarks[23],
      results.poseLandmarks[24]
    );
    midHipMidShoulderAngle = findCounterAngle(midHip, midShoulder, 0);
    midHipAngle = findAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[24],
      270
    );
    if (midHipAngle > 360) {
      midHipAngle = midHipAngle - 360;
    }
  } else {
    leftKneeAngle = findDependentAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      results.poseLandmarks[27],
      true
    );
    rightKneeAngle = findDependentAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      results.poseLandmarks[28],
      true
    );
    leftHipKneeAngle = findAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      180
    );
    rightHipKneeAngle = findAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      180
    );
    hipRotationAngle = computeRotationAngle(
      midHip,
      results.poseLandmarks[23],
      results.poseLandmarks[24]
    );
    hipRotationAngle = 180 - hipRotationAngle;

    midHipMidShoulderAngle = findAngle(midHip, midShoulder, 0);
    midHipAngle = findCounterAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[24],
      270
    );
    midHipAngle = 180 - midHipAngle;
  }

  return {
    leftKneeAngle,
    rightKneeAngle,
    leftHipKneeAngle,
    rightHipKneeAngle,
    midHipMidShoulderAngle,
    midHipAngle,
    hipRotationAngle,
  };
}

export function checkIfFront(results) {
  return results.poseLandmarks[23].x > results.poseLandmarks[24].x;
}

export function getMinFromData(data) {
  return Math.min(...data.map((d) => d.y));
}

export function getMaxFromData(data) {
  return Math.max(...data.map((d) => d.y));
}

export function getCenterOfMassTimeSeries(resultsData) {
  const poseLandmarks = {};

  resultsData.forEach((data) => {
    Object.keys(data.poseLandmarks).forEach((joint) => {
      joint = parseInt(joint);
      if (poseLandmarks[joint]) {
        poseLandmarks[joint].push(data.poseLandmarks[joint]);
      } else {
        poseLandmarks[joint] = [data.poseLandmarks[joint]];
      }
    });
  });

  return poseLandmarks;
}
