import React, { useEffect } from 'react';
import HeaderComponent from '../../components/HeaderComponent';
import DrawerComponent from '../../components/DrawerComponent';
import { useState } from 'react';
import CalendarComponent from '../../components/Dashboard/CalendarComponent';
import SessionComponent from '../../components/Dashboard/SessionComponent';
import BgComponent from '../../components/Background/BgComponent';
import ProgressComponent from '../../components/Dashboard/ProgressComponent';
import TopPerformers from '../../components/TDash/TopPerformers';
import { getScheduleData } from '../../redux/actions/getDashboard';
import { getClassHome } from '../../redux/actions/getClassHome';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const Tdash = () => {
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  let { role } = JSON.parse(localStorage.getItem('user_data'));
  const schedule = useSelector(({ schedule }) => schedule.values);
  const progress = useSelector(({ cprogress }) => cprogress.values);
  const classes = useSelector(({ thome }) => thome.values);
  console.log('CSTUDS::', progress);
  useEffect(() => {
    if (!schedule) {
      getClassHome(dispatch, setLoad);
      getScheduleData(dispatch, moment().format('YYYY-MM-DD'), (some) => some);
    }
  }, []);
  // useEffect(()=>{
  //   if (window.performance) {
  //     if (performance.navigation.type == 1) {
  //       (async ()=> await getClassProgress(dispatch, (a)=>a))()
  //     }
  //   }
  // })
  return (
    <div className="teacher-home">
      <BgComponent />
      <HeaderComponent />
      <DrawerComponent open={open} setOpen={setOpen} />

      <div className="container teacher-dashboard">
        {load ? (
          <center>
            <CircularProgress
              size={30}
              color="success"
              sx={{ justifyContent: 'center' }}
            />
          </center>
        ) : (
          <TopPerformers
            topthree={progress && progress.top_three}
            classes={classes ? classes.data : null}
          />
        )}
        <CalendarComponent />

        {load ? (
          <center>
            <CircularProgress
              size={30}
              color="success"
              sx={{ justifyContent: 'center' }}
            />
          </center>
        ) : (
          <ProgressComponent
            data={progress && progress}
            classes={classes ? classes.data : null}
          />
        )}
        <SessionComponent role={role} data={schedule} />
      </div>
    </div>
  );
};

export default Tdash;
