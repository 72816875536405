import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ic1 from '../../assets/ic1.png';
import av2 from '../../assets/av2_ol.png';
import av1 from '../../assets/av1.png';
import collapse from '../../assets/collapse.png';
import viewAll from '../../assets/view-all.png';
import { useTranslation } from 'react-i18next';

const OtherParticipants = ({ joiners, setDuration, shouldShowTimer }) => {
  const { t } = useTranslation();
  const [sec, setSec] = useState(0);
  const [min, setMin] = useState(0);
  const [r1, setR1] = useState(0);
  const [r2, setR2] = useState(3);
  const user = JSON.parse(localStorage.getItem('user_data'));

  const sp = useSelector(({ sweatPoints }) => sweatPoints.values.total);

  setTimeout(() => {
    if (!shouldShowTimer) {
      setSec(sec > 59 ? 0 : sec + 1);
      setMin(sec === 59 ? min + 1 : min);
      setDuration(min * 60 + sec);
    }
  }, 1000);

  return (
    <div className="gamer">
      <div className="gamer-left">
        <div className="gamer-item">
          <img
            alt="Cindy Baker"
            src={user.profile_pic ? user.profile_pic : av1}
          />
          <span>{t('TopThreeComponent.me')}</span>
        </div>
        <div className="timer-container">
          <div className="timer">{`${min}:${sec}`}</div>
          <div className="arena-points-container">
            <img src={ic1} />
            <div className="arena-points-right">
              <span>
                <span>{t('arena.sweat')}</span> {t('arena.points')}
              </span>
              <h2>{sp}</h2>
            </div>
          </div>
        </div>
        <div
          className="gamer-item hoverable"
          onClick={() => {
            if (r1 > 0) {
              setR1(r1 - 3);
              setR2(r2 - 3);
            }
          }}
        >
          <img src={collapse} />
          <span>{t('arena.collapse')}</span>
        </div>
      </div>

      {joiners.map(
        (item, index) =>
          index >= r1 &&
          index < r2 && (
            <div className="gamer-item participant" key={item.item}>
              <img
                alt="Cindy Baker"
                src={item.profile_pic ? item.profile_pic : av2}
              />
              <span>{item.name}</span>
            </div>
          )
      )}

      <div
        className="gamer-item hoverable"
        onClick={() => {
          if (r2 < joiners.length) {
            setR1(r1 + 3);
            setR2(r2 + 3);
          }
        }}
      >
        <img src={viewAll} />
        <span>{t('TopThreeComponent.view')}</span>
      </div>
      {/* </Grid> */}
    </div>
  );
};

export default OtherParticipants;
