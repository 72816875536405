import React from 'react';
import { Stats } from './Stats';
import './trainers.css';
import { WorkoutType } from './WorkoutType';

export const TrainerContainer = () => {
  const workoutTypes = [
    {
      name: 'Squats',
      status: 'completed',
    },
    {
      name: 'Jumping jacks',
      status: 'completed',
    },
    {
      name: 'Pushups',
      status: 'excluded',
    },
    {
      name: 'Burpees',
      status: 'not-complete',
    },
    {
      name: 'Bear crawls',
      status: 'not-complete',
    },
    {
      name: 'Jump squats',
      status: 'not-complete',
    },
    {
      name: 'Lunges',
      status: 'not-complete',
    },
  ];
  const workoutCats = [
    'Strength 1',
    'Flexiblity 1',
    'Mobility 1',
    'Endurance 1',
    'Strength 2',
    'Flexiblity 2',
    'Mobility 2',
    'Endurance 2',
  ];
  return (
    <div className="trainer_cont">
      <div style={{ display: 'flex' }}>
        <div className="trainer_head" style={{ width: '85%' }}>
          Trainer Mode
        </div>
        <div className="reset-box">Reset</div>
      </div>
      <div className="trainer_subhead">Stats</div>
      <Stats level={10} streak={100} />
      <div className="trainer_subhead">Exercise Stack</div>
      {workoutCats.map((cats, catsInd) => (
        <>
          <div className="trainer_lighthead" key={catsInd}>
            {cats}
          </div>
          <div className="workouts_row">
            {workoutTypes.map((item, index) => (
              <WorkoutType name={item.name} status={item.status} key={index} />
            ))}
          </div>
        </>
      ))}
    </div>
  );
};
