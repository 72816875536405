import React, { useEffect, useState } from 'react';
import SearchBar from '../Ranking/SearchBar';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router';
import { getClassStuds } from '../../redux/actions/getClassHome';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ClassHomeComponent = ({ classes }) => {
  const { t } = useTranslation();
  const [isLower1200, setIsLower1200] = useState(false);
  console.log('CLasses::', classes);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ar, setAr] = useState(classes.data);
  const [filter, setFilter] = useState('');
  let det = classes.data;
  useEffect(() => {
    console.log('details is::', ar);
    if (filter !== '')
      det = classes.data.filter((item) =>
        new RegExp('^' + filter, 'i').test(item.class_name)
      );
    setAr(det);
  }, [filter]);
  useEffect(() => {
    if (document.body.clientWidth < 1200) {
      setIsLower1200(true);
    } else {
      setIsLower1200(false);
    }
    window.addEventListener('resize', () => {
      if (document.body.clientWidth < 1200) {
        setIsLower1200(true);
      } else {
        setIsLower1200(false);
      }
    });
  });

  return (
    <div className="class-home-inner">
      <div className="top">
        <div className="title">
          <div className="class-home-violet-rect"></div>
          <h1>{t('classHomeComponent.title')}</h1>
        </div>
        <SearchBar
          title="Search Classes"
          setFilter={setFilter}
          filter={filter}
        />
      </div>
      <div className="middle">
        <div>{t('DrawerComponent.schedule')}</div>
        <div>{t('classHomeComponent.time')}</div>
        <div>{t('classHomeComponent.school')}</div>
        <div>{t('classHomeComponent.class')}</div>
        <div>{t('classHomeComponent.numberOfStudents')}</div>
      </div>
      <div className="bottom">
        {isLower1200
          ? classes &&
            ar.map((item) => (
              <div
                key={item.class_id}
                className="row hoverable"
                onClick={async () => {
                  await getClassStuds(
                    dispatch,
                    item.class_id,
                    item.schedule === 'ALL'
                      ? 'ALL'
                      : moment(item.schedule).format('DD-MM'),
                    (a) => a
                  );
                  navigate('/class-students', {
                    state: { class_id: item.class_id },
                  });
                }}
              >
                <div>
                  <div>
                    <h3>
                      {item.schedule != 'ALL'
                        ? moment(item.schedule).format('DD-MM-YYYY')
                        : t('classHomeComponent.all')}
                    </h3>
                  </div>
                  <div>
                    <h3>
                      {item.schedule !== 'ALL'
                        ? moment
                            .tz(item.schedule, 'Europe/Berlin')
                            .local()
                            .format('HH:mm')
                        : t('classHomeComponent.all')}
                    </h3>
                  </div>
                </div>
                <div>
                  <h3>{item.school}</h3>
                </div>
                <div>
                  <h3>{item.class_name}</h3>
                </div>
                <div>
                  <h3>{item.studs}</h3>
                </div>
              </div>
            ))
          : classes &&
            ar.map((item) => (
              <div
                key={item.class_id}
                className="row hoverable"
                onClick={async () => {
                  await getClassStuds(
                    dispatch,
                    item.class_id,
                    item.schedule === 'ALL'
                      ? 'ALL'
                      : moment(item.schedule).format('DD-MM'),
                    (a) => a
                  );
                  navigate('/class-students', {
                    state: { class_id: item.class_id },
                  });
                }}
              >
                <div>
                  <h3>
                    {' '}
                    {item.schedule != 'ALL'
                      ? moment(item.schedule).format('DD-MM-YYYY')
                      : t('classHomeComponent.all')}
                  </h3>
                </div>
                <div>
                  <h3>
                    {item.schedule != 'ALL'
                      ? moment
                          .tz(item.schedule, 'Europe/Berlin')
                          .local()
                          .format('HH:mm')
                      : t('classHomeComponent.all')}
                  </h3>
                </div>
                <div>
                  <h3>{item.school}</h3>
                </div>
                <div>
                  <h3>{item.class_name}</h3>
                </div>
                <div>
                  <h3>{item.studs}</h3>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default ClassHomeComponent;
