const initialState = {
  values: {
    iteration: 0,
    calibrated: false,
    scores: {},
    sensorData: {},
    maxScore: 1,
    exerciseIds: [],
    exerciseTypes: [],
    scheduleSpecificExerciseConfigs: [],
    metrics: [],
    results: {},
    overviewRedirect: null,
    transitionVideoURL: null,
    raiseLevelSounds: true,
    drawConnectors: true,
    showSummaries: true,
    scheduleId: null,
    language: 'en',
    scoreMultiplication: {},
    coreAudioFeedbackConfig: {},
    cameraDeviceId: null,
  },
};
export const iterationsReducer = (state = initialState, action) => {
  let newScores;
  let exerciseTypes;
  let metrics;
  switch (action.type) {
    case 'ADD-ITER': {
      return {
        values: { ...state.values, iteration: state.values.iteration + 1 },
      };
    }
    case 'SET-ITER-CAMERA-DEVICE-ID': {
      return {
        values: { ...state.values, cameraDeviceId: action.payload },
      };
    }
    case 'SET-ITER': {
      return {
        values: { ...state.values, iteration: action.payload.iteration },
      };
    }
    case 'SET-ITER-VAlUES': {
      return {
        values: { ...state.values, ...action.payload },
      };
    }
    case 'SAFE-ITER-SCORE':
      newScores = { ...state.values.scores };
      newScores[state.values.iteration] = Math.min(
        Number(action.payload.score),
        state.values.maxScore
      );
      return {
        ...state,
        values: {
          ...state.values,
          scores: newScores,
        },
      };
    case 'ITER-CALIBRATED':
      return { values: { ...state.values, calibrated: true } };
    case 'SET-ITER-EXERCISES':
      return {
        values: { ...initialState.values, exerciseIds: action.payload },
      };
    case 'SET-ITER-SCORE-MULTIPLICATION':
      return {
        values: {
          ...state.values,
          scoreMultiplication: { [state.values.iteration]: action.payload },
        },
      };
    case 'SET-ITER-CORE-AUDIO-FEEDBACK-CONFIG':
      return {
        values: {
          ...state.values,
          coreAudioFeedbackConfig: action.payload,
        },
      };
    case 'SET-ITER-OVERVIEW-REDIRECT':
      return {
        values: { ...state.values, overviewRedirect: action.payload },
      };
    case 'SET-SCHEDULE-SPECIFIC-CONFIGS':
      return {
        values: {
          ...state.values,
          scheduleSpecificExerciseConfigs: action.payload,
        },
      };
    case 'SET-ITER-LANGUAGE':
      return {
        values: { ...state.values, language: action.payload },
      };
    case 'SET-ITER-EXERCISE-TYPE':
      exerciseTypes = [...state.values.exerciseTypes];
      exerciseTypes[state.values.iteration] = action.payload;
      return {
        values: { ...state.values, exerciseTypes },
      };
    case 'SET-ITER-TRANSITION-VIDEO-URL':
      return {
        values: { ...state.values, transitionVideoURL: action.payload },
      };
    case 'SET-ITER-METRICS':
      metrics = [...state.values.metrics];
      metrics[state.values.iteration] = action.payload;
      return {
        values: { ...state.values, metrics: [...metrics] },
      };
    case 'SET-ITER-RAISE-LEVEL-SOUNDS':
      return {
        values: { ...state.values, raiseLevelSounds: action.payload },
      };
    case 'SET-ITER-DRAW-CONNECTORS':
      return {
        values: { ...state.values, drawConnectors: action.payload },
      };
    case 'SET-ITER-SHOW-SUMMARIES':
      return {
        values: {
          ...state.values,
          showSummaries: action.payload === undefined ? true : action.payload,
        },
      };
    case 'SET-ITER-SCHEDULE-ID':
      return {
        values: { ...state.values, scheduleId: action.payload },
      };
    case 'SET-ITER-SENSOR-DATA':
      return {
        values: {
          ...state.values,
          sensorData: {
            ...state.values.sensorData,
            [state.values.iteration]: action.payload,
          },
        },
      };
    case 'SET-ITER-RESULTS':
      return {
        values: {
          ...state.values,
          results: {
            ...state.values.results,
            [state.values.iteration]: action.payload,
          },
        },
      };
    case 'RESET-ITER':
      return { values: { ...initialState.values, ...action.payload } };
    default: {
      return state;
    }
  }
};
