import React, { useState } from 'react';
import BgComponent from '../../../components/Background/BgComponent';
import { AssessmentPath } from '../../../components/Demo/Assessments/Exhibition/AssessmentPath';
import { LastTest } from '../../../components/Demo/Assessments/Exhibition/LastTest';
import '../../../components/Demo/Assessments/assessments.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import DrawerComponent from '../../../components/DrawerComponent';
import HeaderComponent from '../../../components/HeaderComponent';

export const Exhibition = () => {
  const matches = useMediaQuery('(max-width:600px)');

  const [open, setOpen] = useState(false);
  return (
    <div className="teacher-home">
      <BgComponent pageName={'schedule'} />
      <HeaderComponent />
      <DrawerComponent open={open} setOpen={setOpen} />
      <DrawerComponent open={open} setOpen={setOpen} />
      <div className={matches ? 'container-assessment' : 'container'}>
        <AssessmentPath />
        {/* <ProgressComponent data={board} />
        <SessionComponent data={schedule} /> */}
        <LastTest />
      </div>
    </div>
  );
};
