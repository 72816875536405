import React, { useEffect, useState } from 'react';
import ChatBgComponent from '../../components/Background/ChatBgComponent';
import DrawerComponent from '../../components/DrawerComponent';
import HeaderComponent from '../../components/HeaderComponent';
import AllChats from '../../components/Chat/AllChats';
import Chat from '../../components/Chat/Chat';
import { io } from 'socket.io-client';
import '../../components/Chat/chat.css';
import { useDispatch, useSelector } from 'react-redux';
import { getClassChatStuds } from '../../redux/actions/getClassHome';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TeacherChatScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chatstuds = useSelector(({ chatstuds }) => chatstuds.values);
  const [tch, setTch] = useState(null);
  const [loadtchapi, setTchapi] = useState(false);
  const [load, setLoad] = useState(false);
  const [socket, setSocket] = useState(null);
  const [showChat, setShowChat] = useState(false);
  let newSocket = null;
  console.log('Tchrs', chatstuds);
  useEffect(() => {
    if (!chatstuds) getClassChatStuds(dispatch, (a) => a);
  }, []);
  useEffect(() => {
    getClassChatStuds(dispatch, (a) => a);
  }, [loadtchapi]);
  useEffect(() => {
    setLoad(true);
    newSocket = io(
      `${process.env.REACT_APP_CHAT_SOCKET}?token=${localStorage.getItem(
        'token'
      )}`
    );
    if (!socket) setSocket(newSocket);
    newSocket.on('connect', () => {
      setLoad(false);
      console.log('CHat Socket::', newSocket.connected); // true
    });
  }, [setSocket]);
  useEffect(() => {
    return () => {
      setSocket(null);
      newSocket.emit('leftChat', 'data fron user effect');
    };
  }, []);
  return (
    <div className="chat-component-container">
      <HeaderComponent pageName={'chat'} />
      <DrawerComponent />
      <ChatBgComponent />
      {load ? (
        <CircularProgress size={28} color="primary" />
      ) : (
        <div className="chat-container">
          <div className="chat-container-inner">
            <h1>{t('ChatScreen.title')}</h1>
            <div className="both-chats-container">
              <AllChats
                teachers={chatstuds ? chatstuds : null}
                setTch={setTch}
                showChat={showChat}
                setShowChat={setShowChat}
              />
              <Chat
                chatsocket={socket}
                tch={tch}
                loadtchapi={loadtchapi}
                setTchapi={setTchapi}
                showChat={showChat}
                setShowChat={setShowChat}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeacherChatScreen;
