import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from '@mui/material/Button';
import ResultPdf from './ResultPdf';

export default function ResultPdfDownloadButton({
  cycleImage,
  pressureImage,
  pressureMapImage,
  doctorMetricsImage,
  patientInfo,
  metrics = {},
}) {
  return (
    <Button>
      <PDFDownloadLink
        document={
          <ResultPdf
            doctorMetricsImage={doctorMetricsImage}
            cycleImage={cycleImage}
            pressureImage={pressureImage}
            pressureMapImage={pressureMapImage}
            metrics={metrics}
            patientInfo={patientInfo}
          />
        }
        fileName="gait_report.pdf"
      >
        {({ loading }) => (loading ? 'Loading document...' : 'Download now!')}
      </PDFDownloadLink>
    </Button>
  );
}
