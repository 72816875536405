import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import logo from '../assets/EXAR_logo.png';
import ExitButton from './ExitButton';

const HeaderComponentDemo = ({ demoType, redirectTarget = '/summary' }) => {
  return (
    <Container
      maxWidth="xxl"
      sx={{
        // backgroundColor: bgcol ? bgcol : '#FCFCF9',
        backgroundColor: 'transparent',
        display: 'flex',
        alignContent: 'center',
        position: 'relative',
        padding: '.5rem 0',
        zIndex: 1000,
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          justifyContent: 'center',
          maxWidth: '50%',
          margin: 'auto',
          alignSelf: 'center',
        }}
        elevation={0}
      >
        <img src={logo} alt="Exar" style={{ objectFit: 'contain' }} />
      </Toolbar>
      <ExitButton redirectTarget={redirectTarget} demoType={demoType} />
    </Container>
  );
};

export default HeaderComponentDemo;
