import * as React from 'react';
import InputBarComponent from './InputBarComponent';
import ChangePwdComponent from './ChangePwdComponent';
import { Snackbar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { CircularProgress } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/system';
import { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { loginWithJWT } from '../redux/actions/loginwithJwt';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function LoginComponent({ open, setOpen, setFp }) {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();
  const [type, setType] = useState('');
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState('');
  const dispatch = useDispatch();
  const [change, setChange] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log('Params::', state);
  const onSubmit = (data) => {
    setLoad(true);
    console.log('Name is ::' + JSON.stringify(data));
    loginWithJWT(
      data.email,
      data.password,
      dispatch,
      setLoad,
      setMsg,
      setType,
      setChange
    );
  };
  useEffect(() => {
    // if (msg === "Network Error") navigate("/home");
    if (msg === 'Login Successfull') {
      if (state && state.from == 'exercise') navigate('/exercise');
      else {
        let { role } = JSON.parse(localStorage.getItem('user_data'));
        if (role == 'teacher') {
          navigate('/teacherhome', { state: { role: 'teacher' } });
        } else if (role == 'superadmin')
          navigate('/admin-dashboard', { state: { role: 'superadmin' } });
        else navigate('/home');
      }
    }
  }, [msg]);
  return (
    <Box>
      <Snackbar
        open={msg === '' ? false : true}
        autoHideDuration={60000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={() => {
          setMsg('');
        }}
      >
        <Alert severity={type}>{msg}</Alert>
      </Snackbar>
      {change ? (
        <ChangePwdComponent open={change} setMsg={setMsg} />
      ) : (
        <Dialog
          PaperProps={{
            sx: {
              borderRadius: 10,
              maxheight: '53%',
              width: '95vw',
              padding: 2,
            },
          }}
          scroll="body"
          BackdropComponent={styled(Backdrop, {
            name: 'MuiModal',
            slot: 'Backdrop',
            overridesResolver: (props, styles) => {
              return styles.backdrop;
            },
          })({
            zIndex: -1,
            backgroundColor: 'rgb(242, 247, 247,0.5)',
          })}
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle sx={{ fontSize: 18 }}>
            {t('welcomeView.login')}
            <span
              style={{ float: 'right', color: 'grey', cursor: 'pointer' }}
              onClick={() => setOpen(false)}
            >
              &#x2715;
            </span>
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent style={{ overflow: 'hidden' }}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputBarComponent
                    name="email"
                    label={t('ProfileComponent.email')}
                    type="email"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputBarComponent
                    name="password"
                    label={t('welcomeView.password')}
                    type="password"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </DialogContent>
            <DialogActions
              disableSpacing={true}
              sx={{ justifyContent: 'center', height: '10%' }}
            >
              {load ? (
                <CircularProgress color="inherit" />
              ) : (
                <>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      borderRadius: 20,
                      backgroundColor: '#FF592C',
                    }}
                    className="cover-whitespace"
                    onClick={() => setOpen(true)}
                  >
                    {t('welcomeView.login')}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 20,
                      backgroundColor: '#FF592C',
                      marginLeft: '5%',
                    }}
                    className="cover-whitespace"
                    onClick={() => {
                      setOpen(false);
                      setFp(true);
                    }}
                  >
                    {t('welcomeView.forgetPass')}
                  </Button>
                </>
              )}
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Box>
  );
}
