import React from 'react';
import './ranking.css';

const ClubRow = (props) => {
  const { details } = props;
  console.log('ClubRowdetails', details.club, details.swetpoints, details);
  // let total_members = details[0].members.length;
  return (
    <div className="studet-row" style={{ cursor: 'pointer' }}>
      <div className="val-item">
        <h3>{details.rank}</h3>
      </div>
      <div className="val-item">
        <h3>{details.club}</h3>
      </div>
      <div className="val-item">
        <h3>{details.age}</h3>
      </div>
      <div className="val-item">
        <h3>{details.xcoins}</h3>
      </div>
      <div className="val-item">
        <h3>{details.sweatpoints}</h3>
      </div>
      <div className="val-item">
        <h3>{details.noofmem}</h3>
      </div>
      {/* <div className="val-item">
                <h3>{details.workouts ? details.workouts : 0}</h3>
            </div>
            <div className="val-item">
                <h3>{details.coins ? details.coins : 0}</h3>
            </div> */}
    </div>
  );
};

export default ClubRow;
