import { useRef } from 'react';
import { getHighestAndLowestPixels } from '../../../utilities/Demo/segmentation';

export function usePersonPixelHeight() {
  const personHeightPixels = useRef(0);

  const setPixelHeight = (results) => {
    const { highestPixel, lowestPixel } = getHighestAndLowestPixels(
      results.segmentationMask
    );
    const pHPCandidate = Math.abs(highestPixel.y - lowestPixel.y);
    if (pHPCandidate > personHeightPixels.current) {
      personHeightPixels.current = Math.abs(highestPixel.y - lowestPixel.y);
    }
  };

  return { setPixelHeight, personHeightPixels };
}
