import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useCountsInTimeCoreFeedback } from '../../../../../hooks/Demo/feedbackTypes';
import { safeIterScore } from '../../../../../hooks/Demo/sharedLogic';
import {
  checkIfRightSide,
  checkIfSide,
} from '../../../../../utilities/Demo/angles';
import { loadTurnAroundInCompleteCircleAudio } from '../../../../../utilities/Demo/audioFeedback';
import { checkIfFront } from '../../../../../utilities/Demo/gaitAnalysis';
import {
  findAdjustedAngle,
  findDependentAngle,
} from '../../../../../utilities/Demo/physio';
import PoseDetection from '../../../PoseDetection';
import {
  computeMetrics,
  kneeRomMetricsLeft,
  kneeRomMetricsRight,
  leftUpperLegRomMetrics,
  rightUpperLegRomMetrics,
} from '../../../../../utilities/Demo/Physio/BergBalance/metrics';

export default function TurnAroundInCompleteCircle({
  onComplete,
  showWebcam = false,
}) {
  const timeThresh = 120;
  const dispatch = useDispatch();
  const side = React.useRef(false);
  const isLeftSide = React.useRef(false);
  const turnedLeft = React.useRef(false);
  const turnedRight = React.useRef(false);
  const turnedCenter = React.useRef(false);
  const turnedBack = React.useRef(false);
  const turningStates = React.useRef([]);
  const collecting = React.useRef(false);
  const {
    correctCount,
    updateFeedback,
    updateCounts,
    seconds,
    playAudio,
    raiseLevel,
    audioFeedback,
    setStartingTime,
    startingTime,
    cptScore,
    finishedIntro,
  } = useCountsInTimeCoreFeedback({
    onComplete,
    timeThresh,
    loadAudio: loadTurnAroundInCompleteCircleAudio,
    maxScore: 1,
  });
  const metrics = useRef(
    leftUpperLegRomMetrics()
      .concat(rightUpperLegRomMetrics())
      .concat(kneeRomMetricsLeft())
      .concat(kneeRomMetricsRight())
  );
  cptScore.current = () => {
    if (correctCount.current === 0) return 0;
    if (
      correctCount.current === 1 &&
      seconds.current - startingTime.current >= 5
    )
      return 2 / 3;
    if (
      correctCount.current === 1 &&
      seconds.current - startingTime.current < 5
    )
      return 1;
  };

  function observation(
    hipKneeAngleLeft,
    hipKneeAngleRight,
    kneeAngleLeft,
    kneeAngleRight,
    results
  ) {
    if (
      (results.poseLandmarks[23].visibility > 0.5 ||
        results.poseLandmarks[24].visibility > 0.5) &&
      (results.poseLandmarks[25].visibility > 0.5 ||
        results.poseLandmarks[26].visibility > 0.5) &&
      (results.poseLandmarks[27].visibility > 0.5 ||
        results.poseLandmarks[28].visibility > 0.5)
    ) {
      computeMetrics({
        metrics: metrics.current,
        angles: {
          current: {
            kneeAngleLeft,
            kneeAngleRight,
            hipKneeAngleLeft,
            hipKneeAngleRight,
          },
        },
        dispatch,
      });
      setStartingTime(seconds);
      side.current = checkIfSide(results, 10);
      isLeftSide.current = checkIfRightSide(results);
      const front = checkIfFront(results);
      if (side.current && isLeftSide.current) {
        turnedLeft.current = true;
        turningStates.current.push('left');
      }
      if (side.current && !isLeftSide.current) {
        turnedRight.current = true;
        turningStates.current.push('right');
      }
      if (front && !side.current) {
        turnedCenter.current = true;
        turningStates.current.push('center');
      }
      if (!front && !side.current) {
        turnedBack.current = true;
        turningStates.current.push('back');
      }
      if (
        turnedCenter.current &&
        turnedBack.current &&
        turnedLeft.current &&
        turnedRight.current &&
        turningStates.current[0] ===
          turningStates.current[turningStates.current.length - 1]
      ) {
        collecting.current = false;
        turnedCenter.current = false;
        turnedRight.current = false;
        turnedLeft.current = false;
        turnedBack.current = false;
        correctCount.current = 1;
        updateCounts(correctCount.current);
        const score = cptScore.current();
        if (!raiseLevel(score)) {
          playAudio(audioFeedback.current?.achievement, true);
        } else {
          safeIterScore(dispatch, score);
        }
        onComplete();
      } else {
        updateFeedback('');
      }
    } else {
      updateFeedback('Person out of frame!');
      collecting.current = false;
    }
  }

  function onResults(results) {
    if (!finishedIntro.current) return;

    const hipKneeAngleLeft = findAdjustedAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25]
    );
    const hipKneeAngleRight = findAdjustedAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26]
    );
    const kneeAngleLeft = findDependentAngle(
      results.poseLandmarks[23],
      results.poseLandmarks[25],
      results.poseLandmarks[27]
    );
    const kneeAngleRight = findDependentAngle(
      results.poseLandmarks[24],
      results.poseLandmarks[26],
      results.poseLandmarks[28]
    );
    // drawAngle(canvasCtx, hipKneeAngleLeft, results.poseLandmarks[23]);
    // drawAngle(canvasCtx, hipKneeAngleRight, results.poseLandmarks[24]);
    // drawAngle(canvasCtx, kneeAngleLeft, results.poseLandmarks[25]);
    // drawAngle(canvasCtx, kneeAngleRight, results.poseLandmarks[26]);

    observation(
      hipKneeAngleLeft,
      hipKneeAngleRight,
      kneeAngleLeft,
      kneeAngleRight,
      results
    );
  }

  return <PoseDetection onResults={onResults} showWebcam={showWebcam} />;
}
