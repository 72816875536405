import React, { useState } from 'react';
import Pose from '../../assets/Pose_02.png';
import Planet from '../../assets/planet_7.png';
import WelcomeComponent from '../../components/WelcomeComponent';
import HeaderComponent from '../../components/HeaderComponent';
import SignUpComponent from '../../components/Demo/Auth/SignUpComponent';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useAuthCheck } from '../../hooks/Demo/sharedLogic';

const Signup = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  useAuthCheck();

  return (
    <div className="signup-container">
      <img src={Pose} className="login-bcg-img-1" alt="EXAR" />
      <img src={Planet} className="login-bcg-img-2" alt="EXAR" />
      <HeaderComponent pageName={'welcome'} />
      <WelcomeComponent />
      <div className="signup-text">
        <h1>{t('signUpComponent.signup-exar')}</h1>
        <Button
          variant="contained"
          className="signup-button"
          sx={{
            borderRadius: 20,
            backgroundColor: '#FF592C',
          }}
          onClick={() => setOpen(true)}
        >
          <center>{t('signUpComponent.signup')}</center>
        </Button>
        <div
          className="signin-existing"
          style={{ position: 'relative', color: 'black' }}
        >
          <p>
            {' '}
            <br />
            {t('signUpComponent.already-reg')}{' '}
            <Link to="/">{t('signUpComponent.sign-here')}</Link>{' '}
          </p>
        </div>
        {open && <SignUpComponent open={open} setOpen={setOpen} />}
      </div>
    </div>
  );
};

export default Signup;
