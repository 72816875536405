import axios from 'axios';

export const resampleAndMean = (series) => {
  const apiBaseURL = process.env.REACT_APP_POSE_EVAL_SERVICE_URL;
  if (series.length === 0) return { data: { series: [] } };
  series = series.filter((item) => item.length > 0);
  try {
    return axios.post(apiBaseURL + '/gait_analysis', {
      series: series,
      token: process.env.REACT_APP_POSE_EVAL_SERVICE_TOKEN,
    });
  } catch (error) {
    console.error(error);
  }
};

export const sendReportEmailToPatient = ({ patientId, blob, authToken }) => {
  const apiBaseURL = process.env.REACT_APP_POSE_EVAL_SERVICE_URL;
  const formData = new FormData();
  formData.append('file', blob, 'report.pdf');
  formData.append('patient_id', patientId);

  try {
    return axios.post(apiBaseURL + '/report_email', formData, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
};
