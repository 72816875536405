import Grid from '@mui/material/Grid';
import React from 'react';
import { LastTest } from '../../../components/Demo/ExerciseManagement/Results/ScheduleResults/LastTest';

export default function ScheduleResults() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LastTest />
      </Grid>
      {/* <ProgressComponent data={board} />
        <SessionComponent data={schedule} /> */}
    </Grid>
  );
}
