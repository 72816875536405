export function checkArmsUsed(results, isLeft) {
  const leftShoulder = results.poseLandmarks[11];
  const rightShoulder = results.poseLandmarks[12];
  const LeftHip = results.poseLandmarks[23];
  const RightHip = results.poseLandmarks[24];
  const leftMidHipShoulderX = (leftShoulder.x + LeftHip.x * 2) / 3;
  const rightMidHipShoulderX = (rightShoulder.x + RightHip.x * 2) / 3;
  const leftHand = results.poseLandmarks[15];
  const rightHand = results.poseLandmarks[16];
  if (rightHand.visibility > 0.3) {
    if (rightHand.y < rightShoulder.y * 0.8) {
      return true;
    }
    if (rightHand.y > RightHip.y * 1) {
      return true;
    }
    if (isLeft) {
      if (rightHand.x < rightMidHipShoulderX * 0.6) {
        return true;
      }
      if (rightHand.x > rightMidHipShoulderX * 1.3) {
        return true;
      }
    } else {
      if (rightHand.x > rightMidHipShoulderX * 1.4) {
        return true;
      }
      if (rightHand.x < rightMidHipShoulderX * 0.7) {
        return true;
      }
    }
  }
  if (leftHand.visibility > 0.3) {
    if (leftHand.y < leftShoulder.y * 0.8) {
      return true;
    }
    if (leftHand.y > LeftHip.y * 1) {
      return true;
    }
    if (isLeft) {
      if (leftHand.x < leftMidHipShoulderX * 0.6) {
        return true;
      }
      if (leftHand.x > leftMidHipShoulderX * 1.3) {
        return true;
      }
    } else {
      if (leftHand.x > leftMidHipShoulderX * 1.4) {
        return true;
      }
      if (leftHand.x < leftMidHipShoulderX * 0.7) {
        return true;
      }
    }
  }
}

export function checkArmsUsedFront(results) {
  const leftShoulder = results.poseLandmarks[11];
  const rightShoulder = results.poseLandmarks[12];
  const LeftHip = results.poseLandmarks[23];
  const RightHip = results.poseLandmarks[24];
  const leftMidHipShoulderX = (leftShoulder.x + LeftHip.x * 2) / 3;
  const rightMidHipShoulderX = (rightShoulder.x + RightHip.x * 2) / 3;
  const leftHand = results.poseLandmarks[15];
  const rightHand = results.poseLandmarks[16];
  if (rightHand.visibility > 0.3) {
    // if (rightHand.y < rightShoulder.y) {
    //   return true;
    // }
    if (rightHand.y > RightHip.y * 0.9) {
      return true;
    }
    if (rightHand.x < rightMidHipShoulderX * 0.7) {
      return true;
    }
    if (rightHand.x > rightMidHipShoulderX * 1.4) {
      return true;
    }
  }
  if (leftHand.visibility > 0.3) {
    // if (leftHand.y < leftShoulder.y) {
    //   return true;
    // }
    if (leftHand.y > LeftHip.y * 0.9) {
      return true;
    }
    if (leftHand.x < leftMidHipShoulderX * 0.7) {
      return true;
    }
    if (leftHand.x > leftMidHipShoulderX * 1.4) {
      return true;
    }
  }
}
