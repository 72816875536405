import React from 'react';
import { useSelector } from 'react-redux';
import { useStretchCountCoreFeedback } from '../../../../hooks/Demo/feedbackTypes';
import { loadExerciseConfigAudio } from '../../../../utilities/Demo/audioFeedback';
import FeedbackGenerator from './FeedbackGenerator';
import { countScore } from '../../../../utilities/Demo/ExerciseManagement/Generators/scoreComputations';

function calculatePoints(reachedCentimeters) {
  return reachedCentimeters / 14;
}

export default function StretchCountFeedbackGenerator({
  onComplete,
  config,
  showWebcam,
}) {
  const patientHeight = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values.height
  );
  const iterLanguage = useSelector(({ iter }) => iter.values.language);
  const {
    updateFeedback,
    seconds,
    playAudio,
    audioFeedback,
    setStartingTime,
    condition,
    setSide,
    finishedIntro,
    addCandidate,
    candidates,
    cptScore,
    correctCount,
  } = useStretchCountCoreFeedback({
    onComplete,
    timeThresh: config.timeThresh,
    loadAudio: () => loadExerciseConfigAudio(config, iterLanguage),
    maxScore: config.scoreConfig?.maxScore || 10,
    calculatePoints,
    // clampBestValue: (value) => normStretch({ config, targetRef: value }),
  });
  cptScore.current = countScore({
    config,
    correctCount,
  });

  const onCorrect = ({
    results,
    refValue,
    personHeightPixels,
    // axis,
    stretchTargetAxis,
    framingMotionAxis,
  }) => {
    let candidate = refValue[stretchTargetAxis]
      ? refValue[stretchTargetAxis]
      : refValue;
    let framingMotionCandidate = refValue[framingMotionAxis]
      ? refValue[framingMotionAxis]
      : refValue;

    if (framingMotionAxis === 'motion') {
      framingMotionCandidate =
        Math.abs(candidates.current.slice(-1)[0] - candidate) || 0;
    }

    addCandidate(candidate, framingMotionCandidate);
    setStartingTime(seconds);
    return condition({
      results,
      personHeightPixels,
      personHeightRWU: Number(patientHeight) || 180,
      motion: framingMotionAxis === 'motion',
    });
  };

  return (
    <FeedbackGenerator
      updateFeedback={updateFeedback}
      config={config}
      finishedIntro={finishedIntro}
      onCorrect={onCorrect}
      setSide={setSide}
      playAudio={playAudio}
      audioFeedback={audioFeedback}
      showWebcam={showWebcam}
    />
  );
}
