const initialState = {
  values: { secondsPoseHeld: 0, interruptions: 0, time: 0, feedback: '' },
};

export const balanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_BALANCE_OBSERVATION': {
      return { values: { ...state.values, ...action.payload } };
    }
    case 'BALANCE_FEEDBACK': {
      return { values: { ...state.values, ...action.payload } };
    }
    case 'RESET_BALANCE_OBSERVATION':
      return initialState;
    default: {
      return state;
    }
  }
};
