import React, { useEffect, useState } from 'react';
import FileUpload from '../../../components/Demo/Sports2D/FileUpload';
import FileList from '../../../components/Demo/Sports2D/FileList';
import {
  createProject,
  getProjects,
} from '../../../service/Demo/Sports2D/tasks';
import FirebaseAuthContext from '../../../contexts/auth/FirebaseAuthContext';
import CsvChart from '../../../components/Demo/Sports2D/CsvChart';
import { useSelector } from 'react-redux';
import { getLatestSports2DResults } from '../../../utilities/Demo/Sports2D/firestore';
import VideoPlayer from '../../../components/Demo/Sports2D/VideoPlayer';
import DownloadButton from '../../../components/Demo/Sports2D/DownloadButton';

export default function Sports2DDashboard() {
  const [projects, setProjects] = useState([]);
  const [reloadLastResult, setReloadLastResult] = useState(false);
  const patientInfo = useSelector(
    ({ gaitPatientInfo }) => gaitPatientInfo.values
  );
  const [latestResult, setLatestResult] = useState(null);
  const { user } = React.useContext(FirebaseAuthContext);

  useEffect(() => {
    user.getIdToken().then((token) => {
      getProjects(token).then((res) => {
        const retrievedProjects = res.data.projects;
        if (retrievedProjects.length > 0) {
          setProjects(retrievedProjects);
        } else {
          createProject({
            name: 'Project 1',
            authToken: token,
          }).then((res) => {
            setProjects([res.data]);
          });
        }
      });
    });

    getLatestSports2DResults({
      patientId: patientInfo.id,
      doctorId: patientInfo.doctorId,
    }).then((results) => {
      if (results.length > 0) {
        console.log(results[0], 'LOL');
        setLatestResult(results[0]);
      }
    });
  }, [reloadLastResult]);

  return (
    <div style={{ padding: 30 }}>
      <h1>Angle Dashboard</h1>
      <FileList projectId={projects[0]?.id} />
      <FileUpload
        projectId={projects[0]?.id}
        setReload={() => setReloadLastResult(!reloadLastResult)}
      />
      <CsvChart anglesFileId={latestResult?.angles_file_id} />
      <DownloadButton
        filePath={'files/' + latestResult?.angles_file_id}
        buttonLabel={'Download CSV'}
      />
      <VideoPlayer fileId={latestResult?.video_file_id} />
      <DownloadButton
        filePath={'files/' + latestResult?.video_file_id}
        buttonLabel={'Download Video'}
      />
    </div>
  );
}
