import { CircularProgress, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useSelector } from 'react-redux';
import ResultBox from './ResultBox';

export function MetricsItem({ title, value, titleFontSize = 12 }) {
  return (
    <Grid item md={4} xs={4}>
      <Paper sx={{ mb: 2, mt: 2 }}>
        <Grid container spacing={2} sx={{ padding: 2, paddingTop: 0 }}>
          <Grid item xs={6} sm={6} md={6}>
            <ResultBox
              value={title}
              color={'#f1a60c'}
              fontSize={titleFontSize}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <ResultBox
              value={
                typeof value === 'number'
                  ? Math.round(value * 100) / 100
                  : value
              }
              color={'#f1730c'}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default function ResultsMetrics() {
  const gait = useSelector(({ gait }) => gait.values);
  const gaitResults = useSelector(({ gaitResults }) => gaitResults);

  if (!gaitResults.metrics) return <CircularProgress />;

  return (
    <Grid container spacing={0}>
      <MetricsItem title="Steps left" value={gait.stepsLeft} />
      <MetricsItem title="Steps right" value={gait.stepsRight} />
      <MetricsItem title="Cadence [m/s]" value={gaitResults.metrics.cadence} />
      <MetricsItem
        title="Stride time left [s]"
        value={gaitResults.metrics.strideTimeLeft}
      />
      <MetricsItem
        title="Stride time right [s]"
        value={gaitResults.metrics.strideTimeRight}
      />
      <MetricsItem
        title="Stride time symmetry index [%]"
        value={gaitResults.metrics.strideTimeSymmetryIndex}
      />
      <MetricsItem
        title="Step length left [cm]"
        value={gaitResults.metrics.stepLengthLeft}
      />
      <MetricsItem
        title="Step length right [cm]"
        value={gaitResults.metrics.stepLengthRight}
      />
      <MetricsItem
        title="Step length symmetry index [%]"
        value={gaitResults.metrics.stepLengthSymmetryIndex}
      />
      <MetricsItem
        title="Stance time left [s]"
        value={gaitResults.metrics.stanceTimeLeft}
      />
      <MetricsItem
        title="Stance time right [s]"
        value={gaitResults.metrics.stanceTimeRight}
      />
      <MetricsItem
        title="Stance time symmetry index [%]"
        value={gaitResults.metrics.stanceTimeSymmetryIndex}
      />
      <MetricsItem
        title="Speed [m/s]"
        value={gaitResults.metrics.walkingSpeed}
      />
    </Grid>
  );
}
